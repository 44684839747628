<template>
  <div>
    <PDropdown
      v-if="connected"
      :onOpen="() => toggleWalletBlur(true)"
      :onClose="() => toggleWalletBlur(false)"
      :class="{ 'z-20': walletBlurShow }"
      dataCy="nav-bar_connect-wallet-dropdown-button"
    >
      <template v-slot:button>
        <div
          class="flex items-center justify-between rounded-full sm:rounded py-2 px-2.5 sm:py-2.5 sm:px-4 bg-background-2 space-x-3 sm:space-x-7 text-white text-sm border"
        >
          <div class="flex space-x-2 items-center">
            <ExclamationTriangleSolidIcon
              v-if="needToShowTermsButton"
              class="h-4 text-warning"
            />
            <img
              v-else-if="label === 'MetaMask'"
              class="w-3"
              src="@/assets/metamask-logo.svg"
              alt="metamask"
            />
            <img
              v-else
              class="w-3"
              src="@/assets/wallet-connect-logo.svg"
              alt="walletconnect"
            />
            <span
              v-if="isRightNetwork"
              data-testid="navbar:wallet-button-address"
              class="text-xs sm:text-sm"
            >
              {{ formattedAddress }}
            </span>
            <span
              class="wrong-network text-xs sm:text-sm whitespace-nowrap"
              v-else
            >
              Wrong Network
            </span>
          </div>
          <ChevronDownIcon
            class="h-4 text-inactive rounded-full bg-background-3"
          />
        </div>
      </template>
      <template v-slot:header v-if="needToShowTermsButton">
        <div class="flex flex-col gap-2 -mx-3">
          <span class="text-xs"
            >Welcome back! You still need to confirm the terms to start.</span
          >
          <PButton
            size="sm"
            class="w-full"
            data-testid="show-terms"
            @click="viewTermsClick"
            data-cy="nav-bar_view-terms-button"
          >
            View Terms
          </PButton>
        </div>
      </template>
      <template v-slot:main>
        <PDropdownItem
          v-if="!isRightNetwork"
          @click="switchNetwork"
          data-cy="nav-bar_switch-network-button"
          class="flex space-x-3 text-warning bg-warning bg-opacity-10 hover:text-warning"
        >
          <ExclamationTriangleIcon class="h-4" />
          <span class="text-xs">Switch Network</span>
        </PDropdownItem>
        <PDropdownItem
          @click="openInExplorer"
          data-cy="nav-bar_open-in-bscscan-button"
          class="flex space-x-3 text-inactive"
        >
          <img
            src="@/assets/bscscan-logo-circle.svg"
            alt="BSCScan"
            class="h-4"
          />
          <span class="text-xs">Open in BSCscan</span>
        </PDropdownItem>
        <PDropdownItem
          @click="toggleMailSettingsDialog(true)"
          data-cy="nav-bar_mail-settings-button"
          class="flex space-x-3 text-inactive"
        >
          <AdjustmentsVerticalIcon class="h-4" />
          <span class="text-xs">Mail Settings</span>
        </PDropdownItem>
        <PDropdownItem
          @click="disconnect"
          data-cy="nav-bar_disconnect-button"
          class="flex space-x-3 text-inactive"
        >
          <ArrowRightOnRectangleIcon class="h-4" />
          <span class="text-xs">Disconnect</span>
        </PDropdownItem>
      </template>
    </PDropdown>
    <PButton
      v-else
      size="sm"
      class="flex-1"
      data-testid="connect-wallet"
      @click="connect"
      data-cy="nav-bar_connect-wallet-button"
    >
      Connect Wallet
    </PButton>
    <Transition name="fade">
      <div
        v-show="walletBlurShow"
        class="fixed inset-0 z-15 flex justify-center items-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"
      />
    </Transition>
  </div>
</template>
<script>
import { updateAccount } from '../../hooks/useAccount'
import { useDialogs } from '@/hooks/useDialogs'
import {
  resetPosition,
  subscribeActivePosition,
  unsubscribeActivePosition,
  subscribeHistoricalPositions,
  unsubscribeHistoricalPositions
} from '@/hooks/useUserPosition'
import {
  subscribeGlobalPositions,
  unsubscribeGlobalPositions
} from '@/hooks/useGlobalPosition'
import { subscribeAmm, unsubscribeAmm } from '@/hooks/useAMM'
import {
  resetOrders,
  subscribeOrders,
  unsubscribeOrders
} from '@/hooks/useOrders'
import { subscribeLiquidations } from '@/hooks/useLiquidations'
import { subscribeTrades } from '@/hooks/useTrades'
import { useAppState } from '@/hooks/useAppState'
import {
  fetchGlobalState,
  fetchLeaderboard
} from '@/hooks/useCompetitionPositions'
import { mapState } from 'vuex'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import {
  ArrowRightOnRectangleIcon,
  AdjustmentsVerticalIcon,
  ExclamationTriangleIcon
} from '@heroicons/vue/24/outline'
import { ExclamationTriangleIcon as ExclamationTriangleSolidIcon } from '@heroicons/vue/20/solid'
import { FEATURE_FLAGS } from '@/config/constants'
import { isFeatureEnabled } from '@/utils/utils'
import {
  subscribeTraderDayData,
  subscribeTrader30DaysData,
  unsubscribeTraderDayData,
  unsubscribeTrader30DaysData
} from '@/hooks/useTraderDayData'
import {
  unsubscribeRewardsMilestonesData,
  subscribeRewardsWalletData,
  subscribeRewardsMilestonesData,
  unsubscribeRewardsWalletData
} from '@/hooks/useRewards'
import { PButton, PDropdown, PDropdownItem } from '../../../palmswap-vue-ui'
import { addressUrl } from '@/helpers'
import { useInvite } from '@/hooks/useInvite'
import { subscribeSponsors } from '@/hooks/useSponsors'
import { subscribeStakes } from '@/hooks/useStakes'
import { useLaunchpadReferrals } from '@/hooks/useLaunchpad'
import { useReferrals, subscribeReferral } from '@/hooks/useReferrals'
import {
  subscribeWalletInfo,
  unsubscribeWalletInfo
} from '@/hooks/useWalletInfo'
import { useStore } from 'vuex'
import { isMainnet } from '@/helpers/index'

export default {
  components: {
    PButton,
    PDropdown,
    PDropdownItem,
    ExclamationTriangleIcon,
    ChevronDownIcon,
    AdjustmentsVerticalIcon,
    ArrowRightOnRectangleIcon,
    ExclamationTriangleSolidIcon
  },
  data() {
    return {
      walletBlurShow: false
    }
  },
  computed: {
    ...mapState({
      connected: (state) => state.web3Modal.connected,
      account: (state) => state.user.address.account,
      walletAddress: (state) => state.user.address.wallet,
      needToShowTAC: (state) => state.user.needToShowTAC,
      isRightNetwork: (state) =>
        state.web3Modal.chainId === Number(process.env.VUE_APP_NETWORK_ID),
      notificationsIds: (state) => state.notifications.ids,
      authToken: (state) => state.web3Modal.authToken,
      label: (state) => state.web3Modal.label
    }),
    needToShowTermsButton() {
      return !this.authToken || this.needToShowTAC
    },
    formattedAddress() {
      if (this.account !== '') {
        return `${this.account.slice(0, 6)}...${this.account.slice(
          this.account.length - 4,
          this.account.length
        )}`
      }
      return ''
    },
    launchpadReferrals() {
      return this.referralsCount || 0
    },
    allReferrals() {
      if (this.referrals && this.launchpadReferrals) {
        return this.referrals + this.referralsCount
      } else return 0
    }
  },
  setup() {
    const {
      toggleTermsAndConditionsDialog,
      toggleBCSOfflineDialog,
      toggleMailSettingsDialog,
      toggleLinkWalletDialog
    } = useDialogs()
    const { pairSymbol } = useAppState()
    const { storeReferral } = useInvite()
    const { referralsCount } = useLaunchpadReferrals()
    const { referrals } = useReferrals()
    const { state } = useStore()

    if (state.user.address.account) {
      subscribeSponsors(state.user.address.account)
      subscribeReferral(state.user.address.account)
    }

    return {
      pairSymbol,
      storeReferral,
      toggleTermsAndConditionsDialog,
      toggleBCSOfflineDialog,
      toggleMailSettingsDialog,
      toggleLinkWalletDialog,
      referrals,
      referralsCount,
      isMainnet
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch('initialize')
    })

    subscribeAmm(this.pairSymbol)
    subscribeGlobalPositions(this.pairSymbol)
    fetchGlobalState()
    fetchLeaderboard()

    if (isFeatureEnabled(FEATURE_FLAGS.TRADING_REWARDS)) {
      subscribeRewardsMilestonesData()
    }
  },
  unmounted() {
    unsubscribeActivePosition()
    unsubscribeTraderDayData()
    unsubscribeTrader30DaysData()
    unsubscribeHistoricalPositions()
    unsubscribeGlobalPositions()
    unsubscribeAmm()
    unsubscribeOrders()

    if (isFeatureEnabled(FEATURE_FLAGS.TRADING_REWARDS)) {
      unsubscribeRewardsWalletData()
      unsubscribeRewardsMilestonesData()
    }

    unsubscribeWalletInfo()
  },
  methods: {
    viewTermsClick() {
      if (this.needToShowTAC) {
        this.toggleTermsAndConditionsDialog(true)
        return
      }
      if (!this.authToken) {
        this.toggleLinkWalletDialog(true)
      }
    },
    toggleWalletBlur(blur) {
      this.walletBlurShow = blur
    },
    async connect() {
      if (isFeatureEnabled(FEATURE_FLAGS.OFFLINE_POPUP)) {
        this.toggleBCSOfflineDialog(true)
      } else {
        await this.$store.dispatch('connect')
        if (this.needToShowTAC) {
          this.toggleTermsAndConditionsDialog(true)
        }
      }
    },
    async disconnect() {
      await this.$store.dispatch('resetApp')
      this.$store.commit('stopScheduler')
      this.$notify({
        title: 'Disconnected from wallet',
        type: 'success'
      })
      this.notificationsIds.map((id) => {
        this.$notify.close(id)
      })
      this.$store.commit('clearIds')
    },
    openInExplorer() {
      window.location.href = addressUrl(this.walletAddress)
    },
    async switchNetwork() {
      await this.$store.dispatch('changeNetwork')
    }
  },
  watch: {
    needToShowTAC(newValue) {
      if (newValue) {
        this.toggleTermsAndConditionsDialog(true)
      } else {
        this.toggleTermsAndConditionsDialog(false)
      }
    },
    walletAddress(newValue) {
      if (newValue === '') {
        resetPosition()
        resetOrders()
      } else {
        subscribeWalletInfo(newValue)
        updateAccount({ address: this.account, smartWallet: newValue })
        subscribeActivePosition(this.pairSymbol)
        subscribeHistoricalPositions(this.pairSymbol)
        subscribeLiquidations(this.pairSymbol)
        subscribeTraderDayData(newValue)

        if (isFeatureEnabled(FEATURE_FLAGS.TRADING_REWARDS)) {
          subscribeRewardsWalletData(newValue)
        }

        subscribeTrader30DaysData(newValue)
        subscribeTrades(this.pairSymbol, newValue)
        this.$notify({
          title: `Welcome ${this.formattedAddress}`,
          type: 'success'
        })
        const referrals = JSON.parse(localStorage.getItem('referrals')) || 0
        const referralId = 'referral'
        if (this.allReferrals && this.allReferrals > referrals) {
          localStorage.setItem('referrals', JSON.stringify(this.allReferrals))
          this.$store.commit('addId', referralId)
          this.$notify({
            id: referralId,
            title: 'You have new Referrals',
            type: 'referral',
            data: {
              kind: 'referral',
              body: `Since your last login you have ${this.allReferrals} new Referrals.`
            }
          })
        }
        this.$store.commit('removeId', referralId)
      }
    },
    account(newValue) {
      if (newValue === '') {
        resetOrders()
      } else {
        updateAccount({ address: newValue, smartWallet: this.walletAddress })
        subscribeSponsors(newValue)
        subscribeReferral(newValue)
        subscribeOrders(newValue)
        this.storeReferral(newValue)
        subscribeStakes(newValue)
        const id = 'Wrong Network Selected'
        this.$store.commit('addId', id)
        if (this.isRightNetwork === false) {
          this.$notify({
            id,
            title: 'Wrong Network Selected',
            type: 'progress',
            duration: -1,
            data: {
              kind: 'priority'
            }
          })
        }
        if (this.isRightNetwork === true) {
          this.$notify.close(id)
          this.$store.commit('removeId', id)
        }
      }
    },
    isRightNetwork(newValue) {
      const id = 'Wrong Network Selected'
      this.$store.commit('addId', id)
      if (newValue === false) {
        this.$notify({
          id,
          title: 'Wrong Network Selected',
          type: 'progress',
          duration: -1,
          data: {
            kind: 'priority'
          }
        })
      }
      if (newValue === true) {
        this.$notify.close(id)
        this.$store.commit('removeId', id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wallet-blur-class {
  top: 58px;
  right: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 92px);
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.wallet-button {
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 12px;
  padding: 6px 16px;
  border-radius: 4px;
  & > span {
    margin: 0 8px;
  }
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
.dropdown-item {
  color: var(--gray80);
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  font-size: 14px;
  color: var(--text);
  text-decoration: none;
  cursor: pointer;
  & > img {
    margin-right: 24px;
    width: 32px;
    height: 32px;
  }
  &:hover {
    color: white;
    background-color: var(--gray60);
  }
}
</style>
<style lang="scss">
.walletconnect-modal__header {
  img,
  p {
    display: none !important;
  }
}
.walletconnect-modal__single_wallet a {
  color: #735bd3 !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  &:before {
    background: url('../../assets/metamask-logo.svg');
    display: inline-block;
    width: 20px;
    margin-right: 5px;
    height: 20px;
    content: ' ';
  }
}
.walletconnect-qrcode__text {
  color: #6c7284 !important;
}
.web3modal-modal-lightbox {
  background-color: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(4px);
  z-index: 10 !important;
}
.web3modal-modal-container {
  .web3modal-provider-wrapper {
    border: none;
    align-items: start;
    height: fit-content !important;
    &:hover {
      .web3modal-provider-container {
        background: #212327;
      }
    }
  }
  .web3modal-provider-icon {
    width: 45px;
    height: 20px;
  }
  .web3modal-provider-container {
    padding: 24px 16px;
    background: #212327;
    border: 1px solid #23252b;
    border-radius: 50px !important;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    border-radius: 3px;
    height: 50px;
  }
  .web3modal-provider-container:after {
    font-size: 12px;
    content: 'Connect' !important;
    color: #6c7284;
  }
  .web3modal-provider-description {
    display: none;
  }
  .web3modal-provider-name {
    font-size: 14px;
    font-weight: 500;
    margin-top: 0px;
    color: white;
    width: fit-content !important;
    margin-right: auto !important;
  }
  .web3modal-modal-card {
    background: #14151a;
    border-radius: 20px;
    height: 230px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.walletconnect-modal__mobile__toggle {
  color: #6c7284;
  font-size: medium;
  background: #14151a !important;
  height: 40px;
  .walletconnect-modal__mobile__toggle_selector {
    background: #202327 !important;
    padding: 11px !important;
  }
  & > a {
    padding: 11px !important;
  }
}
.walletconnect-qrcode__text {
  font-size: 14px !important;
  font-weight: normal !important;
}
.walletconnect-search__input {
  color: white;
  height: 40px;
  font-size: 14px !important;
  font-weight: normal !important;
  border-radius: 7px !important;
  border: 1px solid #23252b !important;
  background: #202327 !important;
  padding: 0 12px !important;
}
.walletconnect-search__input::placeholder {
  color: #6c7284 !important;
}
.walletconnect-qrcode__base {
  background-color: transparent !important;
}
.walletconnect-modal__base {
  color: #6c7284 !important;
  border-radius: 20px !important;
  background: #181b1f !important;
}
.walletconnect-modal__footer > a {
  color: #6c7284 !important;
}
.walletconnect-connect__buttons__wrapper__wrap {
  margin: 0 !important;
}
.walletconnect-connect__button__text {
  font-size: 14px !important;
  font-weight: normal !important;
  color: #6c7284 !important;
  white-space: nowrap;
}
.walletconnect-modal__header {
  top: 31px !important;
  left: -20px !important;
}
.walletconnect-modal__close__wrapper {
  background-color: transparent !important;
}
.walletconnect-modal__close__icon {
  & > div {
    border: 1px solid rgb(255, 255, 255) !important;
  }
}
#walletconnect-qrcode-modal {
  padding: 16px !important;
}

:root {
  --onboard-font-family-normal: Inter, Helvetica, Arial, sans-serif;
  --onboard-font-family-semibold: Inter, Helvetica, Arial, sans-serif;
  --onboard-font-family-light: Inter, Helvetica, Arial, sans-serif;
  --onboard-modal-z-index: 1;
  --onboard-modal-backdrop: rgba(0, 0, 0, 0.7);
  --onboard-modal-border-radius: 20px;
  --onboard-wallet-button-border-radius: 7px;
  --onboard-connect-header-background: #131519;
  --onboard-connect-header-color: white;
  --onboard-modal-color: #6c7284;
  --onboard-modal-background: #191a1f;
  --onboard-main-scroll-container-background: #131519;
  --onboard-close-button-background: #131519;
  --onboard-close-button-color: white;
  --onboard-wallet-button-border-color: #131519;
  --onboard-wallet-button-background: #202327;
  --onboard-wallet-button-color: white;
  --onboard-wallet-button-background-hover: rgba(32, 35, 39, 0.7);
  --onboard-wallet-button-color-hover: #fff;
  --onboard-wallet-app-icon-border-color: transparent;
  --onboard-wallet-app-icon-background-transparent: transparent;
  --onboard-connect-sidebar-background: #191a1f;
  --onboard-connect-sidebar-border-color: #131519;
  --onboard-connect-sidebar-color: #6c7284;
  --onboard-connect-sidebar-progress-background: #23252b;
  --onboard-wallet-app-icon-background-light-gray: #131519;

  --onboard-white: white;
  --onboard-black: black;
  --onboard-primary-1: #735bd3;
  --onboard-primary-100: rgba(115, 91, 211, 0.1);
  --onboard-primary-200: #131519;
  --onboard-primary-300: rgba(115, 91, 211, 0.3);
  --onboard-primary-400: rgba(115, 91, 211, 0.5);
  --onboard-primary-500: #735bd3;
  --onboard-primary-600: rgba(115, 91, 211, 0.8);
  --onboard-primary-700: rgba(115, 91, 211, 1);
  --onboard-gray-100: #ebebed;
  --onboard-gray-200: #c2c4c9;
  --onboard-gray-300: #999ca5;
  --onboard-gray-400: #707481;
  --onboard-gray-500: #33394b;
  --onboard-gray-600: white;
  --onboard-gray-700: white;
  --onboard-success-100: rgba(0, 192, 117, 0.1);
  --onboard-success-200: rgba(0, 192, 117, 0.2);
  --onboard-success-300: rgba(0, 192, 117, 0.3);
  --onboard-success-400: rgba(0, 192, 117, 0.5);
  --onboard-success-500: rgba(0, 192, 117, 0.7);
  --onboard-success-600: rgba(0, 192, 117, 0.8);
  --onboard-success-700: rgba(0, 192, 117, 1);
  --onboard-danger-100: rgba(253, 64, 64, 0.1);
  --onboard-danger-200: rgba(253, 64, 64, 0.2);
  --onboard-danger-300: rgba(253, 64, 64, 0.3);
  --onboard-danger-400: rgba(253, 64, 64, 0.5);
  --onboard-danger-500: rgba(253, 64, 64, 0.7);
  --onboard-danger-600: rgba(253, 64, 64, 0.8);
  --onboard-danger-700: rgba(253, 64, 64, 1);
  --onboard-warning-100: rgba(241, 203, 128, 0.1);
  --onboard-warning-200: rgba(241, 203, 128, 0.2);
  --onboard-warning-300: rgba(241, 203, 128, 0.3);
  --onboard-warning-400: rgba(241, 203, 128, 0.5);
  --onboard-warning-500: rgba(241, 203, 128, 0.7);
  --onboard-warning-600: rgba(241, 203, 128, 0.8);
  --onboard-warning-700: rgba(241, 203, 128, 1);
}

.wrong-network {
  background: #dd4141;
  color: white;
  padding: 4px 10px;
  border-radius: 12px;
}
</style>
