import { BLOCKS_MINTED_COUNT, STAKING_PERIODS } from '@/config/constants'
import { correctDecimalPlaces } from '@/contracts/helpers'

export function apr(totalStaked, poolInfo, stakingPeriod = STAKING_PERIODS[3]) {
  if (totalStaked > 0 && poolInfo) {
    return (
      (BLOCKS_MINTED_COUNT *
        correctDecimalPlaces(poolInfo.rewardPerBlock) *
        stakingPeriod.fractionOfYear) /
      totalStaked
    )
  } else {
    return null
  }
}

export function roi(
  stake,
  totalCurrentlyStaked,
  poolInfo,
  stakingPeriod = STAKING_PERIODS[3]
) {
  const _stake = Number(stake)
  const _totalCurrentlyStaked = Number(totalCurrentlyStaked)
  return (
    ((BLOCKS_MINTED_COUNT *
      (poolInfo ? correctDecimalPlaces(poolInfo.rewardPerBlock) : 1) *
      stakingPeriod.fractionOfYear) /
      (_totalCurrentlyStaked + _stake)) *
    _stake
  )
}
