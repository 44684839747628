<template>
  <button
    :disabled="disabled"
    class="flex-1 border text-xs rounded-sm py-1 cursor-pointer disabled:opacity-75"
    :class="selected ? 'bg-main text-white' : 'bg-background-1'"
  >
    {{ value }}
  </button>
</template>
<script>
export default {
  props: {
    value: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    selected: {
      type: Boolean
    }
  }
}
</script>
