import { computed, ref, watch } from 'vue'
import { apiGatewayClient } from '@/api/gateway'
import { useStore } from 'vuex'

export const STORAGE_KEY = 'referrerAddress'

const referrerAddressRef = ref(localStorage.getItem(STORAGE_KEY))
const setReferrerAddress = (address) => {
  referrerAddressRef.value = address
  localStorage.setItem(STORAGE_KEY, address)
}

const resetReferrerAddress = () => {
  localStorage.removeItem(STORAGE_KEY)
}

const fetchReferral = async (user) => {
  return await apiGatewayClient
    .get(`/referrals/${user.toLowerCase()}`)
    .then(({ data }) => data)
}

const storeReferral = async (user) => {
  const referrer = referrerAddressRef.value
  if (!referrer || !user) {
    return
  }
  try {
    const userLoweCased = user.toLowerCase()
    const referrerLowerCased = referrer.toLowerCase()
    if (userLoweCased === referrerLowerCased) {
      throw new Error("Can't invite own account")
    }
    await apiGatewayClient.post('/referrals', {
      user: userLoweCased,
      referrer: referrerLowerCased
    })
  } catch (e) {
    console.error('Failed to store referral', e)
  } finally {
    resetReferrerAddress()
  }
}

export const useInvite = () => {
  const referrerAddress = computed(() => referrerAddressRef.value)
  const isInvited = computed(() => !!referrerAddressRef.value)

  const { state } = useStore()
  const address = computed(() => state.user.address.account)

  watch(address, async (address) => {
    const referral = await fetchReferral(address)
    if (referral) {
      referrerAddressRef.value = referral.referrer
    }
  })

  return {
    isInvited,
    referrerAddress,
    storeReferral,
    setReferrerAddress,
    resetReferrerAddress
  }
}
