export const PositionSides = {
    SIDE_SHORT: 1,
    SIDE_LONG: 0
};
export const PositionPnlCalcOption = {
    SPOT_PRICE: 0,
    TWAP: 1
};
export const PositionDirection = {
    ADD_TO_AMM: '0',
    REMOVE_FROM_AMM: '1'
};
