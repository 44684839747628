<template>
  <PNotification
    :type="props.item.type"
    @close="closeNotification"
    :closable="true"
  >
    <template v-slot:header>
      <div class="flex items-center gap-2.5">
        <img class="w-7 h-7" :src="icon" />
        <div>{{ props.item.title }}</div>
        <span
          :class="{
            short: props.item.data.side === SIDE_SHORT,
            long: props.item.data.side !== SIDE_SHORT
          }"
          >{{ props.item.data.side === SIDE_SHORT ? 'Sell' : 'Buy' }}</span
        >
      </div>
    </template>
    <template v-slot:body>
      <div class="flex justify-between mb-2">
        <div>Size</div>
        <div class="flex flex-row gap-1.5">
          <div class="text-white">{{ props.item.data.size }}</div>
          {{ currencySymbol }}
        </div>
      </div>
      <div class="flex justify-between">
        <div>
          {{ props.item.data.type === 'not market' ? 'Order Price' : 'Price' }}
        </div>
        <div v-if="props.item.data.type === 'not market'">
          ${{ props.item.data.price }}
        </div>
        <div v-if="props.item.data.type === 'market'">{{ 'Market' }}</div>
        <div v-if="!props.item.data.type">-</div>
      </div>
    </template>
  </PNotification>
</template>
<script>
import { computed } from 'vue'
import { PNotification } from '../../../palmswap-vue-ui'
import { SIDE_SHORT } from '../../contracts/helpers'
import { useAppState } from '../../hooks/useAppState'

export default {
  components: {
    PNotification
  },
  props: {
    props: {}
  },
  setup() {
    const { currencySymbol } = useAppState()
    const icon = computed(() => {
      if (currencySymbol.value === 'BTC') {
        return '/img/btc.svg'
      } else if (currencySymbol.value === 'ETH') {
        return '/img/eth.svg'
      } else {
        return null
      }
    })
    return {
      icon,
      currencySymbol,
      SIDE_SHORT
    }
  },
  methods: {
    closeNotification() {
      this.$emit('closeNotification')
    }
  }
}
</script>
<style lang="css" scoped>
@tailwind components;

@layer components {
  .short {
    @apply bg-dark-red text-red rounded-xs py-0.5 px-1.5 mx-1 select-none;
  }
  .long {
    @apply bg-dark-green text-green rounded-xs py-0.5 px-1.5 mx-1 select-none;
  }
}
</style>
