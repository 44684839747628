import { reactive, computed } from 'vue'

import { useDialogs } from './useDialogs'

export const PENDING = 'Pending'
export const FAILED = 'Failed'
export const SUCCESS = 'Success'

const { toggleTransactionDialog } = useDialogs()

const transactionDialogState = reactive({
  state: PENDING,
  transactionHash: ''
})

const reset = () => {
  transactionDialogState.state = PENDING
  transactionDialogState.transactionHash = ''
}

const setTransactionState = (state) => {
  transactionDialogState.state = state
}
const setTransaction = (tx) => {
  transactionDialogState.transactionHash = tx
}

export const openTransactionDialog = async (state = PENDING, transaction) => {
  setTransaction(transaction)
  setTransactionState(state)
  toggleTransactionDialog(true)
}

export const closeTransactionDialog = () => {
  toggleTransactionDialog(false)
  reset()
}

export const useTransactionDialog = () => {
  return {
    setTransaction,
    setTransactionState,
    state: computed(() => transactionDialogState.state),
    transaction: computed(() => transactionDialogState.transactionHash)
  }
}
