import { createStore } from 'vuex'
import web3ModalStore from '@/store/modules/web3Modal'
import userStore from '@/store/modules/user'
import notificationsStore from '@/store/modules/notifications'
import stakingStore from '@/store/modules/staking'
import tradeStore from '@/store/modules/trade'

const store = createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    web3Modal: web3ModalStore,
    user: userStore,
    notifications: notificationsStore,
    staking: stakingStore,
    tradeStore: tradeStore
  }
})

export default store
