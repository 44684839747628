import Big from 'big.js'

import { utils } from 'ethers'
import { ERC20_DECIMAL_DIGITS } from './constants'
import {
  MAINTENANCE_MARGIN_RATIO,
  LOWER_MARGIN_RATIO
} from '../config/constants'
import metadata from '@/contracts/metadata'
import { currencies } from '@/hooks/useAppState'
import { getChainDetails } from '@/utils/chains'

const switchNetwork = async (chainId, provider) => {
  try {
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }]
    })
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        const chainDetails = getChainDetails(chainId)
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [chainDetails]
        })
      } catch (addError) {
        console.error(addError)
      }
    }
  }
}

export const switchToCurrentNetwork = async (provider) =>
  await switchNetwork(process.env.VUE_APP_NETWORK_ID, provider)

export const NETWORK_BINANCE_TESTNET = 'tbsc'
export const NETWORK_BINANCE_MAINNET = 'bsc'

export const SIDE_SHORT = 1
export const SIDE_LONG = 0

export const SHORT = 'short'
export const LONG = 'long'

export const OPEN_SHORT = 'openShort'
export const OPEN_LONG = 'openLong'

export const orderSideLabels = {
  [OPEN_SHORT]: 'Short',
  [OPEN_LONG]: 'Long'
}

export const CLOSE_LONG = 'closeLong'
export const LIQUIDATION = 'liquidation'

export const TAKE_PROFIT = 'takeProfit'
export const STOP_LOSS = 'stopLoss'

export const DEFAULT_DECIMALS = 18
export const MAX_DECIMAL_PLACE = 4

export function getEstimatedBlockTimestamp(
  networkName,
  currentBlock,
  currentTimestamp,
  targetBlock
) {
  let blockDuration = 0
  if (
    networkName === NETWORK_BINANCE_MAINNET ||
    networkName === NETWORK_BINANCE_TESTNET
  ) {
    blockDuration = 3
  } else {
    throw new Error('unsupported network name')
  }

  const offset = (currentBlock - targetBlock) * blockDuration
  return currentTimestamp - offset
}

export function correctDecimalPlaces(rawBN) {
  try {
    return new Big(rawBN).div(new Big(10).pow(18))
  } catch (e) {
    return Big(0)
  }
}

export function formatUSD(rawBN) {
  return correctDecimalPlaces(rawBN).toNumber()
}

export function ratioToPercentage(ratio) {
  return (
    (parseFloat(new Big(ratio).div(utils.parseUnits('1', 14)).toString()) /
      10000) *
    100
  )
}

export function roundAmount(size) {
  const s = new Big(size)
  return s.round(MAX_DECIMAL_PLACE, Big.roundUp)
}

export function big2BigNum(val, decimals = ERC20_DECIMAL_DIGITS) {
  return new Big(val).mul(new Big(10).pow(decimals))
}

export function big2Decimal(val) {
  return {
    d: big2BigNum(val, ERC20_DECIMAL_DIGITS)
  }
}

export const bigNum2Big = (val, decimals = ERC20_DECIMAL_DIGITS) => {
  return new Big(val.toString()).div(new Big(10).pow(decimals))
}

export const decimal2Big = (decimal) => {
  return bigNum2Big(decimal.d)
}

export const toD = (val) => ({ d: val })

export const num2Decimal = (num, decimals = DEFAULT_DECIMALS) => {
  return toD(utils.parseUnits(String(num), decimals))
}

export function getBaseAssetName(ammProps, slash = true) {
  const split = slash ? '/' : ''
  const priceFeedKey = utils.parseBytes32String(ammProps.priceFeedKey)
  return `${priceFeedKey}${split}${ammProps.quoteAssetSymbol}`
}
export function getUnderlyingName(ammProps) {
  return utils.parseBytes32String(ammProps.priceFeedKey)
}

export function getAssetReserve(ammProps) {
  return [
    new Big(ammProps.baseAssetReserve.div(utils.parseUnits('1', 18))),
    new Big(ammProps.quoteAssetReserve.div(utils.parseUnits('1', 18)))
  ]
}

export const getLiquidationPrice = (positionSize, price, leverage) => {
  if (positionSize.gt(0)) {
    return price.mul(
      new Big(1).add(MAINTENANCE_MARGIN_RATIO).sub(new Big(1).div(leverage))
    )
  }
  return price.mul(
    new Big(1).sub(MAINTENANCE_MARGIN_RATIO).add(new Big(1).div(leverage))
  )
}

export const getFullLiquidationPrice = (positionSize, price, leverage) => {
  if (positionSize.gt(0)) {
    return price.mul(
      new Big(1).add(LOWER_MARGIN_RATIO).sub(new Big(1).div(leverage))
    )
  }
  return price.mul(
    new Big(1).sub(LOWER_MARGIN_RATIO).add(new Big(1).div(leverage))
  )
}

export function findCurrencyFromAddress(ammAddress) {
  const ammsArray = Object.entries(metadata.amms).map(([symbol, address]) => {
    return { symbol: symbol, address: address.toLowerCase() }
  })
  const ammSymbol = ammsArray.find(
    (amm) => amm.address === ammAddress.toLowerCase()
  ).symbol

  const currency = currencies.find(
    (currency) => currency.ammSymbol === ammSymbol
  )

  return currency
}

export async function addToken(
  tokenAddress,
  tokenSymbol,
  tokenDecimals,
  tokenImage
) {
  try {
    await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image: tokenImage
        }
      }
    })
  } catch (error) {
    console.log(error)
  }
}
