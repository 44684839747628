import axios from 'axios'
import { getTokenWithExpiry } from '@/helpers/auth'

export const apiGatewayClient = axios.create({
  baseURL: process.env.VUE_APP_API_GATEWAY_URL
})

apiGatewayClient.authorize = (address) => {
  const authToken = getTokenWithExpiry(address)
  if (authToken) {
    apiGatewayClient.defaults.headers['Authorization'] = authToken
  }
  return apiGatewayClient
}
