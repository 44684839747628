<template>
  <notifications position="bottom right" width="370px">
    <template #body="props">
      <div class="notification-body">
        <OrderNotificationVue
          :props="props"
          @closeNotification="props.close"
          v-if="props.item.data.kind === 'order'"
        />

        <MarginNotificationVue
          :props="props"
          @closeNotification="props.close"
          v-else-if="props.item.data.kind === 'margin'"
        />

        <PNotification
          :type="props.item.data.kind"
          v-else-if="props.item.data.kind === 'priority'"
        >
          <template v-slot:header>{{ props.item.title }}</template>
          <template v-slot:body>
            <div class="mb-5">
              Click
              <a class="text-warning cursor-pointer" @click="changeNetwork"
                >here</a
              >
              to change your Network
            </div>
          </template>
        </PNotification>

        <PNotification
          :type="props.item.type"
          v-else-if="props.item.data.kind === 'close'"
          @close="props.close"
          :closable="true"
        >
          <template v-slot:header>{{ props.item.title }}</template>
          <template v-slot:body>
            <div class="flex justify-between mb-2">
              <div>Size</div>
              <div class="flex flex-row gap-1.5">
                <div class="text-white">{{ props.item.data.size }}</div>
                {{ currencySymbol }}
              </div>
            </div>
            <div class="flex justify-between mb-2">
              <div>Entry Price</div>
              <div>${{ props.item.data.entryPrice }}</div>
            </div>
            <div class="flex justify-between">
              <div>Exit Price</div>
              <div>${{ props.item.data.exitPrice }}</div>
            </div>
          </template>
        </PNotification>

        <PNotification
          :type="props.item.type"
          v-else-if="props.item.data.kind === 'referral'"
        >
          <template v-slot:header>
            <div class="flex w-full items-center justify-between">
              <div>{{ props.item.title }}</div>
              <div class="text-2xl">🎉</div>
            </div>
          </template>
          <template v-if="props.item.data.body" v-slot:body>{{
            props.item.data.body
          }}</template>
        </PNotification>

        <PNotification
          :type="props.item.type"
          v-else-if="props.item.data.kind === 'faucet'"
        >
          <template v-slot:header>{{ props.item.title }}</template>
          <template v-if="props.item.data" v-slot:body>
            <span v-if="props.item.data.body">{{ props.item.data.body }}</span>
            <span v-if="props.item.data.currencySymbol">
              {{ props.item.data.currencySymbol }} successfully claimed.
            </span>
            <span v-if="props.item.data.txUrl">
              <a :href="props.item.data.txUrl" target="_blank" class="underline"
                >Click here</a
              >
              to see the transaction.
            </span>
          </template>
        </PNotification>

        <PNotification :type="props.item.type" v-else>
          <template v-slot:header>{{ props.item.title }}</template>
          <template v-if="props.item.data.body" v-slot:body>{{
            props.item.data.body
          }}</template>
        </PNotification>
      </div>
    </template>
  </notifications>
</template>
<script>
import MarginNotificationVue from './MarginNotification.vue'
import OrderNotificationVue from './OrderNotification.vue'
import { PNotification } from '../../../palmswap-vue-ui'
import { useAppState } from '../../hooks/useAppState'

export default {
  components: {
    OrderNotificationVue,
    PNotification,
    MarginNotificationVue
  },
  setup() {
    const { currencySymbol } = useAppState()
    return {
      currencySymbol
    }
  },
  methods: {
    async changeNetwork() {
      await this.$store.dispatch('changeNetwork')
    }
  }
}
</script>
<style lang="scss" scoped>
.notification-body {
  width: 316px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);
  margin: 15px;
  margin-top: 0px;
}
</style>
