<template>
  <button
    :class="{ overlay: overlay, selected }"
    :disabled="disabled"
    @click="this.$emit('onClick')"
  >
    <slot />
  </button>
</template>
<script>
export default {
  props: {
    overlay: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean
    },
    selected: {
      type: Boolean
    }
  }
}
</script>
<style lang="scss" scoped>
button {
  background: transparent;
  outline: none;
  color: var(--text);
  cursor: pointer;
  padding: 4px 10px;
  border-radius: 4px;
  border: solid 1px transparent !important;
  &:hover {
    color: white;
    background: var(--bg);
  }
  &.overlay {
    background-color: var(--bg);
    &:hover {
      background-color: var(--gray40);
    }
  }

  &.selected {
    border: solid 1px white !important;
    &:hover {
      border: solid 1px white;
    }
  }
}
</style>
