import memoize from 'lodash/memoize';
export const getAuth0 = memoize(async () => {
    const auth0 = await import('@auth0/auth0-vue');
    const { app } = await import('@/main');
    if (typeof process.env.VUE_APP_AUTH0_DOMAIN !== 'string' ||
        typeof process.env.VUE_APP_AUTH0_CLIENT_ID !== 'string') {
        throw new Error('Auth0 env variables are not defined');
    }
    const auth0Plugin = auth0.createAuth0({
        domain: process.env.VUE_APP_AUTH0_DOMAIN,
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        redirect_uri: window.location.origin
    });
    app.use(auth0Plugin);
    return {
        authGuard: auth0.createAuthGuard
    };
});
