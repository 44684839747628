<template>
  <div class="flex justify-center h-12">
    <PTabGroup
      background="plain"
      class="text-xs font-medium"
      :activeIndex="selected"
      :items="[
        {
          text: 'Market',
          click: () => {
            setSelected(0)
          }
        },
        {
          text: 'Limit',
          click: () => {
            setSelected(1)
          }
        },
        {
          text: 'Take Profit',
          click: () => {
            setSelected(2)
          }
        },
        {
          text: 'Stop Market',
          click: () => {
            setSelected(3)
          }
        }
      ]"
    />
  </div>
</template>
<script>
import { PTabGroup } from '../../../../palmswap-vue-ui'

export default {
  components: {
    PTabGroup
  },
  props: {
    selected: {
      type: Number
    }
  },
  emits: ['update:selected'],
  methods: {
    setSelected(val) {
      this.$emit('update:selected', val)
    }
  }
}
</script>
