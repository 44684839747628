<template>
  <PDialog
    @close="toggleMakeOrderDialog(false)"
    position="bottom"
    :hideClose="true"
    class="market-order-dialog"
  >
    <MakeOrder
      class="-mt-8 -mx-4"
      :currencySymbol="currencySymbol"
      :pairSymbol="pairSymbol"
    />
  </PDialog>
</template>
<script>
import MakeOrder from './MakeOrder.vue'
import { useDialogs } from '@/hooks/useDialogs'
import { useCurrency } from '@/hooks/useCurrency'
import { PDialog } from '../../../palmswap-vue-ui'

export default {
  components: {
    PDialog,
    MakeOrder
  },
  setup() {
    const { toggleMakeOrderDialog, metadata } = useDialogs()
    const currency = useCurrency()

    const pairSymbol = metadata.value.pairSymbol
      ? metadata.value.pairSymbol
      : currency.value.name

    return {
      toggleMakeOrderDialog,
      pairSymbol
    }
  }
}
</script>
<style lang="scss">
.market-order-dialog {
  .dialog.dialog-bottom {
    max-height: 85vh;
  }
}
</style>
