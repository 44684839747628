import { createApp } from 'vue';
import App from './App.vue';
import './index.css';
import store from './store';
import './registerServiceWorker';
import router from './router';
import Notifications from '@kyvg/vue3-notification';
import '/palmswap-vue-ui/dist/style.css';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { NODE_ENV } from '@/config/constants';
const app = createApp(App);
if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        app,
        release: process.env.VUE_APP_VERSION || 'Version not defined',
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: NODE_ENV,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router)
            })
        ],
        tracesSampleRate: 1.0
    });
}
app.use(router).use(store).use(Notifications).mount('#app');
export { app };
