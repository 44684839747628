<template>
  <div class="px-5 py-3">
    <SideSelector
      v-model:side="side"
      class="mt-3"
      @click="handleNotification"
    />
    <div class="mt-5 mb-1 text-sm"><span>Trigger Price</span>&nbsp;</div>
    <PriceInput
      v-model="limitPrice"
      @update:modelValue="setPriceTouched"
      v-on:keyup="handleNotification"
    />
    <div class="flex justify-between mt-5 mb-1 text-sm">
      <span>Size</span>&nbsp;
      <div>
        Avbl <span class="text-white">{{ formattedBalance }}</span>
      </div>
    </div>
    <AmountInput
      v-model="size"
      :error="formattedError"
      :totalCollateral="totalCollateral"
      :price="limitPrice"
      :currency-symbol="currencySymbol"
    />
    <Leverage class="mt-5" v-model="leverage" :side="side" />
    <OrderDetails
      v-model:totalCost="totalCost"
      v-model:collateral="collateral"
      :side="side"
      :leverage="leverage"
      :size="size"
      :price="limitPrice"
      class="my-5"
    />
    <transition name="fade">
      <div class="warning-notification" v-if="showWarning">
        <p class="warn-title">This order will start to execute immediately</p>
        <p class="warn-description">
          The limit price is {{ side ? 'below' : 'above' }} the current market
          price
        </p>
      </div>
    </transition>
    <Alert
      class="mb-4"
      v-if="error && error.title"
      :title="error.title"
      :message="error.message"
      type="error"
    />
    <Alert
      v-if="ordersDisabled"
      class="mb-4"
      title="Temporarily disabled"
      message="Limit orders are currently disabled, we are improving them and they will be back shortly"
      type="warning"
    />
    <PlaceOrderButtons
      :side="side"
      :totalCost="totalCost"
      :disabled="isDisabled"
      :loading="isLoading"
      @confirm="handleConfirm"
    />
  </div>
</template>
<script>
import { ref, computed, unref } from 'vue'
import Big from 'big.js'
import { useAppState } from '@/hooks/useAppState'
import { useUserPosition } from '@/hooks/useUserPosition'
import { useAMM } from '@/hooks/useAMM'
import { fetchSpotPrice } from '../../../formatters/price'
import { SIDE_LONG, SIDE_SHORT } from '../../../contracts/helpers'
import SideSelector from './SideSelector.vue'
import AmountInput from './AmountInput.vue'
import { addOrder } from '@/hooks/useOrders'
import { openPosition } from '../../../contracts/position'
import { mapState } from 'vuex'
import Alert from '@/components/common/Alert'
import { ammGetIsOverFluctuationLimit } from '@/contracts/amm'
import { FEATURE_FLAGS } from '@/config/constants'
import { isFeatureEnabled } from '@/utils/utils'
import PlaceOrderButtons from '@/components/trade/make-order/PlaceOrderButtons'
import Leverage from '@/components/trade/make-order/Leverage'
import OrderDetails from '@/components/trade/make-order/OrderDetails'
import { useOrderForm } from '@/hooks/useOrderForm'
import PriceInput from '@/components/trade/make-order/PriceInput'

const TAKE_PROFIT = 0
const STOP_LOSS = 1
export default {
  components: {
    PriceInput,
    PlaceOrderButtons,
    Alert,
    SideSelector,
    AmountInput,
    Leverage,
    OrderDetails
  },
  setup(props) {
    const { currencySymbol, pairSymbolAmm, slippage } = useAppState()
    const { rawPosition, currentPosition } = useUserPosition()
    const { markPrice } = useAMM()

    const {
      maxPosition,
      leverage,
      side,
      size,
      totalCollateral,
      price: limitPrice
    } = useOrderForm()
    const collateral = ref(new Big(0))
    const totalCost = ref(new Big(0))

    const spotPrice = ref(0)
    const amount = ref(null)
    const takeProfitStopLossTab = ref(0)
    const tpslEnabled = ref(false)
    const tpslInput = ref(null)

    const error = ref(null)

    return {
      maxPosition,
      error,
      currentPosition,
      side,
      leverage,
      collateral,
      totalCost,
      size,
      totalCollateral,
      SIDE_LONG,
      SIDE_SHORT,
      TAKE_PROFIT,
      STOP_LOSS,
      limitPrice,
      spotPrice,
      pairSymbolAmm: computed(() => {
        if (props.pairSymbol) {
          return props.pairSymbol.replace('/', '')
        }
        return pairSymbolAmm.value
      }),
      currencySymbol: computed(() => {
        if (props.pairSymbol) {
          return props.pairSymbol.split('/')[0]
        }
        return currencySymbol.value
      }),
      slippage,
      amount,
      position: rawPosition,
      takeProfitStopLossTab,
      tpslEnabled,
      tpslInput,
      markPrice
    }
  },
  data() {
    return {
      isChecked: false,
      isLoading: false,
      showWarning: false,
      isPriceTouched: false
    }
  },
  computed: {
    ...mapState({
      walletBalance: (state) => state.user.balance.wallet,
      walletAddress: (state) => state.user.address.wallet,
      signer: (state) => state.web3Modal.provider.getSigner(),
      orderBookPrice: (state) => state.tradeStore.orderBookValues.price
    }),
    ordersDisabled() {
      return !isFeatureEnabled(FEATURE_FLAGS.LIMIT_ORDERS)
    },
    tpSlValidationError() {
      if (!this.tpslEnabled) return null
      if (this.side === SIDE_LONG) {
        if (this.takeProfitStopLossTab === TAKE_PROFIT) {
          if (Number(this.tpslInput) <= Number(this.limitPrice)) {
            return 'Must be higher than price'
          }
        } else {
          if (Number(this.tpslInput) >= Number(this.limitPrice)) {
            return 'Must be lover than price'
          }
        }
      } else {
        if (this.takeProfitStopLossTab === TAKE_PROFIT) {
          if (Number(this.tpslInput) >= Number(this.limitPrice)) {
            return 'Must be lower than price'
          }
        } else {
          if (Number(this.tpslInput) <= Number(this.limitPrice)) {
            return 'Must be higher than price'
          }
        }
      }
      return null
    },
    formattedError() {
      if (typeof this.error !== 'string') {
        return null
      }
      return this.error
    },
    isValid() {
      const CURRENT_PRICE = this.spotPrice
      const limitPriceBN = new Big(this.limitPrice || 0)

      if (this.side === SIDE_LONG) {
        const isValid =
          this.totalCost.lt(this.walletBalance) &&
          limitPriceBN.lt(CURRENT_PRICE) &&
          !this.tpSlValidationError
        return isValid
      } else {
        const isValid =
          this.totalCost.lt(this.walletBalance) &&
          limitPriceBN.gt(CURRENT_PRICE) &&
          !this.tpSlValidationError
        return isValid
      }
    },
    orderValueFormatted() {
      return this.orderValue !== '0.00'
        ? `~${Number(this.orderValue).toFixed(2)} USDT`
        : '-'
    },
    totalCostFormatted() {
      return this.totalCost && this.totalCost !== '0.00'
        ? `${this.totalCost.round(2).toFixed(2)} USDT`
        : '-'
    },
    isDisabled() {
      return (
        this.error ||
        this.isLoading ||
        this.ordersDisabled ||
        !this.size ||
        this.size.lte(0) ||
        this.totalCost.lte(0)
      )
    },
    formattedBalance() {
      return this.walletBalance
        ? `${(+this.walletBalance).toFixed(2)} USDT`
        : '-'
    }
  },
  created() {
    this.setDefaultPrice(this.side)
  },
  async mounted() {
    this.spotPrice = await fetchSpotPrice(this.pairSymbolAmm)
  },
  methods: {
    setPriceTouched() {
      this.isPriceTouched = true
    },
    resetPriceTouched() {
      this.isPriceTouched = false
    },
    formatUSDT(amount) {
      return `${Number(amount).toFixed(2)} USDT`
    },
    updateAmount(fraction) {
      this.amount = fraction * this.walletBalance
    },
    async validate(isDirect) {
      if (!this.size) {
        return null
      }
      const size = this.size.toNumber()
      if (size <= 0) {
        return 'Size must be greater than 0'
      }

      if (isDirect) {
        let s = this.size

        let maxAvailableSize = this.maxPosition
        if (this.currentPosition !== null && maxAvailableSize > 0) {
          if (this.side === this.currentPosition.side) {
            s = s.add(new Big(this.currentPosition.size)).abs()
            maxAvailableSize = new Big(maxAvailableSize)
              .sub(this.currentPosition.size)
              .round(4)
              .toString()
          } else {
            s = s.sub(new Big(this.currentPosition.size)).abs()
            maxAvailableSize = new Big(maxAvailableSize)
              .add(this.currentPosition.size)
              .round(4)
              .toString()
          }
        }

        const totalCost = this.totalCost.toNumber()
        if (totalCost === 0) return 'Total cost must be greater than 0'
        if (totalCost > this.walletBalance)
          return {
            title: 'Insufficient Funds',
            message: 'To trade this size, please deposit more USDT.'
          }
        if (this.maxPosition > 0 && size > this.maxPosition) {
          return `Max position size is ${maxAvailableSize}`
        }
        const isOverFluctuation = await ammGetIsOverFluctuationLimit(
          unref(this.pairSymbolAmm),
          s
        )
        if (isOverFluctuation) {
          return {
            title: 'Maximum size exceeded',
            message:
              'Reduce the trade size or try to divide the quantity into several orders.'
          }
        }

        return null
      } else {
        const totalCost = this.totalCost.toNumber()
        if (totalCost <= 0) return 'Total cost must be greater than 0'
        if (totalCost > this.walletBalance)
          return {
            title: 'Insufficient Funds',
            message: 'To trade this size, please deposit more USDT.'
          }
        return null
      }
    },
    async handleConfirm() {
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      const limitPrice = new Big(this.limitPrice || 0)
      if (
        (this.side === SIDE_LONG && limitPrice.gte(this.markPrice)) ||
        (this.side === SIDE_SHORT && limitPrice.lte(this.markPrice))
      ) {
        await this.openPosition()
      } else {
        await this.addLimitOrder()
      }
    },
    async addLimitOrder() {
      this.error = await this.validate(false)
      if (this.error) {
        return
      }

      const limitPrice = new Big(this.limitPrice || 0)
      const id = Date.now()
      this.$store.commit('addId', id)
      this.$notify({
        id,
        title: 'Open Limit Order',
        duration: -1,
        type: 'progress',
        data: {
          isLimit: true,
          kind: 'order',
          type: 'not market',
          side: this.side,
          price: limitPrice.round(2).toString(),
          size: this.size.toString()
        }
      })
      try {
        await addOrder(this.pairSymbolAmm, this.signer, this.walletAddress, {
          limitPrice: limitPrice.toString(),
          positionSize: this.size.toString(),
          collateral: this.collateral,
          leverage: this.leverage.toString(),
          side: this.side,
          slippage: this.slippage
        })

        this.$notify({
          id,
          title: 'Open Limit Order',
          type: 'success',
          data: {
            kind: 'order',
            type: 'not market',
            side: SIDE_LONG,
            price: limitPrice.round(2).toString(),
            size: this.size.toString()
          }
        })
      } catch (error) {
        console.error(error)
        this.$notify({
          id,
          title: 'Error while opening Limit Order!',
          type: 'error'
        })
      } finally {
        this.isLoading = false
        this.$notify.close(id)
        this.$store.commit('removeId', id)
        this.amount = 0.0
      }
    },
    async openPosition() {
      this.error = await this.validate(true)
      if (this.error) {
        return
      }

      const id = Date.now()
      this.$store.commit('addId', id)
      const pricePerUnit = this.totalCost.toFixed(2)
      try {
        const res = await openPosition(
          this.walletAddress,
          unref(this.pairSymbolAmm),
          this.side,
          this.collateral,
          this.leverage.toString(),
          this.signer,
          this.slippage,
          this.size,
          () => {
            this.$notify({
              id,
              title: 'Directly open Position',
              duration: -1,
              type: 'progress',
              data: {
                kind: 'order',
                type: 'not market',
                side: this.side,
                price: pricePerUnit,
                size: this.size.toFixed(2)
              }
            })
          }
        )
        if (!res.success) {
          this.error = res.error
          return
        }
        await this.$store.dispatch('updateBalance')

        this.$notify({
          id,
          title: 'Directly open Position',
          type: 'success',
          data: {
            kind: 'order',
            type: 'not market',
            side: this.side,
            price: pricePerUnit,
            size: this.size.toFixed(2)
          }
        })
      } catch (error) {
        console.error(error)
        this.$notify({
          id,
          title: 'Error while opening position!',
          type: 'error'
        })
      } finally {
        this.isLoading = false
        this.$notify.close(id)
        this.$store.commit('removeId', id)
        this.amount = 0.0
        this.leverageStr = 1
      }
    },
    handleNotification() {
      if (!this.limitPrice) {
        this.showWarning = false
        return
      }
      const limitPrice = new Big(this.limitPrice || 0)
      if (
        (this.side === SIDE_LONG && limitPrice.gte(this.markPrice)) ||
        (this.side === SIDE_SHORT && limitPrice.lte(this.markPrice))
      ) {
        this.showWarning = true
      } else {
        this.showWarning = false
      }
    },
    setDefaultPrice(side) {
      this.limitPrice =
        side === SIDE_LONG
          ? this.markPrice.minus(1).toFixed(2)
          : this.markPrice.plus(1).toFixed(2)
    }
  },
  props: {
    pairSymbol: {
      type: String,
      default: null
    }
  },
  watch: {
    async totalCost() {
      const limitPrice = new Big(this.limitPrice || 0)
      if (
        (this.side === SIDE_LONG && limitPrice.gte(this.markPrice)) ||
        (this.side === SIDE_SHORT && limitPrice.lte(this.markPrice))
      ) {
        this.error = await this.validate(true)
      } else {
        this.error = await this.validate(false)
      }
    },
    orderBookPrice() {
      this.limitPrice = this.orderBookPrice
    },
    side(newSide) {
      this.setDefaultPrice(newSide)
    },
    markPrice() {
      if (!this.isPriceTouched) {
        this.setDefaultPrice(this.side)
      }
    },
    pairSymbolAmm() {
      this.resetPriceTouched()
    }
  }
}
</script>

<style lang="scss" scoped>
.currency {
  margin-top: 4px;
  margin-right: 11px;
}
.side-select {
  margin-top: 24px;
}

.available-label {
  display: flex;
  align-self: flex-start;
  margin: 24px 0 0 0;
  & span {
    font-size: 12px;
    text-align: left;
    &:nth-child(1) {
      color: var(--text);
    }
    &:nth-child(2) {
      color: white;
    }
  }
}

.stop-loss-label {
  margin: 0;
  font-size: 12px;
  text-align: left;
  margin: 0 0 9px 0;
  display: flex;
  align-items: center;
  color: white;
  & > img {
    margin-left: 4px;
  }
}
.action-buttons {
  display: flex;
  justify-content: space-around;
  &-wrapper {
    width: 100%;
  }
  & > * {
    flex: 1;
  }
}

.order-value {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 24px;
  width: 100%;
}
.total-cost {
  margin: 12px 0;
}
.costs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  & > p {
    font-size: 12px;
    & > span {
      color: white;
    }
  }
}

.limit-tp-sl {
  margin-bottom: 8px;
}

.tabs {
  margin-bottom: 24px;
}

.tpsl-input {
  margin-bottom: 24px;
  width: 100%;
}

.warning-notification {
  font-size: 12px;
  text-align: left;
  background-color: #191919;
  width: 100%;
  padding: 12px 10px;
  border-radius: 5px;
  border-left: 2px solid #ffffff;
  margin-bottom: 10px;
  .warn-title {
    color: #ffffff;
    font-weight: 700;
    margin: 0 0 8px 0;
  }
  .warn-description {
    margin: 0;
  }
}
</style>
