// https://docs.apex.win/apex-docs/developer-guide/smart-contracts
import { utils, constants } from 'ethers';
import { DEFAULT_DECIMALS } from '../helpers';
import { getAmmContract, getSignedContracts, getSmartWalletContract, setSignedContracts } from '../contracts';
import Big from 'big.js';
import { TAKE_PROFIT, STOP_LOSS } from '@/contracts/helpers';
import { ammGetSponsor } from '@/contracts/amm';
export const getSmartWallet = async (signer) => {
    setSignedContracts(signer);
    const { smartWalletFactorySigned } = getSignedContracts();
    const address = await signer.getAddress();
    const smartWallet = await smartWalletFactorySigned.getSmartWallet(address);
    localStorage.setItem('smartWallet.' + address.toLowerCase(), smartWallet);
    return smartWallet;
};
export const createSmartWallet = async (signer, sponsor, callback) => {
    setSignedContracts(signer);
    const { smartWalletFactorySigned } = getSignedContracts();
    const address = await signer.getAddress();
    try {
        const registeredSponsor = await ammGetSponsor(address);
        const tx = await smartWalletFactorySigned.spawn((!registeredSponsor && sponsor) || constants.AddressZero);
        await tx.wait();
    }
    catch (e) {
        if (e.data.message ===
            'execution reverted: SmartWalletFactory: not whitelisted') {
            callback && callback();
        }
        else {
            throw e;
        }
    }
    const smartWallet = await smartWalletFactorySigned.getSmartWallet(address);
    localStorage.setItem('smartWallet.' + address.toLowerCase(), smartWallet);
    return smartWallet;
};
export const deposit = async (amount, signer, smartWallet, progressCallback) => {
    setSignedContracts(signer);
    const { usdtSigned } = getSignedContracts();
    if (progressCallback) {
        progressCallback();
    }
    const depositAmount = utils.parseUnits(amount.toString(), DEFAULT_DECIMALS);
    const transferTx = await usdtSigned.transfer(smartWallet, depositAmount);
    const receipt = await transferTx.wait();
    return receipt;
};
export const withdraw = async (amount, signer, smartWallet, progressCallback) => {
    setSignedContracts(signer);
    const { usdtSigned, smartWalletFactorySigned } = getSignedContracts();
    if (progressCallback) {
        progressCallback();
    }
    const smartWalletContract = getSmartWalletContract(smartWallet, signer);
    const address = await signer.getAddress();
    const withdrawalAmount = utils.parseUnits(amount.toString(), DEFAULT_DECIMALS);
    const whitelisted = await smartWalletFactorySigned.isWhitelisted(usdtSigned.address);
    if (!whitelisted) {
        console.error('Contract not whitelisted in SmartWalletFactory: ', usdtSigned.address);
    }
    return smartWalletContract.transferQuoteToken(address, withdrawalAmount);
};
export const deleteOrder = async (signer, orderID) => {
    setSignedContracts(signer);
    const { limitOrderBookSigned } = getSignedContracts();
    const tx = await limitOrderBookSigned.deleteOrder(orderID);
    const receipt = await tx.wait();
    return receipt;
};
//https://github.com/perpfutui/perpetual-limit-orders/blob/main/contracts/LimitOrderBook.sol#L46
export const OrderType = {
    MARKET: {
        index: 0,
        label: 'MARKET'
    },
    LIMIT: {
        index: 1,
        label: 'LIMIT'
    },
    STOPMARKET: {
        index: 2,
        label: 'STOPMARKET'
    },
    STOPLIMIT: {
        index: 3,
        label: 'STOPLIMIT'
    },
    TRAILINGSTOPMARKET: {
        index: 4,
        label: 'TRAILINGSTOPMARKET'
    },
    TRAILINGSTOPLIMIT: {
        index: 5,
        label: 'TRAILINGSTOPLIMIT'
    }
};
const OrderIndex = {
    0: OrderType.MARKET,
    1: OrderType.LIMIT,
    2: OrderType.STOPMARKET,
    3: OrderType.STOPLIMIT,
    4: OrderType.TRAILINGSTOPMARKET,
    5: OrderType.TRAILINGSTOPLIMIT
};
export const getOrdersForSigner = async (symbol, signer) => {
    const amm = getAmmContract(symbol);
    setSignedContracts(signer);
    const { limitOrderBookSigned } = getSignedContracts();
    const orders = []; // TODO: getOrders function removed - await limitOrderBookSigned.getOrders()
    const filtered = orders
        .map((order, idx) => ({ ...order, id: idx }))
        .filter((order) => order.trader === signer._address && order.asset === amm.address)
        .map((o) => {
        const order = {
            ...o,
            collateral: new Big(o.collateral.toString()).div(10 ** DEFAULT_DECIMALS),
            leverage: new Big(o.leverage.toString()).div(10 ** DEFAULT_DECIMALS),
            orderSize: new Big(o.orderSize.toString()).div(10 ** DEFAULT_DECIMALS),
            limitPrice: new Big(o.limitPrice.toString()).div(10 ** DEFAULT_DECIMALS),
            tipFee: new Big(o.tipFee.toString()).div(10 ** DEFAULT_DECIMALS),
            slippage: new Big(o.slippage.toString()).div(10 ** DEFAULT_DECIMALS),
            type: OrderIndex[o.orderType].label
        };
        return order;
    });
    return filtered;
};
export const getTPSLs = async (symbol, signer, currentPrice) => {
    if (signer) {
        const orders = await getOrdersForSigner(symbol, signer);
        return orders
            .filter((order) => (order.type === OrderType.STOPMARKET.label && order.reduceOnly) ||
            (order.type === OrderType.LIMIT.label && order.reduceOnly))
            .map((o) => ({
            ...o,
            operationType: o.type === OrderType.LIMIT.label ? TAKE_PROFIT : STOP_LOSS
        }));
    }
    else {
        return [];
    }
};
