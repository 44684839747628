const tradeStore = {
  state: {
    orderBookValues: {
      price: 0,
      size: 0
    }
  },
  getters: {},
  mutations: {
    setOrderBookValues(state, payload) {
      state.orderBookValues.price = payload.price
      state.orderBookValues.size = payload.size
    }
  }
}
export default tradeStore
