import castArray from 'lodash/castArray';
/**
 * Creates an always shown wallet with wallet connect workaround:
 * - Always shown wallet connection option
 * - On desktop if wallet extension isn't installed or there is another injected wallet set as default a WalletConnect QR code will be shown.
 * - On mobile, it will redirect to mobile wallet's DApp browser
 */
export const walletWithWalletConnectWorkaroundModule = ({ label, isInterfaceAvailable, walletsInjected, walletConnectModule, openInMobile }) => {
    return ({ device }) => {
        const walletInjected = walletsInjected.find((wallet) => wallet.label === label);
        if (!walletInjected) {
            console.warn(`"${label}" injected wallet wasn't found`);
            return null;
        }
        return {
            label,
            getIcon: walletInjected.getIcon,
            getInterface: async (helpers) => {
                if (isInterfaceAvailable()) {
                    return await walletInjected.getInterface(helpers);
                }
                else if (device.type === 'desktop') {
                    const walletConnect = walletConnectModule({
                        qrcodeModalOptions: {
                            mobileLinks: []
                        }
                    })({ device });
                    if (!walletConnect) {
                        throw new Error('Mo walletConnect');
                    }
                    return castArray(walletConnect)[0].getInterface(helpers);
                }
                else {
                    openInMobile(window.location);
                    throw new Error(`Please install "${label}" and use within the ${label} DApp browser`);
                }
            }
        };
    };
};
