<template>
  <div :class="`alert ${type}`">
    <div class="title">{{ title }}</div>
    <p>
      {{ message }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String
    },
    message: {
      type: String
    },
    type: {
      type: String,
      default: 'info',
      validator: (val) => {
        return ['success', 'info', 'error', 'warning'].includes(val)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.alert {
  font-size: 11px;
  box-sizing: border-box;
  background-color: #191919;
  text-align: left;
  overflow: visible;
  padding: 1rem;
  border-radius: 5px;
  border-style: solid;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 2px;
  border-right-width: 0px;
  .title {
    font-weight: bold;
  }
  &.error {
    border-color: var(--error);
    .title {
      color: var(--error);
    }
  }
  &.warning {
    border-color: var(--warning);
    .title {
      color: var(--warning);
    }
  }
  &.info {
    border-color: white;
    .title {
      color: white;
    }
  }
  &.success {
    border-color: var(--success);
    .title {
      color: var(--success);
    }
  }
  p {
    color: var(--gray20);
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
}
</style>
