<template>
  <PDialog
    title="Referrals"
    position="center"
    :width="width"
    @close="toggleLaunchpadReferralsDialog(false)"
  >
    <div class="flex flex-col space-y-5">
      <PDatePicker
        range
        v-model="dateFilter"
        :initialDate="dateFilter"
        class="md:w-1/2 lg:w-1/3"
      />
      <ReferralsTable :referrals="referralParticipants" />
    </div>
  </PDialog>
</template>

<script>
import { PDialog, PDatePicker } from '../../../palmswap-vue-ui'
import { useDialogs } from '@/hooks/useDialogs'
import { useLaunchpadReferrals } from '@/hooks/useLaunchpad'
import { useStore } from 'vuex'
import { computed, watch, ref } from 'vue'
import { sub, startOfDay, endOfDay, getUnixTime } from 'date-fns'
import ReferralsTable from '@/components/launchpad/ReferralsTable'
import useBreakpoints from '@/hooks/useBreakpoints'
export default {
  components: {
    ReferralsTable,
    PDatePicker,
    PDialog
  },
  setup() {
    const { toggleLaunchpadReferralsDialog } = useDialogs()
    const { referralParticipants, fetchPaidReferrals, affiliate } =
      useLaunchpadReferrals()
    const { state } = useStore()
    const { breakpoints } = useBreakpoints()
    const address = computed(() => state.user.address.account)
    const now = new Date()
    const dateFilter = ref([sub(now, { years: 1 }), now])

    watch(
      [address, dateFilter, affiliate],
      ([address, dateFilter, affiliate]) => {
        if (!address) {
          return
        }
        const [startTime, endTime] = dateFilter
        fetchPaidReferrals(
          address,
          getUnixTime(startOfDay(startTime)),
          getUnixTime(endOfDay(endTime)),
          affiliate.isSalesManager
        )
      },
      { immediate: true }
    )
    return {
      referralParticipants,
      toggleLaunchpadReferralsDialog,
      dateFilter,
      breakpoints,
      affiliate
    }
  },
  computed: {
    width() {
      if (this.breakpoints.lg === true) {
        return '1/2'
      }
      if (this.breakpoints.md === true) {
        return '2/3'
      }
      return 'full'
    }
  }
}
</script>
