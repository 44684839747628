<template>
  <PDialog
    title="Market Close"
    :position="dialogPosition"
    :hideClose="isMobile"
    @close="toggleMarketCloseDialog(false)"
  >
    <div class="flex flex-col space-y-5 w-full sm:w-96">
      <div
        class="border border-gray-800 rounded-lg flex flex-col p-3 space-y-1 text-center"
      >
        <div class="text-sm">Mark Price</div>
        <div class="font-semibold text-xl text-white">
          {{ markPriceFormatted }}
        </div>
      </div>
      <div class="flex flex-col space-y-2 text-sm">
        <div class="flex">
          <div class="flex-1 flex justify-start">Entry Price</div>
          <div class="flex-1 flex justify-end">
            <span class="font-semibold text-white">
              {{ entryPriceFormatted }}
            </span>
            &nbsp;USDT
          </div>
        </div>
        <div class="flex">
          <div class="flex-1 flex justify-start">Exit Price</div>
          <div class="flex-1 flex justify-end">
            <span class="font-semibold text-white">
              {{ exitPriceFormatted }}
            </span>
            &nbsp;USDT
          </div>
        </div>
        <div class="flex">
          <div class="flex-1 flex justify-start">Est. Realized P&amp;L</div>
          <div
            class="flex-1 flex justify-end"
            :class="`${profitSide > 0 && 'text-green'} ${
              profitSide < 0 && 'text-red'
            }`"
          >
            <span class="font-semibold">
              {{ pnl }}
            </span>
            &nbsp;USDT
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-2">
        <p class="text-sm">Close</p>
        <CurrencyInput
          :minValue="0"
          v-model.number="amount"
          dataCy="market-close-dialog_amount-input"
          @update:model-value="handleAmountUpdate"
          :currency="currency"
          :error="error"
        />
        <FractionSelector @update="setPercentage" />
      </div>
      <div class="text-sm text-left" v-if="isPartialClose">
        The position will be partially closed otherwise it can lead to a high
        price impact.
      </div>
      <div class="flex justify-center">
        <PButton
          v-if="!isLoading"
          class="w-full"
          :color="profitSide > 0 ? 'success' : 'danger'"
          @click="handleConfirm"
          :disabled="!isValid"
          data-cy="market-close-dialog_close-position-button"
        >
          Close {{ percentage }}% of Position
        </PButton>
        <transition name="fade">
          <SmallLoader class="my-2.5" v-if="isLoading" />
        </transition>
      </div>
    </div>
  </PDialog>
</template>
<script>
import { computed } from 'vue'
import Big from 'big.js'
import SmallLoader from '../common/SmallLoader'
import { PDialog, PButton } from '../../../palmswap-vue-ui'
import { useDialogs } from '@/hooks/useDialogs'
import { useAppState, currencies } from '@/hooks/useAppState'
import { useAMM } from '@/hooks/useAMM'
import { useUserPosition } from '@/hooks/useUserPosition'
import useBreakpoints from '@/hooks/useBreakpoints'
import { closePosition } from '../../contracts/position'
import { getAmmContractAddress } from '@/contracts/contracts'
import { PARTIAL_LIQUIDATION_RATIO } from '../../config/constants.js'
import { fetchSpotPrice } from '../../formatters/price'
import { formatPnl, formatPositionSize } from '../../utils/utils'
import { mapState } from 'vuex'
import CurrencyInput from '@/components/trade/make-order/CurrencyInput'
import FractionSelector from '@/components/common/FractionSelector'

const formatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: '2',
  minimumFractionDigits: '2'
})
let timeout
export default {
  components: {
    FractionSelector,
    PButton,
    CurrencyInput,
    SmallLoader,
    PDialog
  },
  setup() {
    const LOSS = -1
    const PROFIT = 1
    const { toggleMarketCloseDialog, metadata } = useDialogs()
    const { slippage } = useAppState()
    const pairSymbolAmm = computed(() => {
      if (metadata.value.pairSymbol) {
        return metadata.value.pairSymbol.replace('/', '')
      }
      return ''
    })

    const { getIsOverFluctuationLimit, getAddress, markPrice } = useAMM()
    const { rawPositions } = useUserPosition()
    const rawPosition = computed(
      () => rawPositions.value.find((i) => i.pair === pairSymbolAmm.value) || {}
    )
    const currency = computed(
      () =>
        currencies.find(
          (currency) => currency.ammSymbol === pairSymbolAmm.value
        ) || ''
    )
    const available = computed(() => {
      return formatPositionSize(rawPosition.value?.size.abs())
    })
    const positionSize = computed(() => {
      if (rawPosition.value) {
        return rawPosition.value.size
      } else {
        return new Big(0)
      }
    })
    const exitPrice = computed(() => {
      if (rawPosition.value) {
        return parseFloat(rawPosition.value.exitPrice)
      } else {
        return 0
      }
    })
    const entryPrice = computed(() =>
      rawPosition.value ? rawPosition.value.entryPrice.abs().toNumber() : 0
    )
    const profit = computed(() => {
      return formatPnl(rawPosition.value?.pnl.abs())
    })
    const profitSide = computed(() => {
      return new Big(rawPosition.value?.pnl || 0).gt(0) ? PROFIT : LOSS
    })
    const { isMobile } = useBreakpoints()

    return {
      markPrice,
      positionSize,
      rawPosition,
      profitSide,
      fetchSpotPrice,
      profit,
      entryPrice,
      exitPrice,
      available,
      currency,
      pairSymbolAmm,
      toggleMarketCloseDialog,
      slippage,
      getIsOverFluctuationLimit,
      getAddress,
      isMobile
    }
  },
  async mounted() {
    const spotPrice = await this.fetchSpotPrice(this.pairSymbolAmm)
    this.spotPrice = spotPrice.toNumber()
    await this.updateIsOverFluctuationLimit()
    this.isFetching = false
  },
  unmounted() {
    clearTimeout(timeout)
  },
  data() {
    return {
      isLoading: false,
      isFetching: true,
      spotPrice: 0,
      isOverFluctuationLimit: false,
      percentage: 100,
      amount: this.positionSize.abs().toFixed(4),
      error: null
    }
  },
  computed: {
    ...mapState({
      walletAddress: (state) => state.user.address.wallet,
      signer: (state) => state.web3Modal.provider.getSigner()
    }),
    isValid() {
      return !this.positionSize.eq(0) && !this.error && !this.isFetching
    },
    isPartialClose() {
      return PARTIAL_LIQUIDATION_RATIO !== 0 && this.isOverFluctuationLimit
    },
    pnl() {
      if (this.isPartialClose) {
        return formatPnl(
          this.rawPosition.pnl.abs().toNumber() * PARTIAL_LIQUIDATION_RATIO
        )
      }
      return (
        (this.rawPosition.pnl.abs().toNumber() / 100) *
        this.percentage
      ).toFixed(2)
    },
    exitPriceFormatted() {
      if (this.exitPrice) {
        return ((this.exitPrice / 100) * this.percentage).toFixed(2)
      } else return 0
    },
    entryPriceFormatted() {
      if (this.entryPrice) {
        return ((this.entryPrice / 100) * this.percentage).toFixed(2)
      } else return 0
    },
    markPriceFormatted() {
      return this.markPrice ? `$${formatter.format(this.markPrice)}` : '-'
    },
    dialogPosition() {
      if (this.isMobile) {
        return 'bottom'
      } else {
        return 'center'
      }
    }
  },
  methods: {
    async handleConfirm() {
      const id = Date.now()
      this.$store.commit('addId', id)
      const entryPrice = this.entryPrice.toFixed(2)
      const exitPrice = this.exitPrice.toFixed(2)
      const size = this.amount
      this.$notify({
        id,
        title: 'Close position',
        type: 'progress',
        duration: -1,
        data: {
          kind: 'close',
          size,
          entryPrice,
          exitPrice
        }
      })
      try {
        this.isLoading = true
        const ammAddr = getAmmContractAddress(this.pairSymbolAmm)
        this.toggleMarketCloseDialog(false)
        await closePosition(
          this.walletAddress,
          ammAddr,
          this.signer,
          this.percentage
        )
        await this.$store.dispatch('updateBalance')
        this.$notify({
          id,
          title: 'Close position',
          type: 'success',
          data: {
            kind: 'close',
            size,
            entryPrice,
            exitPrice
          }
        })
      } catch (error) {
        console.error(error)
        this.$notify({
          id,
          title: 'Close position',
          type: 'error',
          data: {
            kind: 'close',
            size,
            entryPrice,
            exitPrice
          }
        })
      } finally {
        this.toggleMarketCloseDialog(false)
        this.$notify.close(id)
        this.$store.commit('removeId', id)
        this.isLoading = false
      }
    },
    async updateIsOverFluctuationLimit() {
      if (!this.positionSize.eq(0) && PARTIAL_LIQUIDATION_RATIO !== 0) {
        this.isOverFluctuationLimit = await this.getIsOverFluctuationLimit(
          this.pairSymbolAmm,
          this.positionSize
        )
        if (this.percentage) {
          this.setPercentage(this.percentage / 100)
        }
      }
    },
    setPercentage(fraction) {
      this.error = null
      let percentage = fraction * 100
      let amount = this.positionSize.mul(fraction).abs().toFixed(4)
      if (
        this.isOverFluctuationLimit &&
        PARTIAL_LIQUIDATION_RATIO !== 0 &&
        fraction >= PARTIAL_LIQUIDATION_RATIO
      ) {
        percentage = PARTIAL_LIQUIDATION_RATIO * 100
        amount = this.positionSize
          .mul(PARTIAL_LIQUIDATION_RATIO)
          .abs()
          .toFixed(4)
      }
      this.percentage = percentage
      this.amount = amount
    },
    handleAmountUpdate(newVal) {
      this.error = null
      if (newVal > this.positionSize.abs()) {
        this.error = "Close amount can't be bigger than position size"
        this.percentage = 100
        return
      }
      const balancePercent = this.positionSize.abs() / 100
      const percentage = newVal / balancePercent
      this.percentage = percentage.toFixed(2)
      const partialLiquidationPercentage = PARTIAL_LIQUIDATION_RATIO * 100
      if (
        this.isOverFluctuationLimit &&
        partialLiquidationPercentage !== 0 &&
        this.percentage > partialLiquidationPercentage
      ) {
        this.error =
          'Price impact is too high. The position can only be partially closed.'
      }
    }
  }
}
</script>
