const notificationsStore = {
  state: {
    ids: []
  },
  getters: {},
  mutations: {
    clearIds(state) {
      state.ids = []
    },
    addId(state, payload) {
      if (!state.ids.includes(payload)) {
        state.ids.push(payload)
      }
    },
    removeId(state, payload) {
      state.ids.filter((id) => id !== payload)
    }
  },
  actions: {}
}
export default notificationsStore
