<template>
  <div class="dialog-wrapper">
    <div class="dialog">
      <div class="connect-dialog">
        <DialogHeader
          title="Thank you to all participants of the trading competition."
          @on-close="toggleBCSOfflineDialog()"
        ></DialogHeader>
        <div class="container">
          <div>
            Palmswap is currently offline due to issues with the BSC testnet
            node, we will be back for you shortly. Check
            <a class="link" href="/leaderboard">here</a> if you are among the
            top 100 traders.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DialogHeader from '@/components/common/DialogHeader.vue'
import { useDialogs } from '@/hooks/useDialogs'
export default {
  components: { DialogHeader },
  setup() {
    const { isBCSOfflineOpen, toggleBCSOfflineDialog } = useDialogs()
    return {
      isBCSOfflineOpen,
      toggleBCSOfflineDialog
    }
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.connect-dialog {
  @apply max-w-full z-[1] bg-[#1f2023] p-6 w-[550px];
}
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  margin-top: 30px;
}

.link {
  color: #5c7ec9;
  cursor: pointer;
}
.dialog {
  @apply min-w-0 rounded-[4px] bg-[#1f2023] border-[1px] border-solid border-[#282828];

  &-wrapper {
    background-color: var(--black60);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
