<template>
  <PDialog title="ROI Calculator" @close="toggleROICalculatorDialog(false)">
    <div class="text-sm w-96">
      <hr class="mb-5" />
      <div class="space-y-5">
        <div class="space-y-2">
          <div class="flex">
            <label>PALM-{{ lpToken }} LP staked</label>
          </div>
          <div
            class="w-full flex flex-col border rounded bg-background-3 relative"
          >
            <Input v-model.number="usd" @update:model-value="getToken">
              <template #suffix>USD</template>
            </Input>
            <Input v-model.number="token" @update:model-value="getUSD">
              <template #suffix>PALM-{{ lpToken }} LP</template>
            </Input>
          </div>
        </div>
        <div>Staked for</div>
        <div class="flex gap-2">
          <div
            class="rounded-sm flex-1 h-7 flex justify-center items-center cursor-pointer"
            v-for="(item, index) in stakingPeriods"
            @click="setStakedFor(item)"
            :key="index"
            :class="
              isStakedFor(item.label)
                ? 'bg-main text-white border border-main'
                : 'bg-background-1 text-inactive border'
            "
          >
            {{ item.label }}
          </div>
        </div>
        <div
          class="w-full space-y-2 bg-main bg-opacity-10 pt-4 pb-6 px-6 rounded-lg border border-main text-sm text-white mb-8"
        >
          <div>ROI at current rates</div>
          <div class="text-3xl font-medium">{{ roiUsdFormatted }}</div>
          <div class="text-inactive">
            ~{{ roiTokenFormatted }} ({{ aprFormatted }})
          </div>
        </div>
      </div>
      <hr class="my-5" />
      <div class="flex justify-between items-center">
        <div class="text-inactive text-sm">Details</div>
        <ChevronDownIcon class="h-4" />
      </div>
    </div>
  </PDialog>
</template>
<script>
import { useDialogs } from '@/hooks/useDialogs'
import { PDialog } from '../../../palmswap-vue-ui'
import Input from '@/components/common/Input'
import { computed } from 'vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'
import {
  getBnbLpPriceInUsd,
  getPalmPrice,
  getBnbLpPriceInPalm
} from '@/contracts/farming'
import Big from 'big.js'
import { usePriceFeed } from '@/hooks/usePriceFeed'
import { poolInfos } from '@/hooks/usePoolInfos'
import metadata from '@/contracts/metadata'
import { apr, roi } from '@/helpers/staking'
import { STAKING_PERIODS } from '@/config/constants'
import {
  numberWithCommas,
  formatUSD,
  formatPercentage
} from '@/formatters/price'
import { correctDecimalPlaces } from '@/contracts/helpers'

export default {
  components: {
    PDialog,
    Input,
    ChevronDownIcon
  },
  async mounted() {
    this.lpPriceInPalm = await getBnbLpPriceInPalm()
  },
  setup() {
    const { metadata } = useDialogs()
    const lpToken = computed(() => (metadata.value ? metadata.value : ''))
    const { price } = usePriceFeed('BNBUSD')
    const bnbPrice = computed(() => {
      return price.value || new Big(0)
    })

    return { lpToken, bnbPrice }
  },
  data() {
    return {
      usd: null,
      token: null,
      lpPrice: null,
      roi: null,
      palmPrice: null,
      poolInfos,
      lpPriceInPalm: null,
      stakingPeriods: STAKING_PERIODS,
      selectedStakingPeriod: STAKING_PERIODS[0]
    }
  },
  computed: {
    tokenAddress() {
      return this.lpToken === 'BNB' ? metadata.palmBNBLP : metadata.palmUSDTLP
    },
    poolInfo() {
      if (this.poolInfos) {
        return this.poolInfos.find(
          (poolInfo) =>
            poolInfo.token.toLowerCase() === this.tokenAddress.toLowerCase()
        )
      } else {
        return null
      }
    },
    totalDeposits() {
      if (this.poolInfo) {
        return correctDecimalPlaces(this.poolInfo.totalStaked.toString())
      } else return Big(0)
    },
    roiToken() {
      return this.token
        ? roi(
            this.token,
            this.totalDeposits.toNumber(),
            this.poolInfo,
            this.selectedStakingPeriod
          )
        : null
    },
    roiTokenFormatted() {
      return this.roiToken
        ? `${numberWithCommas(this.roiToken, 4)} PALM`
        : `— PALM`
    },
    roiUsd() {
      return this.roiToken ? this.roiToken * this.palmPrice : null
    },
    roiUsdFormatted() {
      return this.roiUsd ? formatUSD(this.roiUsd) : '$—'
    },
    apr() {
      if (this.lpPriceInPalm && this.poolInfo && this.token) {
        return apr(
          this.lpPriceInPalm.mul(this.totalDeposits.add(this.token)),
          this.poolInfo,
          this.selectedStakingPeriod
        )
      } else {
        return null
      }
    },
    aprFormatted() {
      return this.apr ? formatPercentage(this.apr, this.apr > 1 ? 0 : 2) : '—%'
    }
  },
  watch: {
    async bnbPrice(val) {
      this.lpPrice = await getBnbLpPriceInUsd(val.div(1e8))
      this.palmPrice = await getPalmPrice(val)
    }
  },
  methods: {
    toggleROICalculatorDialog: useDialogs().toggleROICalculatorDialog,
    isStakedFor(val) {
      return val === this.selectedStakingPeriod.label
    },
    setStakedFor(val) {
      this.selectedStakingPeriod = val
    },
    getToken(val) {
      if (val) {
        const token = val / this.lpPrice
        this.token = token
      } else this.token = 0
    },
    getUSD(val) {
      if (val) {
        const usd = this.lpPrice * val
        this.usd = usd
      } else this.usd = 0
    }
  }
}
</script>
