import { watch, ref, computed } from 'vue'

import { useDialogs } from './useDialogs'

const { toggleConfirmDialog } = useDialogs()

const confirmed = ref(false)
const canceled = ref(false)

const titleRef = ref('')
const textRef = ref('')

export const confirm = () => {
  confirmed.value = true
  toggleConfirmDialog(false)
}

export const cancel = () => {
  canceled.value = true
  toggleConfirmDialog(false)
}

const reset = () => {
  canceled.value = false
  confirmed.value = false
}

const setText = (text) => {
  textRef.value = text
}
const setTitle = (title) => {
  titleRef.value = title
}

export const openConfirmDialog = async ({ text, title }) => {
  setText(text)
  setTitle(title)
  toggleConfirmDialog(true)
  return new Promise((resolve, reject) => {
    const stop = watch([confirmed, canceled], ([confirmed, canceled]) => {
      if (confirmed) {
        stop()
        reset()
        return resolve()
      } else if (canceled) {
        stop()
        reset()
        return reject()
      }
    })
  })
}

export const useConfirmDialog = () => {
  return {
    setTitle,
    setText,
    title: computed(() => titleRef.value),
    text: computed(() => textRef.value)
  }
}
