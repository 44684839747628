// https://docs.apex.win/apex-docs/developer-guide/smart-contracts
import { utils, constants } from 'ethers'
import { DEFAULT_DECIMALS, toD } from '../helpers'
import {
  getAmmContract,
  getSignedContracts,
  getSmartWalletContract,
  setSignedContracts
} from '../contracts'
import Big from 'big.js'

export const addTPSL = async (
  symbol, //ex. ETHUSDT
  signer, //signer instance
  smartWallet, //smartWallet address
  orderDetails = {
    type: 0,
    price: '0.00', //XXX: Figure out what type of value is here
    positionSize: '0.00', //XXX: Figure out what type of value is here
    collateral: '0.00', //XXX: Figure out what type of value is here
    leverage: '0.00', //XXX: Figure out what type of value is here
    slippage: '0',
    reduceOnly: true
  }
) => {
  const parseValue = (val) =>
    toD(
      utils.parseUnits(Number(val).toFixed(DEFAULT_DECIMALS), DEFAULT_DECIMALS)
    )

  const price = orderDetails.price
  const _price = parseValue(orderDetails.price)
  let positionSize = new Big(
    Number(orderDetails.positionSize).toFixed(DEFAULT_DECIMALS)
  )

  const _positionSize = toD(
    utils.parseUnits(positionSize.toString(), DEFAULT_DECIMALS)
  )
  const _collateral = parseValue(orderDetails.collateral)
  const _leverage = parseValue(Math.max(1, Number(orderDetails.leverage)))
  const _reduceOnly = Boolean(orderDetails.reduceOnly)
  const _expiry = 0

  setSignedContracts(signer)
  const { limitOrderBookSigned, usdtSigned } = getSignedContracts()
  const smartWalletContract = getSmartWalletContract(smartWallet, signer)

  const amm = getAmmContract(symbol)

  const minTipFee = await limitOrderBookSigned.minimumTipFee()
  const _tipFee = parseValue(new Big(minTipFee).div(10 ** 18).toString())

  const allowance = await usdtSigned.allowance(
    smartWallet,
    limitOrderBookSigned.address
  )
  if (
    allowance.lt(utils.parseUnits(new Big(price).toFixed(2), DEFAULT_DECIMALS))
  ) {
    await smartWalletContract.approveQuoteToken(
      limitOrderBookSigned.address,
      constants.MaxUint256
    )
  }

  let tx = null
  if (orderDetails.type === 0) {
    const _slippage = parseValue(1)

    tx = await limitOrderBookSigned.addLimitOrder(
      amm.address,
      _price,
      _positionSize,
      _collateral,
      _leverage,
      _slippage,
      _tipFee,
      _reduceOnly,
      _expiry
    )
  } else {
    const _slippage = parseValue(0)

    tx = await limitOrderBookSigned.addStopOrder(
      amm.address,
      _price,
      _positionSize,
      _collateral,
      _leverage,
      _slippage,
      _tipFee,
      _reduceOnly,
      _expiry
    )
  }

  const receipt = await tx.wait()

  return receipt
}
