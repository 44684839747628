<template>
  <PDialog
    title="Earnings"
    position="center"
    :width="width"
    @close="toggleReferralEarningsDialog(false)"
  >
    <div class="flex flex-col space-y-5">
      <PDatePicker
        range
        v-model="dateFilter"
        :initialDate="dateFilter"
        class="md:w-1/2 lg:w-1/3"
      />
      <EarningsTable
        :earnings="
          affiliate.isSalesManager ? formattedBnbCommissions : earnings
        "
      />
    </div>
  </PDialog>
</template>

<script>
import { PDialog, PDatePicker } from '../../../palmswap-vue-ui'
import { useDialogs } from '@/hooks/useDialogs'
import EarningsTable from '@/components/launchpad/EarningsTable'
import { useLaunchpadReferrals } from '@/hooks/useLaunchpad'
import { useStore } from 'vuex'
import { computed, watch, ref } from 'vue'
import { sub, startOfDay, endOfDay, getUnixTime } from 'date-fns'
import useBreakpoints from '@/hooks/useBreakpoints'
export default {
  components: {
    EarningsTable,
    PDatePicker,
    PDialog
  },
  setup() {
    const { toggleReferralEarningsDialog } = useDialogs()
    const {
      earnings,
      bnbCommissionEvents,
      fetchReferralEarnings,
      fetchBnbCommissions,
      affiliate
    } = useLaunchpadReferrals()
    const { state } = useStore()
    const { breakpoints } = useBreakpoints()
    const address = computed(() => state.user.address.account)
    const now = new Date()
    const dateFilter = ref([sub(now, { years: 1 }), now])

    watch(
      [address, dateFilter],
      ([address, dateFilter]) => {
        if (!address) {
          return
        }
        const [startTime, endTime] = dateFilter
        fetchReferralEarnings(
          address,
          getUnixTime(startOfDay(startTime)),
          getUnixTime(endOfDay(endTime))
        )
        fetchBnbCommissions(
          address,
          getUnixTime(startOfDay(startTime)),
          getUnixTime(endOfDay(endTime))
        )
      },
      { immediate: true }
    )
    return {
      earnings,
      bnbCommissionEvents,
      toggleReferralEarningsDialog,
      dateFilter,
      breakpoints,
      affiliate
    }
  },
  computed: {
    width() {
      if (this.breakpoints.lg === true) {
        return '1/2'
      }
      if (this.breakpoints.md === true) {
        return '2/3'
      }
      return 'full'
    },
    formattedBnbCommissions() {
      return this.bnbCommissionEvents.map((item) => {
        return {
          id: item.id,
          user: item.from,
          amount: item.investAmount,
          commissionAmount: item.commissionAmount,
          timestamp: item.timestamp
        }
      })
    }
  }
}
</script>
