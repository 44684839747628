<template>
  <PDialog hideClose>
    <template v-slot:header>
      <div class="flex flex-col sm:justify-center">
        <div class="text-lg text-white text-center font-medium">
          Welcome to Palmswap
        </div>
        <div class="text-xs text-inactive text-center px-6 mb-1 leading-5">
          Start trading on the first on chain perpetual <br />
          exchange on BNB Chain.
        </div>
      </div>
    </template>
    <div class="flex flex-col gap-8 px-7">
      <div class="flex gap-5 items-start">
        <div class="bg-background-3 p-3 rounded-full text-white">
          <ChartBarIcon class="h-4" />
        </div>
        <div class="flex flex-col gap-1">
          <div class="text-white text-base font-medium">Liquidity</div>
          <div class="text-inactive text-sm">
            High liquidity on the most popular <br />
            pairs.
          </div>
        </div>
      </div>
      <div class="flex gap-5 items-start">
        <div class="bg-background-3 p-3 rounded-full text-white">
          <Squares2X2Icon class="h-4" />
        </div>
        <div class="flex flex-col gap-1">
          <div class="text-white text-base font-medium">Advanced</div>
          <div class="text-inactive text-sm">
            Trade with leverage and manage <br />
            risk using advanced tools.
          </div>
        </div>
      </div>
      <div class="flex gap-5 items-start">
        <div class="bg-background-3 p-3 rounded-full text-white">
          <GiftIcon class="h-4" />
        </div>
        <div class="flex flex-col gap-1">
          <div class="text-white text-base font-medium">Rewards</div>
          <div class="text-inactive text-sm">
            Earn $PALM rewards for each trade <br />
            you make on the platform.
          </div>
        </div>
      </div>
      <div class="flex gap-5 items-start">
        <div class="bg-background-3 p-3 rounded-full text-white">
          <UserGroupIcon class="h-4" />
        </div>
        <div class="flex flex-col gap-1">
          <div class="text-white text-base font-medium">Referral Program</div>
          <div class="text-inactive text-sm">
            Invite others to earn from trading <br />
            fees.
          </div>
        </div>
      </div>
      <div class="h-10 flex">
        <PButton
          color="main"
          outlined
          size="md"
          class="h-full w-full text-sm flex flex-none justify-center items-center"
          @click="toggleWelcomeDialog(false)"
          data-cy="welcome-dialog_get-started-button"
        >
          Get Started
        </PButton>
      </div>
    </div>
  </PDialog>
</template>
<script>
import { useDialogs } from '@/hooks/useDialogs'
import { PButton, PDialog } from '../../palmswap-vue-ui'
import {
  ChartBarIcon,
  UserGroupIcon,
  GiftIcon,
  Squares2X2Icon
} from '@heroicons/vue/20/solid'

export default {
  components: {
    PButton,
    PDialog,
    ChartBarIcon,
    UserGroupIcon,
    GiftIcon,
    Squares2X2Icon
  },
  setup() {
    const { toggleWelcomeDialog } = useDialogs()

    return {
      toggleWelcomeDialog
    }
  }
}
</script>
<style lang="scss">
::-webkit-scrollbar {
  display: none;
}
</style>
