import { ref, computed, onMounted, onUnmounted, unref, watch } from 'vue'
import { gql } from '@apollo/client'
import { getAmmContractAddress } from '../contracts/contracts'
import { httpClient, wssClient } from '../api/graph'
import {
  TRADES_24H_BY_TRADER,
  TRADES_24H_DATAPOINT,
  TRADES_24H_DATAPOINT_BY_SYMBOL,
  TRADES_SUBSCRIPTION
} from '../api/queries'
import { formatTrade } from '../utils/utils'
import { sub, format } from 'date-fns'
import Big from 'big.js'
import { correctDecimalPlaces } from '@/contracts/helpers'
import metadata from '../contracts/metadata'

const amms = Object.values(metadata.amms)

const rawTradesRef = ref([])
const tradeSubscriptionRef = ref(null)

const toDisplayableTrades = (rawTrades) => {
  return rawTrades.map((rawTrade) => rawTrade)
}

const getTimestampRange = (range = 24) => {
  const endTime = Math.floor(Date.now() / 1000)
  const startTime = endTime - 60 * 3600 * range
  return [startTime, endTime]
}

export const subscribeTrades = (ammSymbol, trader) => {
  if (tradeSubscriptionRef.value) {
    tradeSubscriptionRef.value.unsubscribe()
  }

  tradeSubscriptionRef.value = wssClient
    .subscribe({
      query: gql(TRADES_SUBSCRIPTION),
      variables: {
        trader: trader.toLowerCase(),
        asset: getAmmContractAddress(ammSymbol).toLowerCase()
      }
    })
    .subscribe({
      next(data) {
        rawTradesRef.value = data.data.positionChangedEvents.map((trade) =>
          formatTrade(trade)
        )
      },
      error(err) {
        console.error('err', err)
      }
    })
}

export const subscribeLast24hStats = (ammSymbol) => {
  const timeAfter = Number(format(sub(new Date(), { hours: 24 }), 't'))
  if (ammSymbol) {
    const market = getAmmContractAddress(ammSymbol).toLowerCase()
    return wssClient.subscribe({
      query: gql(TRADES_24H_DATAPOINT_BY_SYMBOL),
      variables: {
        timeAfter,
        market
      }
    })
  } else {
    return wssClient.subscribe({
      query: gql(TRADES_24H_DATAPOINT),
      variables: {
        amms,
        timeAfter
      }
    })
  }
}

export const unsubscribeTrades = () => {
  if (tradeSubscriptionRef.value) {
    tradeSubscriptionRef.value.unsubscribe()
  }
  tradeSubscriptionRef.value = null
}

export const fetch24hTradesByTrader = async (trader) => {
  const [startTime, endTime] = getTimestampRange()
  const { data } = await httpClient.query({
    query: gql(TRADES_24H_BY_TRADER),
    variables: {
      amms,
      trader,
      endTime,
      startTime
    }
  })
  return data.positionChangedEvents || []
}

export const useTradesStats = (_ammSymbol) => {
  const ammSymbol = computed(() => unref(_ammSymbol))

  /**
   * @type { import("vue").Ref<{ volume: any, fees: any, count: number }> }
   */
  const trades24hStats = ref({})

  const createSubscription = (_ammSymbol) => {
    return subscribeLast24hStats(_ammSymbol).subscribe({
      next(data) {
        trades24hStats.value = data.data.tradesDataPoints.reduce(
          (acc, dataPoint) => ({
            volume: acc.volume.add(
              correctDecimalPlaces(dataPoint.volume.toString())
            ),
            fees: acc.fees.add(correctDecimalPlaces(dataPoint.fees.toString())),
            count: acc.count + Number(dataPoint.count)
          }),
          { volume: new Big(0), fees: new Big(0), count: 0 }
        )
      },
      error(err) {
        console.error('err', err)
      }
    })
  }

  let subscription = createSubscription(ammSymbol.value)

  onUnmounted(() => {
    if (!subscription) {
      return
    }
    subscription.unsubscribe()
  })

  return {
    trades24hStats: computed(() => trades24hStats.value)
  }
}

export const useTrades = () => {
  const rawTrades = computed(() => {
    return rawTradesRef.value
  })
  const trades = computed(() => {
    if (!rawTradesRef.value) {
      return []
    }
    return toDisplayableTrades(rawTradesRef.value)
  })

  return {
    rawTrades,
    trades
  }
}
