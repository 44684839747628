<template>
  <PDialog title="Stake In Pool" @close="toggleStakeDialog(false)" width="96">
    <div class="text-sm">
      <hr class="mb-5" />
      <div class="space-y-5">
        <div class="space-y-2">
          <div class="flex justify-between items-center">
            <label>Stake</label>
            <label>Balance {{ balanceFormatted }}</label>
          </div>
          <Input
            v-model="stakeAmount"
            @input="updateInputHandler"
            placeholder="0.0000"
            :error="error"
          >
            <template #suffix>{{ token.symbol }}</template>
          </Input>
        </div>
        <div class="space-y-2">
          <PRangeSlider
            color="main"
            min="0"
            max="100"
            step="1"
            suffix="%"
            :model-value="percentage"
            @update:model-value="updateSliderHandler"
          />
        </div>
        <div class="flex justify-between">
          <div>Annual ROI at current rates</div>
          <div>{{ roiFormatted }}</div>
        </div>
        <div class="space-y-2">
          <div>
            <PButton class="w-full" @click="handleConfirm">Confirm</PButton>
          </div>
          <div>
            <a href="https://pancakeswap.finance/swap" target="_blank">
              <PButton class="w-full" color="secondary">Get PALM</PButton>
            </a>
          </div>
        </div>
      </div>
    </div>
  </PDialog>
</template>
<script>
import { useDialogs } from '@/hooks/useDialogs'
import { PDialog, PRangeSlider, PButton } from '../../../palmswap-vue-ui'
import Input from '@/components/common/Input'
import { mapState } from 'vuex'
import { depositStake } from '@/contracts/staking'
import metadata from '@/contracts/metadata'
import { utils } from 'ethers'
import { formatUSD } from '@/formatters/price'
import { TOKENS } from '@/config/constants'
import { poolInfos } from '@/hooks/usePoolInfos'
import { correctDecimalPlaces } from '@/contracts/helpers'
import { truncateNumber } from '@/formatters/price'
import { roi } from '@/helpers/staking'

export default {
  components: {
    PDialog,
    PRangeSlider,
    Input,
    PButton
  },
  methods: {
    toggleStakeDialog: useDialogs().toggleStakeDialog,
    updateSliderHandler(newPercentage) {
      this.percentage = newPercentage
      newPercentage == 100
        ? (this.stakeAmount = this.balance)
        : (this.stakeAmount = ((this.balance * newPercentage) / 100).toFixed(4))
    },
    updateInputHandler() {
      this.percentage = Math.min(
        parseInt((this.stakeAmount / this.balance) * 100),
        100
      )
    },
    async handleConfirm() {
      if (!this.stakeAmount || this.stakeAmount <= 0) {
        this.error = 'Stake amount must be greater than 0'
        return
      }
      if (Number(this.stakeAmount) > Number(this.balance)) {
        this.error = `You cannot stake more than your ${this.token.symbol} balance`
        return
      }
      if (isNaN(this.stakeAmount)) {
        this.error = 'Stake amount is invalid—must be a number'
        return
      }
      const _tokenAddress = this.tokenAddress
      const _token = this.token
      this.toggleStakeDialog(false)
      const stakeAmountWei = utils.parseUnits(
        this.stakeAmount.toString(),
        'ether'
      )
      const id = Date.now()
      this.$store.commit('addId', id)
      try {
        const resultDeposit = await depositStake(
          _tokenAddress,
          stakeAmountWei,
          this.signer,
          () => {
            this.$notify({
              id,
              title: 'Deposit',
              type: 'progress',
              duration: -1,
              data: {
                body: `Your deposit of ${this.stakeAmount} ${_token.symbol} is waiting for confirmation.`
              }
            })
          }
        )
        if (resultDeposit) {
          await this.$store.dispatch('updateBalance')
          this.$notify({
            id,
            title: 'Deposit',
            type: 'success',
            data: {
              body: `Your deposit of ${this.stakeAmount} ${_token.symbol} is confirmed.`
            }
          })
        } else {
          this.$notify({
            id,
            title: 'Error while confirming the deposit',
            type: 'error'
          })
        }
      } catch (errorConfirm) {
        console.error(errorConfirm)
        this.$notify({
          id,
          title: 'Error while confirming the deposit',
          type: 'error'
        })
      } finally {
        this.$notify.close(id)
        this.$store.commit('removeId', id)
      }
    }
  },
  data() {
    return {
      stakeAmount: null,
      percentage: 0,
      error: null,
      tokens: TOKENS,
      poolInfos
    }
  },
  computed: {
    ...mapState({
      balance: (state) =>
        state.user.balance[useDialogs().metadata.value.tokenId],
      signer: (state) => state.web3Modal.provider.getSigner()
    }),
    tokenId() {
      return useDialogs().metadata.value
        ? useDialogs().metadata.value.tokenId
        : null
    },
    palmPrice() {
      return useDialogs().metadata.value
        ? useDialogs().metadata.value.palmPrice
        : null
    },
    tokenAddress() {
      return metadata[this.tokenId]
    },
    token() {
      return this.tokens.find((token) => token.id === this.tokenId)
    },
    balanceFormatted() {
      if (this.balance) {
        return `${truncateNumber(this.balance, 3)} ${this.token.symbol}`
      } else {
        return null
      }
    },
    poolInfo() {
      if (this.poolInfos) {
        return this.poolInfos.find(
          (poolInfo) =>
            poolInfo.token.toLowerCase() === this.tokenAddress.toLowerCase()
        )
      } else {
        return null
      }
    },
    roi() {
      if (this.stakeAmount && this.poolInfo && this.palmPrice) {
        const rewardPalm = roi(
          this.stakeAmount,
          correctDecimalPlaces(this.poolInfo.totalStaked),
          this.poolInfo
        )
        const rewardUsd = rewardPalm * this.palmPrice
        return rewardUsd
      } else {
        return null
      }
    },
    roiFormatted() {
      if (this.stakeAmount && this.poolInfo && this.palmPrice) {
        return formatUSD(this.roi)
      } else {
        return '—'
      }
    }
  }
}
</script>
