import { ref, computed } from 'vue';
import { gql } from '@apollo/client';
import { wssClient } from '../api/graph';
import { AMM_POSITION_SUBSCRIPTION, POSITION_CHANGED_EVENT_SUBSCRIPTION } from '../api/queries';
import { getPosition } from '../contracts/position';
import { getAmmContractAddress } from '../contracts/contracts';
import { formatHistoricalPosition } from '../utils/utils';
import { useAccount } from './useAccount';
import { currencies } from './useAppState';
import { updateLast24hPrice } from './useChart';
const rawPositionRef = ref(null);
const rawPositionsRef = ref([]);
const activePositionSubscriptionRef = ref(null);
const historicalPositionsSubscriptionRef = ref(null);
const historicalPositionsRef = ref([]);
// TODO: init on module level to reduce memory usage
const useTrader = () => {
    const { isConnected, account } = useAccount();
    return computed(() => {
        return isConnected.value && account.smartWallet
            ? account.smartWallet
            : '';
    });
};
export const subscribeActivePosition = (ammSymbol) => {
    const trader = useTrader();
    if (activePositionSubscriptionRef.value) {
        activePositionSubscriptionRef.value.unsubscribe();
    }
    fetchPosition(ammSymbol);
    fetchAllPositions();
    activePositionSubscriptionRef.value = wssClient
        .subscribe({
        query: gql(AMM_POSITION_SUBSCRIPTION),
        variables: {
            id: `${getAmmContractAddress(ammSymbol).toLowerCase()}-${trader.value.toLowerCase()}`
        }
    })
        .subscribe({
        next(data) {
            fetchPosition(ammSymbol);
            fetchAllPositions();
        }
    });
};
export const unsubscribeActivePosition = () => {
    if (activePositionSubscriptionRef.value) {
        activePositionSubscriptionRef.value.unsubscribe();
    }
    activePositionSubscriptionRef.value = null;
};
export const subscribeHistoricalPositions = (ammSymbol) => {
    const trader = useTrader();
    // TODO: query when total count is bigger than 1000
    if (historicalPositionsSubscriptionRef.value) {
        historicalPositionsSubscriptionRef.value.unsubscribe();
    }
    historicalPositionsSubscriptionRef.value = wssClient
        .subscribe({
        query: gql(POSITION_CHANGED_EVENT_SUBSCRIPTION),
        variables: {
            trader: trader.value.toLowerCase(),
            amm: getAmmContractAddress(ammSymbol).toLowerCase()
        }
    })
        .subscribe({
        next(data) {
            historicalPositionsRef.value = data.data.positionChangedEvents.map((event) => formatHistoricalPosition(event));
            updateLast24hPrice(ammSymbol);
        },
        error(err) {
            console.error('err', err);
        }
    });
};
export const unsubscribeHistoricalPositions = () => {
    if (historicalPositionsSubscriptionRef.value) {
        historicalPositionsSubscriptionRef.value.unsubscribe();
    }
    historicalPositionsSubscriptionRef.value = null;
};
export const fetchPosition = async (ammSymbol, ammAddress) => {
    const trader = useTrader();
    const _ammAddress = ammSymbol ? getAmmContractAddress(ammSymbol) : ammAddress;
    let rawPosition = await getPosition(_ammAddress, trader.value);
    if (!rawPosition ||
        rawPosition.trader.toLowerCase() !== trader.value.toLowerCase())
        rawPosition = null;
    rawPositionRef.value = rawPosition;
};
export const fetchAllPositions = async () => {
    const trader = useTrader();
    const res = [];
    const symbols = {};
    currencies
        .map((x) => x.ammSymbol)
        .forEach((symbol) => {
        const ammAddress = getAmmContractAddress(symbol);
        res.push(getPosition(ammAddress, trader.value));
        symbols[ammAddress.toLowerCase()] = symbol;
    });
    const positions = await Promise.all(res);
    rawPositionsRef.value = positions
        .filter(Boolean)
        .filter((x) => x.trader.toLowerCase() === trader.value.toLowerCase());
};
export const resetPosition = async () => {
    rawPositionRef.value = null;
    rawPositionsRef.value = [];
    historicalPositionsRef.value = [];
};
export const useUserPosition = () => {
    const rawPosition = computed(() => {
        return rawPositionRef.value;
    });
    const currentPosition = computed(() => {
        if (!rawPositionRef.value) {
            return null;
        }
        return toDisplayablePosition(rawPositionRef.value);
    });
    const rawPositions = computed(() => {
        return rawPositionsRef.value;
    });
    const allPositions = computed(() => {
        return rawPositionsRef.value.map((x) => toDisplayablePosition(x));
    });
    const closedPositions = computed(() => {
        return historicalPositionsRef.value;
    });
    return {
        rawPosition,
        currentPosition,
        rawPositions,
        allPositions,
        closedPositions
    };
};
const toDisplayablePosition = (rawPosition) => ({
    ammAddress: rawPosition.amm,
    pair: rawPosition.pair,
    symbol: rawPosition.symbol,
    size: rawPosition.size,
    margin: rawPosition.margin.toNumber(),
    leverage: rawPosition.leverage.toNumber(),
    leverageFromNotional: rawPosition.leverageFromNotional.toNumber(),
    liquidationPrice: rawPosition.liquidationPrice.toNumber(),
    pnl: rawPosition.pnl.toNumber(),
    entryPrice: rawPosition.entryPrice.abs().toNumber(),
    side: rawPosition.side,
    openNotional: rawPosition.openNotional.toNumber()
});
