<template>
  <div class="dialog-header">
    <slot></slot>
    <span class="title">{{ title }}</span>
    <Button class="close" @click="$emit('onClose')"
      ><img src="@/assets/close.svg"
    /></Button>
  </div>
</template>
<script>
import Button from '@/components/common/Button'

export default {
  components: {
    Button
  },
  props: {
    title: {
      type: String
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  .title {
    color: white;
  }
  .close {
    padding: 8px;
    & > img {
      margin-top: 4px;
    }
  }
}
</style>
