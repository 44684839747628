import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import Big from 'big.js'
import { useAppState } from '@/hooks/useAppState'
import { useAMM } from '@/hooks/useAMM'
import { MAX_DECIMAL_PLACE, SIDE_LONG, SIDE_SHORT } from '@/contracts/helpers'
import { getFee, getPositionPrice } from '@/contracts/position'

export const ORDER_TYPE_MARKET = 0
export const ORDER_TYPE_LIMIT = 1
export const ORDER_TYPE_TAKE_PROFIT = 2
export const ORDER_TYPE_STOP_LOSS = 3

export const orderTypes = [
  ORDER_TYPE_MARKET,
  ORDER_TYPE_LIMIT,
  ORDER_TYPE_TAKE_PROFIT,
  ORDER_TYPE_STOP_LOSS
]

export const orderTypeLabels = {
  [ORDER_TYPE_MARKET]: 'Market',
  [ORDER_TYPE_LIMIT]: 'Limit',
  [ORDER_TYPE_TAKE_PROFIT]: 'Take profit',
  [ORDER_TYPE_STOP_LOSS]: 'Stop Market'
}

const orderTypeRef = ref(ORDER_TYPE_MARKET)
const sideRef = ref(SIDE_LONG)

export const selectOrderType = (orderType) => {
  if (!orderTypes.includes(orderType)) {
    return
  }
  orderTypeRef.value = orderType
}

export const selectSide = (side) => {
  if (side === SIDE_LONG || side === SIDE_SHORT) {
    sideRef.value = side
  }
}

export const useOrderForm = (isMarket = false) => {
  const { pairSymbol } = useAppState()
  const { getMaxPosition, markPrice } = useAMM()
  const { state } = useStore()
  const account = computed(() => state.user.address.account)
  const walletBalance = computed(() => state.user.balance.wallet)

  const selectedOrderType = computed(() => orderTypeRef.value)
  const leverage = ref(new Big(1))
  const size = ref()
  const side = sideRef
  const price = ref()
  const maxPosition = ref(0)
  const totalFee = ref(new Big(0))
  const totalCollateral = ref(new Big(0))

  watch(
    [pairSymbol, account],
    async ([pairSymbol, account]) => {
      totalFee.value = await getFee()
      maxPosition.value = await getMaxPosition(pairSymbol, account)
    },
    { immediate: true }
  )

  watch(
    [walletBalance, pairSymbol, totalFee, leverage, side, price, markPrice],
    async ([walletBalance, pairSymbol, totalFeeBN, leverage, side, price]) => {
      const totalBalance = new Big(walletBalance || 0)
      const maxCollateral = totalBalance.mul(leverage)
      const fees = totalFeeBN.mul(maxCollateral)
      const notional = totalBalance.sub(fees).mul(leverage)
      let collateral = new Big(0)
      if (isMarket) {
        collateral = await getPositionPrice(pairSymbol, notional, side)
      } else if (price) {
        collateral = notional.div(price)
      }
      totalCollateral.value = collateral.round(MAX_DECIMAL_PLACE, Big.roundDown)
    }
  )

  return {
    selectedOrderType,
    leverage,
    size,
    maxPosition: computed(() => maxPosition.value),
    side,
    totalFee,
    totalCollateral,
    price
  }
}
