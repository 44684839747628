// eip-3085 chain parameters
export const binanceSmartChain = {
  chainId: '0x38',
  chainName: 'Binance Smart Chain',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance',
    symbol: 'BNB'
  },
  rpcUrls: [
    'https://bsc-dataseed.binance.org',
    'https://bsc-dataseed1.defibit.io',
    'https://bsc-dataseed1.ninicoin.io/'
  ],
  blockExplorerUrls: ['https://bscscan.com']
}

export const binanceSmartChainTestnet = {
  chainId: '0x61',
  chainName: 'Binance Smart Chain Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance',
    symbol: 'tBNB'
  },
  rpcUrls: [
    'https://data-seed-prebsc-1-s1.binance.org:8545',
    'https://data-seed-prebsc-1-s3.binance.org:8545',
    'https://bsctestapi.terminet.io/rpc'
  ],
  blockExplorerUrls: ['https://testnet.bscscan.com']
}

export const getChainDetails = (chainId) => {
  const chains = [binanceSmartChain, binanceSmartChainTestnet]
  return chains.find((chain) => chain.chainId === chainId)
}
