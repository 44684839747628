<template>
  <Dialog>
    <div class="confirm-dialog">
      <DialogHeader :title="title" @on-close="cancel" />
      <div class="confirm-dialog-text">
        {{ text }}
      </div>
      <div class="confirm-dialog-actions">
        <Button overlay @click="cancel">Cancel</Button>
        <SubmitButton big @click="confirm">Confirm</SubmitButton>
      </div>
    </div>
  </Dialog>
</template>
<script>
import DialogHeader from '@/components/common/DialogHeader.vue'
import Dialog from '@/components/common/Dialog.vue'
import { useDialogs } from '@/hooks/useDialogs'
import { confirm, cancel } from '@/hooks/confirmDialog'
import { useConfirmDialog } from '../hooks/confirmDialog'
import Button from './common/Button.vue'
import SubmitButton from './common/SubmitButton.vue'

export default {
  components: {
    Dialog,
    DialogHeader,
    Button,
    SubmitButton
  },

  setup() {
    const { toggleDepositDialog } = useDialogs()
    const { title, text } = useConfirmDialog()
    return {
      toggleDepositDialog,
      confirm,
      cancel,
      title,
      text
    }
  }
}
</script>
<style lang="scss" scoped>
.confirm {
  &-dialog {
    padding: 16px 24px 24px 24px;
    width: 400px;
    background: #1f2023;
    &-text {
      width: 300px;
      margin-left: auto;
      margin-right: auto;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
    }
    &-actions {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;

      margin-top: 40px;
      > * {
        height: 44px;
      }
    }
  }
}
</style>
