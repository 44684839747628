export const LAST_TRADES_COUNT = 50
export const MAINTENANCE_MARGIN_RATIO = 0.0625
export const LOWER_MARGIN_RATIO = 0.025
export const PARTIAL_LIQUIDATION_RATIO = 0.25
export const NODE_ENV = process.env.VUE_APP_NODE_ENV || 'production'
export const API_URL = 'https://api.palmswap.org'
// NODE_ENV === 'production'
//   ? 'https://api.palmswap.org'
//   : `https://api.${NODE_ENV}.palmswap.org`

export const FEATURE_FLAGS = {
  LIMIT_ORDERS: 'LIMIT_ORDERS',
  OFFLINE_POPUP: 'OFFLINE_POPUP',
  STAKING: 'STAKING',
  LAUNCHPAD: 'LAUNCHPAD',
  TRADING_REWARDS: 'TRADING_REWARDS',
  VESTING: 'VESTING',
  INVITE: 'INVITE',
  FAUCET: 'FAUCET',
  FARM: 'FARM',
  HYBRID: 'HYBRID',
  BTC_TRADING: 'BTC_TRADING',
  COMPETITION: 'COMPETITION'
}

export const TOKENS = [
  {
    id: 'palmToken',
    symbol: 'PALM'
  }
]

export const BLOCK_INTERVAL = 3 // seconds

export const BLOCKS_MINTED_COUNT = (365 * 24 * 60 * 60) / BLOCK_INTERVAL

export const FEE_STRUCTURE = [
  { volume: '< 500.000', open: '0.100%', close: '0.100%', volumeNum: 0 },
  { volume: '≥ 500.000', open: '0.090%', close: '0.090%', volumeNum: 500000 },
  {
    volume: '≥ 1,000.000',
    open: '0.080%',
    close: '0.080%',
    volumeNum: 1000000
  },
  {
    volume: '≥ 5,000.000',
    open: '0.075%',
    close: '0.075%',
    volumeNum: 5000000
  },
  {
    volume: '≥ 10,000.000',
    open: '0.070%',
    close: '0.070%',
    volumeNum: 10000000
  },
  {
    volume: '≥ 50,000.000',
    open: '0.050%',
    close: '0.050%',
    volumeNum: 50000000
  },
  {
    volume: '≥ 100,000.000',
    open: '0.025%',
    close: '0.025% ',
    volumeNum: 100000000
  }
]

export const FEE_DISCOUNTS = [
  {
    tierNumber: 1,
    minStakingBalance: 500,
    discount: 0.025
  },
  {
    tierNumber: 2,
    minStakingBalance: 5000,
    discount: 0.05
  },
  {
    tierNumber: 3,
    minStakingBalance: 10000,
    discount: 0.1
  },
  {
    tierNumber: 4,
    minStakingBalance: 50000,
    discount: 0.15
  },
  {
    tierNumber: 5,
    minStakingBalance: 100000,
    discount: 0.2
  },
  {
    tierNumber: 6,
    minStakingBalance: 200000,
    discount: 0.25
  },
  {
    tierNumber: 7,
    minStakingBalance: 500000,
    discount: 0.3
  }
]

export const STAKING_PERIODS = [
  {
    label: '1D',
    fractionOfYear: 1 / 365
  },
  {
    label: '7D',
    fractionOfYear: 7 / 365
  },
  {
    label: '30D',
    fractionOfYear: 30 / 365
  },
  {
    label: '1Y',
    fractionOfYear: 1
  },
  {
    label: '5Y',
    fractionOfYear: 5
  }
]

export const PROTECTED_ENVIRONMENTS = ['dev', 'staging']

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
