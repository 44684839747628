<template>
  <div class="dialog-wrapper">
    <div class="dialog rounded-2xl bg-background-2 border"><slot /></div>
  </div>
</template>
<style lang="scss" scoped>
.dialog {
  &-wrapper {
    background-color: var(--black60);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
