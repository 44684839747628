<template>
  <PToggle :items="items" :activeIndex="side" />
</template>
<script>
import { SIDE_LONG, SIDE_SHORT } from '../../../contracts/helpers'
import { PToggle } from '../../../../palmswap-vue-ui'

export default {
  components: {
    PToggle
  },
  props: {
    side: {
      type: Number,
      validator(value) {
        return [SIDE_LONG, SIDE_SHORT].includes(value)
      }
    }
  },
  emits: ['update:side'],
  data() {
    return {
      SIDE_LONG,
      SIDE_SHORT,
      items: [
        {
          text: 'Buy / Long',
          color: 'green',
          dataCy: 'trade-page_make-order_buy-button',
          click: () => this.setSide(SIDE_LONG)
        },
        {
          text: 'Sell / Short',
          color: 'red',
          dataCy: 'trade-page_make-order_sell-button',
          click: () => this.setSide(SIDE_SHORT)
        }
      ]
    }
  },
  methods: {
    setSide(newSide) {
      this.$emit('update:side', newSide)
    }
  }
}
</script>
