import { utils } from 'ethers'

export const ERC20_DECIMAL_DIGITS = 18
export const BASE_ASSET_DIGITS = 7
export const BASE_ASSET_DIGITS_OF_HISTORY = 4
export const QUOTE_ASSET_DIGITS = 2
export const PNL_DIGITS = 2
export const FUNDING_RATE_DIGITS = 4
export const FUNDING_PAYMENT_DIGITS = 4
export const MARGIN_RATIO_DIGITS = 1

export const LAUNCHPAD_INFLUENCER_ROLE = utils.keccak256(
  utils.toUtf8Bytes('INFLUENCER_ROLE')
)
export const LAUNCHPAD_ADMIN_ROLE =
  '0x0000000000000000000000000000000000000000000000000000000000000000'
