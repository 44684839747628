import { ref } from 'vue'
import { wssClient } from '../api/graph'
import { POOL_INFOS_SUBSCRIPTION } from '../api/queries'
import { gql } from '@apollo/client/core'

let poolInfos = ref()
let poolInfosSubscription = ref()

function subscribePoolInfos() {
  if (poolInfosSubscription.value) {
    poolInfosSubscription.value.unsubscribe()
    poolInfos.value = null
  }

  poolInfosSubscription.value = wssClient
    .subscribe({
      query: gql(POOL_INFOS_SUBSCRIPTION)
    })
    .subscribe({
      next(data) {
        poolInfos.value = data.data.poolInfos
      }
    })
}

export { subscribePoolInfos, poolInfos }
