import { utils } from 'ethers'
import { DEFAULT_DECIMALS } from '@/contracts/helpers'

const { formatUnits } = utils
export function parsePriceData({ close, high, low, open, startTime }) {
  return {
    close: formatUnits(close, DEFAULT_DECIMALS),
    high: formatUnits(high, DEFAULT_DECIMALS),
    low: formatUnits(low, DEFAULT_DECIMALS),
    open: formatUnits(open, DEFAULT_DECIMALS),
    time: parseInt(startTime)
  }
}

export function parseVolumeData({ time, volume }) {
  return {
    value: Number(formatUnits(volume, DEFAULT_DECIMALS)),
    time: parseInt(time)
  }
}
