<template>
  <span class="mt-1 flex w-full space-x-1">
    <RangeButton
      value="10%"
      :disabled="disabled"
      @click="selectFraction(0.1)"
      data-cy="set-fraction-0.1-button"
    />
    <RangeButton
      value="25%"
      :disabled="disabled"
      @click="selectFraction(0.25)"
      data-cy="set-fraction-0.25-button"
    />
    <RangeButton
      value="50%"
      :disabled="disabled"
      @click="selectFraction(0.5)"
      data-cy="set-fraction-0.5-button"
    />
    <RangeButton
      value="75%"
      :disabled="disabled"
      @click="selectFraction(0.75)"
      data-cy="set-fraction-0.75-button"
    />
    <RangeButton
      value="100%"
      :disabled="disabled"
      @click="selectFraction(1)"
      data-cy="set-fraction-1-button"
    />
  </span>
</template>

<script>
import RangeButton from '@/components/common/RangeButton.vue'

export default {
  components: {
    RangeButton
  },
  props: {
    disabled: {
      type: Boolean
    }
  },
  emits: ['update'],
  methods: {
    selectFraction(fraction) {
      this.$emit('update', fraction)
    }
  }
}
</script>
