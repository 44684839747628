import { ref, computed } from 'vue'
import { gql } from '@apollo/client'
import { httpRewardsClient } from '../api/graph'
import { REWARDS_WALLET, REWARDS_MILESTONE_STATES } from '../api/queries'

const rewardsWalletRef = ref([])
const rewardsWalletSubscriptionRef = ref(null)
const rewardsMilestonesRef = ref([])
const rewardsMilestonesSubscriptionRef = ref(null)

const toDisplayableRewardsWalletData = (RewardsWalletsData) => {
  return RewardsWalletsData
}

const toDisplayableRewardsMilestonesData = (RewardsMilestonesData) => {
  return RewardsMilestonesData
}

export const subscribeRewardsWalletData = (trader) => {
  if (rewardsWalletSubscriptionRef.value) {
    rewardsWalletSubscriptionRef.value.unsubscribe()
  }
  rewardsWalletSubscriptionRef.value = httpRewardsClient
    .subscribe({
      query: gql(REWARDS_WALLET),
      variables: {
        id: trader.toLowerCase()
      }
    })
    .subscribe({
      next(data) {
        rewardsWalletRef.value = data.data.smartWallets
      },
      error(err) {
        console.error('err', err)
      }
    })
}

export const unsubscribeRewardsWalletData = () => {
  if (rewardsWalletSubscriptionRef.value) {
    rewardsWalletSubscriptionRef.value.unsubscribe()
  }
  rewardsWalletSubscriptionRef.value = null
}

export const subscribeRewardsMilestonesData = () => {
  if (rewardsMilestonesSubscriptionRef.value) {
    rewardsMilestonesSubscriptionRef.value.unsubscribe()
  }
  rewardsMilestonesSubscriptionRef.value = httpRewardsClient
    .subscribe({
      query: gql(REWARDS_MILESTONE_STATES)
    })
    .subscribe({
      next(data) {
        rewardsMilestonesRef.value = data.data.milestoneStates
      },
      error(err) {
        console.error('err', err)
      }
    })
}

export const unsubscribeRewardsMilestonesData = () => {
  if (rewardsMilestonesSubscriptionRef.value) {
    rewardsMilestonesSubscriptionRef.value.unsubscribe()
  }
  rewardsMilestonesSubscriptionRef.value = null
}

export const useRewardsData = () => {
  const rewardsWalletData = computed(() => {
    if (!rewardsWalletRef.value) {
      return null
    }
    return toDisplayableRewardsWalletData(rewardsWalletRef.value)
  })

  const rewardsMilestonesData = computed(() => {
    if (!rewardsMilestonesRef.value) {
      return {}
    }
    return toDisplayableRewardsMilestonesData(rewardsMilestonesRef.value)
  })

  return {
    rewardsWalletData,
    rewardsMilestonesData
  }
}
