<template>
  <PDialog
    title="Stake to get more"
    @close="toggleInviteStakeDialog(false)"
    width="2/5"
  >
    <div class="text-xs mt-2.5 mb-4">
      The more tokens are staked the more percentages you should get on invited
      referrals. The past earnings will not change, as soon as a new staking
      level is reached you will get the percentages for the next trades of the
      customers.
    </div>
    <div class="flex items-center text-white text-sm mb-3">
      <div class="flex-1">PALM in Staking</div>
      <div class="flex-1 ml-6">Trading Fees Earned</div>
    </div>
    <div class="bg-background-3 rounded-t">
      <div
        class="flex items-center h-10 border-b text-sm px-6"
        v-for="data in taleData"
        :key="data.palms"
      >
        <div class="flex-1 h-full border-r flex items-center">
          {{ numberWithCommas(data.palms, 0) }}
        </div>
        <div class="flex-1 text-white ml-6">{{ data.percent }}%</div>
      </div>
    </div>
  </PDialog>
</template>
<script>
import { PDialog } from '../../../palmswap-vue-ui'
import { useDialogs } from '@/hooks/useDialogs'
import { numberWithCommas } from '@/formatters/price'

export default {
  components: {
    PDialog
  },
  setup() {
    const { toggleInviteStakeDialog } = useDialogs()
    return {
      toggleInviteStakeDialog
    }
  },
  data() {
    return {
      taleData: [
        { palms: 0, percent: 20 },
        { palms: 5000, percent: 22 },
        { palms: 25000, percent: 24 },
        { palms: 50000, percent: 26 },
        { palms: 100000, percent: 28 },
        { palms: 500000, percent: 30 },
        { palms: 2500000, percent: 35 },
        { palms: 5000000, percent: 40 }
      ]
    }
  },
  computed: {},
  methods: {
    numberWithCommas
  }
}
</script>

<style scoped>
.tr-head {
  display: none !important;
}
</style>
