<template>
  <div class="flex text-sm items-center space-x-1">
    <div class="rounded border bg-background-1 p-3 flex space-x-2">
      <span class="text-white">Reduce Only</span>
      <div>
        <PSwitch
          :modelValue="modelValue"
          @update:modelValue="$emit('update:modelValue', $event)"
        />
      </div>
    </div>
    <PTooltip
      content="Reduce Only is a special order condition that only allows you to reduce or close a current position you hold."
      icon
      placement="bottom"
    >
      <div class="hidden"></div>
    </PTooltip>
  </div>
</template>

<script>
import { PSwitch, PTooltip } from '../../../../palmswap-vue-ui'

export default {
  name: 'ReduceOnly',
  components: {
    PSwitch,
    PTooltip
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: Boolean
  }
}
</script>
