<template>
  <PDialog title="Claim" @close="toggleClaimStakeDialog(false)" width="96">
    <div class="text-sm">
      <hr class="mb-5" />
      <div class="space-y-5">
        <div class="space-y-2">
          <div class="flex justify-between items-center">
            <label>Rewards</label>
            <label class="flex items-center gap-1">
              <div>Balance</div>
              <div>
                <PTooltip :content="unclaimedRewardAmount">
                  {{ unclaimedRewardAmountFormatted }}
                </PTooltip>
              </div>
              <div><PLogo type="token" class="h-4" /></div>
            </label>
          </div>
          <Input
            v-model="stakeAmount"
            @input="updateInputHandler"
            placeholder="0.00"
            :error="error"
          >
            <template #suffix>PALM</template>
          </Input>
        </div>
        <div class="space-y-2">
          <PRangeSlider
            color="main"
            min="0"
            max="100"
            step="1"
            suffix="%"
            :model-value="percentage"
            @update:model-value="updateSliderHandler"
          />
        </div>
        <div class="flex justify-between">
          <div>Withdraw Cooldown Period</div>
          <PTooltip
            :content="`If you want to claim your tokens, your tokens will first be put into the Cool Down pool, these are withdrawable after 7 days.`"
            placement="left"
            icon
            class="cursor-default"
          >
            {{ cooldownPeriodFormatted }}
          </PTooltip>
        </div>
        <div
          class="w-full bg-main bg-opacity-10 pt-4 pb-6 px-6 rounded-lg border border-main text-sm text-white mb-8"
        >
          <div class="mb-4">
            When earnings are reinvested, there is
            <b>no cooldown period</b>&mdash;they are immediately credited to
            your Staked Balance.
          </div>
          <PButton class="w-full h-12" @click="handleCompound" color="main">
            Compound
          </PButton>
        </div>
        <PButton class="w-full" @click="handleClaim">Withdraw</PButton>
      </div>
    </div>
  </PDialog>
</template>
<script>
import { useDialogs } from '@/hooks/useDialogs'
import {
  PDialog,
  PRangeSlider,
  PButton,
  PLogo,
  PTooltip
} from '../../../palmswap-vue-ui'
import Input from '@/components/common/Input'
import { mapState } from 'vuex'
import { getUserInfo, claimStake, compoundStake } from '@/contracts/staking'
import metadata from '@/contracts/metadata'
import { utils } from 'ethers'
import { truncateNumber } from '@/formatters/price'
import { poolInfos } from '@/hooks/usePoolInfos'

export default {
  components: {
    PDialog,
    PRangeSlider,
    Input,
    PButton,
    PLogo,
    PTooltip
  },
  methods: {
    toggleClaimStakeDialog: useDialogs().toggleClaimStakeDialog,
    updateSliderHandler(newPercentage) {
      this.percentage = newPercentage
      newPercentage == 100
        ? (this.stakeAmount = this.unclaimedRewardAmount)
        : (this.stakeAmount = (
            (this.unclaimedRewardAmount * newPercentage) /
            100
          ).toFixed(4))
    },
    updateInputHandler() {
      this.percentage = Math.min(
        parseInt((this.stakeAmount / this.unclaimedRewardAmount) * 100),
        100
      )
    },
    async handleClaim() {
      if (!this.stakeAmount || this.stakeAmount <= 0) {
        this.error = 'Claim amount must be greater than 0'
        return
      }
      if (Number(this.stakeAmount) > Number(this.unclaimedRewardAmount)) {
        this.error = 'You cannot claim more than your reward PALM balance'
        return
      }
      if (isNaN(this.stakeAmount)) {
        this.error = 'Claim amount is invalid—must be a number'
        return
      }
      const _tokenAddress = this.tokenAddress
      this.toggleClaimStakeDialog(false)
      const stakeAmountWei = utils.parseUnits(
        this.stakeAmount.toString(),
        'ether'
      )
      const id = Date.now()
      this.$store.commit('addId', id)
      try {
        const result = await claimStake(
          metadata.palmToken,
          stakeAmountWei,
          this.signer,
          () => {
            this.$notify({
              id,
              title: 'Claim',
              type: 'progress',
              duration: -1,
              data: {
                body: `Your claim of ${this.stakeAmount} PALM is waiting for confirmation.`
              }
            })
          }
        )
        if (result) {
          await this.$store.dispatch('updateBalance')
          this.$store.commit(
            'updateUserInfo',
            await getUserInfo(_tokenAddress, this.account)
          )
          this.$notify({
            id,
            title: 'Claim',
            type: 'success',
            data: {
              body: `Your claim of ${this.stakeAmount} PALM is confirmed.`
            }
          })
        } else {
          this.$notify({
            id,
            title: 'Error while confirming the claim',
            type: 'error'
          })
        }
      } catch (errorConfirm) {
        console.error(errorConfirm)
        this.$notify({
          id,
          title: 'Error while confirming the withdrawal',
          type: 'error'
        })
      } finally {
        this.$notify.close(id)
        this.$store.commit('removeId', id)
      }
    },
    async handleCompound() {
      if (!this.stakeAmount || this.stakeAmount <= 0) {
        this.error = 'Claim amount must be greater than 0'
        return
      }
      if (Number(this.stakeAmount) > Number(this.unclaimedRewardAmount)) {
        this.error = 'You cannot claim more than your reward PALM balance'
        return
      }
      if (isNaN(this.stakeAmount)) {
        this.error = 'Claim amount is invalid—must be a number'
        return
      }
      this.toggleClaimStakeDialog(false)
      const stakeAmountWei = utils.parseUnits(
        this.stakeAmount.toString(),
        'ether'
      )
      const id = Date.now()
      this.$store.commit('addId', id)
      try {
        const result = await compoundStake(
          metadata.palmToken,
          stakeAmountWei,
          this.signer,
          () => {
            this.$notify({
              id,
              title: 'Claim',
              type: 'progress',
              duration: -1,
              data: {
                body: `Your claim of ${this.stakeAmount} PALM is waiting for confirmation.`
              }
            })
          }
        )
        if (result) {
          await this.$store.dispatch('updateBalance')
          this.$store.commit(
            'updateUserInfo',
            await getUserInfo(metadata.palmToken, this.account)
          )
          this.$notify({
            id,
            title: 'Claim',
            type: 'success',
            data: {
              body: `Your claim of ${this.stakeAmount} PALM is confirmed.`
            }
          })
        } else {
          this.$notify({
            id,
            title: 'Error while confirming the claim',
            type: 'error'
          })
        }
      } catch (errorConfirm) {
        console.error(errorConfirm)
        this.$notify({
          id,
          title: 'Error while confirming the withdrawal',
          type: 'error'
        })
      } finally {
        this.$notify.close(id)
        this.$store.commit('removeId', id)
      }
    }
  },
  data() {
    return {
      stakeAmount: null,
      percentage: 0,
      error: null,
      userInfo: null,
      poolInfos
    }
  },
  computed: {
    ...mapState({
      balance: (state) => state.user.balance.palmToken,
      signer: (state) => state.web3Modal.provider.getSigner(),
      account: (state) => state.user.address.account
    }),
    tokenId() {
      if (useDialogs().metadata.value) {
        return useDialogs().metadata.value.tokenId
      } else {
        return null
      }
    },
    tokenAddress() {
      return metadata[this.tokenId]
    },
    unclaimedRewardAmount() {
      if (useDialogs().metadata.value) {
        return useDialogs().metadata.value.unclaimedRewardAmount
      } else {
        return null
      }
    },
    unclaimedRewardAmountFormatted() {
      if (useDialogs().metadata.value) {
        return truncateNumber(this.unclaimedRewardAmount, 6)
      } else {
        return null
      }
    },
    poolInfo() {
      if (this.poolInfos) {
        return this.poolInfos.find(
          (poolInfo) =>
            poolInfo.token.toLowerCase() === this.tokenAddress.toLowerCase()
        )
      } else {
        return null
      }
    },
    cooldownPeriod() {
      if (this.poolInfo) {
        return this.poolInfo.cooldownPeriod / (24 * 60 * 60)
      } else {
        return null
      }
    },
    cooldownPeriodFormatted() {
      if (this.poolInfo) {
        return `${this.cooldownPeriod} days`
      } else {
        return null
      }
    }
  },
  async mounted() {
    this.userInfo = await getUserInfo(metadata.palmToken, this.account)
  }
}
</script>
