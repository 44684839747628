<template>
  <PDialog
    title="ROI Calculator"
    @close="toggleRoiCalculatorDialog(false)"
    width="96"
  >
    <div class="text-sm">
      <hr class="mb-5" />
      <div class="space-y-5">
        <div class="space-y-2">
          <div v-if="userHasBalance" class="flex justify-between items-center">
            <label>Staked Balance</label>
            <label class="flex items-center gap-1">
              <div>Balance</div>
              <div>{{ balanceFormatted }}</div>
              <div><PLogo type="token" class="h-4" /></div>
            </label>
          </div>
          <div
            class="w-full flex flex-col border rounded bg-background-3 relative"
          >
            <Input
              v-model.number="usdInput"
              @update:model-value="updateInput($event, 'usd', 'token')"
            >
              <template #suffix>USD</template>
            </Input>
            <Input
              v-model.number="tokenInput"
              @update:model-value="updateInput($event, 'token', 'usd')"
            >
              <template #suffix>{{ token.symbol }}</template>
            </Input>
          </div>
          <div
            v-if="userHasBalance"
            class="flex justify-between items-center gap-0.5"
          >
            <RangeButton
              value="$100"
              :selected="usdInput == 100"
              @click="setInput('usd', 100)"
            />
            <RangeButton
              value="$1,000"
              :selected="usdInput == 1000"
              @click="setInput('usd', 1000)"
            />
            <RangeButton
              value="My Balance"
              :selected="Number(tokenInput) == Number(balance)"
              @click="setInput('token', balance)"
            />
          </div>
        </div>
        <div class="space-y-2">
          <div>Staked for</div>
          <div class="flex justify-between items-center gap-0.5">
            <RangeButton
              v-for="stakingPeriod in stakingPeriods"
              :key="stakingPeriod.label"
              :value="stakingPeriod.label"
              :selected="stakingPeriod === selectedStakingPeriod"
              @click="selectStakingPeriod(stakingPeriod)"
            />
          </div>
        </div>
        <div
          class="w-full space-y-2 bg-main bg-opacity-10 pt-4 pb-6 px-6 rounded-lg border border-main text-sm text-white mb-8"
        >
          <div>ROI at current rates</div>
          <div class="text-3xl font-medium">{{ roiUsdFormatted }}</div>
          <div class="text-inactive">
            {{ roiTokenFormatted }} ({{ aprFormatted }})
          </div>
        </div>
      </div>
    </div>
  </PDialog>
</template>

<script>
import { PDialog, PLogo } from '../../../palmswap-vue-ui'
import { useDialogs } from '@/hooks/useDialogs'
import { mapState } from 'vuex'
import Input from '@/components/common/Input'
import metadata from '@/contracts/metadata'
import { correctDecimalPlaces } from '@/contracts/helpers'
import {
  numberWithCommas,
  formatUSD,
  formatPercentage
} from '@/formatters/price'
import { TOKENS, STAKING_PERIODS } from '@/config/constants'
import { roi, apr } from '@/helpers/staking'
import { poolInfos } from '@/hooks/usePoolInfos'
import RangeButton from '@/components/common/RangeButton.vue'

export default {
  components: {
    PDialog,
    PLogo,
    Input,
    RangeButton
  },
  methods: {
    toggleRoiCalculatorDialog: useDialogs().toggleRoiCalculatorDialog,
    convert(val, source) {
      switch (source) {
        case 'usd':
          return (val / this.palmPrice).toFixed(4)
        case 'token':
          return (val * this.palmPrice).toFixed(2)
      }
    },
    updateInput(val, source) {
      switch (source) {
        case 'usd':
          this.tokenInput = val ? this.convert(val, 'usd', 'token') : null
          break
        case 'token':
          this.usdInput = val ? this.convert(val, 'token', 'usd') : null
          break
      }
    },
    selectStakingPeriod(stakingPeriod) {
      this.selectedStakingPeriod = stakingPeriod
    },
    setInput(source, val) {
      switch (source) {
        case 'usd':
          this.usdInput = val
          this.updateInput(val, 'usd', 'token')
          break
        case 'token':
          this.tokenInput = val
          this.updateInput(val, 'token', 'usd')
          break
      }
    }
  },
  data() {
    return {
      usdInput: null,
      tokenInput: null,
      poolInfos,
      stakingPeriods: STAKING_PERIODS,
      selectedStakingPeriod: STAKING_PERIODS[0]
    }
  },
  computed: {
    ...mapState({
      balance: (state) =>
        state.user.balance[useDialogs().metadata.value.tokenId]
    }),
    palmPrice() {
      return useDialogs().metadata.value
        ? useDialogs().metadata.value.palmPrice
        : null
    },
    balanceFormatted() {
      return this.balance
        ? `${numberWithCommas(this.balance, 4, true)} ${this.token.symbol}`
        : null
    },
    userHasBalance() {
      return this.balance > 0
    },
    tokenId() {
      return useDialogs().metadata.value
        ? useDialogs().metadata.value.tokenId
        : null
    },
    tokenAddress() {
      return metadata[this.tokenId]
    },
    token() {
      return TOKENS.find((token) => token.id === this.tokenId)
    },
    poolInfo() {
      if (this.poolInfos) {
        return this.poolInfos.find(
          (poolInfo) =>
            poolInfo.token.toLowerCase() === this.tokenAddress.toLowerCase()
        )
      } else {
        return null
      }
    },
    totalStaked() {
      return this.poolInfo
        ? correctDecimalPlaces(this.poolInfo.totalStaked)
        : null
    },
    roiToken() {
      return this.usdInput || this.tokenInput
        ? roi(
            this.tokenInput,
            this.totalStaked,
            this.poolInfo,
            this.selectedStakingPeriod
          )
        : null
    },
    roiTokenFormatted() {
      return this.roiToken
        ? `${numberWithCommas(this.roiToken, 4)} ${this.token.symbol}`
        : `— ${this.token.symbol}`
    },
    roiUsd() {
      return this.usdInput || this.tokenInput
        ? this.roiToken * this.palmPrice
        : null
    },
    roiUsdFormatted() {
      return this.roiUsd ? formatUSD(this.roiUsd) : '$—'
    },
    apr() {
      return this.tokenInput
        ? apr(
            Number(this.totalStaked) + Number(this.tokenInput),
            this.poolInfo,
            this.selectedStakingPeriod
          )
        : null
    },
    aprFormatted() {
      return this.apr ? formatPercentage(this.apr, this.apr > 1 ? 0 : 2) : '—%'
    }
  }
}
</script>
