<template>
  <PDialog
    title="Referral Program"
    position="center"
    @close="toggleReferralProgram(false)"
  >
    <div class="flex flex-col space-y-5 w-96">
      <div class="text-sm leading-5">
        Customers you invite to Palm PAD automatically become your customers on
        Palm Exchange. With every new referral you´ll earn 3% of the deposited
        amount of your customers, converted into PALM. Tokens.
      </div>
      <PButton class="w-full" color="main" size="lg" @click="visitProgram">
        Visit Referral Program
      </PButton>
    </div>
  </PDialog>
</template>

<script>
import { PDialog, PButton } from '../../../palmswap-vue-ui'
import { useDialogs } from '@/hooks/useDialogs'

export default {
  name: 'ReferralProgramDialog',
  components: { PButton, PDialog },
  setup() {
    const { toggleReferralProgram } = useDialogs()
    return { toggleReferralProgram }
  },
  methods: {
    visitProgram() {
      this.toggleReferralProgram(false)
      this.$router.push({ path: '/launchpad/referral' })
    }
  }
}
</script>

<style scoped></style>
