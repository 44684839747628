<template>
  <Dialog>
    <div class="error-dialog">
      <DialogHeader @on-close="toggleErrorDialog(false)" />
      <div class="error-dialog-container">
        <Error :message="'Transaction rejected'" />
        <div class="error-dialog-container-actions">
          <Button overlay @on-close="toggleErrorDialog(false)">Dismiss</Button>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script>
import DialogHeader from '@/components/common/DialogHeader.vue'
import Dialog from '@/components/common/Dialog.vue'
import Button from '@/components/common/Button.vue'
import { useDialogs } from '@/hooks/useDialogs'
import Error from './common/Error.vue'

export default {
  components: {
    Dialog,
    Button,
    DialogHeader,
    Error
  },
  data: function () {
    return {
      error: 'Transaction rejected'
    }
  },

  setup() {
    const { toggleErrorDialog } = useDialogs()
    return {
      toggleErrorDialog
    }
  }
}
</script>
<style lang="scss" scoped>
.error {
  &-dialog {
    padding: 16px 24px 24px 24px;
    width: 400px;
    box-sizing: border-box;
    &-container {
      padding: 0 16px 0 16px;
      &-message {
        margin-top: 35px;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
      }
      &-actions {
        margin-top: 40px;
        > * {
          height: 44px;
          width: 100%;
        }
      }
    }
  }
}
</style>
