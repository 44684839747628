<template>
  <div>
    <div class="flex space-x-1">
      <CurrencyInput
        class="flex-1"
        v-model.number="amount"
        @update:model-value="updateEquivalent"
        :currency="currency"
        :number="true"
        placeholder="0.0000"
        :error="error"
        :disabled="disabled"
        :minValue="0"
        inputmode="decimal"
        dataCy="trade-page_make-order_currency-input"
      />
      <Input
        class="transition-all"
        :class="equivalentEnabled ? 'w-1/2' : 'w-12'"
        v-model.number="equivalent"
        @update:model-value="updateAmount"
        :number="true"
        :placeholder="equivalentEnabled ? '0.0000' : ''"
        :disabled="!equivalentEnabled"
        :minValue="0"
        inputmode="decimal"
        dataCy="trade-page_make-order_usd-input"
      >
        <template v-slot:suffix>
          <button
            class="cursor-pointer text-xs flex h-full w-full items-center select-none px-3"
            @click="toggleEquiv"
          >
            USD
          </button>
        </template>
      </Input>
    </div>
    <FractionSelector :disabled="fractionDisabled" @update="setFraction" />
  </div>
</template>
<script>
import Big from 'big.js'
import { useCurrency } from '@/hooks/useCurrency'
import FractionSelector from '@/components/common/FractionSelector'
import CurrencyInput from '@/components/trade/make-order/CurrencyInput'
import Input from '@/components/common/Input'
import { roundAmount } from '@/contracts/helpers'
import { mapState } from 'vuex'

const ensureBig = (val) => new Big(Number(val))

export default {
  components: {
    CurrencyInput,
    FractionSelector,
    Input
  },
  data() {
    return {
      equivalent: null,
      equivalentEnabled: false,
      amount: null,
      fraction: null
    }
  },
  computed: {
    ...mapState({
      orderBookSize: (state) => state.tradeStore.orderBookValues.size
    }),
    maxAmount() {
      return this.maxSize || this.totalCollateral
    },
    fractionDisabled() {
      return this.disabled || this.maxAmount.lte(0)
    },
    equivToAmount() {
      if (this.equivalent && this.price) {
        const newBig = ensureBig(this.equivalent)
        const price = ensureBig(this.price)
        return newBig.div(price)
      } else return 0
    },
    amountToEquiv() {
      if (this.amount && this.price) {
        const newBig = ensureBig(this.amount)
        const price = ensureBig(this.price)
        return roundAmount(newBig.mul(ensureBig(price)))
      } else return 0
    }
  },
  emits: ['update:modelValue'],
  props: {
    totalCollateral: Big,
    modelValue: String,
    error: String,
    maxSize: Big,
    disabled: Boolean,
    isMarket: Boolean,
    price: Big,
    currencySymbol: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const currency = useCurrency(props.currencySymbol)

    return {
      currency
    }
  },
  watch: {
    orderBookSize() {
      const val = new Big(this.orderBookSize)
      this.$emit('update:modelValue', val && new Big(val))
      this.setAmount(val)
      this.setEquiv(this.amountToEquiv)
    },
    amount(val) {
      this.$emit('update:modelValue', val && new Big(val))
    },
    maxAmount(val) {
      if (Number(val) > 0 && this.amount && this.fraction) {
        this.setAmount(this.maxAmount.mul(this.fraction))
        this.setEquiv(this.amountToEquiv)
      }
    }
  },
  methods: {
    setFraction(fraction) {
      this.fraction = fraction
      this.setAmount(this.maxAmount.mul(fraction))
      this.setEquiv(this.amountToEquiv)
    },
    setEquiv(val) {
      this.equivalent = this.equivalentEnabled ? val : null
    },
    toggleEquiv() {
      this.equivalentEnabled = !this.equivalentEnabled
      this.amount && this.setEquiv(this.amountToEquiv)
    },
    updateEquivalent() {
      this.fraction = null
      if (this.amountToEquiv) {
        this.setEquiv(this.amountToEquiv)
      } else this.setEquiv('')
    },
    updateAmount(val) {
      this.fraction = null
      this.$emit('update:modelValue', val && new Big(val))
      if (this.equivToAmount) {
        this.setAmount(this.equivToAmount)
      } else this.setAmount('')
    },
    setAmount(newAmount) {
      this.amount = roundAmount(newAmount).toString()
    },
    formatUSDT(amount) {
      return `${Number(amount).toFixed(2)} USDT`
    }
  }
}
</script>

<style scoped>
:deep(.label-container) {
  @apply right-0 !important;
}
</style>
