import { getPriceFeedContract } from '@/contracts/contracts'
import { watch, ref, computed } from 'vue'

export const usePriceFeed = (symbol) => {
  const priceRef = ref(null)
  const priceFeedContract = getPriceFeedContract(symbol)
  watch(
    symbol,
    async () => {
      priceRef.value = await priceFeedContract.latestAnswer()
    },
    { immediate: true }
  )
  const price = computed(() => {
    return priceRef.value
  })
  const getPriceAtRound = async (roundId) => {
    return await priceFeedContract.getAnswer(roundId)
  }
  return {
    price,
    getPriceAtRound
  }
}
