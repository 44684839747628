<template>
  <component
    :is="linkComponent"
    :to="route"
    :href="route"
    @click="handleNavigation"
  >
    <div
      :class="{
        'text-inactive hover:text-white hover:opacity-50': $route.name !== name,
        'text-white from-tab-start border-main': $route.name === name
      }"
      class="transition mt-4 mb-1"
    >
      {{ name }}
    </div>
  </component>
</template>
<script>
import { useDialogs } from '@/hooks/useDialogs'

export default {
  props: ['name', 'route'],
  setup() {
    const { toggleLeavingNetworkDialog, toggleSidebarDialog } = useDialogs()
    return { toggleLeavingNetworkDialog, toggleSidebarDialog }
  },
  computed: {
    isExternal() {
      return this.route.startsWith('http')
    },
    linkComponent() {
      return this.isExternal ? 'a' : 'router-link'
    }
  },
  methods: {
    handleNavigation(e) {
      this.toggleSidebarDialog(false)
      if (this.isExternal && this.name !== 'Faucet') {
        e.preventDefault()
        this.toggleLeavingNetworkDialog(true, {
          url: this.route,
          name: this.name
        })
      }
    }
  }
}
</script>
