import { computed, unref } from 'vue'
import { useAppState, getCurrency } from './useAppState'

export const useCurrency = (_symbol = null) => {
  const symbol = computed(() => unref(_symbol))

  const { currency: defaultCurrency } = useAppState()

  return computed(() => {
    if (!symbol.value) {
      return defaultCurrency.value
    }

    try {
      return getCurrency(symbol.value)
    } catch (error) {
      return null
    }
  })
}
