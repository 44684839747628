<template>
  <div class="label-value">
    <span class="flex items-center gap-1 text-xs">
      {{ label }}
      <PTooltip v-if="tooltip" :content="this.tooltip" placement="right" icon>
        <div class="hidden"></div>
      </PTooltip>
    </span>
    <span v-if="!isLoading" class="text-xs">
      {{ value }}
    </span>
    <TinyLoader v-else />
  </div>
</template>
<script>
import TinyLoader from '../../common/TinyLoader.vue'
import { PTooltip } from '../../../../palmswap-vue-ui'

export default {
  components: {
    TinyLoader,
    PTooltip
  },
  props: {
    isLoading: {
      type: Boolean
    },
    label: {
      type: String
    },
    value: {
      type: String
    },
    tooltip: {
      type: String
    }
  }
}
</script>
<style lang="scss" scoped>
.label-value {
  & > span:last-child {
    color: white;
  }
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
}
</style>
