import { gql } from '@apollo/client'
import { client } from '@/api/graph'
import {
  CANDLE_STICKS,
  REFERRAL_COMMISSIONS_OF,
  REFERRALS_DATA_POINTS,
  REFERRALS_OF,
  REFERRAL_OF,
  STATS_24H
} from '@/api/queries'
import { getAmmContractAddress } from '@/contracts/contracts'

export const fetchChartData = async (symbol, countBack, endTime, interval) => {
  const market = getAmmContractAddress(symbol)
  const { data } = await client.query({
    query: gql(CANDLE_STICKS),
    variables: {
      interval,
      first: countBack > 1000 ? 1000 : countBack,
      endTime,
      market
    }
  })

  return data.candleSticks || []
}

export const fetch24hStats = async (symbol, startTime, endTime) => {
  const market = getAmmContractAddress(symbol)
  const { data = {} } = await client.query({
    query: gql(STATS_24H),
    variables: {
      startTime,
      endTime,
      market
    }
  })

  return data.candleSticks || []
}

export const fetchReferralsDataPoints = async (
  referrer,
  interval,
  startTime,
  endTime
) => {
  const { data } = await client.query({
    query: gql(REFERRALS_DATA_POINTS),
    variables: {
      referrer,
      startTime,
      endTime,
      interval
    }
  })
  return data.referralsDataPoints || []
}

export const fetchReferrals = async (referrer) => {
  const { data } = await client.query({
    query: gql(REFERRALS_OF),
    variables: {
      referrer
    }
  })
  return data.referrals || []
}

export const fetchReferral = async (referral) => {
  const { data } = await client.query({
    query: gql(REFERRAL_OF),
    variables: {
      referral
    }
  })
  return (data.referrals || [])[0]
}

export const fetchReferralCommissions = async (referrer) => {
  const { data } = await client.query({
    query: gql(REFERRAL_COMMISSIONS_OF),
    variables: {
      referrer
    }
  })
  return data.referralCommissions || []
}
