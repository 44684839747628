import { ref, computed } from 'vue'
import { gql } from '@apollo/client'
import { client, wssClient } from '../api/graph'
import {
  LAUNCHPAD_AFFILIATE_SUBSCRIPTION,
  LAUNCHPAD_EARNINGS_SUBSCRIPTION,
  LAUNCHPAD_FUNDED_EVENTS_QUERY,
  LAUNCHPAD_PROJECT_SUBSCRIPTION,
  LAUNCHPAD_REFERRAL_PARTICIPANTS_SUBSCRIPTION,
  LAUNCHPAD_SALES_MANAGER_REFERRAL_PARTICIPANTS_SUBSCRIPTION,
  LAUNCHPAD_REFERRALS_QUERY,
  LAUNCHPAD_SALES_MANAGER_REFERRALS_QUERY,
  LAUNCHPAD_BNB_COMMISSION_SUBSCRIPTION,
  LAUNCHPAD_BNB_COMMISSION_QUERY
} from '../api/queries'
import {
  formatLaunchpadAffiliate,
  formatLaunchpadFundingEvent,
  formatLaunchpadParticipant,
  formatLaunchpadProject,
  formatLaunchpadBnbCommissionEvent
} from '../utils/utils'
import metadata from '@/contracts/metadata'
import Big from 'big.js'
import { palmPad } from '@/contracts/contracts'
import { useStore } from 'vuex'
import { BigNumber } from 'ethers'
import { apiGatewayClient } from '@/api/gateway'

const rawLaunchpadProjectRef = ref({})
const rawLaunchpadLatestEventsRef = ref([])
const rawLaunchpadLatestBnbCommissionEventsRef = ref([])
const rawLaunchpadOverviewRef = ref({})
const rawLaunchpadFundedEventsRef = ref([])
const rawLaunchpadBnbCommissionEventsRef = ref([])
const rawLaunchpadLatestParticipantsRef = ref([])
const rawLaunchpadParticipantsRef = ref([])
const rawReferralsRef = ref({})
const subscriptionProjectRef = ref(null)
const subscriptionEarningsRef = ref(null)
const subscriptionBnbCommissionRef = ref(null)
const subscriptionAffiliateRef = ref(null)
const subscriptionParticipantsRef = ref(null)

export const subscribeProject = () => {
  if (subscriptionProjectRef.value) {
    subscriptionProjectRef.value.unsubscribe()
  }

  subscriptionProjectRef.value = wssClient
    .subscribe({
      query: gql(LAUNCHPAD_PROJECT_SUBSCRIPTION),
      variables: {
        id: metadata.palmPad.toLowerCase()
      }
    })
    .subscribe({
      next(data) {
        const launchpadProject = data.data.launchpadProject
        rawLaunchpadProjectRef.value = launchpadProject
          ? formatLaunchpadProject(launchpadProject)
          : {}
      },
      error(err) {
        console.error('err', err)
      }
    })
}

export const subscribeEarnings = (sponsor) => {
  if (subscriptionEarningsRef.value) {
    subscriptionEarningsRef.value.unsubscribe()
  }

  subscriptionEarningsRef.value = wssClient
    .subscribe({
      query: gql(LAUNCHPAD_EARNINGS_SUBSCRIPTION),
      variables: {
        project: metadata.palmPad.toLowerCase(),
        sponsor
      }
    })
    .subscribe({
      next(data) {
        const launchpadFundedEvents = data.data.launchpadFundedEvents
        rawLaunchpadLatestEventsRef.value = launchpadFundedEvents
          ? launchpadFundedEvents.map(formatLaunchpadFundingEvent)
          : []
      },
      error(err) {
        console.error('err', err)
      }
    })
}

export const subscribeBnbCommissions = (sponsor) => {
  if (subscriptionBnbCommissionRef.value) {
    subscriptionBnbCommissionRef.value.unsubscribe()
  }

  subscriptionBnbCommissionRef.value = wssClient
    .subscribe({
      query: gql(LAUNCHPAD_BNB_COMMISSION_SUBSCRIPTION),
      variables: {
        user: sponsor
      }
    })
    .subscribe({
      next(data) {
        const launchpadBNBCommissionEvents =
          data.data.launchpadBNBCommissionEvents
        rawLaunchpadLatestBnbCommissionEventsRef.value =
          launchpadBNBCommissionEvents
            ? launchpadBNBCommissionEvents.map(
                formatLaunchpadBnbCommissionEvent
              )
            : []
      },
      error(err) {
        console.error('err', err)
      }
    })
}

export const subscribeAffiliate = (sponsor) => {
  if (subscriptionAffiliateRef.value) {
    subscriptionAffiliateRef.value.unsubscribe()
  }

  subscriptionAffiliateRef.value = wssClient
    .subscribe({
      query: gql(LAUNCHPAD_AFFILIATE_SUBSCRIPTION),
      variables: {
        sponsor
      }
    })
    .subscribe({
      next(data) {
        const salesManager = rawLaunchpadProjectRef.value.salesManager ?? ''
        const launchpadAffiliate = data.data.launchpadAffiliate

        rawLaunchpadOverviewRef.value = launchpadAffiliate
          ? formatLaunchpadAffiliate(launchpadAffiliate, salesManager)
          : {
              id: sponsor.toLowerCase(),
              isVip: salesManager.toLowerCase() === sponsor.toLowerCase(),
              isSalesManager:
                salesManager.toLowerCase() === sponsor.toLowerCase()
            }
      },
      error(err) {
        console.error('err', err)
      }
    })
}

export const subscribeParticipants = (sponsor, isSalesManager) => {
  if (subscriptionParticipantsRef.value) {
    subscriptionParticipantsRef.value.unsubscribe()
  }

  subscriptionParticipantsRef.value = wssClient
    .subscribe({
      query: gql(
        isSalesManager
          ? LAUNCHPAD_SALES_MANAGER_REFERRAL_PARTICIPANTS_SUBSCRIPTION
          : LAUNCHPAD_REFERRAL_PARTICIPANTS_SUBSCRIPTION
      ),
      variables: {
        sponsor
      }
    })
    .subscribe({
      next(data) {
        const launchpadParticipants = data.data.launchpadParticipants
        rawLaunchpadLatestParticipantsRef.value = launchpadParticipants
          ? launchpadParticipants.map(formatLaunchpadParticipant)
          : []
      },
      error(err) {
        console.error('err', err)
      }
    })
}

const fetchReferralEarnings = async (sponsor, startTime, endTime) => {
  const { data } = await client.query({
    query: gql(LAUNCHPAD_FUNDED_EVENTS_QUERY),
    variables: {
      startTime,
      endTime,
      project: metadata.palmPad.toLowerCase(),
      sponsor: sponsor.toLowerCase()
    }
  })
  rawLaunchpadFundedEventsRef.value = data.launchpadFundedEvents
    ? data.launchpadFundedEvents.map(formatLaunchpadFundingEvent)
    : []
  return data.launchpadFundedEvents
}

const fetchBnbCommissions = async (sponsor, startTime, endTime) => {
  const { data } = await client.query({
    query: gql(LAUNCHPAD_BNB_COMMISSION_QUERY),
    variables: {
      startTime,
      endTime,
      user: sponsor.toLowerCase()
    }
  })

  rawLaunchpadBnbCommissionEventsRef.value = data.launchpadBNBCommissionEvents
    ? data.launchpadBNBCommissionEvents.map(formatLaunchpadBnbCommissionEvent)
    : []
  return data.launchpadBNBCommissionEvents
}

const fetchPaidReferrals = async (
  sponsor,
  startTime,
  endTime,
  isSalesManager
) => {
  const { data } = await client.query({
    query: gql(
      isSalesManager
        ? LAUNCHPAD_SALES_MANAGER_REFERRALS_QUERY
        : LAUNCHPAD_REFERRALS_QUERY
    ),
    variables: {
      startTime,
      endTime,
      sponsor: sponsor.toLowerCase()
    }
  })
  rawLaunchpadParticipantsRef.value = data.launchpadParticipants
    ? data.launchpadParticipants.map(formatLaunchpadParticipant)
    : []
  return data.launchpadParticipants
}

const fetchReferrals = async (sponsor) => {
  const data = await apiGatewayClient
    .get(`/referrals?referrer=${sponsor.toLowerCase()}`)
    .then(({ data }) => data)
  rawReferralsRef.value = data
}

export const useLaunchpadTerms = () => {
  const getTermsData = () => {
    return JSON.parse(localStorage.getItem('LAUNCHPAD_TERMS_ACCEPTED')) || {}
  }
  const saveTermsData = (termsData) => {
    localStorage.setItem('LAUNCHPAD_TERMS_ACCEPTED', JSON.stringify(termsData))
  }
  const hasAccepted = (account) => {
    const termsData = getTermsData()
    return !!termsData[account]
  }
  const accept = (account) => {
    let termsData = getTermsData()
    termsData[account] = 1
    saveTermsData(termsData)
  }
  return {
    accept,
    hasAccepted
  }
}

export const useLaunchpadProject = () => {
  const launchpadProject = computed(() => {
    const rawProject = rawLaunchpadProjectRef.value
    const price = rawProject.saleAmount
      ? rawProject.totalFundedAmount.div(rawProject.saleAmount)
      : new Big(0)
    return {
      ...rawProject,
      price
    }
  })

  const contract = palmPad

  const getInvestedAmount = async (address) => {
    return await contract.paidAmount(address)
  }

  const getBnbCommission = async (address) => {
    return await contract.bnbCommission(address)
  }

  const { state } = useStore()
  const signer = computed(
    () => state.web3Modal.provider && state.web3Modal.provider.getSigner()
  )

  const invest = async (amount, sponsor) => {
    const value = BigNumber.from(amount.toString())
    if (sponsor) {
      return await contract
        .connect(signer.value)
        .fundWithSponsor(sponsor, { value })
    }
    return await signer.value.sendTransaction({
      to: contract.address,
      value
    })
  }

  const claimBnbCommission = async () => {
    return await contract.connect(signer.value).claimBnbCommission()
  }

  return {
    launchpadProject,
    subscribeProject,
    getInvestedAmount,
    getBnbCommission,
    invest,
    claimBnbCommission
  }
}

export const useLaunchpadReferrals = () => {
  const latestEarnings = computed(() => {
    return rawLaunchpadLatestEventsRef.value
  })
  const earnings = computed(() => {
    return rawLaunchpadFundedEventsRef.value
  })
  const latestReferrals = computed(() => {
    return rawLaunchpadLatestParticipantsRef.value
  })
  const affiliate = computed(() => {
    return rawLaunchpadOverviewRef.value
  })
  const referralsCount = computed(() => {
    return rawReferralsRef.value && rawReferralsRef.value.count
  })
  const referralParticipants = computed(() => {
    return rawLaunchpadParticipantsRef.value
  })

  const latestBnbCommissionEvents = computed(() => {
    return rawLaunchpadLatestBnbCommissionEventsRef.value
  })

  const bnbCommissionEvents = computed(() => {
    return rawLaunchpadBnbCommissionEventsRef.value
  })

  return {
    subscribeBnbCommissions,
    subscribeEarnings,
    subscribeParticipants,
    subscribeAffiliate,
    fetchBnbCommissions,
    fetchReferralEarnings,
    fetchReferrals,
    fetchPaidReferrals,
    latestReferrals,
    referralParticipants,
    affiliate,
    referralsCount,
    latestEarnings,
    latestBnbCommissionEvents,
    bnbCommissionEvents,
    earnings
  }
}
