import { constants } from 'ethers'
import { usdt, palmToken, palmBNBLP, palmUSDTLP } from '@/contracts/contracts'
import { getUser } from '@/hooks/useUserSettings'
import { isMainnet } from '@/helpers'
import { formatEther } from '@ethersproject/units'

const LAST_CONNECTED_KEY = 'lastConnectedAt'

const userStore = {
  state: {
    address: {
      account: '',
      wallet: ''
    },
    balance: {
      account: 0,
      palmToken: 0,
      palmBNBLP: 0,
      palmUSDTLP: 0,
      wallet: 0
    },
    needToShowTAC: false,
    lastConnectedAt: localStorage.getItem(LAST_CONNECTED_KEY)
  },
  getters: {},
  mutations: {
    setAccountBalance(state, payload) {
      state.balance.account = payload
    },
    setAccountPalmTokenBalance(state, payload) {
      state.balance.palmToken = payload
    },
    setAccountPalmBNBLPBalance(state, payload) {
      state.balance.palmBNBLP = payload
    },
    setAccountPalmUSDTLPBalance(state, payload) {
      state.balance.palmUSDTLP = payload
    },
    setWalletBalance(state, payload) {
      state.balance.wallet = payload
    },
    setAccountAddress(state, payload) {
      state.address.account = payload ? payload.toLowerCase() : payload
    },
    setWalletAddress(state, payload) {
      state.address.wallet = payload ? payload.toLowerCase() : payload
    },
    setTACVisible(state, payload) {
      state.needToShowTAC = payload
    },
    setLastConnectedAt(state, payload) {
      state.lastConnectedAt = payload
    }
  },
  actions: {
    async getAccountBalance({ state, commit }) {
      let balance = 0
      if (state.address.account) {
        balance = await usdt.balanceOf(state.address.account)
        balance = formatEther(balance.toString())
      }
      commit('setAccountBalance', balance)
    },
    async getAccountPalmTokenBalance({ state, commit }) {
      let balance = 0
      if (state.address.account) {
        balance = await palmToken.balanceOf(state.address.account)
        balance = formatEther(balance.toString())
      }
      commit('setAccountPalmTokenBalance', balance)
    },
    async getAccountPalmBNBLPBalance({ state, commit }) {
      let balance = 0
      if (state.address.account) {
        balance = await palmBNBLP.balanceOf(state.address.account)
        balance = formatEther(balance.toString())
      }
      commit('setAccountPalmBNBLPBalance', balance)
    },
    async getAccountPalmUSDTLPBalance({ state, commit }) {
      let balance = 0
      if (state.address.account) {
        try {
          balance = await palmUSDTLP.balanceOf(state.address.account)
          balance = formatEther(balance.toString())
        } catch (e) {
          console.error(e)
        }
      }
      commit('setAccountPalmUSDTLPBalance', balance)
    },
    async getWalletBalance({ state, commit }) {
      let balance = 0
      if (state.address.wallet) {
        balance = await usdt.balanceOf(state.address.wallet)
        balance = formatEther(balance.toString())
      }
      commit('setWalletBalance', balance)
    },
    async resetUserData({ commit }) {
      commit('setAccountBalance', 0)
      commit('setAccountPalmTokenBalance', 0)
      commit('setAccountPalmBNBLPBalance', 0)
      commit('setAccountPalmUSDTLPBalance', 0)
      commit('setWalletBalance', 0)
      commit('setAccountAddress', '')
      commit('setWalletAddress', '')
    },
    async updateBalance({ dispatch }) {
      await dispatch('getAccountBalance')
      await dispatch('getAccountPalmTokenBalance')
      await dispatch('getAccountPalmBNBLPBalance')
      // fix empty address Ethers.js error
      !isMainnet && dispatch('getAccountPalmUSDTLPBalance')
      await dispatch('getWalletBalance')
    },
    async setTACVisible({ state, commit }) {
      const user = await getUser(state.address.account)
      const smartWallet = localStorage.getItem(
        'smartWallet.' + state.address.account.toLowerCase()
      )
      if (
        !user ||
        !user.termsAccepted ||
        !smartWallet ||
        smartWallet == constants.AddressZero
      ) {
        commit('setTACVisible', true)
      } else {
        commit('setTACVisible', false)
      }
    },
    updateLastConnectedAt({ commit, payload: time }) {
      const timeStamp = time ? Number(time) : Date.now()
      localStorage.setItem(LAST_CONNECTED_KEY, timeStamp)
      commit('setLastConnectedAt', timeStamp)
    }
  }
}
export default userStore
