import { ref, computed } from 'vue'
import { gql } from '@apollo/client'
import { wssClient } from '../api/graph'
import { SMART_WALLET_INFO } from '../api/queries'

const walletInfoRef = ref({})
const walletInfoSubscriptionRef = ref(null)

export const subscribeWalletInfo = (trader) => {
  if (walletInfoSubscriptionRef.value) {
    walletInfoSubscriptionRef.value.unsubscribe()
  }

  walletInfoSubscriptionRef.value = wssClient
    .subscribe({
      query: gql(SMART_WALLET_INFO),
      variables: {
        id: trader.toLowerCase()
      }
    })
    .subscribe({
      next(data) {
        walletInfoRef.value = data.data.smartWallet
      },
      error(err) {
        console.error('err', err)
      }
    })
}

export const unsubscribeWalletInfo = () => {
  if (walletInfoSubscriptionRef.value) {
    walletInfoSubscriptionRef.value.unsubscribe()
  }
  walletInfoSubscriptionRef.value = null
}

export const useWalletInfo = () => {
  const walletInfo = computed(() => {
    if (!walletInfoRef.value) {
      return {}
    }
    return walletInfoRef.value
  })

  return {
    walletInfo
  }
}
