import { clearingHouse } from '@/contracts/contracts';
import { getAddress } from '@ethersproject/address';
const isArbitragerMapping = {};
export const getIsArbitrager = async (_address) => {
    const checksumAddress = getAddress(_address);
    if (!(checksumAddress in isArbitragerMapping)) {
        isArbitragerMapping[checksumAddress] = await clearingHouse.arbitragers(checksumAddress);
    }
    return isArbitragerMapping[checksumAddress];
};
