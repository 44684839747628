import { HttpLink, ApolloClient, InMemoryCache } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import axios from 'axios'
import { API_URL, FEATURE_FLAGS } from '../config/constants'
import { isFeatureEnabled } from '@/utils/utils'

export const httpCompetitionClient = axios.create({
  baseURL: `https://${process.env.VUE_APP_COMPETITION_GRAPH_URL}`
})

const createWssRewardsLink = () =>
  new WebSocketLink({
    uri: `wss://${process.env.VUE_APP_REWARDS_GRAPH_URL}`,
    options: {
      reconnect: true
    }
  })

export const httpRewardsClient = new ApolloClient({
  link:
    isFeatureEnabled(FEATURE_FLAGS.TRADING_REWARDS) && createWssRewardsLink(),
  cache: new InMemoryCache()
})

export const apiClient = axios.create({
  baseURL: API_URL
})

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
}

export const client = new ApolloClient({
  uri: `https://${process.env.VUE_APP_GRAPH_URL}`,
  cache: new InMemoryCache(),
  defaultOptions
})

const wssLink = new WebSocketLink({
  uri: `wss://${process.env.VUE_APP_GRAPH_URL}`,
  options: {
    reconnect: true
  }
})

const httpLink = new HttpLink({
  uri: `https://${process.env.VUE_APP_GRAPH_URL}`
})

export const wssClient = new ApolloClient({
  link: wssLink,
  cache: new InMemoryCache()
})

export const httpClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
})
