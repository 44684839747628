<template>
  <div>
    <div class="flex justify-between text-sm mb-2">
      <div>Leverage up to 10x</div>
      <div>Slider <PSwitch v-model="isSlider" :disabled="disabled" /></div>
    </div>
    <div v-if="!isSlider" class="flex space-x-1">
      <Input
        class="pb-2"
        :modelValue="formattedValue"
        :modelModifiers="{ number: true }"
        @update:modelValue="updateLeverage"
        :disabled="disabled"
        data-cy="trade-page_make-order_leverage-input"
      />
      <div class="flex space-x-1">
        <PButton
          color="secondary"
          outlined
          :disabled="disabled"
          :overlay="true"
          @click="updateLeverage(2)"
          data-cy="trade-page_make-order_set-leverage-2-button"
        >
          2x
        </PButton>
        <PButton
          color="secondary"
          outlined
          :disabled="disabled"
          :overlay="true"
          @click="updateLeverage(5)"
          data-cy="trade-page_make-order_set-leverage-5-button"
        >
          5x
        </PButton>
        <PButton
          color="secondary"
          outlined
          :disabled="disabled"
          :overlay="true"
          @click="updateLeverage(10)"
          data-cy="trade-page_make-order_set-leverage-10-button"
          class="text-xs p-1"
        >
          10x
        </PButton>
      </div>
    </div>
    <div v-if="isSlider">
      <PRangeSlider
        :disabled="disabled"
        :color="sliderColor"
        :modelValue="modelValue"
        @update:modelValue="updateLeverage"
      />
    </div>
  </div>
</template>
<script>
import Input from '../../common/Input.vue'
import { PButton, PSwitch, PRangeSlider } from '../../../../palmswap-vue-ui'
import Big from 'big.js'
import { SIDE_LONG } from '@/contracts/helpers'

export default {
  components: { PRangeSlider, PSwitch, Input, PButton },
  props: {
    side: Number,
    modelValue: {},
    disabled: {
      type: Boolean
    }
  },
  data() {
    return { isSlider: false }
  },
  emits: ['update:modelValue'],
  computed: {
    formattedValue() {
      return this.modelValue % 1 === 0
        ? this.modelValue
        : this.modelValue.toFixed(2)
    },
    sliderColor() {
      return this.side === SIDE_LONG ? 'green' : 'red'
    }
  },
  methods: {
    updateLeverage(newLeverage) {
      let validatedLeverage = newLeverage
      if (!this.isSlider) {
        validatedLeverage = Math.floor(newLeverage)
      }
      if (newLeverage < 1) {
        validatedLeverage = 1
      }
      if (newLeverage > 10) {
        validatedLeverage = 10
      }
      this.$emit('update:modelValue', new Big(validatedLeverage))
    }
  }
}
</script>
<style lang="scss" scoped></style>
