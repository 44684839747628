<template>
  <transition name="fade">
    <div>
      <PButton
        color="success"
        v-if="side === SIDE_LONG"
        @click="$emit('confirm', $event)"
        :disabled="disabled"
        class="w-full flex justify-between items-center"
        data-cy="trade-page_make-order_place-order-button"
      >
        <span>Place long order</span>
        <span>
          {{ formattedTotalCost }}
        </span>
      </PButton>
      <PButton
        v-else
        color="danger"
        @click="$emit('confirm', $event)"
        :disabled="disabled"
        class="w-full flex justify-between items-center"
        data-cy="trade-page_make-order_place-order-button"
      >
        <span>Place short order</span>
        <span>
          {{ formattedTotalCost }}
        </span>
      </PButton>
    </div>
  </transition>
</template>

<script>
import { PButton } from '../../../../palmswap-vue-ui'
import { SIDE_LONG, SIDE_SHORT } from '../../../contracts/helpers'

export default {
  name: 'PlaceOrderButtons',
  components: {
    PButton
  },
  emits: ['confirm'],
  props: {
    totalCost: {},
    disabled: Boolean,
    loading: Boolean,
    side: Number
  },
  setup() {
    return {
      SIDE_LONG,
      SIDE_SHORT
    }
  },
  computed: {
    formattedTotalCost() {
      const totalCost = this.totalCost ? this.totalCost.toFixed(2) : '0'
      return `${totalCost} USDT`
    }
  }
}
</script>

<style scoped></style>
