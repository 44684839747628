<template>
  <PNavDropdown
    name="Earn"
    :isActiveRoute="isActiveRoute"
    :onOpen="() => toggleBlur(true)"
    :onClose="() => toggleBlur(false)"
    :class="{ 'z-20': blurShow }"
  >
    <template v-slot:main>
      <div class="flex flex-col gap-2 w-40">
        <PNavDropdownItem>
          <NavItemWrapper
            v-if="isStakingEnabled"
            name="Staking"
            :route="`${
              isTestnet ? 'https://app.palmswap.org' : ''
            }/earn/staking`"
            class="flex gap-1 items-center cursor-pointer px-3 py-2 rounded-lg hover:bg-background-2"
          >
            <Square3Stack3DIcon class="h-4" />
            <div :class="{ 'text-white': isActiveRouteStaking }">Staking</div>
          </NavItemWrapper>
        </PNavDropdownItem>
        <PNavDropdownItem>
          <NavItemWrapper
            v-if="isFarmEnabled"
            name="Farm"
            :route="`${isTestnet ? 'https://app.palmswap.org' : ''}/earn/farm`"
            class="flex gap-1 items-center cursor-pointer px-3 py-2 rounded-lg hover:bg-background-2"
          >
            <img src="../../assets/farmIcon.svg" class="h-4" />
            <div :class="{ 'text-white': isActiveRouteFarm }">Farm</div>
          </NavItemWrapper>
        </PNavDropdownItem>
        <PNavDropdownItem>
          <NavItemWrapper
            v-if="isLaunchpadEnabled"
            name="Launchpad"
            :route="`${isTestnet ? 'https://app.palmswap.org' : ''}/launchpad`"
            class="flex gap-1 items-center cursor-pointer px-3 py-2 rounded-lg hover:bg-background-2"
          >
            <ArrowDownOnSquareIcon class="h-4" />
            <div :class="{ 'text-white': isActiveRouteLaunchpad }">
              Launchpad
            </div>
          </NavItemWrapper>
        </PNavDropdownItem>
        <PNavDropdownItem>
          <NavItemWrapper
            v-if="isVestingEnabled"
            name="Claim"
            :route="`${isTestnet ? 'https://app.palmswap.org' : ''}/claim`"
            class="flex gap-1 items-center cursor-pointer px-3 py-2 rounded-lg hover:bg-background-2"
          >
            <Squares2X2Icon class="h-4" />
            <div :class="{ 'text-white': isActiveRouteClaim }">Claim</div>
          </NavItemWrapper>
        </PNavDropdownItem>
      </div>
    </template>
  </PNavDropdown>
  <Transition name="fade">
    <div
      v-show="blurShow"
      class="fixed -inset-2 z-10 flex justify-center items-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"
    />
  </Transition>
</template>

<script>
import { isFeatureEnabled } from '@/utils/utils'
import { FEATURE_FLAGS, NODE_ENV } from '@/config/constants'
import {
  PNavDropdown,
  PNavDropdownItem
} from '../../../palmswap-vue-ui/dist/palmswap-ui.es'
import { Squares2X2Icon, ArrowDownOnSquareIcon } from '@heroicons/vue/24/solid'
import { Square3Stack3DIcon } from '@heroicons/vue/20/solid'
import NavItemWrapper from '@/components/top-bar/NavItemWrapper'

export default {
  components: {
    PNavDropdown,
    Squares2X2Icon,
    ArrowDownOnSquareIcon,
    Square3Stack3DIcon,
    NavItemWrapper,
    PNavDropdownItem
  },
  data() {
    return {
      blurShow: false
    }
  },
  setup() {
    const isStakingEnabled = isFeatureEnabled(FEATURE_FLAGS.STAKING)
    const isFarmEnabled = isFeatureEnabled(FEATURE_FLAGS.FARM)
    const isLaunchpadEnabled = isFeatureEnabled(FEATURE_FLAGS.LAUNCHPAD)
    const isVestingEnabled = isFeatureEnabled(FEATURE_FLAGS.VESTING)
    const isTestnet = NODE_ENV === 'testnet'
    return {
      isStakingEnabled,
      isFarmEnabled,
      isLaunchpadEnabled,
      isVestingEnabled,
      isTestnet
    }
  },
  computed: {
    isActiveRoute() {
      if (!this.$route.name) {
        return false
      }
      return (
        this.$route.name === 'Launchpad' ||
        this.$route.name === 'Staking' ||
        this.$route.name === 'Farm' ||
        this.$route.name === 'Claim'
      )
    },
    isActiveRouteLaunchpad() {
      if (!this.$route.name) {
        return false
      }
      return this.$route.name === 'Launchpad'
    },
    isActiveRouteStaking() {
      if (!this.$route.name) {
        return false
      }
      return this.$route.name === 'Staking'
    },
    isActiveRouteFarm() {
      if (!this.$route.name) {
        return false
      }
      return this.$route.name === 'Farm'
    },
    isActiveRouteClaim() {
      if (!this.$route.name) {
        return false
      }
      return this.$route.name === 'Claim'
    }
  },
  methods: {
    toggleBlur(blur) {
      this.blurShow = blur
    }
  }
}
</script>
