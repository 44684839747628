<template>
  <PDialog title="Mail Settings" @close="toggleMailSettingsDialog(false)">
    <div class="flex flex-col space-y-5 text-sm">
      <div class="text-center">Important updates delivered by email.</div>
      <div class="flex flex-col space-y-5 border-top">
        <div>
          <div class="mb-2">Email for notifications</div>
          <PTextField
            class="items-center"
            v-model.trim="email"
            data-cy="mail-settings-dialog_email-input"
            placeholder="Enter your email address"
          >
            <template v-slot:label v-if="showIcon">
              <div class="text-main flex items-center" v-if="user.verified">
                <CheckCircleIcon class="h-6" />
              </div>
              <div class="text-warning flex items-center" v-else>
                <PTooltip content="Unverified email address" placement="right">
                  <ExclamationCircleIcon class="h-6" />
                </PTooltip>
              </div>
            </template>
          </PTextField>
        </div>
        <div class="flex items-start space-x-3">
          <div>
            <PCheckbox id="general-notifications" v-model="general" />
          </div>
          <div class="flex flex-col">
            <div class="text-sm text-white leading-4 mb-1">General</div>
            <div class="text-sm">
              Newsletter, Market Updates, Product Updates
            </div>
          </div>
        </div>
        <div class="flex items-start space-x-3">
          <div>
            <PCheckbox id="account-notifications" v-model="account" />
          </div>
          <div class="flex flex-col">
            <div class="text-sm text-white leading-4 mb-1">Account</div>
            <div class="text-sm">Deposit, Withdrawals, Account Updates</div>
          </div>
        </div>
        <div class="flex items-start space-x-3">
          <div>
            <PCheckbox id="trading-notifications" v-model="trading" />
          </div>
          <div class="flex flex-col">
            <div class="text-sm text-white leading-4 mb-1">Trading</div>
            <div class="text-sm">
              Order Updates, Liquidation Updates, Funding Payments
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center space-y-3">
        <PButton
          class="w-full"
          color="main"
          @click="saveNotificationSettings"
          data-cy="mail-settings-dialog_save-button"
        >
          Save
        </PButton>
        <PButton
          link
          color="secondary"
          size="sm"
          @click="disableNotifications"
          data-cy="mail-settings-dialog_disable-mail-notifications-button"
        >
          Disable Mail Notifications
        </PButton>
      </div>
    </div>
  </PDialog>
</template>

<script>
import { useDialogs } from '@/hooks/useDialogs'
import {
  PDialog,
  PTextField,
  PCheckbox,
  PButton,
  PTooltip
} from '../../palmswap-vue-ui'
import { useUserSettings } from '@/hooks/useUserSettings'
import { ref, watch } from 'vue'
import {
  CheckCircleIcon,
  ExclamationCircleIcon
} from '@heroicons/vue/24/outline'

export default {
  name: 'MailSettingsDialog',
  components: {
    PButton,
    PTooltip,
    PCheckbox,
    PTextField,
    CheckCircleIcon,
    ExclamationCircleIcon,
    PDialog
  },
  setup() {
    const { toggleMailSettingsDialog } = useDialogs()
    const email = ref('')
    const general = ref(true)
    const account = ref(true)
    const trading = ref(true)

    const { user, saveUser, deleteUser } = useUserSettings()
    watch(user, (user) => {
      if (!user) {
        return
      }
      email.value = user.email
      const notifications = user.notifications
      if (notifications) {
        general.value = notifications.general
        account.value = notifications.account
        trading.value = notifications.trading
      }
    })

    return {
      toggleMailSettingsDialog,
      user,
      saveUser,
      email,
      general,
      account,
      trading,
      deleteUser
    }
  },
  methods: {
    async saveNotificationSettings() {
      try {
        const needsVerification = this.user
          ? this.user.email !== this.email
          : true
        const verificationCode = this.user ? this.user.verificationCode : ''
        await this.saveUser({
          email: this.email,
          notifications: {
            general: this.general,
            account: this.account,
            trading: this.trading
          },
          termsApproved: this.user ? this.user.termsApproved : false,
          verified: !needsVerification, // re-send verification when email changes
          verificationCode: needsVerification ? '' : verificationCode
        })
        this.$notify({
          title: 'Notifications settings updated',
          type: 'success'
        })
      } catch (e) {
        this.$notify({
          title: "Couldn't update notification settings",
          type: 'error'
        })
        console.error(e)
      } finally {
        this.toggleMailSettingsDialog(false)
      }
    },
    async disableNotifications() {
      try {
        if (!this.user) {
          return
        }
        await this.deleteUser(this.user.smartWallet)
        this.$notify({
          title: 'Notifications has been disabled',
          type: 'success'
        })
      } catch (e) {
        this.$notify({
          title: "Couldn't update notification settings",
          type: 'error'
        })
        console.error(e)
      } finally {
        this.toggleMailSettingsDialog(false)
      }
    }
  },
  computed: {
    showIcon() {
      if (!this.user) {
        return false
      }
      return this.email === this.user.email
    }
  }
}
</script>
