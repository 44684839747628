<template>
  <div
    class="flex items-center space-x-2 hover:bg-background-1 transition-colors rounded-lg p-2"
  >
    <div
      :class="
        $route.path.split('/').filter(Boolean).pop() === routeName
          ? 'bg-main text-white'
          : 'bg-background-3'
      "
      class="rounded-lg p-3 transition-colors"
    >
      <slot></slot>
    </div>
    <div class="text-white">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: ['title', 'routeName']
}
</script>
