import { reactive, computed, watch, ref } from 'vue'
import { getTermsStatusFromStorage } from '../utils/utils'
import {
  accountGetAccountBalance,
  accountGetWalletBalance,
  accountGetPalmBNBLPBalance,
  accountGetPalmUSDTLPLPBalance
} from '../contracts'

export const isConsentNeededRef = ref(false)

const defaultAccount = {
  address: null,
  smartWallet: null,
  sponsor: null,
  netId: null,
  provider: null,
  terms: false,
  newsletter: { email: null, general: false, account: false, trading: false },
  walletBalance: 0,
  accountBalance: 0,
  palmBNBLPBalance: 0,
  palmUSDTLPBalance: 0
}

const account = reactive({ ...defaultAccount })

watch(
  () => account.address,
  async (newAddress) => {
    if (newAddress) {
      await updateBalance()
      await updateLPBalance()
      isConsentNeededRef.value = true
      if (getTermsStatusFromStorage(newAddress)) {
        setTerms(true)
        isConsentNeededRef.value = false
      }
    }
  }
)

export const updateBalance = async () => {
  try {
    const { address, smartWallet } = useAccount()
    if (address.value) {
      const walletBalance = await accountGetWalletBalance(address.value)
      account.walletBalance = walletBalance
    }
    if (smartWallet.value) {
      const accountBalance = await accountGetAccountBalance(smartWallet.value)
      account.accountBalance = accountBalance
    }
  } catch (e) {
    console.error('UpdateBalance failed: ', e)
  }
}

export const updateLPBalance = async () => {
  try {
    const { address } = useAccount()
    const palmBNBLPBalance = await accountGetPalmBNBLPBalance(address.value)
    account.palmBNBLPBalance = palmBNBLPBalance
    const palmUSDTLPBalance = await accountGetPalmUSDTLPLPBalance(address.value)
    account.palmUSDTLPBalance = palmUSDTLPBalance
  } catch (e) {
    console.error('UpdateBalance failed: ', e)
  }
}

export const updateAccount = ({
  address,
  netId,
  provider,
  walletBalance,
  accountBalance,
  smartWallet
}) => {
  account.address = address
  account.netId = netId
  account.provider = provider
  account.walletBalance = walletBalance
  account.accountBalance = accountBalance
  account.smartWallet = smartWallet
}

export const resetAccount = () => {
  account.address = defaultAccount.address
  account.netId = defaultAccount.netId
  account.provider = defaultAccount.provider
  account.walletBalance = defaultAccount.walletBalance
  account.accountBalance = defaultAccount.accountBalance
  account.provider = defaultAccount.provider
  account.terms = defaultAccount.terms
  account.newsletter = defaultAccount.newsletter
  account.smartWallet = defaultAccount.smartWallet
  account.sponsor = defaultAccount.sponsor
  account.palmBNBLPBalance = defaultAccount.palmBNBLPBalance
  account.palmUSDTLPBalance = defaultAccount.palmUSDTLPBalance
}

export const toggleIsConsentNeeded = () => {
  isConsentNeededRef.value = !isConsentNeededRef.value
}

const setTerms = (terms) => {
  account.terms = terms
  localStorage.setItem(account.address, JSON.stringify({ terms: true }))
}
const setNewsletter = (newsletter) => {
  account.newsletter = newsletter
}

export const useAccount = () => {
  const terms = computed(() => account.terms)
  const newsletter = computed(() => account.newsletter)
  const disconnect = async () => {
    resetAccount()
  }
  const provider = computed(() => {
    return account.provider
  })
  const isConnected = computed(() => {
    return account.address
  })
  const address = computed(() => {
    return account.address
  })
  const walletBalance = computed(() => {
    return account.walletBalance
  })
  const accountBalance = computed(() => {
    return account.accountBalance
  })
  const isConsentNeeded = computed(() => {
    return isConsentNeededRef.value
  })
  const smartWallet = computed(() => {
    return account.smartWallet
  })
  const palmBNBLPBalance = computed(() => {
    return account.palmBNBLPBalance
  })
  const palmUSDTLPBalance = computed(() => {
    return account.palmUSDTLPBalance
  })

  return {
    smartWallet,
    walletBalance,
    accountBalance,
    disconnect,
    isConnected,
    isConsentNeeded,
    address,
    provider,
    terms,
    newsletter,
    account,
    setTerms,
    setNewsletter,
    palmBNBLPBalance,
    palmUSDTLPBalance
  }
}
