<template>
  <PDialog
    class="hide-scrollbar"
    @close="toggleLaunchpadTermsDialog(false)"
    :position="dialogPosition"
  >
    <template v-slot:header>
      <div class="w-full flex sm:justify-center">
        <img src="../../assets/logo-new-symbol.svg" class="self-center h-9" />
      </div>
    </template>
    <div
      class="h-full sm:h-100 relative flex flex-col overflow-y-scroll justify-between sm:max-w-md"
    >
      <div class="formatted-content text-sm">
        <p class="font-bold text-xl text-center">
          PALM Token Sale - Terms and Conditions
        </p>
        <ol>
          <li>
            <p>
              <u><strong>Background</strong></u>
            </p>
          </li>
        </ol>
        <p>
          Palm Labs Inc., incorporated in British Virgin Islands (the
          &ldquo;<strong>Company</strong>&rdquo; or
          &ldquo;<strong>Palmswap</strong>&rdquo;) is designing, developing and
          intends to deploy the Palmswap protocol (the
          &ldquo;<strong>Protocol</strong>&rdquo;) which is a smart contract
          based perpetual contracts trading platform and a launchpad for
          cryptocurrencies, all as set forth below (&ldquo;<strong
            >Palmswap Project</strong
          >&rdquo; or the &ldquo;<strong>Project</strong>&rdquo;).
        </p>
        <p>
          These Palmswap Terms and Conditions
          (&ldquo;<strong>Terms</strong>&rdquo;), which may be amended from time
          to time at the Company&rsquo;s sole discretion, govern the sale of the
          PALM Token, within the Sale Period (as hereinafter defined) as well as
          the conditions under which individuals and entities
          (&ldquo;<strong>User</strong>&rdquo; or
          &ldquo;<strong>you</strong>&rdquo;) are able to participate in this
          sale including your use of the smart contract for their distribution,
          as further described in
          <u
            ><a href="https://app.palmswap.org/launchpad/palmswap"
              >https://app.palmswap.org/launchpad/palmswap</a
            ></u
          >
          (&ldquo;<strong>PALM Token Sale</strong>&rdquo;), and is made between
          the User and the Company.
        </p>
        <p>
          The Project&rsquo;s token is a utility token and is to be purchased,
          handled and used as such, and are <em>inter alia</em> designed to
          serve as the engine powering the Palmswap Project&rsquo;s ecosystem
          but entails no other rights, uses, purpose, attributes,
          functionalities or features, express or implied. The usability and
          functionality of the PALM Token hinges upon the development,
          deployment and use of related smart contracts or other technologies,
          by PALM Token Users and other third parties (&ldquo;<strong
            >PALM Token</strong
          >&rdquo; or &ldquo;<strong>PALM</strong>&rdquo;). Although PALM Tokens
          are transferable and may be tradable, they are not, and are not
          intended to be, an investment, currency, security, commodity, a swap
          on a currency, security or commodity or any other kind of financial
          instrument.
        </p>
        <p>
          The PALM Token shall be created upon the deployment of the Project,
          which is intended to be used to access and interact with the Protocol
          and may allow voting on certain decisions related to the use of the
          Protocol as further set forth below as well as in the Palmswap
          Whitepaper as referred to in this document.
        </p>
        <p>
          By accepting these Terms and participating in the PALM Token Sale, the
          User understands and acknowledges that these Terms are binding between
          the User and the Company.
        </p>
        <p>
          The Company may sell, allocate and distribute PALM Tokens following
          the PALM Token Sale.
        </p>
        <p>
          In order to participate in the PALM Token Sale, User must deposit the
          BNB cryptocurrency, the Binance Smart Chain native token.
        </p>
        <ol start="2">
          <li>
            <p>
              <u
                ><strong>Overview on Palmswap Project and Token Sale</strong></u
              >
            </p>
          </li>
        </ol>
        <p>
          The Palmswap Project aims to create a platform that utilizes elements
          of blockchain and web-based data transfer to enable users to interact
          with its technology, which may offer several frameworks (for more
          information,
          <u
            ><a href="https://docs.palmswap.org/"
              >https://docs.palmswap.org/</a
            ></u
          >
          where the whitepaper (&ldquo;<strong>Whitepaper</strong>&rdquo;) is
          available. The information contained on the website and the Whitepaper
          are of descriptive nature only, are not binding and do not form part
          of the Terms.
        </p>
        <p>
          In order to fund the future software development, promotion,
          marketing, legal compliance procedures, and ecosystem building related
          to the Palmswap Project, the Company initiates this PALM Token Sale.
        </p>
        <p>
          The User understands and accepts that while the individuals and
          entities, including the Company, assigned to further develop the
          Project will make reasonable efforts to develop and complete the
          Palmswap Project, it is possible that such development may fail and
          User&rsquo;s PALM Token become useless and/or valueless due to
          technical, commercial, regulatory or any other reasons. It is further
          possible that the Palmswap Project as well as the functionality of the
          PALM Token may substantially be modified over time for commercial,
          legal, technical or other reasons. The User also understands and
          acknowledges that the Company may at its discretion and at any time
          change the name of the PALM Tokens and/or its symbol. The User is also
          aware of the risk that even if all or parts of the Palmswap Project
          are successfully developed and released in full or in parts, due to a
          lack of public interest, the Palmswap Project could be fully or
          partially abandoned, remain commercially unsuccessful or shut down for
          lack of interest, regulatory or other reasons. The User therefore
          understands and accepts that obtaining PALM Token may carry
          significant financial risks (including the complete loss of value of
          PALM if any, and attributed features of the Palmswap Project).
        </p>
        <p>
          THIS DOCUMENT DOES NOT CONSTITUTE A PROSPECTUS OF ANY SORT, IS NOT A
          SOLICITATION FOR INVESTMENT AND DOES NOT PERTAIN IN ANY WAY TO AN
          INITIAL PUBLIC OFFERING OR A SHARE/EQUITY OFFERING AND DOES NOT
          PERTAIN IN ANY WAY TO AN OFFERING OF SECURITIES IN ANY JURISDICTION.
        </p>
        <ol start="3">
          <li>
            <p>
              <u><strong>Description of PALM Token</strong></u>
            </p>
            <ol>
              <li>
                <p>
                  <u><strong>Technical Description</strong></u>
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          The PALM Tokens are blockchain-based digital information units that
          are built on the BEP20 Token Standard of the Binance Smart Chain
          blockchain and created by one or several smart contract software codes
          (&ldquo;<strong>Smart Contract System</strong>&rdquo;) that were
          developed by the Company. The PALM Token constitute a transferable
          representation of attributed functions that are specified in the Smart
          Contract System.
        </p>
        <ol>
          <ol start="2">
            <li>
              <p>
                <u><strong>PALM Token Functionalities</strong></u>
              </p>
              <ol>
                <li>
                  <p><u>Overview</u></p>
                </li>
              </ol>
            </li>
          </ol>
        </ol>
        <p>
          The PALM Tokens that are purchased by the User are intended to be used
          to access and interact with the Protocol and to vote on certain
          decisions related to the use of the Protocol as further set forth
          below as well as in the Palmswap Whitepaper as referred to in this
          document (&ldquo;<strong>Token Functionalities</strong>&rdquo;).
        </p>
        <ol>
          <ol>
            <ol start="2">
              <li>
                <p><u>Token Functionalities</u></p>
              </li>
            </ol>
          </ol>
        </ol>
        <p>
          The Token Functionalities of the PALM Token can be described as
          follows:
        </p>
        <ol type="a">
          <li>
            <p>Stake PALM to decrease trading fees on Palmswap.</p>
          </li>
          <li>
            <p>
              Stake PALM to increase affiliate commissions in terms of Palmswaps
              Affiliate Program.
            </p>
          </li>
          <li>
            <p>Stake PALM to participate in governance voting.</p>
          </li>
        </ol>
        <ol>
          <ol>
            <ol start="3">
              <li>
                <p><u>No Ownership, Revenue or Participation Rights</u></p>
              </li>
            </ol>
          </ol>
        </ol>
        <p>
          The User understands and acknowledges that the relationship between
          the User and the Company will always remain on a non-monetary basis.
          This means that the PALM Token do not represent or constitute any
          ownership rights or stake, shares or security or equivalent rights in
          the Company and therefore convey no rights to receive or participate
          in future profits, revenue streams, proceeds, or other forms of
          financial gains that are related to the activities of the Company.
        </p>
        <p>
          Furthermore, the PALM Token does not create or confer any enforceable
          contractual or other obligations against the Company. The User
          therefore understands and acknowledges that the PALM Token do not, and
          shall not be construed to, grant to the User any license or right of
          any nature with respect to any intellectual property rights, rights of
          publicity, or equivalent rights in or related to the Company or any of
          its affiliates.
        </p>
        <p>
          THIS DOCUMENT OR ANY OTHER MATERIALS PROVIDED BY THE COMPANY OR
          RELATING TO THE PALM SALE DO NOT CONSTITUTE A PROSPECTUS OF ANY SORT,
          IS NOT A SOLICITATION FOR INVESTMENT AND DOES NOT PERTAIN IN ANY WAY
          TO AN INITIAL PUBLIC OFFERING OR A SHARE/EQUITY OFFERING AND DOES NOT
          PERTAIN IN ANY WAY TO AN OFFERING OF SECURITIES IN ANY JURISDICTION.
          NEITHER THIS DOCUMENT NOR ANY OTHER MATERIALS HAVE BEEN (OR WILL BE)
          REGISTERED AS A PROSPECTUS WITH ANY GOVERNMENTAL AUTHORITIES.
        </p>
        <ol>
          <ol>
            <ol start="4">
              <li>
                <p><u>Purchase and Allocation of PALM</u></p>
              </li>
            </ol>
          </ol>
        </ol>
        <p>
          <strong>PALM Token Sale and Economics during the Sale Period</strong>
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <p class="text-left">Total Allocation</p>
              </td>
              <td>
                <p class="text-left">
                  100,000,000 PALM Tokens (10% if the Total Supply of the PALM
                  Tokens)
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p class="text-left">Price</p>
              </td>
              <td>
                <p class="text-left">
                  AVG. Price (price is calculated during the sale),
                  <em>i.e</em>, the total BNB amount deposited by Users, during
                  the Sale period, <em>divided </em>by the Total Allocation
                  described above.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p class="text-left">Token Vesting Period</p>
              </td>
              <td>
                <p class="text-left">
                  25% at TGE (&ldquo;<strong>TGE</strong>&rdquo; refers to the
                  actual deployment of the PALM Token on the Binance Smart
                  Chain, which was set to September 24, 2022, which may change,
                  in Company&rsquo;s discretion), and then, 75% shall be
                  allocated, during a period of 90 days, and following a 30 days
                  cliff period.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p><br /><br /></p>
        <p><br /><br /></p>
        <p>
          <u><strong>PALM Token Supply</strong></u>
        </p>
        <ol>
          <ol>
            <ol start="5">
              <li>
                <p><u>Purchase of PALM Tokens</u></p>
              </li>
            </ol>
          </ol>
        </ol>
        <p>
          To be eligible to participate in the PALM Token Sale, all Users are
          required to have a BEP20 compatible wallet that supports the BEP20
          token standard in order to receive PALM Tokens (&ldquo;<strong
            >User Wallet</strong
          >&rdquo;). The Company reserves the right to prescribe additional
          guidance regarding specific wallet requirements.
        </p>
        <p>
          To buy PALM during the PALM Token Sale, the User can use the BNB token
          (&ldquo;<strong>Web Application</strong>&rdquo;). The purchase of PALM
          Token is not subject to any fees, other than third-party fees, such as
          the Binance Smart Chain gas fees. This means that the entire amount
          that is used for the purchase by the User will be credited to the
          purchase of PALM Token.
        </p>
        <p>
          There is no maximum number of BNB Tokens that can be used by each User
          to purchase PALM during the PALM Token Sale. Palmswap works with a
          &lsquo;community fair launch&rsquo; mechanism. In a &lsquo;community
          fair launch&rsquo; mechanism framework, the community can determine
          the price and thus the initial value of the token is created naturally
          without front-running and speculation. The &lsquo;fair launch&rsquo;
          mechanism&rsquo;s pool will only be live for 3 days from September
          20th until September 23rd (the &ldquo;<strong>Sale Period</strong
          >&rdquo;). It starts with a low price and goes up with each investment
          as the weights of the pool change.&nbsp;
        </p>
        <p>
          The User understands and acknowledges that the number of PALM Tokens
          that are effectively purchased by the User is not guaranteed until the
          final allocation of PALM to the User Wallet of the User.
        </p>
        <p>
          The Company anticipates that delivery of PALM Tokens from the Smart
          Contract System to the User will occur within three weeks after the
          Sale Period concludes, but reserves the right to delay delivery up to
          its sole discretions after the conclusion of the Sale Period.
        </p>
        <ol>
          <ol>
            <ol start="6">
              <li>
                <p><u>KYC and AML Information</u></p>
              </li>
            </ol>
          </ol>
        </ol>
        <ol type="a">
          <li>
            <p>
              The User acknowledges that pursuant to any applicable anti-money
              laundering, anti-terrorist financing, government sanction and
              &ldquo;KYC&rdquo;-laws, which may apply to the Company
              (collectively, including any guidelines or orders thereunder, the
              &ldquo;AML Legislation&rdquo;), the Company may be required to
              obtain, verify and record information regarding the User, its
              directors, partners or authorized signing officers of the User and
              the transactions contemplated by these Terms.
            </p>
          </li>
          <li>
            <p>
              The User undertakes to promptly provide, or cause to be provided
              to the Company, or to a service provider elected by the Company
              (&ldquo;<strong>Service Provider</strong>&rdquo;), all required
              information, including supporting documentation and other
              evidence, as may be reasonably requested by the Company, in order
              to complete the Company&rsquo;s or the Service Provider&rsquo;s
              examination (&ldquo;<strong>KYC-Check</strong>&rdquo;) to ensure
              compliance with applicable AML Legislation, whether now or
              hereafter in existence. The same applies in case one of the banks
              of the Company requires additional information regarding the
              source of funds of deposits/transactions made or intended to be
              made by the Company.
            </p>
          </li>
          <li>
            <p>
              The User understands that the outcome of the KYC-Check, e.g., the
              non-admission of the User to participate in the PALM Token Sale,
              or his exclusion at any time later, lies in the sole discretion of
              the Company and/or the Service Provider. The User further
              understands that the amount of information requested to provide as
              part of the KYC-Check may be subject to change over time and that
              the User may at a later point in time be required to provide
              additional documents and/or information, based on which the
              User&rsquo;s participation in the PALM Token Sale may be rejected.
            </p>
          </li>
          <li>
            <p>
              The Company or the Service Provider may designate the KYC-Check
              process to a provider of its choosing. All documents and
              information submitted by the User may be sent to one or more
              KYC-Check providers for review.
            </p>
          </li>
          <li>
            <p>
              The Company may due to a failure of User to provide requested
              documentations or if it decides that the outcome of a KYC-Check
              imposes risks, reject the User&rsquo;s participation in the PALM
              Token Sale, In such case, the purchase shall be invalid with
              retroactive effect and the Company shall be entitled to
              immediately deny the relevant person&rsquo;s admissibility to the
              PALM Token Sale, reject delivery of any PALM and request return of
              any delivered Tokens, irrespective of any payment that could have
              been made by that purchasing User.
            </p>
          </li>
        </ol>
        <p><br /><br /></p>
        <p><br /><br /></p>
        <ol>
          <ol>
            <ol start="7">
              <li>
                <p><u>Allocation of PALM Tokens</u></p>
              </li>
            </ol>
          </ol>
        </ol>
        <p>
          No Refund of Purchase Price - The User understands and acknowledges
          that all PALM Token sales are final and that the payments made during
          the PALM Token Sale are non-refundable. Subject to the above, by
          contributing to the Project, the User understands and acknowledges
          that the User has no right to request a refund for any reason.
        </p>
        <ol>
          <ol>
            <ol start="8">
              <li>
                <p><u>No Guaranteed Tradability</u></p>
              </li>
            </ol>
          </ol>
        </ol>
        <p>
          The Company does not guarantee that the tradability of any particular
          PALM Token on any exchanges (Centralized exchanges or other types of
          exchanges). As a consequence, the User understands and acknowledges
          that the User does not purchase the right to trade the PALM Token on
          any exchange by participating in the PALM Token Sale. The Company does
          not assume any liability regarding the tradability of the PALM Token.
        </p>
        <ol>
          <ol>
            <ol start="9">
              <li>
                <p><u>Removal of PALM Tokens from Application</u></p>
              </li>
            </ol>
          </ol>
        </ol>
        <p>
          The User understands and acknowledges that the Company reserves its
          right to temporarily or permanently remove the PALM Token from the
          Protocol if such action is reasonably required to comply with existing
          contractual, legal, or regulatory obligations (&ldquo;<strong
            >Token Removal</strong
          >&rdquo;). In case of a Token Removal, the User may lose the ability
          to access certain Token Functionalities that have been added to the
          PALM Token by the Company.
        </p>
        <p>
          Since the PALM Token is issued on the Binance Smart Chain blockchain,
          its existence will not be affected by a Token Removal. This means that
          the User will retain the ability to transfer the PALM Token to another
          Binance Smart Chain address outside of the Protocol.
        </p>
        <ol start="4">
          <li>
            <p>
              <u><strong>Taxation</strong></u>
            </p>
          </li>
        </ol>
        <p>
          All taxes (including VAT, if any), charges, levies, assessments and
          other fees of any kind imposed on the receipt or import of PALM Token
          by the User shall be the responsibility of, and for the account of,
          the User.
        </p>
        <ol start="5">
          <li>
            <p>
              <u><strong>Risks</strong></u>
            </p>
          </li>
        </ol>
        <p>
          The User understands and accepts the risks related to the PALM Token.
          In particular, but not limited to, the User understands the inherent
          risks listed hereinafter.
        </p>
        <ol>
          <ol>
            <li>
              <p>
                <u><strong>Risks of Weakness</strong></u>
              </p>
            </li>
          </ol>
        </ol>
        <p>
          The User understands and accepts that the underlying software
          application and software platform, the Protocol, other involved
          software and technology as well as technical concepts and theories are
          still unproven in a live environment, which is why there is no
          warranty that the process for receiving, use and ownership of the PALM
          Token will be uninterrupted or error-free and which is why there is an
          inherent risk that the software and related technologies and theories
          could contain weaknesses, vulnerabilities or bugs causing, inter alia,
          the complete loss of the PALM Token, respectively their functionality.
        </p>
        <p>
          The User particularly understands and accepts that the Protocol may be
          (once, and if, it reaches decentralization) fully decentralized and
          immutable and that, in consequence, it may be difficult or impossible
          to cure software weaknesses.
        </p>
        <ol>
          <ol start="2">
            <li>
              <p>
                <u><strong>Regulatory Risk</strong></u>
              </p>
            </li>
          </ol>
        </ol>
        <p>
          The User understands and accepts that blockchain technology allows new
          forms of interactions. There is a possibility that certain
          jurisdictions will apply existing regulations, or introduce new
          regulations addressing blockchain technology-based applications, which
          may be contrary to the current setup and which may, inter alia, result
          in substantial modifications of the Protocol, including the
          termination of the Project and the loss of the Tokens or their
          functionality for the User.
        </p>
        <p>
          The User understands and accepts that even if the PALM Token does not
          create or confer any contractual or other obligations against any
          party (including the Company, the project team members or other
          developers, auditors, contractors or founders associated with the
          Company and/or the Protocol), certain regulators may nevertheless
          qualify PALM Tokens as securities or other financial instruments under
          their applicable law. It remains in the User&rsquo;s responsibility to
          comply with any applicable laws and regulations when holding or
          dealing PALM Tokens.
        </p>
        <ol>
          <ol start="3">
            <li>
              <p>
                <u><strong>The User understands and agrees that:</strong></u>
              </p>
              <ol>
                <li>
                  <p>
                    the PALM Token are not, and there is no anticipation that
                    the PALM Token will ever be registered with the U.S.
                    Securities and Exchange Commission
                    (&ldquo;<strong>SEC</strong>&rdquo;) under the U.S.
                    Securities Act of 1933 (the &ldquo;<strong
                      >Securities Act</strong
                    >&rdquo;), any other similar act and/or law and/or
                    regulation of any other jurisdiction, or with any
                    governmental agency, and that this offering is being made
                    with the intention of relying upon, and remaining in
                    compliance with, the requirements of Regulation S as
                    promulgated under the Securities Act;
                  </p>
                </li>
                <li>
                  <p>
                    neither the Company nor its affiliates are registered or
                    licensed with any federal or state regulator as an
                    investment adviser, commodity trading adviser, commodity
                    pool operator, broker-dealer, money services business (an
                    &ldquo;MSB&rdquo;), money transmitter (an &ldquo;MT&rdquo;),
                    New York Department of Financial Services-licensed virtual
                    currency business, designated contract merchant, or any
                    other form of a regulated financial institution whatsoever;
                  </p>
                </li>
                <li>
                  <p>
                    the offering of PALM Tokens is being made only in those
                    jurisdictions and to those persons where and to whom they
                    may lawfully be offered for sale, and is not, and under no
                    circumstances is to be construed as, a prospectus, an
                    advertisement or a public offering of Tokens in the United
                    States or elsewhere;
                  </p>
                </li>
                <li>
                  <p>
                    no securities or other regulatory authority has expressed an
                    opinion about the status of PALM Tokens and it is a criminal
                    offense under the laws of some jurisdictions to claim
                    otherwise; and
                  </p>
                </li>
                <li>
                  <p>
                    these Terms have not been reviewed by, passed on or
                    submitted to any regulatory agency or self-regulatory
                    organization. As a result, the User will not be afforded the
                    full set of protections provided to the clients and
                    customers of such entities under any applicable laws; and
                  </p>
                </li>
                <li>
                  <p>
                    if PALM Token are deemed to be securities, in one or more
                    jurisdictions, or these Terms or the issuance of the PALM
                    Tokens constitutes a non-exempt forward contract, or the
                    Company or its affiliates are required to register with a
                    regulatory agency, the PALM Token and the Company could be
                    subject to significant additional regulation, including
                    restrictions on transferability and resale or operational
                    activity. This could lead to significant changes with
                    respect to PALM Tokens, how PALM Tokens are structured, how
                    they are purchased and sold, and other issues, and would
                    greatly increase the Company&rsquo;s costs in creating and
                    facilitating transactions in PALM Tokens. Such regulation
                    could lead to the Tokens losing functionality and/or
                    depreciating partially or fully in value, subject the
                    Company and its affiliates, directors, and officers to
                    potential penalties, including civil and criminal penalties,
                    or make the PALM Tokens illegal or impossible to use, buy,
                    or sell in any jurisdictions. Further, a regulator could
                    take action against the Company or its affiliates if it
                    views Tokens as an unregistered offering of securities or
                    the Company&rsquo;s operations otherwise as a violation of
                    existing law. Any of these outcomes would negatively affect
                    the value and functionality of the Tokens and/or could cause
                    the Company to cease operations.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                <u><strong>Risk of Abandonment / Lack of Success</strong></u>
              </p>
            </li>
          </ol>
        </ol>
        <p>
          The User understands and accepts that the creation of the Protocol may
          be abandoned for a number of reasons including, but not limited to,
          regulatory matters, lack of interest from the public, lack of funding,
          incapacitation of key developers and project members, force majeure
          (including pandemics) or lack of commercial success or prospects. The
          User therefore understands that there are no assurances, even if the
          Protocol was partially or fully developed and launched, that the User
          will receive any benefits through the PALM Tokens held by it.
        </p>
        <ol>
          <ol start="5">
            <li>
              <p>
                <u><strong>Risk of Withdrawing Partners</strong></u>
              </p>
            </li>
          </ol>
        </ol>
        <p>
          The User understands and accepts that the feasibility of the Protocol
          as a whole depends strongly on the collaboration of front-end
          providers and other crucial partners. The User therefore understands
          that there is no assurance that the Protocol as a whole will be
          successfully developed and deployed.
        </p>
        <ol>
          <ol start="6">
            <li>
              <p>
                <u><strong>Risk of Private Key Loss</strong></u>
              </p>
            </li>
          </ol>
        </ol>
        <p>
          PALM Tokens allocated to a particular address can only be accessed
          with the private key that corresponds to that address. The User
          understands and accepts that if its private key file or wallet
          password were lost or stolen, the allocated PALM Tokens associated
          with the User&rsquo;s account (&lsquo;address&rsquo;) or password
          would be unrecoverable and would be permanently lost. The Company has
          no control over the PALM Tokens; therefore, the User shall have no
          recourse to seek any refunds, recovery or replacements from the
          Company in the event that the PALM Tokens are lost or stolen.
        </p>
        <ol>
          <ol start="7">
            <li>
              <p>
                <u><strong>Risk of Theft</strong></u>
              </p>
            </li>
          </ol>
        </ol>
        <p>
          The User understands and accepts that, while best efforts are made to
          reduce potential software attacks on the Protocol, other involved
          software, other technology components and/or platforms may be exposed
          to attacks by hackers or other individuals that could result in theft
          or loss of the PALM Tokens.
        </p>
        <ol>
          <ol start="8">
            <li>
              <p>
                <u><strong>Risk of Protocol Attacks</strong></u>
              </p>
            </li>
          </ol>
        </ol>
        <p>
          The User understands and accepts that, as with other blockchain-based
          tokens, the blockchain used for the Protocol could be susceptible to
          consensus-related attacks, including but not limited to double-spend
          attacks, majority validation power attacks, censorship attacks, and
          byzantine behavior in the consensus algorithm. Any successful attack
          presents a risk to the Protocol, the expected proper execution and
          sequencing of Token-transactions and the expected proper execution and
          sequencing of contract computations.
        </p>
        <ol>
          <ol start="9">
            <li>
              <p>
                User understands and accepts the risks above in connection to
                purchasing PALM Tokens from the Company during the PALM Token
                Sale and thereafter. Additionally, and without derogating from
                any other Section User understands the inherent risks listed
                hereinafter:
              </p>
              <ol>
                <li>
                  <p>
                    User understands and accepts that the software underlying
                    the PALM Tokens, the mechanism controlling the purchase and
                    related smart contracts, and other involved software,
                    technology and technical concepts and theories are still
                    extremely novel, which is why there is no warranty that the
                    process for receiving, use and ownership of PALM Tokens will
                    be uninterrupted or error-free, and which is why there is an
                    inherent risk that the software and related technologies and
                    theories could contain weaknesses, vulnerabilities or bugs
                    causing, <em>inter alia</em>, the complete (or partial) loss
                    of the purchased PALM Tokens.
                  </p>
                </li>
                <li>
                  <p>
                    User understands and accepts that the blockchain technology
                    allows new forms of interaction. There is a possibility that
                    certain jurisdictions will apply existing regulations, or
                    introduce new regulations addressing blockchain technology
                    based applications, which may be contrary to the current
                    setup of the PALM Tokens and/or the Project and the smart
                    contracts, and which may, <em>inter alia</em>, result in
                    substantial modifications of their use and the envisioned
                    Project or PALM Token ecosystem, including its termination
                    and the loss of the PALM Tokens for the User.
                  </p>
                </li>
                <li>
                  <p>
                    Company may use third parties to manage and operate the PALM
                    Token Distribution processes, in whole or in parts. Company
                    has no control over the process employed and the requirement
                    from User made by such third parties.
                  </p>
                </li>
                <li>
                  <p>
                    User should take great care that the website used to
                    purchase PALM Tokens has the following universal resource
                    locator (URL):
                    <u
                      ><a href="https://app.palmswap.org/launchpad/palmswap"
                        >https://app.palmswap.org/launchpad/palmswap</a
                      ></u
                    >
                  </p>
                </li>
                <li>
                  <p>
                    Failure to use the official website and follow such
                    procedures may result in User not receiving any PALM Tokens,
                    and loss of any EOS tokens used for the purchase attempt.
                  </p>
                </li>
                <li>
                  <p>
                    User understands that concerning the PALM Tokens, no market
                    liquidity may be guaranteed.
                  </p>
                </li>
                <li>
                  <p>
                    The Binance Smart Chain blockchain network (which is not
                    related to the Company in any manner) may be subject to
                    delays and latencies.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <li>
            <p>
              <u
                ><strong
                  >Representations, Warranties, and Acknowledgments of the
                  User</strong
                ></u
              >
            </p>
          </li>
        </ol>
        <p>
          By accepting these Terms, participating in the PALM Token Sale and
          purchasing PALM Tokens from the Company, the User represents and
          warrants that:
        </p>
        <ol>
          <ol>
            <ol>
              <li>
                <p>
                  The User is not being listed, or associated with any person or
                  entity being listed, on any of the US Department of
                  Commerce&rsquo;s Denied Persons or Entity List, the US
                  Department of Treasury&rsquo;s Specially Designated Nationals
                  or Blocked Persons Lists, the US Department of State&rsquo;s
                  Debarred Parties List, the EU Consolidated List of Persons,
                  Groups and Entities Subject to EU Financial Sanctions, or the
                  Swiss SECO&rsquo;s Overall List of Sanctioned Individuals,
                  Entities and Organizations, and neither the User nor any of
                  its affiliates, officers or directors is a resident of a
                  country or territory that has been designated as
                  non-cooperative with international anti-money laundering
                  principles or procedures by an intergovernmental group or
                  organization, such as the Financial Action Task Force on money
                  laundering;
                </p>
              </li>
              <li>
                <p>
                  The User confirms not to be resident of, citizen of or located
                  in a geographic area that is subject to UN, US, EU, Swiss or
                  any other sovereign country sanctions or embargoes;
                </p>
              </li>
              <li>
                <p>
                  <a name="_GoBack"></a> The User does not reside in a territory
                  which may restrict in any way the sale of the PALM Token;
                  and/or if User have a registered office or place of residence
                  in the United States of America, Brazil, Cuba, Crimea and
                  Sevastopol, Luhansk People's Republic, Donetsk People's
                  Republic, Iran, Afghanistan, Syria, North Korea, or Antigua
                  and Barbuda, or any other territory, as may be amended by the
                  US DOT (<u
                    ><a href="https://home.treasury.gov/"
                      >https://home.treasury.gov/</a
                    ></u
                  >) and/or any other applicable governmental authority of
                  competent jurisdiction. We retain the right to amend the list
                  presented herein from time to time due to changes in
                  regulatory requirements without prior notice and/or The User
                  is a Non-U.S. person as defined in Rule 902 (k)(2) under the
                  Securities Act. The User has not entered into this Agreement
                  because of, and will not itself engage in, any &ldquo;directed
                  selling efforts&rdquo; (as such term is defined in Regulation
                  S under the Securities Act) in respect to this Agreement and
                  any PALM Tokens issuable hereunder, which would include any
                  activities undertaken for the purpose of, or that could
                  reasonably be expected to have the effect of, conditioning the
                  market in the United States for the resale of this Agreement
                  or the PALM Tokens;
                </p>
              </li>
              <li>
                <p>
                  The User understands that no public market now exists for PALM
                  Tokens, that none is currently anticipated to develop for
                  either, and the Company has made no assurances that a public
                  market will ever exist for PALM Tokens;
                </p>
              </li>
              <li>
                <p>
                  The User is outside the United States when receiving PALM
                  Tokens and is acquiring PALM Tokens as principal for its own
                  account, and not with a view to, or for, resale, distribution
                  or fractionalization thereof, in whole or in part, and no
                  other person has a direct or indirect beneficial interest in
                  PALM Tokens; The User has such knowledge and experience in
                  financial and business matters that the User is capable of
                  evaluating the merits and risks of entering into PALM Token
                  Sale being allocated the PALM Tokens, is able to incur a
                  complete loss without impairing the User&rsquo;s financial
                  condition and is able to bear the economic risk for an
                  indefinite period of time. The User has a deep understanding
                  of the functionality, usage, storage, transmission mechanisms
                  and intricacies associated with cryptographic tokens, like BTC
                  and BNB, and blockchain-based software systems and intends to
                  obtain Tokens for the use of the Project;
                </p>
              </li>
              <li>
                <p>
                  The User understands and expressly accepts that there is no
                  warranty whatsoever on the Tokens and/or the success of the
                  Project, expressed or implied, to the extent permitted by law,
                  and that the Tokens to be created and obtained are at the sole
                  risk of the user on an &ldquo;as is&rdquo; and &ldquo;under
                  development&rdquo; basis and without, to the extent permitted
                  by law, any warranties of any kind, including, but not limited
                  to, warranties of title or implied warranties, merchantability
                  or fitness for a particular purpose. The User is aware that it
                  will not receive money or any other compensation for any PALM
                  Tokens created but not allocated, not used or that remains
                  unused;
                </p>
              </li>
              <li>
                <p>
                  any funds used to pay the purchase price are (i) good, clean,
                  clear and are of non-criminal origin; (ii) completely free and
                  clear of any liens or encumbrances of any kind of any rights
                  of third-party interests; and (iii) have no origins that may
                  be connected to any breach of money laundering regulations
                  whatsoever, as defined in the jurisdiction of origin, or
                  internationally;
                </p>
              </li>
              <li>
                <p>
                  the User is of a sufficient age to legally obtain PALM Tokens
                  in the User&rsquo;s jurisdiction and does not act on behalf of
                  any third party;
                </p>
              </li>
              <li>
                <p>
                  the User is not purchasing PALM Tokens for the purpose of
                  speculative investment but to use the PALM Tokens as further
                  described herein;
                </p>
              </li>
              <li>
                <p>
                  the User understands that the addition of Token
                  Functionalities to PALM Tokens is not guaranteed and that the
                  PALM Tokens might consequently not provide the User with any
                  Token Functionalities at all;
                </p>
              </li>
              <li>
                <p>
                  the User understands that he may lose the ability to access
                  certain Token Functionalities or trade the PALM Tokens on an
                  exchange in case of a Token Removal.
                </p>
              </li>
              <li>
                <p>
                  the User understands that the PALM Tokens do not represent or
                  constitute any ownership rights or stake, shares or security
                  or equivalent rights in the Company and do not convey any
                  rights to receive or participate in future profits, revenue
                  streams, or proceeds in connection with the PALM Tokens.
                </p>
              </li>
              <li>
                <p>
                  the User understands that the PALM do not create or confer any
                  enforceable contractual or other obligations against the
                  Company and that the PALM do not grant to the User any license
                  or right of any nature with respect to any intellectual
                  property rights, rights of publicity, or equivalent rights in
                  or related to the Company or any of its affiliates.
                </p>
              </li>
              <li>
                <p>
                  the User waives the right to participate in a class action
                  lawsuit or a class wide arbitration against the Company or any
                  entity or individual involved in the PALM Token Sale or the
                  operation of the Protocol.
                </p>
              </li>
              <li>
                <p>
                  the User will not hold the Company or any associated party
                  liable for any tax liability associated with the User&rsquo;s
                  participation in the PALM Token Sale.
                </p>
              </li>
            </ol>
          </ol>
          <li>
            <p>
              <u><strong>Exclusion of Liability</strong></u>
            </p>
          </li>
        </ol>
        <p>
          The User acknowledges and agrees that, to the extent permitted by any
          applicable law, the User will not hold the Company or any developers,
          auditors, contractors or founders of the Company liable for any and
          all damages or injury whatsoever caused by or related to the use of,
          or the inability to use any function under any cause or action
          whatsoever of any kind in any jurisdiction, including, without
          limitation, actions for breach of warranty, breach of contract or tort
          (including negligence) and that the Company shall not be liable for
          any indirect, incidental, special, exemplary or consequential damages,
          including for loss of profits, goodwill or data, in any way whatsoever
          arising out of the use of, or the inability to use PALM Tokens.
        </p>
        <ol start="8">
          <li>
            <p>
              <u><strong>Miscellaneous</strong></u>
            </p>
          </li>
        </ol>
        <p>
          The User agrees that if any portion of these Terms is found illegal or
          unenforceable, in whole or in part, such provision shall, as to such
          jurisdiction, be ineffective solely to the extent of such
          determination of invalidity or unenforceability without affecting the
          validity or enforceability thereof in any other manner or jurisdiction
          and without affecting the remaining provisions of the Terms, which
          shall continue to be in full force and effect.
        </p>
        <p>
          By participating in the PALM Token Sale and purchasing PALM Tokens
          from the Company, no form of partnership, joint venture or any other
          similar relationship between the User and the Company is created.
        </p>
        <p>
          These Terms contains the entire agreement between the User and the
          Company with regard to the subject matter regulated herein and
          supersede all previous written or oral communication between the User
          and the Company as well as any public statements about the PALM Token
          Sale made by anyone in the past, present and future.
        </p>
        <p>
          The User shall have no right to assign or transfer its rights and
          obligations under these Terms, either as a whole or in part, to any
          third party without the prior written and express consent of the
          Company. The User and the Company agree that any assignment or
          transfer in violation of this Section shall be null and void.
        </p>
        <p>
          To the fullest extent permitted by law, the User waives the right to
          participate in a class action lawsuit or a class-wide arbitration
          against the Company or any individual involved in the PALM Token Sale
          or the operation of the Protocol.
        </p>
        <p>
          These Terms shall be governed by and construed in accordance with laws
          of The Republic of Seychelles, excluding the conflict of law rules.
          The application of the United Nations Convention for Contracts for the
          Sales of Goods is hereby expressly excluded.
        </p>
        <p>
          Any dispute, controversy or claim arising out of or in connection with
          these Terms or the breach, termination, existence, legal competence or
          invalidity thereof, shall be exclusively settled by the ordinary
          courts of the British Virgin Islands.
        </p>
        <p>***</p>
      </div>
      <div class="flex items-start">
        <PCheckbox v-model="termsAccepted" id="termsId">
          <div class="text-sm whitespace-normal ml-2 mt-6">
            I confirm reading in full, understanding this Agreement and the
            terms thereof, and being bound by the provisions of this Agreement
          </div>
        </PCheckbox>
      </div>
      <PButton
        :color="`${isValid ? 'primary' : 'tertiary'}`"
        outlined
        size="lg"
        class="mt-8 w-full w-full flex flex-none justify-center items-center"
        @click="save"
        data-cy="launchpad-terms-dialog_confirm-button"
      >
        Confirm
      </PButton>
    </div>
  </PDialog>
</template>

<script>
import { PButton, PCheckbox, PDialog } from '../../../palmswap-vue-ui'
import { mapState } from 'vuex'
import { useDialogs } from '@/hooks/useDialogs'
import { useLaunchpadTerms } from '@/hooks/useLaunchpad'

export default {
  components: {
    PButton,
    PCheckbox,
    PDialog
  },
  data() {
    return {
      termsAccepted: false
    }
  },
  setup() {
    const { toggleLaunchpadTermsDialog, metadata } = useDialogs()
    const { accept } = useLaunchpadTerms()
    return {
      toggleLaunchpadTermsDialog,
      accept,
      metadata
    }
  },
  methods: {
    save() {
      if (!this.isValid) {
        return
      }
      this.accept(this.account)
      if (this.metadata && this.metadata.confirm) {
        this.metadata.confirm()
      }
      this.toggleLaunchpadTermsDialog(false)
    }
  },
  computed: {
    ...mapState({
      account: (state) => state.user.address.account
    }),
    isValid() {
      return this.termsAccepted
    },
    dialogPosition() {
      if (this.isMobile) {
        return 'full'
      } else {
        return 'center'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.formatted-content {
  p {
    margin-bottom: 10px;
  }
}
</style>
