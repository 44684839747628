<template>
  <CurrencyInput
    :modelModifiers="{ number: true }"
    currency="USDT"
    placeholder="0.00"
    dataCy="trade-page_make-order_price-input"
  />
</template>

<script>
import CurrencyInput from '@/components/trade/make-order/CurrencyInput'

export default {
  name: 'PriceInput',
  components: {
    CurrencyInput
  }
}
</script>

<style scoped></style>
