<template>
  <PNotification
    :type="props.item.type"
    @close="closeNotification"
    :closable="true"
  >
    <template v-slot:header>{{ props.item.title }}</template>
    <template v-slot:body>
      <div
        class="flex justify-between mb-2"
        v-for="field in props.item.data.fields"
        :key="field.label"
      >
        <div>{{ field.label }}</div>
        <div class="flex flex-row items-center gap-1.5">
          <div>{{ field.prevValue }}</div>
          <ArrowRightIcon class="w-4 h-4" />
          <div class="text-white">{{ field.nextValue }}</div>
        </div>
      </div>
    </template>
  </PNotification>
</template>
<script>
import { PNotification } from '../../../palmswap-vue-ui'
import { SIDE_SHORT } from '../../contracts/helpers'
import { useAppState } from '../../hooks/useAppState'
import { ArrowRightIcon } from '@heroicons/vue/20/solid'

export default {
  components: {
    PNotification,
    ArrowRightIcon
  },
  props: {
    props: {}
  },
  setup() {
    const { currencySymbol } = useAppState()
    return {
      currencySymbol,
      SIDE_SHORT
    }
  },
  methods: {
    closeNotification() {
      this.$emit('closeNotification')
    }
  }
}
</script>
<style lang="scss" scoped></style>
