<template>
  <div class="input-wrapper">
    <p v-if="!this.$slots.label && label" class="label">{{ label }}</p>
    <PTextField
      :modelValue="modelValue"
      :placeholder="placeholder"
      :type="type"
      @update:modelValue="handleInput"
      @keypress="filterKeys"
      :disabled="disabled"
      @focus="onFocus"
      :min="minValue"
      :error="error"
      :data-cy="dataCy"
    >
      <template v-slot:label>
        <slot name="suffix"></slot>
      </template>
    </PTextField>
  </div>
</template>
<script>
import { PTextField } from '../../../palmswap-vue-ui'
export default {
  components: {
    PTextField
  },
  props: {
    dataCy: {
      type: String
    },
    label: {
      type: String
    },
    big: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    modelValue: {
      type: String
    },
    modelModifiers: {
      type: Object,
      default: () => ({})
    },
    placeholder: {
      type: String
    },
    inputmode: {
      type: String
    },
    error: {
      type: String
    },
    minValue: {
      type: Number,
      default: () => -99999999
    },
    type: {
      type: String,
      default: () => 'text'
    }
  },
  emits: ['update:modelValue', 'onFocus'],
  data() {
    return {
      touched: false
    }
  },
  watch: {
    modelValue(newValue, oldValue) {
      if (
        newValue !== oldValue &&
        oldValue !== undefined &&
        oldValue !== null
      ) {
        this.touched = true
      }
    }
  },
  methods: {
    onFocus() {
      this.$emit('onFocus')
    },
    touch() {
      if (!this.touched) {
        this.touched = true
      }
    },
    handleInput(val) {
      if (!this.touched) {
        this.touched = true
      }
      let updatedValue = val
      if (this.modelModifiers.number) {
        updatedValue = val.replace(/[^\d.]+/g, '')
      }
      this.$emit('update:modelValue', updatedValue)
    },
    filterKeys(e) {
      if (this.modelModifiers.number) {
        const charCode = typeof e.which === 'undefined' ? e.keyCode : e.which
        if (String.fromCharCode(charCode).match(/[^\d.]+/g)) {
          e.preventDefault()
        }
      }
    }
  },
  computed: {
    showError() {
      return this.touched && !!this.error && this.error.length > 0
    },
    hasLabelSlot() {
      return this.$slots.label
    }
  }
}
</script>

<style lang="scss" scoped>
p.label {
  font-size: 12px;
  color: var(--text);
  text-align: left;
  margin: 0 0 9px 0;
}
input {
  background: #191919;
  border-radius: 4px;
  border: 0;
  outline: none;
  padding: 11px;
  font-size: 14px;
  width: 100%;
  color: white;
  border: 1px solid #282828;
  box-sizing: border-box;
  &::placeholder {
    color: var(--text);
  }
  &.error {
    border: solid 1px var(--error);
  }
}
.input {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &-group {
    position: relative;
    width: 100%;
    .suffix {
      position: absolute;
      right: 0;
      top: 4px;
    }
  }
}

div.error {
  color: var(--error);
  margin: 4px;
  text-align: left;
  font-size: 12px;
}
</style>
