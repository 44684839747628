<template>
  <div class="my-5 text-xs">
    <div
      class="mb-3 flex cursor-pointer select-none justify-between items-center text-inactive"
      @click="toggleSlippageAccordion"
      data-cy="trade-page_make-order_show-slippage-button"
      v-if="isMarket"
    >
      <div>Slippage Tolerance</div>
      <div class="flex justify-center gap-1 items-center">
        <div class="text-white">{{ slippageFormatted }}</div>
        <ChevronDownIcon
          class="text-inactive h-4 w-4"
          :class="{ 'transform rotate-180': isSlippageOpen }"
        />
      </div>
    </div>
    <SlippageAccordion v-if="isSlippageOpen" />
    <LabelValue
      class="mb-3"
      :value="orderValueFormatted"
      :isLoading="isLoading"
      label="Order Value"
    />
    <LabelValue
      v-if="isMarket"
      class="mt-3"
      :isLoading="isLoading"
      :value="priceImpactFormatted"
      label="Est. slippage"
    />
  </div>
</template>

<script>
import LabelValue from './LabelValue.vue'
import Big from 'big.js'
import { getSpotPrice } from '@/contracts/amm'
import { calculateTotalCost, getCollateral } from '@/contracts/position'
import { useAppState } from '@/hooks/useAppState'
import { useAMM } from '@/hooks/useAMM'
import { useOrderForm } from '@/hooks/useOrderForm'
import SlippageAccordion from './SlippageAccordion.vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

let timeout

export default {
  name: 'OrderDetails',
  components: {
    LabelValue,
    SlippageAccordion,
    ChevronDownIcon
  },
  props: [
    'totalCost',
    'collateral',
    'leverage',
    'size',
    'isMarket',
    'price',
    'side'
  ],
  emits: ['update:totalCost', 'update:collateral'],
  data() {
    return {
      isLoading: false,
      priceImpact: new Big(0),
      orderValue: new Big(0),
      isSlippageOpen: false
    }
  },
  setup() {
    const { pairSymbol, slippage } = useAppState()
    const { totalFee } = useOrderForm()
    const { markPrice } = useAMM()
    return {
      pairSymbol,
      markPrice,
      totalFee,
      slippage
    }
  },
  computed: {
    slippageFormatted() {
      return `${this.slippage}%`
    },
    orderValueFormatted() {
      return !this.orderValue.eq(0)
        ? `~${this.orderValue.toFixed(2)} USDT`
        : '-'
    },
    priceImpactFormatted() {
      if (this.size) {
        return `${this.priceImpact.abs().toFixed(4)}%`
      } else return '0.0000%'
    }
  },
  watch: {
    markPrice(newValue, oldValue) {
      if (this.markPrice && oldValue && newValue) {
        if (
          this.isMarket &&
          oldValue.toNumber().toFixed(2) !== newValue.toNumber().toFixed(2)
        ) {
          this.calculateTotalCost()
        }
      }
    },
    price() {
      this.calculateTotalCost()
    },
    size() {
      this.calculateTotalCost()
    },
    leverage() {
      this.calculateTotalCost()
    }
  },
  methods: {
    toggleSlippageAccordion() {
      this.isSlippageOpen = !this.isSlippageOpen
    },
    calculateTotalCost() {
      if (!this.size || this.size.eq(0)) {
        this.isLoading = false
        this.orderValue = new Big(0)
        this.$emit('update:totalCost', new Big(0))
        this.$emit('update:collateral', new Big(0))
        return
      }
      this.isLoading = true
      clearTimeout(timeout)
      timeout = setTimeout(async () => {
        try {
          const collateral = this.isMarket
            ? await getCollateral(
                this.pairSymbol,
                this.size,
                this.leverage,
                this.side
              )
            : new Big(this.price).mul(this.size).div(this.leverage)

          this.orderValue = collateral.mul(this.leverage)
          const markPrice = await getSpotPrice(this.pairSymbol)
          if (this.size.gt(0)) {
            this.priceImpact = collateral
              .mul(this.leverage)
              .div(this.size)
              .div(this.markPrice || markPrice)
              .sub(new Big(1))
              .mul(100)
          }

          const totalCost = await calculateTotalCost(
            collateral,
            this.totalFee.mul(this.orderValue)
          )
          this.$emit('update:collateral', collateral)
          this.$emit('update:totalCost', totalCost)
        } catch (e) {
          console.error(e)
        } finally {
          this.isLoading = false
        }
      }, 500)
    }
  }
}
</script>

<style scoped></style>
