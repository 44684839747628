import {
  decimal2Big,
  SIDE_LONG,
  DEFAULT_DECIMALS,
  toD,
  correctDecimalPlaces
} from './helpers'
import {
  getAmmContract,
  usdt,
  palmBNBLP,
  palmUSDTLP,
  getSmartWalletContract,
  getSignedContracts
} from './contracts'
import Big from 'big.js'
import { utils } from 'ethers'
import { provider } from './providers'

export * from './apex'

export const accountGetWalletBalance = async (address) => {
  const balance = await usdt.balanceOf(address)
  return correctDecimalPlaces(balance)
}

export const accountGetPalmBNBLPBalance = async (address) => {
  const balance = await palmBNBLP.balanceOf(address)
  return correctDecimalPlaces(balance)
}

export const accountGetPalmUSDTLPLPBalance = async (address) => {
  const balance = await palmUSDTLP.balanceOf(address)
  return correctDecimalPlaces(balance)
}

export const accountGetAccountBalance = async (smartWallet) => {
  const balance = await usdt.balanceOf(smartWallet)
  return correctDecimalPlaces(balance)
}

export const accountGetLastWithdrawInfo = async (smartWallet) => {
  const smartWalletContract = getSmartWalletContract(smartWallet, provider)
  return await Promise.all([
    smartWalletContract.lastWithdrawDayIdx(),
    smartWalletContract.lastDayWithdrawnAmount()
  ])
}

export const getDailyWithdrawLimit = async () => {
  // const smartWalletFactoryContract =
  //   getSignedContracts().smartWalletFactorySigned
  // return await smartWalletFactoryContract.dailyWithdrawLimit()
  return '100000000000000000000000'
}

export const calculatePositionSize = async (
  symbol,
  side,
  collateral,
  leverage
) => {
  if (collateral === null || collateral.eq(0)) {
    return new Big(0)
  }
  const amm = getAmmContract(symbol)
  const dir = side === SIDE_LONG ? 0 : 1
  const notional = collateral.mul(leverage)
  try {
    const positionReceived = await amm.getInputPrice(
      dir,
      toD(
        utils.parseUnits(
          notional.round(DEFAULT_DECIMALS).toString(),
          DEFAULT_DECIMALS
        )
      )
    )
    return decimal2Big(positionReceived)
  } catch (e) {
    console.error('Position received error: ', e)
  }
  return new Big(0)
}
