<template>
  <component
    :is="linkComponent"
    :to="route"
    :href="route"
    @click="handleNavigation"
  >
    <slot />
  </component>
</template>
<script>
import { useDialogs } from '@/hooks/useDialogs'

export default {
  props: ['name', 'route'],
  setup() {
    const { toggleLeavingNetworkDialog } = useDialogs()
    return { toggleLeavingNetworkDialog }
  },
  computed: {
    isActiveRoute() {
      if (!this.$route.name) {
        return false
      }
      return (
        this.$route.name === this.name || this.$route.name.startsWith(this.name)
      )
    },
    isExternal() {
      return this.route.startsWith('http')
    },
    linkComponent() {
      return this.isExternal ? 'a' : 'router-link'
    }
  },
  methods: {
    handleNavigation(e) {
      if (this.isExternal && this.name !== 'Faucet') {
        e.preventDefault()
        this.toggleLeavingNetworkDialog(true, {
          url: this.route,
          name: this.name
        })
      }
    }
  }
}
</script>
