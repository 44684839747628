import Big from 'big.js'
import { getSpotPrice } from '../contracts/amm'

export const formatUSD = (rawPrice, precision = 2) => {
  if (rawPrice === undefined) {
    return '-'
  } else {
    let price = 0
    if (typeof rawPrice.toFixed !== 'undefined') {
      price = parseFloat(rawPrice.toFixed(precision))
    } else {
      price = parseFloat(rawPrice)
    }

    return `$${price.toFixed(precision)}`
  }
}

export const fetchSpotPrice = async (pairSymbol) => {
  const spBN = await getSpotPrice(pairSymbol)
  return new Big(spBN.toString()).div(10 ** 18)
}

export const formatPercentage = (rawPercent, decimals = 2) => {
  return Number(rawPercent).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: decimals
  })
}

export const numberWithCommas = (x, decimals = 2, hideZeroDecimals = false) => {
  const hasZeroDecimals = x % 1 === 0
  const _decimals = hasZeroDecimals && hideZeroDecimals ? 0 : decimals
  return (
    x &&
    Number(x).toLocaleString('en-US', {
      minimumFractionDigits: _decimals,
      maximumFractionDigits: _decimals
    })
  )
}

export const truncateNumber = (x, decimalsShown = 4) => {
  if (Number.isInteger(Number(x))) {
    return x
  }
  const [ints, decimals] = String(x).split('.')
  return decimals.length > decimalsShown
    ? `${ints}.${decimals.slice(0, decimalsShown)}…`
    : String(x)
}

export const nFormatter = (num, digits = 4) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

export const formatSideColor = (rawValue) => {
  if (rawValue > 0) {
    return 'green'
  } else if (rawValue < 0) {
    return 'red'
  } else {
    return 'faded'
  }
}
