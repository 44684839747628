import { ref, computed } from 'vue';
import { deleteOrder, addLimitOrder } from '@/contracts/apex';
import { gql } from '@apollo/client';
import { wssClient } from '../api/graph';
import { ORDERS_SUBSCRIPTION } from '../api/queries';
import { formatOrder } from '../utils/utils';
import metadata from '../contracts/metadata';
import store from '@/store';
import { useNotification } from '@kyvg/vue3-notification';
import { OPEN_SHORT, SIDE_LONG, SIDE_SHORT } from '@/contracts/helpers';
const amms = Object.values(metadata.amms);
const rawIsLoadingRef = ref(false);
const rawOrdersRef = ref([]);
const subscriptionRef = ref(null);
export const addOrder = async (pairSymbol, signer, smartWallet, { limitPrice, positionSize, collateral, leverage, side, slippage }) => {
    await addLimitOrder(pairSymbol, signer, smartWallet, {
        limitPrice: limitPrice.toString(),
        positionSize: positionSize.toString(),
        collateral: collateral,
        leverage: leverage.toString(),
        side,
        slippage
    });
};
export const resetOrders = async () => {
    rawIsLoadingRef.value = false;
    rawOrdersRef.value = [];
};
export const subscribeOrders = (trader) => {
    if (subscriptionRef.value) {
        subscriptionRef.value.unsubscribe();
    }
    subscriptionRef.value = wssClient
        .subscribe({
        query: gql(ORDERS_SUBSCRIPTION),
        variables: {
            amms,
            trader: trader.toLowerCase()
        }
    })
        .subscribe({
        next(data) {
            rawOrdersRef.value = data.data.orders.map((order) => formatOrder(order));
        },
        error(err) {
            console.error('err', err);
        }
    });
};
export const unsubscribeOrders = () => {
    if (subscriptionRef.value) {
        subscriptionRef.value.unsubscribe();
    }
    subscriptionRef.value = null;
};
export const useOrders = () => {
    const { notify } = useNotification();
    const rawIsLoading = computed(() => {
        return rawIsLoadingRef.value;
    });
    const rawOrders = computed(() => {
        return rawOrdersRef.value;
    });
    /**
     * @deprecated Use rawOrders instead
     */
    const orders = computed(() => {
        return rawOrdersRef.value;
    });
    const cancelOrder = async (order) => {
        const id = Date.now();
        store.commit('addId', id);
        try {
            notify({
                id,
                title: 'Cancelling Order',
                duration: -1,
                type: 'progress',
                data: {
                    kind: 'order',
                    side: order.orderSide === OPEN_SHORT ? SIDE_SHORT : SIDE_LONG,
                    price: order.limitPrice.round(2).toString(),
                    size: order.orderSize.abs().toFixed(6)
                }
            });
            await deleteOrder(store.state.web3Modal.provider.getSigner(), order.id);
            notify({
                id,
                title: 'Removed Order',
                type: 'success',
                data: {
                    kind: 'order',
                    side: order.orderSide === OPEN_SHORT ? SIDE_SHORT : SIDE_LONG,
                    price: order.limitPrice.round(2).toString(),
                    size: order.orderSize.abs().toFixed(6)
                }
            });
        }
        catch (e) {
            //Weird exception if cancel is clicked
        }
        finally {
            notify.close(id);
            store.commit('removeId', id);
        }
    };
    return {
        rawOrders,
        orders,
        isLoading: rawIsLoading,
        cancelOrder
    };
};
