<template>
  <div class="h-full flex flex-col justify-center items-center">
    <div
      class="flex flex-col w-1/2 items-center space-y-10 border rounded p-10 bg-background-2"
    >
      <img src="@/assets/error.svg" class="w-20" />
      <div class="text-center">
        <div class="text-white text-2xl">
          {{ title }}
        </div>
        <div>
          {{ message }}
        </div>
      </div>
      <a href="/" class="text-main text-lg hover:underline">Go to Homepage</a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: 'Oops!' },
    message: { type: String, default: 'An unexpected error occurred.' }
  }
}
</script>
