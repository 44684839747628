import { computed } from 'vue'
import { useGrid } from 'vue-screen'

export default function () {
  const breakpoints = useGrid('tailwind')

  const isMobile = computed(() => breakpoints.sm === false)

  return { isMobile, breakpoints }
}
