<template>
  <Dialog>
    <div class="tpsl-dialog">
      <DialogHeader
        title="TP/SL for entire position"
        @on-close="toggleTPSLDialog(false)"
      />
      <Tabs
        class="orders-tabs"
        left="Take Profit"
        right="Stop Loss"
        v-model:selected="tabSelected"
      />
      <div class="tpsl-dialog-content">
        <DialogField
          label="Market price"
          :value="markPriceFormatted"
          currency="USDT"
        ></DialogField>
        <DialogField
          label="Liquid price"
          :value="liquidationPrice"
          currency="USDT"
        ></DialogField>
        <div v-if="tabSelected === 0">
          <div class="section">
            <Input
              v-model="takeProfit"
              :error="takeProfitError"
              ref="profitInput"
              type="number"
            >
              <template v-slot:label>
                <p class="section-input-label">
                  <span>Take Profit USDT</span>
                </p>
              </template>
              <template v-slot:suffix>
                <div class="section-input-suffix">Last price</div>
              </template>
            </Input>
            <div class="section-percentage-buttons">
              <Button
                overlay
                @click="fractionProfit = 0.1"
                :selected="fractionProfit === 0.1"
                >10%</Button
              >
              <Button
                overlay
                @click="fractionProfit = 0.25"
                :selected="fractionProfit === 0.25"
                >25%</Button
              >
              <Button
                overlay
                @click="fractionProfit = 0.5"
                :selected="fractionProfit === 0.5"
                >50%</Button
              >
              <Button
                overlay
                @click="fractionProfit = 1.0"
                :selected="fractionProfit === 1.0"
                >100%</Button
              >
              <Button
                overlay
                @click="fractionProfit = 1.5"
                :selected="fractionProfit === 1.5"
                >150%</Button
              >
            </div>
            <div class="section-summary">
              Last Traded Price increasing to {{ takeProfit }} will trigger
              market Take Profit order.
              <span v-if="takeProfitValid && +estimatedProfit">
                Your estimated profit will be
                <span class="section-summary-profit">
                  {{ estimatedProfit }}
                  USDT
                </span>
              </span>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="section">
            <Input v-model="stopLoss" :error="stopLossError" type="number">
              <template v-slot:label>
                <p class="section-input-label">
                  <span>Stop Loss USDT</span>
                </p>
              </template>
              <template v-slot:suffix>
                <div class="section-input-suffix">Last price</div>
              </template>
            </Input>
            <div class="section-percentage-buttons">
              <Button
                overlay
                @click="fractionLoss = 0.1"
                :selected="fractionLoss === 0.1"
                >10%</Button
              >
              <Button
                overlay
                @click="fractionLoss = 0.25"
                :selected="fractionLoss === 0.25"
                >25%</Button
              >
              <Button
                overlay
                @click="fractionLoss = 0.5"
                :selected="fractionLoss === 0.5"
                >50%</Button
              >
              <Button
                overlay
                @click="fractionLoss = 1.0"
                :selected="fractionLoss === 1.0"
                >100%</Button
              >
              <Button
                overlay
                @click="fractionLoss = 1.5"
                :selected="fractionLoss === 1.5"
                >150%</Button
              >
            </div>
            <div class="section-summary">
              Last Traded Price decreasing to {{ stopLoss }} will trigger market
              Stop Loss order.
              <span v-if="stopLossValid && +estimatedLoss">
                Your estimated loss will be
                <span class="section-summary-loss">
                  {{ estimatedLoss }}
                  USDT
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="tpsl-dialog-actions">
          <Button @click="toggleTPSLDialog(false)">Cancel</Button>
          <!-- :disabled="!isValid || isLoading" -->
          <SubmitButton @click="handleConfirm()">
            Confirm
            <SmallLoader class="loading" v-if="isLoading" />
          </SubmitButton>
          <!-- <transition name="fade">
                        <SmallLoader class="loading" v-if="isLoading" />
                    </transition> -->
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script>
import { fetchSpotPrice } from '../formatters/price'
import DialogHeader from '@/components/common/DialogHeader.vue'
import Dialog from '@/components/common/Dialog.vue'
import { useAMM } from '@/hooks/useAMM'
import { useDialogs } from '@/hooks/useDialogs'
import Input from './common/Input.vue'
import DialogField from './common/DialogField.vue'
import SmallLoader from './common/SmallLoader.vue'
import Button from './common/Button.vue'
import SubmitButton from './common/SubmitButton.vue'
import Tabs from './common/Tabs.vue'
import { useUserPosition } from '../hooks/useUserPosition'
import { SIDE_LONG, SIDE_SHORT } from '../contracts/helpers'
import { useAppState } from '../hooks/useAppState'
import { addTPSL } from '../contracts'
import { mapState } from 'vuex'

export default {
  components: {
    Dialog,
    Input,
    Button,
    DialogField,
    DialogHeader,
    SubmitButton,
    Tabs,
    SmallLoader
  },
  data: function () {
    return {
      isLoading: false,
      tabSelected: 0,
      fractionLoss: null,
      fractionProfit: null,
      takeProfitValid: false,
      stopLossValid: false,
      takeProfit: null,
      stopLoss: null,
      takeProfitError: null,
      stopLossError: null,
      spotPrice: null
    }
  },
  setup() {
    const { toggleTPSLDialog } = useDialogs()
    const { currentPosition, rawPosition } = useUserPosition()
    const { pairSymbol, slippage } = useAppState()
    const { markPrice } = useAMM()
    const { liquidationPrice, side } = currentPosition?.value || {}

    return {
      pairSymbol,
      fetchSpotPrice,
      side,
      toggleTPSLDialog,
      markPrice,
      markPriceFormatted: markPrice?.value.toFixed(2),
      liquidationPrice: liquidationPrice.toFixed(2),
      position: rawPosition,
      slippage
    }
  },
  async mounted() {
    const spotPrice = await this.fetchSpotPrice(this.pairSymbol)
    this.spotPrice = spotPrice.toNumber()
    this.stopLoss = (
      Number(this.markPrice) +
      30 * (this.side === SIDE_SHORT ? 1 : -1)
    ).toFixed(2)
    this.takeProfit = (
      Number(this.markPrice) +
      30 * (this.side === SIDE_SHORT ? -1 : 1)
    ).toFixed(2)
  },
  computed: {
    ...mapState({
      walletAddress: (state) => state.user.address.wallet,
      signer: (state) => state.web3Modal.provider.getSigner()
    }),
    isValid: function () {
      return this.tabSelected === 0 ? this.takeProfitValid : this.stopLossValid
    }
  },
  watch: {
    stopLoss(stopLoss) {
      if (this.tabSelected === 1) {
        if (this.side === SIDE_SHORT) {
          this.stopLossValid = +stopLoss > +this.markPrice
          if (!this.stopLossValid) {
            this.stopLossError = 'Stop Loss cannot be below the market price!'
          } else {
            this.stopLossError = ''
          }
        } else if (this.side === SIDE_LONG) {
          this.stopLossValid = +stopLoss < +this.markPrice
          if (!this.stopLossValid) {
            this.stopLossError = 'Stop Loss cannot be above the market price!'
          } else {
            this.stopLossError = ''
          }
        } else {
          this.stopLossValid = false
        }
      }
    },
    takeProfit(takeProfit) {
      if (this.side === SIDE_SHORT) {
        this.takeProfitValid = +takeProfit <= +this.markPrice.toFixed(2)
        if (!this.takeProfitValid) {
          this.takeProfitError = 'Take Profit cannot be above the market price!'
        } else {
          this.takeProfitError = ''
        }
      } else if (this.side === SIDE_LONG) {
        this.takeProfitValid = +takeProfit >= +this.markPrice.toFixed(2)
        if (!this.takeProfitValid) {
          this.takeProfitError = 'Take Profit cannot be below the market price!'
        } else {
          this.takeProfitError = ''
        }
      } else {
        this.takeProfitValid = false
      }
    },
    fractionLoss(newVal) {
      this.stopLoss = (this.spotPrice * newVal).toFixed(2)
      this.estimatedLoss = Math.abs(this.spotPrice * (newVal - 1)).toFixed(2)
    },
    fractionProfit(newVal) {
      this.takeProfit = (this.spotPrice * newVal).toFixed(2)
      this.estimatedProfit = Math.abs(this.spotPrice * (newVal - 1)).toFixed(2)
    }
  },
  methods: {
    async handleConfirm() {
      this.isLoading = true
      const id = Date.now()
      this.$store.commit('addId', id)
      this.$notify({
        id,
        title:
          this.tabSelected === 0
            ? `Setting up Take Profit at ${this.takeProfit} USDT`
            : `Setting up Stop Loss at ${this.stopLoss} USDT`,
        type: 'progress',
        duration: -1
      })
      try {
        await addTPSL(this.pairSymbol, this.signer, this.walletAddress, {
          type: this.tabSelected,
          price: this.tabSelected === 0 ? this.takeProfit : this.stopLoss,
          positionSize: this.position.size.toString(),
          collateral: this.position.margin.toString(),
          leverage: this.position.leverage.toString(),
          slippage: this.slippage.toString()
        })
        this.toggleTPSLDialog(false)
      } catch (e) {
        console.error(e)
        this.$notify({
          id,
          title: `Error while setting up TP/SL!`,
          type: 'error'
        })
      } finally {
        this.$notify.close(id)
        this.$store.commit('removeId', id)
        this.isLoading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.material_block.loading {
  margin-left: 8px;
}

.tpsl {
  &-dialog {
    padding: 16px 24px 24px 24px;
    width: 400px;
    display: flex;
    flex-direction: column;

    &-content {
      margin-top: 16px;

      .section {
        .section-input {
          border: 1px solid #282828;

          &-label {
            font-size: 12px;
            line-height: 16px;
            color: #ffffff;
          }

          &-suffix {
            font-size: 12px;
            line-height: 16px;
            margin: 8px 12px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #b7b8bc;
          }
        }

        &-percentage-buttons {
          margin-top: 24px;
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-gap: 6px;

          > * {
            font-size: 10px;
            line-height: 14px;
            background: #26272a;
          }
        }

        &-summary {
          margin-top: 16px;
          font-size: 12px;
          line-height: 16px;
          color: #a5a6a7;
          text-align: left;

          &-loss {
            color: var(--error);
          }

          &-profit {
            color: #64dba1;
          }
        }
      }
    }

    &-actions {
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;

      button {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        height: 48px;
        color: white;

        &:first-of-type {
          background: #2c2d35;
        }
      }
    }
  }
}
</style>
