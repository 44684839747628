import { ref } from 'vue'
import { wssClient } from '../api/graph'
import { GLOBAL_INFOS_SUBSCRIPTION } from '../api/queries'
import { gql } from '@apollo/client/core'

let globalInfos = ref()
let globalInfosSubscription = ref()

function subscribeGlobalInfos() {
  if (globalInfosSubscription.value) {
    globalInfosSubscription.value.unsubscribe()
  }

  globalInfosSubscription.value = wssClient
    .subscribe({
      query: gql(GLOBAL_INFOS_SUBSCRIPTION)
    })
    .subscribe({
      next(data) {
        globalInfos.value = data.data.globalInfos
      }
    })
}

export { subscribeGlobalInfos, globalInfos }
