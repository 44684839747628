<template>
  <div class="flex justify-between text-xs bg-background-1">
    <div class="flex items-center gap-6">
      <div class="flex items-center space-x-1">
        <PStatusMarker :color="indicator" />
        <div class="text-faded">{{ description }}</div>
        <div class="hidden sm:block">Syndica (211ms)</div>
      </div>
      <div class="flex items-center divide-x-2">
        <div v-for="currency in currencies" :key="currency.symbol" class="px-8">
          <button
            @click="handleSymbolClick(currency)"
            class="flex items-center gap-2 px-2 py-1 -my-1 rounded hover:bg-background-3 transition-all"
          >
            <span>{{ currency.ticker }}</span>
            <span
              v-if="!isMainnet"
              :class="`text-${formatSideColor(currency.change)}`"
            >
              {{ formatPercentage(currency.change) }}
            </span>
            <span>{{ findFormattedMarkPrice(currency) }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="flex items-center gap-4">
      <Auth v-if="isProtectedEnvironment" />
      <PTooltip :content="tooltipText" placement="top" @click="copyToClipboard">
        <div class="flex items-center gap-0.5">
          <div>Powered by Palmswap</div>
          <ClipboardIcon class="h-3.5" />
        </div>
      </PTooltip>
    </div>
  </div>
</template>

<script>
import { PStatusMarker, PTooltip } from '../../palmswap-vue-ui'
import { currencies } from '@/hooks/useAppState'
import {
  formatPercentage,
  formatSideColor,
  formatUSD
} from '@/formatters/price'
import { amms } from '@/hooks/useAmms'
import {
  correctDecimalPlaces,
  findCurrencyFromAddress
} from '@/contracts/helpers'
import { apiStatusPage } from '@/api/status'
import { onMounted, onUnmounted, ref } from 'vue'
import { isMainnet, isProtectedEnvironment } from '@/helpers'
import { ClipboardIcon } from '@heroicons/vue/24/outline'
import { useDialogs } from '@/hooks/useDialogs'

const mapIndicator = (indicator) => {
  const colorMap = {
    none: 'green',
    operational: 'green',
    minor: 'warning',
    major: 'warning',
    critical: 'red',
    maintenance: 'red'
  }
  return colorMap[indicator] || 'green'
}

export default {
  components: {
    PStatusMarker,
    Auth: async () => {
      await import('@auth0/auth0-vue')

      return await import('@/components/auth/Auth')
    },
    PTooltip,
    ClipboardIcon
  },
  data() {
    return {
      currencies: currencies.filter((i) => i.enabled),
      amms,
      isMainnet,
      isProtectedEnvironment
    }
  },
  setup() {
    const indicator = ref('green')
    const description = ref('All Systems Operational')
    let interval
    const updateStatus = async () => {
      try {
        const { data } = await apiStatusPage.get('status.json')
        indicator.value = mapIndicator(data.status.indicator)
        description.value = data.status.description
      } catch (e) {
        console.error(e)
      }
    }
    const { toggleLeavingNetworkDialog } = useDialogs()
    onMounted(() => {
      interval = setInterval(updateStatus, 30000)
    })
    onUnmounted(() => {
      clearInterval(interval)
    })
    updateStatus()
    return { indicator, description, toggleLeavingNetworkDialog }
  },
  methods: {
    handleSymbolClick(currency) {
      const path = `/trade/${currency.ticker}`
      this.$router.push({ path })
    },
    formatPercentage,
    formatSideColor,
    findCurrencyFromAddress,
    findFormattedMarkPrice(currency) {
      if (this.amms && this.markPrices.length > 0) {
        const price = this.markPrices.find(
          (markPrice) => markPrice.currency === currency
        )
        if (price) {
          return price.formattedMarkPrice
        }
      }
      return '—'
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.version)
    }
  },
  computed: {
    markPrices() {
      if (this.amms && this.amms.baseAssetReserve > 0) {
        return this.amms.map((amm) => {
          const quoteAssetReserve = correctDecimalPlaces(amm.quoteAssetReserve)
          const baseAssetReserve = correctDecimalPlaces(amm.baseAssetReserve)
          const markPrice = quoteAssetReserve.div(baseAssetReserve)
          const formattedMarkPrice = formatUSD(markPrice)

          const currency = this.findCurrencyFromAddress(amm.id)

          return { currency, formattedMarkPrice }
        })
      }
      return []
    },
    version() {
      return process.env.VUE_APP_VERSION || 'Version not defined'
    },
    tooltipText() {
      return `${this.version}`
    }
  }
}
</script>
