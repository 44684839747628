<template>
  <div class="dialog-field">
    <div>{{ label }}</div>
    <div class="value">{{ value }}</div>
    <div>{{ currency }}</div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String
    },
    value: {},
    currency: {
      type: String
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-field {
  display: flex;
  font-size: 14px;
  margin: 8px 0;
}
.value {
  margin-right: 4px;
  color: white;
  flex: 1;
  text-align: right;
}
</style>
