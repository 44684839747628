<template>
  <PDialog
    class="hide-scrollbar"
    @close="toggleTermsAndConditionsDialog(false)"
    :position="dialogPosition"
  >
    <template v-slot:header>
      <div class="w-full flex sm:justify-center">
        <img src="../../assets/logo-new-symbol.svg" class="self-center h-9" />
      </div>
    </template>
    <div
      class="h-full sm:h-100 relative flex flex-col overflow-y-scroll justify-between sm:max-w-md"
    >
      <div>
        <div class="text-xl text-white text-center font-medium">
          You are almost ready to start trading
        </div>
        <div class="text-sm text-inactive text-center px-6 mb-4 leading-5">
          To start trading on Palmswap you have to accept our acknowledge terms,
          if you want to be notified by mail you need to enter your email
          address.
        </div>
        <div
          class="text-sm text-inactive text-center px-6 mb-4 leading-5"
          v-if="referrerAddress"
        >
          Your sponsor is
          <span class="text-main"> {{ referrerAddressFormatted }} </span>. You
          get <span class="text-main">10% discount</span> on all trading fees
        </div>
        <div class="flex flex-col gap-2 flex-1">
          <Accordion class="terms">
            <template v-slot:title
              >Acknowledge Terms <span class="text-red">*</span></template
            >
            <template v-slot:content>
              <div class="border mb-4 w-full h-px" />
              <div class="text-sm text-inactive leading-5">
                <div>
                  By trading on Palmswap, you agree to the Terms of Use.
                </div>
                <div class="mt-3">
                  It appears that you are accessing Palmswap from outside the
                  United States or a restricted territory. You hereby agree,
                  represent and warrant that:
                </div>
                <ul class="list-disc p-3">
                  <li>
                    You are not a person or company who is a resident of, or is
                    located, incorporated or has a registered agent in, the
                    United States or a restricted location.
                  </li>
                  <li>
                    You will not in the future access this site or trade on
                    Palmswap while located within the United States or a
                    restricted location.
                  </li>
                  <li>
                    You are not using, and will not in the future use, a VPN to
                    mask your physical location from a restricted location.
                  </li>
                  <li>
                    You are lawfully permitted to access this site and trade on
                    Palmswap under the laws of the jurisdiction in which you
                    reside and are located.
                  </li>
                </ul>
              </div>
              <div class="border my-4 w-full h-px" />
              <div class="flex items-center gap-2.5">
                <PCheckbox v-model="terms" id="termsId"></PCheckbox>
                <div class="text-sm">I agree to Terms of Use</div>
              </div>
            </template>
            <template v-slot:right-addon>
              <div class="flex items-center">
                <PCheckbox v-model="terms" disabled></PCheckbox>
              </div>
            </template>
          </Accordion>
          <small v-if="!terms && submitClicked" class="text-red">
            * Please fill required fields
          </small>
          <Accordion>
            <template v-slot:title
              >Create your smart wallet
              <span class="text-red">*</span></template
            >
            <template v-slot:content>
              <div v-if="!hasWallet" class="w-full">
                <div class="border mb-4 w-full h-px" />
                <div class="text-sm text-inactive leading-5">
                  Please create your own smart wallet to use the different order
                  types. The creator wallet of the smart wallet controls the
                  funds and is the only address that can transfer the funds out
                  of the smart wallet.
                </div>
                <div class="border my-4 w-full h-px" />
                <PButton
                  size="sm"
                  class="h-12 w-1/3"
                  @click="createSmartWallet"
                  data-cy="tac-dialog_create-wallet-button"
                >
                  Create
                </PButton>
              </div>
              <div v-if="hasWallet" class="w-full">
                <div class="border mb-4 w-full h-px" />
                <div class="text-sm text-inactive leading-5">
                  Wallet has been created.
                </div>
                <div class="border my-4 w-full h-px" />
              </div>
            </template>
            <template v-slot:right-addon>
              <div class="flex items-center">
                <PCheckbox v-model="hasWallet" disabled />
              </div>
            </template>
          </Accordion>
          <small v-if="!hasWallet && submitClicked" class="text-red">
            * Please fill required fields
          </small>
          <Accordion class="email">
            <template v-slot:title>Email for notifications</template>
            <template v-slot:content>
              <div class="border mb-4 w-full h-px" />
              <div class="text-sm text-inactive leading-5 mb-3">
                Receive important notifications by email.
              </div>
              <Input
                class="w-full"
                v-model="email"
                placeholder="Please enter your email"
                type="email"
                dataCy="tac-dialog_email-input"
              />
              <div class="border mt-5 w-full h-px" />
              <div class="flex flex-col mt-5 gap-4">
                <div class="flex items-start gap-2.5">
                  <PCheckbox v-model="emailGeneral" id="emailGeneralId" />
                  <div class="flex flex-col">
                    <div class="text-sm leading-3">General</div>
                    <div class="text-xs">
                      Newsletter, Market Updates, Product Updates
                    </div>
                  </div>
                </div>
                <div class="flex items-start gap-2.5">
                  <PCheckbox v-model="emailAccount" id="emailAccountId" />
                  <div class="flex flex-col">
                    <div class="text-sm leading-3">Account</div>
                    <div class="text-xs">
                      Deposit, Withdrawals, Account Updates
                    </div>
                  </div>
                </div>
                <div class="flex items-start gap-2.5">
                  <PCheckbox v-model="emailTrading" id="emailTradingId" />
                  <div class="flex flex-col">
                    <div class="text-sm leading-3">Trading</div>
                    <div class="text-xs">
                      Order Updates, Liquidation Updates, Funding Payments
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Accordion>
          <Accordion
            :toggleFunc="checkIfEnabled"
            :open="terms && hasWallet"
            :class="{ 'border-red': disableDeposit }"
          >
            <template v-slot:title>Make a deposit</template>
            <template v-slot:content>
              <div class="border w-full h-px" />
              <div class="w-full flex justify-center py-4">
                <DepositForm :walletInfo="false" />
              </div>
              <div class="border w-full h-px" />
            </template>
          </Accordion>
          <small v-if="disableDeposit" class="text-red">
            Create a smart wallet and accept the Terms of Use.
          </small>
        </div>
      </div>
      <PButton
        :color="`${isValid ? 'primary' : 'tertiary'}`"
        outlined
        size="lg"
        class="mt-4 w-full w-full flex flex-none justify-center items-center"
        @click="save"
        data-cy="tac-dialog_confirm-button"
      >
        Confirm
      </PButton>
    </div>
  </PDialog>
</template>
<script>
import useBreakpoints from '@/hooks/useBreakpoints'
import { useDialogs } from '@/hooks/useDialogs'
import { PButton, PCheckbox, PDialog } from '../../../palmswap-vue-ui'
import Accordion from '@/components/common/Accordion'
import Input from '@/components/common/Input'
import DepositForm from '@/components/deposit/DepositForm'
import { constants } from 'ethers'
import { mapState } from 'vuex'
import { createSmartWallet, getSmartWallet } from '../../contracts'
import { useInvite } from '@/hooks/useInvite'
import { formatAddress } from '@/formatters/contract'
import { useUserSettings } from '@/hooks/useUserSettings'

export default {
  components: {
    Input,
    Accordion,
    DepositForm,
    PButton,
    PCheckbox,
    PDialog
  },
  computed: {
    ...mapState({
      account: (state) => state.user.address.account,
      wallet: (state) => state.user.address.wallet,
      signer: (state) => Object.freeze(state.web3Modal.provider.getSigner())
    }),
    isValid() {
      return this.terms && this.wallet && this.wallet !== constants.AddressZero
    },
    hasWallet() {
      return Boolean(this.wallet && this.wallet !== constants.AddressZero)
    },
    referrerAddressFormatted() {
      return formatAddress(this.referrerAddress, 3)
    },
    dialogPosition() {
      if (this.isMobile) {
        return 'full'
      } else {
        return 'center'
      }
    }
  },
  setup() {
    const {
      toggleTermsAndConditionsDialog,
      toggleLinkWalletDialog,
      toggleWelcomeDialog
    } = useDialogs()
    const { referrerAddress, resetReferrerAddress } = useInvite()
    const { user, saveUser } = useUserSettings()
    const { isMobile } = useBreakpoints()

    return {
      toggleTermsAndConditionsDialog,
      toggleLinkWalletDialog,
      referrerAddress,
      user,
      saveUser,
      resetReferrerAddress,
      isMobile,
      toggleWelcomeDialog
    }
  },
  data() {
    return {
      terms: false,
      isLoading: false,
      submitClicked: false,
      email: '',
      emailGeneral: true,
      emailAccount: true,
      emailTrading: true,
      disableDeposit: false,
      createdSmartWallet: false
    }
  },
  watch: {
    user(newVal) {
      this.email = newVal.email
    }
  },
  methods: {
    save: async function () {
      this.submitClicked = true
      if (!this.isValid) {
        return false
      }
      this.isLoading = true
      try {
        let user = {
          ...this.user,
          termsAccepted: true
        }
        if (this.email && this.email !== this.user.email) {
          user = {
            ...user,
            email: this.email,
            notifications: {
              general: this.emailGeneral,
              account: this.emailAccount,
              trading: this.emailTrading
            },
            verified: false,
            verificationCode: ''
          }
        }
        await this.saveUser(user)
        this.$store.dispatch('setTACVisible')
        this.$notify({
          id: 'termsConfirmed',
          title: 'You can start trading now',
          type: 'success',
          kind: 'normal'
        })
        this.toggleTermsAndConditionsDialog(false)
        this.createdSmartWallet && this.toggleWelcomeDialog(true)
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
    checkIfEnabled: function () {
      if (this.terms === false || this.hasWallet === false) {
        this.disableDeposit = !this.disableDeposit
      }
    },
    createSmartWallet: async function () {
      let smartWallet = ''
      smartWallet = await getSmartWallet(this.signer)
      this.$store.commit('addId', 'createSmartWallet')
      if (smartWallet === constants.AddressZero) {
        this.$notify({
          id: 'createSmartWallet',
          title: 'Creating a new wallet',
          duration: -1,
          type: 'progress',
          kind: 'normal'
        })
        try {
          smartWallet = await createSmartWallet(
            this.signer,
            this.referrerAddress,
            () => {
              this.$notify({
                id: 'createSmartWalletError',
                title: 'Only ALPHA NFT holders can create Smart Wallet',
                type: 'error',
                duration: 2000,
                kind: 'normal'
              })
            }
          )
          this.$store.commit('removeId', 'createSmartWallet')
        } catch (e) {
          this.$notify({
            id: 'createSmartWalletError',
            title: "Couldn't create Smart Wallet",
            type: 'error',
            duration: 2000,
            kind: 'normal'
          })
        } finally {
          this.createdSmartWallet = true
          this.$notify.close('createSmartWallet')
        }
      }
      if (smartWallet !== constants.AddressZero) {
        this.resetReferrerAddress()
        this.$store.commit('setWalletAddress', smartWallet)
      }
    }
  }
}
</script>
<style lang="scss">
::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  > div {
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
  }
}
</style>
