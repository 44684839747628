import { reactive, ref, computed } from 'vue'

const dialogs = reactive({
  withdrawal: {
    isOpen: false
  },
  deposit: {
    isOpen: false
  },
  mailSettings: {
    isOpen: false
  },
  marketClose: {
    isOpen: false
  },
  marginManagement: {
    isOpen: false
  },
  termsAndConditions: {
    isOpen: false
  },
  tpsl: {
    isOpen: false
  },
  confirm: {
    isOpen: false
  },
  error: {
    isOpen: false
  },
  transaction: {
    isOpen: false
  },
  bscoffline: {
    isOpen: false
  },
  makeOrder: {
    isOpen: false
  },
  referralProgram: {
    isOpen: false
  },
  referralEarnings: {
    isOpen: false
  },
  launchpadReferrals: {
    isOpen: false
  },
  stake: {
    isOpen: false
  },
  unstake: {
    isOpen: false
  },
  claimStake: {
    isOpen: false
  },
  farmClaim: {
    isOpen: false
  },
  ROICalculator: {
    isOpen: false
  },
  RoiCalculator: {
    isOpen: false
  },
  launchpadTerms: {
    isOpen: false
  },
  sidebar: {
    isOpen: false
  },
  leavingNetwork: {
    isOpen: false
  },
  inviteStake: {
    isOpen: false
  },
  linkWallet: {
    isOpen: false
  },
  welcome: {
    isOpen: false
  }
})

const metadataRef = ref({})

const toggleDialog = (name, isOpen = !dialogs[name].isOpen, data = {}) => {
  if (isOpen) {
    metadataRef.value = data
    dialogs[name].isOpen = true
  } else {
    dialogs[name].isOpen = false
    metadataRef.value = data
  }
}

export const useDialogs = () => {
  const isLinkWalletOpen = computed(() => dialogs.linkWallet.isOpen)
  const isWelcomeOpen = computed(() => dialogs.welcome.isOpen)
  const ifInviteStakeOpen = computed(() => dialogs.inviteStake.isOpen)
  const ifROICalculatorOpen = computed(() => dialogs.ROICalculator.isOpen)
  const ifFarmClaimOpen = computed(() => dialogs.farmClaim.isOpen)
  const isWithdrawalDialogOpen = computed(() => dialogs.withdrawal.isOpen)
  const isDepositDialogOpen = computed(() => dialogs.deposit.isOpen)
  const isMailSettingsDialogOpen = computed(() => dialogs.mailSettings.isOpen)
  const isMarketCloseDialogOpen = computed(() => dialogs.marketClose.isOpen)
  const isMarginManagementOpen = computed(() => dialogs.marginManagement.isOpen)
  const isTPSLDialogOpen = computed(() => dialogs.tpsl.isOpen)
  const isTermsAndConditionsOpen = computed(
    () => dialogs.termsAndConditions.isOpen
  )
  const isConfirmOpen = computed(() => dialogs.confirm.isOpen)
  const isErrorOpen = computed(() => dialogs.error.isOpen)
  const isTransactionDialogOpen = computed(() => dialogs.transaction.isOpen)
  const isBCSOfflineOpen = computed(() => dialogs.bscoffline.isOpen)
  const isMakeOrderDialogOpen = computed(() => dialogs.makeOrder.isOpen)
  const isReferralProgramDialogOpen = computed(
    () => dialogs.referralProgram.isOpen
  )
  const isReferralEarningsDialogOpen = computed(
    () => dialogs.referralEarnings.isOpen
  )
  const isLaunchpadReferralsDialogOpen = computed(
    () => dialogs.launchpadReferrals.isOpen
  )
  const isLaunchpadTermsDialogOpen = computed(
    () => dialogs.launchpadTerms.isOpen
  )
  const isStakeDialogOpen = computed(() => dialogs.stake.isOpen)
  const isUnstakeDialogOpen = computed(() => dialogs.unstake.isOpen)
  const isClaimStakeDialogOpen = computed(() => dialogs.claimStake.isOpen)
  const isSidebarDialogOpen = computed(() => dialogs.sidebar.isOpen)
  const isLeavingNetworkDialogOpen = computed(
    () => dialogs.leavingNetwork.isOpen
  )
  const isRoiCalculatorOpen = computed(() => dialogs.RoiCalculator.isOpen)

  const metadata = computed(() => metadataRef.value)

  const toggleInviteStakeDialog = (isOpen) =>
    toggleDialog('inviteStake', isOpen)
  const toggleWithdrawalDialog = (isOpen) => toggleDialog('withdrawal', isOpen)
  const toggleLinkWalletDialog = (isOpen) => toggleDialog('linkWallet', isOpen)
  const toggleDepositDialog = (isOpen) => toggleDialog('deposit', isOpen)
  const toggleMailSettingsDialog = (isOpen) =>
    toggleDialog('mailSettings', isOpen)
  const toggleMarketCloseDialog = (isOpen, data) =>
    toggleDialog('marketClose', isOpen, data)
  const toggleMarginManagement = (isOpen, metadata) =>
    toggleDialog('marginManagement', isOpen, metadata)
  const toggleTermsAndConditionsDialog = (isOpen) =>
    toggleDialog('termsAndConditions', isOpen)
  const toggleTPSLDialog = (isOpen) => toggleDialog('tpsl', isOpen)
  const toggleConfirmDialog = (isOpen) => toggleDialog('confirm', isOpen)
  const toggleErrorDialog = (isOpen) => toggleDialog('error', isOpen)
  const toggleTransactionDialog = (isOpen) =>
    toggleDialog('transaction', isOpen)
  const toggleBCSOfflineDialog = (isOpen) => toggleDialog('bscoffline', isOpen)
  const toggleMakeOrderDialog = (isOpen, metadata) =>
    toggleDialog('makeOrder', isOpen, metadata)
  const toggleWelcomeDialog = (isOpen) => toggleDialog('welcome', isOpen)
  const toggleReferralProgram = (isOpen) =>
    toggleDialog('referralProgram', isOpen)
  const toggleReferralEarningsDialog = (isOpen) =>
    toggleDialog('referralEarnings', isOpen)
  const toggleLaunchpadReferralsDialog = (isOpen) =>
    toggleDialog('launchpadReferrals', isOpen)
  const toggleStakeDialog = (isOpen, metadata) =>
    toggleDialog('stake', isOpen, metadata)
  const toggleUnstakeDialog = (isOpen, metadata) =>
    toggleDialog('unstake', isOpen, metadata)
  const toggleClaimStakeDialog = (isOpen, metadata) =>
    toggleDialog('claimStake', isOpen, metadata)
  const toggleFarmClaimDialog = (isOpen, metadata) =>
    toggleDialog('farmClaim', isOpen, metadata)
  const toggleROICalculatorDialog = (isOpen, metadata) =>
    toggleDialog('ROICalculator', isOpen, metadata)
  const toggleLaunchpadTermsDialog = (isOpen, metadata) =>
    toggleDialog('launchpadTerms', isOpen, metadata)
  const toggleSidebarDialog = (isOpen, metadata) =>
    toggleDialog('sidebar', isOpen, metadata)
  const toggleLeavingNetworkDialog = (isOpen, metadata) =>
    toggleDialog('leavingNetwork', isOpen, metadata)
  const toggleRoiCalculatorDialog = (isOpen, metadata) =>
    toggleDialog('RoiCalculator', isOpen, metadata)

  return {
    isWelcomeOpen,
    ifInviteStakeOpen,
    ifROICalculatorOpen,
    ifFarmClaimOpen,
    isMarketCloseDialogOpen,
    isWithdrawalDialogOpen,
    isDepositDialogOpen,
    isMailSettingsDialogOpen,
    isMarginManagementOpen,
    isTPSLDialogOpen,
    isTermsAndConditionsOpen,
    isConfirmOpen,
    isErrorOpen,
    isTransactionDialogOpen,
    isBCSOfflineOpen,
    isMakeOrderDialogOpen,
    isReferralProgramDialogOpen,
    isReferralEarningsDialogOpen,
    isLaunchpadReferralsDialogOpen,
    isStakeDialogOpen,
    isUnstakeDialogOpen,
    isClaimStakeDialogOpen,
    isLaunchpadTermsDialogOpen,
    isSidebarDialogOpen,
    isLeavingNetworkDialogOpen,
    isRoiCalculatorOpen,
    isLinkWalletOpen,
    metadata,
    toggleWelcomeDialog,
    toggleWithdrawalDialog,
    toggleDepositDialog,
    toggleMarketCloseDialog,
    toggleMarginManagement,
    toggleTPSLDialog,
    toggleTermsAndConditionsDialog,
    toggleConfirmDialog,
    toggleErrorDialog,
    toggleTransactionDialog,
    toggleBCSOfflineDialog,
    toggleMailSettingsDialog,
    toggleMakeOrderDialog,
    toggleReferralProgram,
    toggleReferralEarningsDialog,
    toggleLaunchpadReferralsDialog,
    toggleStakeDialog,
    toggleUnstakeDialog,
    toggleClaimStakeDialog,
    toggleFarmClaimDialog,
    toggleROICalculatorDialog,
    toggleLaunchpadTermsDialog,
    toggleSidebarDialog,
    toggleLeavingNetworkDialog,
    toggleRoiCalculatorDialog,
    toggleLinkWalletDialog,
    toggleInviteStakeDialog
  }
}
