<template>
  <Input :dataCy="dataCy">
    <template v-slot:suffix v-if="label">
      <div class="flex items-center h-full w-full select-none px-3">
        <img class="h-4 mr-2" :src="label.logo" />
        <span class="text-xs">{{ label.symbol }}</span>
      </div>
    </template>
  </Input>
</template>

<script>
import Input from '@/components/common/Input'

const currencies = {
  usdt: { logo: '/img/usdt.svg', symbol: 'USDT' }
}

export default {
  name: 'CurrencyInput',
  components: {
    Input
  },
  props: {
    currency: [Object, String],
    dataCy: String
  },
  computed: {
    label() {
      if (typeof this.currency === 'string') {
        return currencies[this.currency.toLowerCase()]
      }
      return this.currency
    }
  }
}
</script>

<style scoped>
:deep(.label-container) {
  @apply right-0 !important;
}
</style>
