<template>
  <div class="material_block">
    <svg
      class="spinner"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="path"
        fill="none"
        stroke-width="2"
        stroke-linecap="round"
        cx="9"
        cy="9"
        r="8"
      ></circle>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.material_block {
  stroke: white;
  $offset: 42;
  $duration: 1s;
  display: flex;
  align-items: center;
  .spinner {
    animation: rotator $duration linear infinite;
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite;
  }

  // @keyframes colors {
  //     0% {
  //         stroke: #4285f4;
  //     }
  //     25% {
  //         stroke: #de3e35;
  //     }
  //     50% {
  //         stroke: #f7c223;
  //     }
  //     75% {
  //         stroke: #1b9a59;
  //     }
  //     100% {
  //         stroke: #4285f4;
  //     }
  // }

  @keyframes dash {
    0% {
      stroke-dashoffset: $offset;
      transform: rotate(0deg);
    }
    50% {
      stroke-dashoffset: calc($offset/4);
      transform: rotate(180deg);
    }
    100% {
      stroke-dashoffset: $offset;
      transform: rotate(359deg);
    }
  }
}
</style>
