<template>
  <PDialog title="Claim" @close="toggleFarmClaimDialog(false)">
    <div class="text-sm w-96">
      <hr class="mb-5" />
      <div class="space-y-5">
        <div class="space-y-3">
          <div class="flex justify-between items-center">
            <div>Claim</div>
            <div class="text-white flex items-center">
              <div>{{ unclaimedRewardAmountFormatted }}</div>
              <img src="../../assets/logo-purple.png" class="h-5 ml-2" />
            </div>
          </div>
          <Input
            v-model.number="claim"
            @update:model-value="getPercentage"
            placeholder="0.0"
            :minValue="0"
            :error="error"
          >
            <template #suffix>PALM</template>
          </Input>
        </div>
        <div class="space-y-2">
          <PRangeSlider
            color="main"
            min="0"
            max="100"
            step="1"
            suffix="%"
            :model-value="percentage"
            @update:model-value="setPercentage"
          />
        </div>
        <FractionSelector @update="setFractionPercentage" />
        <div
          class="w-full bg-main bg-opacity-10 pt-4 pb-6 px-6 rounded-lg border border-main text-sm text-white mb-8"
        >
          <div class="mb-4">
            You can reinvest the profits you have generated from the farm into
            the staking pool.
          </div>
          <PButton
            color="main"
            class="w-full h-12"
            :disabled="error || !claim"
            @click="handleCompound()"
            >Compound</PButton
          >
        </div>
        <PButton
          class="w-full h-12"
          :disabled="error || !claim"
          @click="handleClaim()"
          >Withdraw</PButton
        >
      </div>
    </div>
  </PDialog>
</template>
<script>
import { useDialogs } from '@/hooks/useDialogs'
import { PDialog, PRangeSlider, PButton } from '../../../palmswap-vue-ui'
import FractionSelector from '@/components/common/FractionSelector'
import Input from '@/components/common/Input'
import { claimStake, compoundStake } from '@/contracts/staking'
import { mapState } from 'vuex'
import { computed } from 'vue'
import metadata from '@/contracts/metadata'
import { getUserInfo } from '@/contracts/staking'
import { correctDecimalPlaces } from '@/contracts/helpers'
import { numberWithCommas } from '@/formatters/price'
import { getPendingAmount } from '@/contracts/staking'
import { ToadScheduler, SimpleIntervalJob, AsyncTask } from 'toad-scheduler'
import { getCurrentBlock } from '@/contracts/contracts'
import { BLOCK_INTERVAL } from '@/config/constants'
import { utils } from 'ethers'

export default {
  components: {
    PDialog,
    PRangeSlider,
    Input,
    PButton,
    FractionSelector
  },
  data() {
    return {
      claim: null,
      percentage: 0,
      userInfo: null,
      pendingAmount: null,
      allowance: 0,
      error: null
    }
  },
  async mounted() {
    if (this.account) {
      let _userInfo
      ;[_userInfo, this.pendingAmount, this.allowance] = await Promise.all([
        getUserInfo(this.tokenAddress, this.account),
        getPendingAmount(this.tokenAddress, this.account)
      ])
      this.$store.commit('updateUserInfo', _userInfo)

      const scheduler = new ToadScheduler()
      const pendingAmountTask = new AsyncTask('update pending amount', () => {
        return getPendingAmount(this.tokenAddress, this.account).then(
          (result) => {
            this.pendingAmount = result
          }
        )
      })
      const currentBlockTask = new AsyncTask('update current block', () => {
        return getCurrentBlock().then((result) => {
          this.currentBlock = result
        })
      })
      const pendingAmountJob = new SimpleIntervalJob(
        { seconds: BLOCK_INTERVAL },
        pendingAmountTask
      )
      const currentBlockJob = new SimpleIntervalJob(
        { seconds: BLOCK_INTERVAL },
        currentBlockTask
      )
      scheduler.addSimpleIntervalJob(pendingAmountJob)
      scheduler.addSimpleIntervalJob(currentBlockJob)
      this.$store.commit('updateScheduler', scheduler)

      this.currentBlock = await getCurrentBlock()
    }
  },
  watch: {
    async account() {
      const userInfo = await getUserInfo(this.tokenAddress, this.account)
      this.userInfo = userInfo

      let _userInfo
      ;[_userInfo, this.pendingAmount, this.allowance] = await Promise.all([
        getUserInfo(this.tokenAddress, this.account),
        getPendingAmount(this.tokenAddress, this.account)
      ])
      this.$store.commit('updateUserInfo', _userInfo)

      const scheduler = new ToadScheduler()
      const pendingAmountTask = new AsyncTask('update pending amount', () => {
        return getPendingAmount(this.tokenAddress, this.account).then(
          (result) => {
            this.pendingAmount = result
          }
        )
      })
      const currentBlockTask = new AsyncTask('update current block', () => {
        return getCurrentBlock().then((result) => {
          this.currentBlock = result
        })
      })
      const pendingAmountJob = new SimpleIntervalJob(
        { seconds: BLOCK_INTERVAL },
        pendingAmountTask
      )
      const currentBlockJob = new SimpleIntervalJob(
        { seconds: BLOCK_INTERVAL },
        currentBlockTask
      )
      scheduler.addSimpleIntervalJob(pendingAmountJob)
      scheduler.addSimpleIntervalJob(currentBlockJob)
      this.$store.commit('updateScheduler', scheduler)

      this.currentBlock = await getCurrentBlock()
    }
  },
  setup() {
    const { metadata } = useDialogs()
    const lpToken = computed(() => (metadata.value ? metadata.value : ''))

    return { lpToken }
  },
  computed: {
    ...mapState({
      balance: (state) => state.user.balance.palmToken,
      signer: (state) => state.web3Modal.provider.getSigner(),
      account: (state) => state.user.address.account
    }),
    tokenAddress() {
      return this.lpToken === 'BNB' ? metadata.palmBNBLP : metadata.palmUSDTLP
    },
    unclaimedRewardAmount() {
      if (this.pendingAmount) {
        return Number(correctDecimalPlaces(this.pendingAmount))
      } else {
        return null
      }
    },
    unclaimedRewardAmountFormatted() {
      if (this.pendingAmount) {
        return `${numberWithCommas(this.unclaimedRewardAmount, 4)} PALM`
      } else {
        return null
      }
    }
  },
  methods: {
    toggleFarmClaimDialog: useDialogs().toggleFarmClaimDialog,
    setPercentage(newPercentage) {
      const balancePercent = this.unclaimedRewardAmount
        ? this.unclaimedRewardAmount / 100
        : 0
      this.claim = balancePercent * newPercentage
      this.percentage = newPercentage
    },
    setFractionPercentage(fraction) {
      const balancePercent = this.unclaimedRewardAmount
        ? this.unclaimedRewardAmount / 100
        : 0
      this.claim = balancePercent * (fraction * 100)
      this.percentage = fraction * 100
    },
    getPercentage(newVal) {
      if (newVal < this.unclaimedRewardAmount) {
        this.error = null
        const balancePercent = this.unclaimedRewardAmount
          ? this.unclaimedRewardAmount / 100
          : 0
        const percentage = balancePercent ? newVal / balancePercent : 0
        this.percentage = percentage.toFixed(2)
      } else {
        this.error = "amount can't be bigger than balance"
        this.percentage = 100
      }
    },
    async handleClaim() {
      this.toggleFarmClaimDialog(false)
      const id = Date.now()
      this.$store.commit('addId', id)
      const amountWei = utils.parseUnits(this.claim.toString(), 'ether')
      try {
        const result = await claimStake(
          metadata.palmBNBLP,
          amountWei,
          this.signer,
          () => {
            this.$notify({
              id,
              title: 'Claim',
              type: 'progress',
              duration: -1,
              data: {
                body: `Your claim is waiting for confirmation.`
              }
            })
          }
        )
        if (result) {
          await this.$store.dispatch('updateBalance')
          this.$notify({
            id,
            title: 'Claim',
            type: 'success',
            data: {
              body: `Your claim is confirmed.`
            }
          })
        } else {
          this.$notify({
            id,
            title: 'Error while confirming the claim',
            type: 'error'
          })
        }
      } catch (errorConfirm) {
        console.error(errorConfirm)
        this.$notify({
          id,
          title: 'Error while confirming the claim',
          type: 'error'
        })
      } finally {
        this.$notify.close(id)
        this.$store.commit('removeId', id)
      }
    },
    async handleCompound() {
      this.toggleFarmClaimDialog(false)
      const id = Date.now()
      this.$store.commit('addId', id)
      const amountWei = utils.parseUnits(this.claim.toString(), 'ether')
      try {
        const result = await compoundStake(
          metadata.palmBNBLP,
          amountWei,
          this.signer,
          () => {
            this.$notify({
              id,
              title: 'Compound',
              type: 'progress',
              duration: -1,
              data: {
                body: `Your compound is waiting for confirmation.`
              }
            })
          }
        )
        if (result) {
          await this.$store.dispatch('updateBalance')
          this.$notify({
            id,
            title: 'Compound',
            type: 'success',
            data: {
              body: `Your compound is confirmed.`
            }
          })
        } else {
          this.$notify({
            id,
            title: 'Error while confirming the compound',
            type: 'error'
          })
        }
      } catch (errorConfirm) {
        console.error(errorConfirm)
        this.$notify({
          id,
          title: 'Error while confirming the compound',
          type: 'error'
        })
      } finally {
        this.$notify.close(id)
        this.$store.commit('removeId', id)
      }
    }
  }
}
</script>
