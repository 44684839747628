<template>
  <div
    class="h-screen flex flex-col bg-background-1 text-inactive text-base fixed w-screen overflow-auto"
  >
    <PalmNotification />
    <NotMobileOptimized />
    <div class="flex-none">
      <NavBar />
    </div>
    <div class="flex-1 sm:px-3 flex flex-col space-y-2.5 sm:pb-2.5">
      <router-view />
      <Footer v-if="!isMobile" />
    </div>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <BSCOfflineDialog v-if="isBCSOfflineOpen" />
    </transition>
    <transition name="fade">
      <TermsAndConditions
        v-if="isTermsAndConditionsOpen && $route.path.startsWith('/trade')"
        class="backdrop-filter backdrop-blur-sm"
      />
    </transition>
    <transition name="fade">
      <LaunchpadTerms
        v-if="isLaunchpadTermsDialogOpen"
        class="backdrop-filter backdrop-blur-sm"
      />
    </transition>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <WithdrawalDialog v-if="isWithdrawalDialogOpen" />
    </transition>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <MailSettingsDialog v-if="isMailSettingsDialogOpen" />
    </transition>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <DepositDialog v-if="isDepositDialogOpen" />
    </transition>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <MarketCloseDialog v-if="isMarketCloseDialogOpen" />
    </transition>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <MarginManagementDialog v-if="isMarginManagementOpen" />
    </transition>
    <transition name="fade">
      <TPSLDialog v-if="isTPSLDialogOpen" />
    </transition>
    <transition name="fade">
      <ConfirmDialog v-if="isConfirmOpen" />
    </transition>
    <transition name="fade">
      <ErrorDialog v-if="false" />
    </transition>
    <transition name="fade">
      <TransactionDialog v-if="isTransactionDialogOpen" />
    </transition>
    <transition name="fade">
      <MakeOrderDialog v-if="isMakeOrderDialogOpen" />
    </transition>
    <transition name="fade">
      <ReferralProgramDialog v-if="isReferralProgramDialogOpen" />
    </transition>
    <transition name="fade">
      <ReferralEarningsDialog v-if="isReferralEarningsDialogOpen" />
    </transition>
    <transition name="fade">
      <ReferralsDialog v-if="isLaunchpadReferralsDialogOpen" />
    </transition>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <StakeDialog v-if="isStakeDialogOpen" />
    </transition>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <UnstakeDialog v-if="isUnstakeDialogOpen" />
    </transition>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <ClaimStakeDialog v-if="isClaimStakeDialogOpen" />
    </transition>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <FarmClaimDialog v-if="ifFarmClaimOpen" />
    </transition>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <ROICalculator v-if="ifROICalculatorOpen" />
    </transition>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <RoiCalculator v-if="isRoiCalculatorOpen" />
    </transition>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <LinkWallet v-if="isLinkWalletOpen" />
    </transition>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <LeavingNetworkDialog
        :currentNetwork="currentNetwork"
        v-if="isLeavingNetworkDialogOpen"
      />
    </transition>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <InviteStakeDialog v-if="ifInviteStakeOpen" />
    </transition>
    <transition name="fade" class="backdrop-filter backdrop-blur-sm">
      <WelcomeDialog v-if="isWelcomeOpen" />
    </transition>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import WithdrawalDialog from '@/components/WithdrawalDialog.vue'
import DepositDialog from '@/components/DepositDialog.vue'
import { useDialogs } from '@/hooks/useDialogs'
import MarketCloseDialog from './components/market-close/MarketCloseDialog.vue'
import MarginManagementDialog from './components/MarginManagementDialog.vue'
import TermsAndConditions from '@/components/terms-and-conditions/TermsAndConditions'
import { useAccount } from './hooks/useAccount'
import TPSLDialog from './components/TPSLDialog.vue'
import ConfirmDialog from './components/ConfirmDialog.vue'
import ErrorDialog from './components/ErrorDialog.vue'
import TransactionDialog from './components/TransactionDialog.vue'
import BSCOfflineDialog from './components/BSCOfflineDialog.vue'
import PalmNotification from './components/common/PalmNotification.vue'
import { FEATURE_FLAGS } from '@/config/constants'
import { isFeatureEnabled } from '@/utils/utils'
import MailSettingsDialog from '@/components/MailSettingsDialog'
import MakeOrderDialog from '@/components/trade/MakeOrderDialog.vue'
import useBreakpoints from '@/hooks/useBreakpoints'
import ReferralProgramDialog from '@/components/launchpad/ReferralProgramDialog'
import { subscribeAmms } from '@/hooks/useAmms'
import { subscribePoolInfos } from '@/hooks/usePoolInfos'
import { subscribeGlobalInfos } from '@/hooks/useGlobalInfos'
import ReferralEarningsDialog from '@/components/launchpad/ReferralEarningsDialog'
import ReferralsDialog from '@/components/launchpad/ReferralsDialog'
import StakeDialog from '@/components/staking/StakeDialog.vue'
import UnstakeDialog from '@/components/staking/UnstakeDialog.vue'
import ClaimStakeDialog from '@/components/staking/ClaimStakeDialog.vue'
import FarmClaimDialog from './components/farm/FarmClaimDialog.vue'
import ROICalculator from './components/farm/ROICalculator.vue'
import RoiCalculator from './components/staking/RoiCalculator.vue'
import NotMobileOptimized from './components/NotMobileOptimized'
import LaunchpadTerms from '@/components/terms-and-conditions/LaunchpadTerms'
import LeavingNetworkDialog from '@/components/LeavingNetworkDialog'
import LinkWallet from './components/terms-and-conditions/LinkWallet.vue'
import InviteStakeDialog from '@/components/portfolio/InviteStakeDialog.vue'
import WelcomeDialog from './components/WelcomeDialog.vue'
import { useAppState } from '@/hooks/useAppState'

export default {
  components: {
    LeavingNetworkDialog,
    LaunchpadTerms,
    ReferralsDialog,
    ReferralEarningsDialog,
    ReferralProgramDialog,
    MailSettingsDialog,
    NavBar,
    Footer,
    NotMobileOptimized,
    WithdrawalDialog,
    DepositDialog,
    MarketCloseDialog,
    MarginManagementDialog,
    TermsAndConditions,
    TPSLDialog,
    ConfirmDialog,
    ErrorDialog,
    TransactionDialog,
    PalmNotification,
    BSCOfflineDialog,
    MakeOrderDialog,
    StakeDialog,
    UnstakeDialog,
    ClaimStakeDialog,
    FarmClaimDialog,
    ROICalculator,
    RoiCalculator,
    InviteStakeDialog,
    WelcomeDialog,
    LinkWallet
  },
  setup() {
    const {
      isWithdrawalDialogOpen,
      isMailSettingsDialogOpen,
      isDepositDialogOpen,
      isMarketCloseDialogOpen,
      isTPSLDialogOpen,
      isMarginManagementOpen,
      isConfirmOpen,
      isTransactionDialogOpen,
      isTermsAndConditionsOpen,
      isBCSOfflineOpen,
      isMakeOrderDialogOpen,
      isReferralProgramDialogOpen,
      isReferralEarningsDialogOpen,
      isLaunchpadReferralsDialogOpen,
      isStakeDialogOpen,
      isUnstakeDialogOpen,
      isClaimStakeDialogOpen,
      isLaunchpadTermsDialogOpen,
      toggleBCSOfflineDialog,
      ifFarmClaimOpen,
      ifROICalculatorOpen,
      isRoiCalculatorOpen,
      isLeavingNetworkDialogOpen,
      ifInviteStakeOpen,
      isLinkWalletOpen,
      isWelcomeOpen
    } = useDialogs()

    const { isConsentNeeded } = useAccount()

    const { currency } = useAppState()

    const { isMobile } = useBreakpoints()

    subscribeAmms()
    subscribePoolInfos()
    subscribeGlobalInfos()

    return {
      isWelcomeOpen,
      isWithdrawalDialogOpen,
      isMailSettingsDialogOpen,
      isDepositDialogOpen,
      isMarketCloseDialogOpen,
      isMarginManagementOpen,
      isConsentNeeded,
      isTPSLDialogOpen,
      isConfirmOpen,
      isTransactionDialogOpen,
      isTermsAndConditionsOpen,
      isBCSOfflineOpen,
      isMakeOrderDialogOpen,
      isReferralProgramDialogOpen,
      isReferralEarningsDialogOpen,
      isLaunchpadReferralsDialogOpen,
      isStakeDialogOpen,
      isUnstakeDialogOpen,
      isClaimStakeDialogOpen,
      isLinkWalletOpen,
      toggleBCSOfflineDialog,
      isLaunchpadTermsDialogOpen,
      isMobile,
      ifFarmClaimOpen,
      ifROICalculatorOpen,
      isRoiCalculatorOpen,
      isLeavingNetworkDialogOpen,
      currency,
      ifInviteStakeOpen
    }
  },
  computed: {
    currentNetwork() {
      return process.env.VUE_APP_NETWORK_ID === '0x38' ? 'mainnet' : 'testnet'
    }
  },
  watch: {
    '$route.path'(value) {
      if (
        value !== '/leaderboard' &&
        isFeatureEnabled(FEATURE_FLAGS.OFFLINE_POPUP)
      ) {
        this.toggleBCSOfflineDialog(true)
      } else {
        this.toggleBCSOfflineDialog(false)
      }

      if (value === '/' || value === '/trade') {
        this.$router.push({ path: `/trade/${this.currency.ticker}` })
      }
    },
    '$route.name'(value) {
      if (value === 'Home') {
        this.$router.push({ path: `/trade/${this.currency.ticker}` })
      }
    },
    currency(newVal) {
      this.$router.push({ path: `/trade/${newVal.ticker}` })
    }
  }
}
</script>

<style lang="scss">
body {
  background-color: #14151a;
  padding: 0;
  margin: 0;
  --black60: rgba(0, 0, 0, 0.6);
  --gray100: #000;
  --gray90: #171717;
  --gray80: #1f1f1f;
  --gray60: #424242;
  --gray40: #666666;
  --gray20: #a6a6a6;
  --gray10: #d1d1d1;
  --gray00: #fff;
  --overlay: rgba(32, 32, 36, 0.8);
  --text: rgba(255, 255, 255, 0.6);
  --bg: #2c2d35;
  --primary: #5d7fca;
  --success: #279b6d;
  --error: #dd4141;
  --warning: #f8c86b;
  font-family: Inter, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text);
}
::-webkit-scrollbar {
  width: 10px;
  background: #24252a;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #191919;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333333;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.blur {
  backdrop-filter: blur(5px);
}

.height {
  height: calc(100% - 70px);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
