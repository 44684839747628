import { ref, computed } from 'vue'
import { httpCompetitionClient, apiClient } from '../api/graph'
import { COMP_GLOBAL_STATE_QUERY } from '../api/queries'
import { formatGlobalState } from '../utils/utils'

const leaderboardItemsRef = ref([])

const rawGlobalStateRef = ref(null)

async function globalStateQuery() {
  const res = await httpCompetitionClient.post('', {
    query: COMP_GLOBAL_STATE_QUERY
  })
  const rawGlobalState = formatGlobalState(res.data.data.globalState)
  return rawGlobalState
}

export async function fetchGlobalState() {
  try {
    const globalStateRes = await globalStateQuery()
    rawGlobalStateRef.value = globalStateRes
  } catch (err) {
    console.error(err)
  }
}

export async function fetchLeaderboard() {
  try {
    const leaderboardRes = await apiClient.get('/leaderboard')
    leaderboardItemsRef.value = leaderboardRes.data
  } catch (e) {
    console.log(e)
  }
}

export const useCompetitionPositions = () => {
  const leaderboardItems = computed(() => {
    if (!leaderboardItemsRef.value) {
      return []
    }
    return leaderboardItemsRef.value
  })

  return {
    leaderboardItems
  }
}

export const useGlobalState = () => {
  const rawGlobalState = computed(() => {
    return rawGlobalStateRef.value
  })

  return {
    rawGlobalState
  }
}
