import { clearingHouse, clearingHouseViewer, getAmmContractAddress, getSmartWalletContract, setSignedContracts } from './contracts';
import { calculatePositionSize } from './index';
import Big from 'big.js';
import { bigNum2Big, DEFAULT_DECIMALS, getEstimatedBlockTimestamp, SIDE_LONG, SIDE_SHORT, toD, num2Decimal, correctDecimalPlaces, getLiquidationPrice, getFullLiquidationPrice } from './helpers';
import { fetchPosition, fetchAllPositions } from '../hooks/useUserPosition';
import { utils, BigNumber } from 'ethers';
import { calcFee, getInputPrice, getOutputPrice } from './amm';
import metadata from './metadata';
import { cacheGet, cacheSet } from '@/utils/utils';
import { getTradingPairByAddress } from '@/hooks/useAppState';
import { PositionDirection, PositionPnlCalcOption, PositionSides } from '@/types/position';
export const getPosition = async (ammAddr, walletAddress) => {
    if (!ammAddr) {
        return null;
    }
    const cacheKey = `position_${ammAddr.toLowerCase()}_${walletAddress.toLowerCase()}`;
    const cachedValue = cacheGet(cacheKey);
    if (cachedValue) {
        return cachedValue;
    }
    const position = await clearingHouseViewer.getPersonalPositionWithFundingPayment(ammAddr, walletAddress);
    const pnl = correctDecimalPlaces((await clearingHouseViewer.getUnrealizedPnl(ammAddr, walletAddress, utils.parseUnits(PositionPnlCalcOption.SPOT_PRICE.toString()))).toString());
    const positionSize = correctDecimalPlaces(position.size['d'].toString());
    const margin = correctDecimalPlaces(position.margin['d'].toString());
    const openNotional = correctDecimalPlaces(position.openNotional['d'].toString());
    if (margin.eq(0) || positionSize.eq(0)) {
        return null;
    }
    const leverage = openNotional.div(margin);
    const entryPrice = openNotional.div(positionSize).abs();
    const liquidationPrice = getLiquidationPrice(positionSize, entryPrice, leverage);
    const fullLiquidationPrice = getFullLiquidationPrice(positionSize, entryPrice, leverage);
    const side = positionSize.gt(0)
        ? PositionSides.SIDE_LONG
        : PositionSides.SIDE_SHORT;
    const tradingPair = getTradingPairByAddress(ammAddr);
    const outputPrice = await getOutputPrice(tradingPair.ammSymbol, side, positionSize.abs());
    const exitPrice = outputPrice.div(positionSize.abs());
    const result = {
        amm: ammAddr.toLowerCase(),
        trader: walletAddress,
        margin,
        symbol: tradingPair.symbol,
        pair: tradingPair.ammSymbol,
        size: positionSize,
        openNotional,
        leverage,
        leverageFromNotional: openNotional.div(margin),
        pnl,
        entryPrice,
        exitPrice,
        side,
        liquidationPrice,
        fullLiquidationPrice
    };
    cacheSet(cacheKey, result, 5000);
    return result;
};
export const openPosition = async (smartWalletAddress, ammSymbol, side, collateral, leverage, signer, slippage = 0.5, size, progressCallback) => {
    const ammAddr = getAmmContractAddress(ammSymbol);
    setSignedContracts(signer);
    const _slippage = slippage / 100; //0.5% === 0.005
    const minPositionSizeReceived = side === SIDE_LONG ? size.mul(1 - _slippage) : size.mul(1 + _slippage);
    const positionSize = await calculatePositionSize(ammSymbol, side, collateral, leverage);
    if ((side === SIDE_LONG &&
        positionSize.lt(minPositionSizeReceived.mul(1.0001))) ||
        (side === SIDE_SHORT &&
            positionSize.gt(minPositionSizeReceived.mul(0.9999)))) {
        return {
            success: false,
            error: 'Increase your slippage to open large position'
        };
    }
    if (progressCallback) {
        progressCallback();
    }
    const positionSizeDecimals = toD(utils.parseUnits(positionSize.mul(new Big(side === SIDE_LONG ? '1' : '-1')).toString(), DEFAULT_DECIMALS));
    const leverageDecimals = toD(utils.parseUnits(leverage, DEFAULT_DECIMALS));
    const minPositionSizeDecimals = toD(utils.parseUnits(minPositionSizeReceived.round(4).toString(), DEFAULT_DECIMALS));
    const tx = await getSmartWalletContract(smartWalletAddress, signer).executeMarketOrder(ammAddr, positionSizeDecimals, leverageDecimals, minPositionSizeDecimals);
    const receipt = await tx.wait();
    await Promise.all([fetchPosition(undefined, ammAddr), fetchAllPositions()]);
    return {
        success: true,
        receipt
    };
};
export const getUnrealizedPnL = async (amm, wallet) => {
    const twapPnl = await clearingHouseViewer.getUnrealizedPnl(amm, wallet, BigNumber.from(PositionPnlCalcOption.TWAP));
    const spotPnl = await clearingHouseViewer.getUnrealizedPnl(amm, wallet, BigNumber.from(PositionPnlCalcOption.SPOT_PRICE));
    return spotPnl.d.gt(twapPnl.d) ? spotPnl : twapPnl;
};
export const calculateTotalCost = async (collateral, fee) => {
    let totalCost = new Big(0);
    try {
        totalCost = fee.add(collateral.abs());
    }
    catch (e) {
        console.error(e);
    }
    return totalCost;
};
export const getCollateral = async (symbol, size, leverage, positionSide) => {
    const dirForInputPrice = positionSide !== SIDE_LONG
        ? PositionDirection.ADD_TO_AMM
        : PositionDirection.REMOVE_FROM_AMM;
    const positionNotional = await getOutputPrice(symbol, dirForInputPrice, size.toString());
    return positionNotional.div(leverage);
};
export const getPositionPrice = async (pairSymbol, positionNotional, positionSide) => {
    const dirForInputPrice = positionSide === SIDE_LONG
        ? PositionDirection.ADD_TO_AMM
        : PositionDirection.REMOVE_FROM_AMM;
    const priceStr = await getInputPrice(pairSymbol, dirForInputPrice, positionNotional);
    return priceStr;
};
export const closePosition = async (smartWalletAddress, ammAddr, signer, percentage) => {
    const minimalQuoteAsset = { d: '0' };
    setSignedContracts(signer);
    const contract = getSmartWalletContract(smartWalletAddress, signer);
    const tx = percentage === 100
        ? await contract.executeClosePosition(ammAddr, minimalQuoteAsset)
        : await contract.executeClosePartialPosition(ammAddr, num2Decimal(percentage / 100), minimalQuoteAsset);
    const receipt = await tx.wait();
    await Promise.all([fetchPosition(undefined, ammAddr), fetchAllPositions()]);
    return receipt;
};
export const getFreeCollateral = async (ammAddr, walletAddress) => {
    const freeCollateral = await clearingHouseViewer.getFreeCollateral(ammAddr, walletAddress);
    return correctDecimalPlaces(freeCollateral['d'].toString());
};
/**
 * @deprecated Unused
 */
const parseClosedPnlItem = (event, currentBlock) => {
    const { values } = event;
    const exchangedPositionSize = new Big(values.exchangedPositionSize).div(new Big(10).pow(18));
    const exitPrice = new Big(values.spotPrice).div(new Big(10).pow(18));
    const side = exchangedPositionSize.toNumber() > 0 ? SIDE_LONG : SIDE_SHORT;
    const entryPrice = new Big(values.positionNotional)
        .div(exchangedPositionSize)
        .div(new Big(10).pow(DEFAULT_DECIMALS));
    const pnl = new Big(values.realizedPnl).div(new Big(10).pow(DEFAULT_DECIMALS));
    let time = null;
    if (currentBlock) {
        const timestamp = getEstimatedBlockTimestamp(metadata.network, currentBlock, Date.now() / 1000, event.blockNumber);
        time = new Date(timestamp * 1000).toISOString();
    }
    else {
        time = new Date().toISOString();
    }
    return {
        id: event.blockNumber,
        size: exchangedPositionSize.abs().toPrecision(18),
        entryPrice: entryPrice.toNumber().toFixed(2),
        side,
        exitPrice: exitPrice.toNumber().toFixed(2),
        pnl: pnl.toNumber(),
        time
    };
};
export const getTotalFee = async (grossAmount, pairSymbol, leverage) => {
    const positionNotional = grossAmount.mul(leverage);
    const { 0: txFee, 1: spreadFee } = await calcFee(pairSymbol, positionNotional);
    return bigNum2Big(txFee.d.add(spreadFee.d));
};
export const getFee = async () => {
    const feeRatioRaw = await clearingHouse.feeRatio();
    return Big(feeRatioRaw).div(new Big(10).pow(DEFAULT_DECIMALS));
};
