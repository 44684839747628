import { ref, computed, watch, onUnmounted } from 'vue'
import { gql } from '@apollo/client'
import { wssClient } from '../api/graph'
import { SPONSORS_SUBSCRIPTION } from '../api/queries'
import { useAccount } from '@/hooks/useAccount'

const sponsorsRef = ref(null)
const subscriptionRef = ref(null)

export const subscribeSponsors = (trader) => {
  if (subscriptionRef.value) {
    subscriptionRef.value.unsubscribe()
  }
  subscriptionRef.value = wssClient
    .subscribe({
      query: gql(SPONSORS_SUBSCRIPTION),
      variables: {
        id: trader.toLowerCase()
      }
    })
    .subscribe({
      next(data) {
        sponsorsRef.value = data.data.sponsor
      },
      error(err) {
        console.error('err', err)
      }
    })
}

export const unsubscribeSponsors = () => {
  if (subscriptionRef.value) {
    subscriptionRef.value.unsubscribe()
  }
  subscriptionRef.value = null
}

export const useSponsors = () => {
  const { address } = useAccount()
  watch(address, (address) => {
    if (address) {
      unsubscribeSponsors()
      subscribeSponsors(address)
    }
  })
  onUnmounted(() => {
    unsubscribeSponsors()
  })
  const sponsor = computed(() => {
    return sponsorsRef.value
  })

  return { sponsor }
}
