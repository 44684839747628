<template>
  <PDialog :title="title" @close="toggleLeavingNetworkDialog(false)" width="96">
    <p class="text-inactive text-sm mb-5">
      {{ text }}
    </p>
    <PButton color="main" class="w-full" @click="visit">
      Visit {{ pagename }}
    </PButton>
  </PDialog>
</template>

<script>
import { PDialog, PButton } from '../../palmswap-vue-ui'
import { useDialogs } from '@/hooks/useDialogs'

const content = {
  mainnet: {
    title: 'Leaving Mainnet',
    text: 'The website you want to visit is on the testnet, you leave the mainnet and enter the testnet. If you have connected your wallet on the mainnet, you need to connect it again on the testnet.'
  },
  testnet: {
    title: 'Leaving Testnet',
    text: 'The website you want to visit is on the mainnet, you leave the testnet and enter the mainnet. If you have connected your wallet on the testnet, you need to connect it again on the mainnet.'
  }
}

export default {
  components: {
    PDialog,
    PButton
  },
  props: {
    currentNetwork: {
      type: String,
      validator: (val) => ['mainnet', 'testnet'].includes(val)
    }
  },
  setup() {
    const { toggleLeavingNetworkDialog, metadata } = useDialogs()
    return {
      toggleLeavingNetworkDialog,
      metadata
    }
  },
  computed: {
    content() {
      return content[this.currentNetwork] || {}
    },
    title() {
      return this.content.title
    },
    text() {
      return this.content.text
    },
    pagename() {
      return this.metadata.name || ''
    }
  },
  methods: {
    visit() {
      window.location.href = this.metadata.url
    }
  }
}
</script>

<style scoped></style>
