<template>
  <transition
    enter-active-class="transition ease-out duration-100"
    enter-from-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
    leave-active-class="transition ease-in duration-75"
    leave-from-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95"
  >
    <div class="flex flex-col">
      <div class="flex justify-between mb-4 text-inactive">
        <button
          class="bg-background-3 h-10 rounded flex items-center justify-center text-sm px-2"
          @click="setSlippage(0.1)"
          :class="{ 'text-white': slippage === 0.1 }"
          data-cy="trade-page_make-order_set-slippage-0.1-button"
        >
          0.1%
        </button>
        <button
          class="bg-background-3 h-10 rounded flex items-center justify-center text-sm px-2"
          @click="setSlippage(0.5)"
          :class="{ 'text-white': slippage === 0.5 }"
          data-cy="trade-page_make-order_set-slippage-0.5-button"
        >
          0.5%
        </button>
        <button
          class="bg-background-3 h-10 rounded flex items-center justify-center text-sm px-2"
          @click="setSlippage(1.0)"
          :class="{ 'text-white': slippage === 1.0 }"
          data-cy="trade-page_make-order_set-slippage-1-button"
        >
          1.0%
        </button>
        <div class="custom-slippage-wrapper relative cursor-pointer">
          <span class="prefix mt-3 text-sm absolute left-4">Custom</span>
          <input
            data-cy="trade-page_make-order_slippage-input"
            type="number"
            class="text-sm outline-none w-36 cursor-pointer text-right pr-9 pl-16 bg-background-3 h-10 rounded placeholder-opacity-100 placeholder-current::placeholder"
            :class="{
              'text-white': isCustom,
              'custom-slippage': true
            }"
            v-model="custom"
          />
          <span class="suffix mt-3 text-sm absolute right-4">%</span>
        </div>
      </div>
      <Alert
        class="mb-5"
        v-if="error"
        :title="error.title"
        :message="error.message"
        type="warning"
      />
    </div>
  </transition>
</template>
<script>
import { useAppState } from '@/hooks/useAppState'
import Alert from '@/components/common/Alert.vue'

export default {
  components: {
    Alert
  },
  setup() {
    const { slippage, setSlippage } = useAppState()
    return {
      slippage,
      setSlippage
    }
  },
  data() {
    return {
      custom: '',
      error: null
    }
  },
  mounted() {
    if (this.isCustom) {
      this.custom = this.slippage
    }
  },
  computed: {
    isCustom() {
      return ![0.1, 0.5, 1.0].some((sl) => sl === this.slippage)
    }
  },
  watch: {
    custom(newCustom) {
      const percentage = Number(newCustom)
      if (percentage <= 2 && percentage >= 0.1) {
        this.setSlippage(Number(newCustom))
        this.error = null
      } else {
        if (!this.isCustom || newCustom === '') {
          this.error = null
          this.setSlippage(0.1)
        }
        this.error = {
          title: 'Incorrect slippage',
          message:
            'Should be a value between 0.1% and 2%. If a value does not match it is set by default 0.1%'
        }
        this.setSlippage(0.1)
      }
    }
  }
}
</script>
