// https://docs.apex.win/apex-docs/developer-guide/smart-contracts
import { utils } from 'ethers'
import { constants } from 'ethers'
import { DEFAULT_DECIMALS, toD, SIDE_LONG } from '../helpers'
import {
  getAmmContract,
  getSignedContracts,
  getSmartWalletContract,
  setSignedContracts
} from '../contracts'
import Big from 'big.js'

export const addLimitOrder = async (
  symbol,
  signer,
  smartWallet,
  orderDetails
) => {
  const foo = (val) =>
    toD(utils.parseUnits(new Big(val).toString(), DEFAULT_DECIMALS))
  const limitPrice = orderDetails.limitPrice
  const _limitPrice = foo(orderDetails.limitPrice)

  const _positionSize = toD(
    utils.parseUnits(
      new Big(orderDetails.positionSize)
        .mul(new Big(orderDetails.side === SIDE_LONG ? '1' : '-1'))
        .toString(),
      DEFAULT_DECIMALS
    )
  )
  const _collateral = foo(orderDetails.collateral.round(4))
  const _leverage = foo(orderDetails.leverage)
  const _slippage = foo(1)
  const _reduceOnly = false
  const _expiry = 0

  setSignedContracts(signer)
  const { limitOrderBookSigned, usdtSigned } = getSignedContracts()
  const smartWalletContract = getSmartWalletContract(smartWallet, signer)

  const amm = getAmmContract(symbol)

  const minTipFee = await limitOrderBookSigned.minimumTipFee()
  const _tipFee = foo(new Big(minTipFee).div(10 ** 18).toString())

  const allowance = await usdtSigned.allowance(
    smartWallet,
    limitOrderBookSigned.address
  )
  if (
    allowance.lt(
      utils.parseUnits(new Big(limitPrice).toFixed(2), DEFAULT_DECIMALS)
    )
  ) {
    await smartWalletContract.approveQuoteToken(
      limitOrderBookSigned.address,
      constants.MaxUint256
    )
  }

  const tx = await limitOrderBookSigned.addLimitOrder(
    amm.address,
    _limitPrice,
    _positionSize,
    _collateral,
    _leverage,
    _slippage,
    _tipFee,
    _reduceOnly,
    _expiry
  )

  const receipt = await tx.wait()

  return receipt
}
