<template>
  <div
    class="flex flex-col items-start p-4 w-full bg-background-2 rounded border"
  >
    <div
      class="flex justify-between items-center w-full cursor-pointer"
      @click="toggleExpand"
    >
      <div class="text-sm text-white font-medium">
        <slot name="title"></slot>
      </div>
      <div class="flex items-center">
        <div><slot name="right-addon"></slot></div>
        <ChevronDownIcon
          class="text-inactive h-7 w-7 ml-1"
          :class="{ 'transform rotate-180': expanded }"
        />
      </div>
    </div>
    <div
      class="flex flex-col justify-center items-start w-full h-0 overflow-hidden"
      :class="{ 'h-auto mt-4 w-full': expanded }"
    >
      <slot name="content" class="content"></slot>
    </div>
  </div>
</template>
<script>
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
export default {
  props: {
    title: {
      type: String,
      default: 'Accordion'
    },
    open: {
      type: Boolean,
      default: true
    },
    toggleFunc: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    ChevronDownIcon
  },
  data() {
    return {
      expanded: false
    }
  },
  created: function () {
    this.expanded = false
  },
  methods: {
    toggleExpand() {
      this.toggleFunc()
      if (this.open) {
        this.expanded = !this.expanded
      }
    }
  }
}
</script>
