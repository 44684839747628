<template>
  <div class="flex flex-col mt-3 space-y-5 w-97">
    <div>
      <div class="flex justify-between text-sm mb-2">
        <span>Wallet Balance</span>
        <span>
          Available:
          <span class="text-white">{{ formattedBalance('account') }} USDT</span>
        </span>
      </div>
      <CurrencyInput
        v-model.number="amount"
        placeholder="0.00"
        currency="USDT"
        :error="error"
        dataCy="deposit-form_amount-input"
      />
    </div>
    <div class="flex space-x-5 items-center" v-if="walletInfo">
      <div class="flex flex-col space-y-1">
        <span class="text-sm">Palmswap Wallet</span>
        <div class="flex space-x-2 items-center">
          <span
            class="text-white font-bold"
            :class="isBalanceBig ? 'text-xl' : 'text-3xl'"
          >
            {{ formattedBalance('wallet') }}
          </span>
          <img src="/img/usdt.svg" class="w-5" />
          <span class="text-sm">USDT</span>
        </div>
      </div>
      <div class="rounded-full bg-background-3">
        <ChevronRightIcon class="w-6" />
      </div>
      <div class="flex flex-col space-y-1">
        <span class="text-sm">After Deposit</span>
        <div class="flex space-x-2 items-center">
          <span
            class="text-white font-bold"
            :class="isBalanceBig ? 'text-xl' : 'text-3xl'"
          >
            {{ balanceAfterDeposit }}
          </span>
          <img src="/img/usdt.svg" class="w-5" />
          <span class="text-sm">USDT</span>
        </div>
      </div>
    </div>
    <div v-if="!enabled">
      <small class="text-red">
        * You need to first create a smart wallet and accept the Terms of Use.
      </small>
    </div>
    <div>
      <PButton
        class="w-full flex justify-center items-center"
        size="lg"
        @click="handleSubmit"
        :disabled="isLoading || !enabled"
        data-cy="deposit-form_deposit-button"
      >
        <span v-if="!isLoading">Deposit</span>
        <SmallLoader v-if="isLoading" />
      </PButton>
    </div>
  </div>
</template>
<script>
import Big from 'big.js'
import { useDialogs } from '@/hooks/useDialogs'
import { deposit } from '../../contracts'
import { mapState } from 'vuex'
import CurrencyInput from '@/components/trade/make-order/CurrencyInput'
import { PButton } from '../../../palmswap-vue-ui'
import SmallLoader from '@/components/common/SmallLoader'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'

export default {
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    walletInfo: {
      type: Boolean,
      default: true
    }
  },
  components: {
    SmallLoader,
    PButton,
    CurrencyInput,
    ChevronRightIcon
  },
  setup() {
    const { toggleDepositDialog } = useDialogs()
    return {
      toggleDepositDialog
    }
  },
  data() {
    return {
      amount: null,
      isLoading: false,
      error: null
    }
  },
  computed: {
    ...mapState({
      accountBalance: (state) => state.user.balance.account, // user wallet balance (metamask, walletconnect)
      walletBalance: (state) => new Big(state.user.balance.wallet),
      walletAddress: (state) => state.user.address.wallet,
      signer: (state) => state.web3Modal.provider.getSigner()
    }),
    isBalanceBig() {
      const number = this.countDigits(this.balanceAfterDeposit)
      return number > 3
    },
    balanceAfterDeposit() {
      if (!this.amount) {
        return this.walletBalance.toFixed(2)
      }
      return this.walletBalance.add(new Big(this.amount)).toFixed(2)
    }
  },
  watch: {
    amount() {
      this.error = null
    }
  },
  methods: {
    async handleSubmit() {
      if (!this.amount || this.amount <= 0) {
        this.error = 'Amount must be greater than 0'
        return
      }
      const id = Date.now()
      this.$store.commit('addId', id)
      this.toggleDepositDialog(false)
      this.isLoading = true
      console.log(this.amount, this.signer, this.walletAddress)
      try {
        const result = await deposit(
          this.amount,
          this.signer,
          this.walletAddress,
          () => {
            this.$notify({
              id,
              title: 'Deposit',
              type: 'progress',
              duration: -1,
              data: {
                body: `Your deposit of ${this.amount} USDT is waiting for confirmations.`
              }
            })
          }
        )
        if (result) {
          await this.$store.dispatch('updateBalance')
          this.isLoading = false
          this.$notify({
            id,
            title: 'Deposit success',
            type: 'success',
            data: {
              body: `Your deposit of ${this.amount} USDT is confirmed.`
            }
          })
        } else {
          this.$notify({
            id,
            title: 'Error while making the deposit',
            type: 'error'
          })
          this.isLoading = false
        }
        this.toggleDepositDialog(false)
      } catch (error) {
        console.error(error)
        this.$notify({
          id,
          title: 'Error while making the deposit',
          type: 'error'
        })
        this.isLoading = false
      } finally {
        this.$notify.close(id)
        this.$store.commit('removeId', id)
      }
    },
    countDigits(n) {
      for (var i = 0; n > 1; i++) {
        n /= 10
      }
      return i
    },
    formattedBalance(type) {
      if (type == 'account') {
        return this.accountBalance ? (+this.accountBalance).toFixed(2) : '0.00'
      }
      return this.walletBalance ? this.walletBalance.toFixed(2) : '0.00'
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
.deposit {
  &-dialog {
    padding: 16px 24px 24px 24px;
    display: flex;
    flex-direction: column;
  }
  &-input {
    flex: 1;
    margin-top: 32px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }
}
.btn-max {
  padding: 8px;
  margin-top: 4px;
  margin-right: 6px;
}
.required {
  color: var(--error);
}
.submit {
  width: 100%;
  height: 48px;
  margin-top: 24px;
}
</style>
