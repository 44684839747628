import { ref } from 'vue'
import { wssClient } from '../api/graph'
import { AMMS_SUBSCRIPTION } from '../api/queries'
import { gql } from '@apollo/client/core'

let amms = ref()
let ammsSubscription = ref()

function subscribeAmms() {
  if (ammsSubscription.value) {
    ammsSubscription.value.unsubscribe()
    amms.value = null
  }

  ammsSubscription.value = wssClient
    .subscribe({
      query: gql(AMMS_SUBSCRIPTION)
    })
    .subscribe({
      next(data) {
        amms.value = data.data.amms
      }
    })
}

export { subscribeAmms, amms }
