<template>
  <Menu
    as="div"
    class="h-7 px-4 border-r border-l flex items-center relative select-none"
    v-slot="{ open }"
    v-if="!isMobile"
  >
    <MenuButton
      class="flex gap-2.5 items-center cursor-pointer"
      :class="{ 'z-20': open }"
    >
      <img src="../../assets/onChainApp.svg" class="h-4" />
      <div class="text-white">On Chain</div>
      <ChevronDownIcon class="text-white h-4" />
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        v-show="open"
        class="origin-top-left absolute top-full mt-2 z-50 left-0 bg-background-1 border rounded-xl px-4 pr-8 py-3 flex flex-col gap-4 w-max"
      >
        <div class="flex gap-2.5 items-center">
          <img src="../../assets/onChainApp.svg" class="h-4" />
          <div class="text-white whitespace-nowrap">On Chain</div>
        </div>
        <a
          class="flex gap-2.5 items-center cursor-pointer no-underline"
          href="https://perp.palmswap.org"
          v-if="isHybridEnabled"
        >
          <img src="../../assets/hybridInactive.svg" class="h-4" />
          <div class="text-inactive whitespace-nowrap">Hybrid</div>
        </a>
      </MenuItems>
    </transition>
    <Transition name="fade">
      <div
        v-show="open"
        class="fixed -inset-2 z-10 flex justify-center items-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"
      />
    </Transition>
  </Menu>

  <div v-if="isMobile" class="w-30">
    <PDropdown titleType="single" title="On Chain">
      <template v-slot:main>
        <div class="divide-y">
          <PDropdownItem>
            <div class="flex gap-2.5 items-center">
              <img src="../../assets/onChainApp.svg" class="h-4" />
              <div class="text-white whitespace-nowrap">On Chain</div>
            </div>
          </PDropdownItem>
          <PDropdownItem v-if="isHybridEnabled">
            <a
              class="flex gap-2.5 items-center cursor-pointer no-underline"
              href="https://perp.palmswap.org"
            >
              <img src="../../assets/hybridInactive.svg" class="h-4" />
              <div class="text-inactive whitespace-nowrap">Hybrid</div>
            </a>
          </PDropdownItem>
        </div>
      </template>
    </PDropdown>
  </div>
</template>

<script>
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { isFeatureEnabled } from '@/utils/utils'
import { FEATURE_FLAGS } from '@/config/constants'
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
import { PDropdown, PDropdownItem } from '../../../palmswap-vue-ui'
import useBreakpoints from '@/hooks/useBreakpoints'

export default {
  components: {
    ChevronDownIcon,
    Menu,
    MenuButton,
    MenuItems,
    PDropdown,
    PDropdownItem
  },
  setup() {
    const isHybridEnabled = isFeatureEnabled(FEATURE_FLAGS.HYBRID)
    const { isMobile } = useBreakpoints()

    return {
      isHybridEnabled,
      isMobile
    }
  }
}
</script>
