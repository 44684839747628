import { NODE_ENV } from '../config/constants';
export const jsonMetadata = {
    dev: {
        usdt: '0x171529efB5fD8AD86Ba8DDBD841bF9FcdF04CC88',
        clearingHouse: '0xA55909da07C98bD55DEf10C86c97f40B7170C3BA',
        smartWalletFactory: '0xeBe959b0D909D2cBa170d62E7a0eB17446656b21',
        limitOrderBook: '0x671c31450D0B07A936184A5Da242976463392ce3',
        clearingHouseViewer: '0x2f757030d46a48c429081624F7F91fe03a09c297',
        ammReader: '0x607a63F21D7F799FF20B357dd77E9e7d9C910DBA',
        referral: '0x7307E9da015a7a9dE1315AaaAd07D160120A74bD',
        palmPad: '0xc12a1D6632db36694af39477238897570e375d4B',
        palmToken: '0xA810241FC78A0198f81C8Bff2BaF6357F2222B1f',
        vesting: '0x692fd189BF5f1FFD16C0e3A579dd4683EAD9E704',
        farmingStaking: '0xb57F3BAa3E703e349d92946c4c2F35e87d6F19b1',
        priceFeeds: {
            BNBUSD: '0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526'
        },
        amms: {
            ETHUSDT: '0xA6848e1d4256749283203A5f463F5C7d50F16A36',
            BTCUSDT: '0x7bf666523B790EE95Fa06f5F06280337A31Cb049'
        },
        network: 'tbsc',
        palmBNBLP: '0x4f5a4e8bd72d3650371cb772796f3a4e93e12060',
        palmUSDTLP: '0xa373D803Bb8eB93905F78De9101FdB5d0481B489',
        feeDistributor: '0xc8adB2cdC6B24124e4e8042EE7842237Cb7a5c4d',
        faucet: '0x4b8A8BD2881eab50d306AE6801a7190dDA664Ac5'
    },
    staging: {
        usdt: '0x171529efB5fD8AD86Ba8DDBD841bF9FcdF04CC88',
        clearingHouse: '0x9E34445b73a0f989A1Ce54b3C0f35ed6C835eAf1',
        smartWalletFactory: '0x6eDFD598c6209F497Af87ef8b71046c46046119F',
        limitOrderBook: '0x9bdC84273c5B9E1f422b4AA604008BA98F205bf3',
        clearingHouseViewer: '0xd1B15bc30bE69A9428DdF7443555bbD6e7EAB8fD',
        ammReader: '0x9aeCcc68125Df53EE3e1697a13C8B6BACC455797',
        referral: '0x7307E9da015a7a9dE1315AaaAd07D160120A74bD',
        palmPad: '0xc12a1D6632db36694af39477238897570e375d4B',
        palmToken: '0xA810241FC78A0198f81C8Bff2BaF6357F2222B1f',
        vesting: '0xaBf5e1D85df0227ee4ec95beB74F66FE3cD27A36',
        farmingStaking: '0xb57F3BAa3E703e349d92946c4c2F35e87d6F19b1',
        priceFeeds: {
            BNBUSD: '0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526'
        },
        amms: {
            ETHUSDT: '0xdAD1B29b17A2bBd51063d33846Fc6Fa446017B11',
            BTCUSDT: '0xE7fbfbd41Dbc15A1b152A947c08312D42dC676ed'
        },
        network: 'tbsc',
        palmBNBLP: '0x4f5a4e8bd72d3650371cb772796f3a4e93e12060',
        palmUSDTLP: '0xa373D803Bb8eB93905F78De9101FdB5d0481B489',
        feeDistributor: '0x3cF0DB5d10E0aa4F2dD7f23811962CEa25B80472',
        faucet: '0x4b8A8BD2881eab50d306AE6801a7190dDA664Ac5'
    },
    testnet: {
        usdt: '0x171529efB5fD8AD86Ba8DDBD841bF9FcdF04CC88',
        clearingHouse: '0x8742D89c73F06De1dC5e6D6e020A929d981Cd32d',
        smartWalletFactory: '0xCcBb7942669150F674e3Ba04533250Eaa8eA6b3D',
        limitOrderBook: '0x5A5cF8ac14517a2a6f4CC2dd9aA2F7aC3C62e964',
        clearingHouseViewer: '0x1D8Aaf49FAb3dbf623106Eded875084896c09324',
        ammReader: '0xD0E08D9A725D1fB2a2e124102937ae42A21e6234',
        referral: '0x7307E9da015a7a9dE1315AaaAd07D160120A74bD',
        palmPad: '0xc12a1D6632db36694af39477238897570e375d4B',
        palmToken: '0xA810241FC78A0198f81C8Bff2BaF6357F2222B1f',
        vesting: '0x6E94ada26a04c85A65627e9bde4bf1b364cCd85C',
        farmingStaking: '0xb57F3BAa3E703e349d92946c4c2F35e87d6F19b1',
        priceFeeds: {
            BNBUSD: '0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526'
        },
        amms: {
            ETHUSDT: '0x8228f94b48019e95968C741Dc63EaF5a2a76B367',
            BTCUSDT: '0x3D1C6309Cfa4D3f99E01D411B056cb23B6A355c7'
        },
        network: 'tbsc',
        palmBNBLP: '0x4f5a4e8bd72d3650371cb772796f3a4e93e12060',
        palmUSDTLP: '0xa373D803Bb8eB93905F78De9101FdB5d0481B489',
        feeDistributor: '0x5990df8dc372Ddbac2C5998bf559e65028ca8752',
        faucet: '0x4b8A8BD2881eab50d306AE6801a7190dDA664Ac5'
    },
    mainnet: {
        usdt: '0x55d398326f99059fF775485246999027B3197955',
        clearingHouse: '0xe01a2245994519A53c408510089B988982a73c9D',
        smartWalletFactory: '0x953c3e0aCEaD0560FC0057F31dB432945193Db84',
        limitOrderBook: '0x9c6AA42215A01d5fDE33033E53447019236941B8',
        clearingHouseViewer: '0xf0D0860Abe875087C1Cca27Fa02845Fc122100c0',
        ammReader: '0x24F7EC37681e12E1A44bb09f2b2522Ea8B354e2b',
        referral: '0x4eCE164D5650670B6bF16E6e0Bec7fe9059FCD82',
        palmPad: '0x96a96915646E9d0e10028EDf77c3079D975700aa',
        palmToken: '0x29745314B4D294B7C77cDB411B8AAa95923aae38',
        vesting: '0x2FF38E53cc1C1039235CA375e86B6E9cACca79F1',
        farmingStaking: '0xb37Ed443784D13228477acA869284694837fCbe0',
        priceFeeds: {
            BNBUSD: '0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee'
        },
        amms: {
            ETHUSDT: '0x3f03a30679b1611e75a01492D06FA24c3Fd96061',
            BTCUSDT: '0xfC5767c37029Fb79a32050bf8d360c1c661Ed84C'
        },
        network: 'bsc',
        palmBNBLP: '0x044066f6Ce410407CC738d0feb5E40b5ab69e83a',
        palmUSDTLP: '',
        feeDistributor: '0xC085d75749672A24557a70D731A00795e3D69481',
        faucet: '0x4b8A8BD2881eab50d306AE6801a7190dDA664Ac5'
    }
};
export const metadata = jsonMetadata[NODE_ENV];
export default metadata;
