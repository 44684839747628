import { DEFAULT_DECIMALS } from './helpers'
import { utils } from 'ethers'
import { getSmartWalletContract } from './contracts'
import Big from 'big.js'

export const addMargin = async (
  smartWalletAddress,
  ammAddr,
  adjustMargin,
  signer
) => {
  const amount = utils.parseUnits(
    new Big(adjustMargin).toFixed(2),
    DEFAULT_DECIMALS
  )

  try {
    const tx = await getSmartWalletContract(
      smartWalletAddress,
      signer
    ).executeAddMargin(ammAddr, { d: amount })

    const receipt = await tx.wait()
    return receipt
  } catch (err) {
    console.error('AddMarginError ', err)
    return null
  }
}

export const removeMargin = async (
  smartWalletAddress,
  ammAddr,
  adjustMargin,
  signer
) => {
  const amount = utils.parseUnits(
    new Big(adjustMargin).toFixed(2),
    DEFAULT_DECIMALS
  )

  try {
    const tx = await getSmartWalletContract(
      smartWalletAddress,
      signer
    ).executeRemoveMargin(ammAddr, { d: amount })

    const receipt = await tx.wait()
    return receipt
  } catch (err) {
    console.error('RemoveMarginError ', err)
    return null
  }
}
