<template>
  <PDialog position="right" @close="toggleSidebarDialog(false)">
    <template v-slot:header>
      <div class="flex">
        <PLogo type="symbol-beta" />
      </div>
      <div class="flex w-full w-60">
        <AppTypeDropdown />
      </div>
    </template>
    <div class="flex flex-col h-full justify-between">
      <div class="items-center w-60">
        <NavItem :name="'Trade'" route="/" />
        <NavItem :name="'Portfolio'" route="/portfolio/overview" />
        <PortfolioNavigation v-if="isActive('portfolio')" />
        <NavItem
          v-if="isCompetitionEnabled"
          name="Leaderboard"
          route="/leaderboard"
        />
        <NavItem
          v-if="isStakingEnabled"
          name="Staking"
          :route="`${isTestnet ? 'https://app.palmswap.org' : ''}/earn/staking`"
        />
        <NavItem
          v-if="isFarmEnabled"
          name="Farm"
          :route="`${isTestnet ? 'https://app.palmswap.org' : ''}/earn/farm`"
        />
        <NavItem
          v-if="isVestingEnabled"
          name="Claim"
          :route="`${isTestnet ? 'https://app.palmswap.org' : ''}/claim`"
        />
        <NavItem
          v-if="isLaunchpadEnabled"
          name="Launchpad"
          :route="`${isTestnet ? 'https://app.palmswap.org' : ''}/launchpad`"
        />
        <NavItem
          v-if="isFaucetEnabled"
          name="Faucet"
          target="__blank"
          :route="'https://faucet.palmswap.org'"
        />
        <NavItem
          v-if="isRewardsEnabled"
          name="Rewards"
          icon="🔥"
          route="/rewards"
        />
        <NavItem
          name="Feedback"
          target="__blank"
          route="https://palmswap.canny.io/"
        />
      </div>
      <div class="flex flex-col" v-if="connected">
        <span class="text-xs leading-6"> Available Balance @Palmswap </span>
        <span class="text-xl pb-5 text-white">{{ availableAssets }}</span>
        <div class="flex space-x-3">
          <PButton
            color="dark"
            size="lg"
            class="w-full flex items-center justify-center"
            rounded="full"
            @click="handleOpenDeposit"
          >
            <span class="text-white text-sm">Deposit</span>
          </PButton>
          <PButton
            color="dark"
            size="lg"
            class="w-full flex items-center justify-center"
            rounded="full"
            @click="handleOpenWithdrawal"
          >
            <span class="text-white text-sm">Withdraw</span>
          </PButton>
        </div>
      </div>
      <div class="flex flex-col items-center" v-else>
        <span class="text-center text-xs leading-6 pb-5">
          Please connect your wallet to deposit<br />funds & start trading
        </span>
        <PButton
          size="lg"
          class="w-full"
          data-testid="connect-wallet"
          rounded="full"
          @click="connect"
        >
          Connect Wallet
        </PButton>
      </div>
    </div>
    <template v-slot:footer>
      <div class="p-3">
        <Footer />
      </div>
    </template>
  </PDialog>
</template>
<script>
import { mapState } from 'vuex'
import PortfolioNavigation from '@/components/portfolio/Navigation.vue'
import { isFeatureEnabled } from '@/utils/utils'
import { FEATURE_FLAGS, NODE_ENV } from '@/config/constants'
import { useDialogs } from '@/hooks/useDialogs'
import NavItem from './NavItem.vue'
import Footer from './Footer.vue'
import { PLogo, PDialog, PButton } from '../../../palmswap-vue-ui'
import AppTypeDropdown from '@/components/top-bar/AppTypeDropdown.vue'

export default {
  components: {
    PortfolioNavigation,
    PLogo,
    PDialog,
    PButton,
    NavItem,
    Footer,
    AppTypeDropdown
  },
  computed: {
    ...mapState({
      connected: (state) => state.web3Modal.connected,
      account: (state) => state.user.address.account,
      walletAddress: (state) => state.user.address.wallet,
      walletBalance: (state) => state.user.balance.wallet,
      needToShowTAC: (state) => state.user.needToShowTAC,
      authToken: (state) => state.web3Modal.authToken
    }),
    formattedAddress() {
      if (this.account !== '') {
        return `${this.account.slice(0, 6)}...${this.account.slice(
          this.account.length - 4,
          this.account.length
        )}`
      }
      return ''
    },
    availableAssets() {
      return `${Number((+this.walletBalance).toFixed(2)).toLocaleString()} USDT`
    }
  },
  setup() {
    const {
      toggleBCSOfflineDialog,
      toggleDepositDialog,
      toggleWithdrawalDialog,
      toggleTermsAndConditionsDialog,
      toggleSidebarDialog,
      toggleLinkWalletDialog
    } = useDialogs()
    const isStakingEnabled = isFeatureEnabled(FEATURE_FLAGS.STAKING)
    const isFarmEnabled = isFeatureEnabled(FEATURE_FLAGS.FARM)
    const isVestingEnabled = isFeatureEnabled(FEATURE_FLAGS.VESTING)
    const isLaunchpadEnabled = isFeatureEnabled(FEATURE_FLAGS.LAUNCHPAD)
    const isRewardsEnabled = isFeatureEnabled(FEATURE_FLAGS.TRADING_REWARDS)
    const isFaucetEnabled = isFeatureEnabled(FEATURE_FLAGS.FAUCET)
    const isCompetitionEnabled = isFeatureEnabled(FEATURE_FLAGS.COMPETITION)
    const isTestnet = NODE_ENV === 'testnet'
    return {
      toggleBCSOfflineDialog,
      toggleDepositDialog,
      toggleWithdrawalDialog,
      toggleTermsAndConditionsDialog,
      toggleSidebarDialog,
      toggleLinkWalletDialog,
      isStakingEnabled,
      isFarmEnabled,
      isVestingEnabled,
      isLaunchpadEnabled,
      isRewardsEnabled,
      isFaucetEnabled,
      isCompetitionEnabled,
      isTestnet
    }
  },
  methods: {
    async connect() {
      this.$emit('close')
      if (isFeatureEnabled(FEATURE_FLAGS.OFFLINE_POPUP)) {
        this.toggleBCSOfflineDialog(true)
      } else {
        await this.$store.dispatch('connect')
        this.$notify({
          title: `Welcome ${this.formattedAddress}`,
          type: 'success'
        })
        if (this.needToShowTAC) {
          this.toggleTermsAndConditionsDialog(true)
        }
      }
    },
    async handleOpenDeposit() {
      this.$emit('close')
      if (this.needToShowTAC) {
        this.toggleTermsAndConditionsDialog(true)
        return
      }
      if (!this.connected) {
        await this.$store.dispatch('connect')
      }
      if (!this.authToken) {
        this.toggleLinkWalletDialog(true)
        return
      }
      if (this.connected) {
        this.toggleDepositDialog(true)
      }
    },
    async handleOpenWithdrawal() {
      this.$emit('close')
      if (this.needToShowTAC) {
        this.toggleTermsAndConditionsDialog(true)
        return
      }
      if (!this.connected) {
        await this.$store.dispatch('connect')
      }
      if (!this.authToken) {
        this.toggleLinkWalletDialog(true)
        return
      }
      if (this.connected) {
        this.toggleWithdrawalDialog(true)
      }
    },
    isActive(name) {
      return this.$route.name.toLowerCase() === name.toLowerCase()
    }
  }
}
</script>
