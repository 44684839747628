import { ref, computed } from 'vue'
import { gql } from '@apollo/client'
import { wssClient } from '../api/graph'
import { TRADER_DAY_DATA, TRADER_30DAYS_DATA } from '../api/queries'

const traderDayDataRef = ref([])
const traderDayDataSubscriptionRef = ref(null)
const trader30DaysDataRef = ref([])
const trader30DaysDataSubscriptionRef = ref(null)

const toDisplayableTraderDayData = (rawTraderDayData) => {
  return rawTraderDayData
}

export const subscribeTraderDayData = (trader) => {
  if (traderDayDataSubscriptionRef.value) {
    traderDayDataSubscriptionRef.value.unsubscribe()
  }

  traderDayDataSubscriptionRef.value = wssClient
    .subscribe({
      query: gql(TRADER_DAY_DATA),
      variables: {
        id: trader.toLowerCase()
      }
    })
    .subscribe({
      next(data) {
        traderDayDataRef.value = data.data.traderDayDatas
      },
      error(err) {
        console.error('err', err)
      }
    })
}

export const unsubscribeTraderDayData = () => {
  if (traderDayDataSubscriptionRef.value) {
    traderDayDataSubscriptionRef.value.unsubscribe()
  }
  traderDayDataSubscriptionRef.value = null
}

export const subscribeTrader30DaysData = (trader) => {
  if (trader30DaysDataSubscriptionRef.value) {
    trader30DaysDataSubscriptionRef.value.unsubscribe()
  }

  trader30DaysDataSubscriptionRef.value = wssClient
    .subscribe({
      query: gql(TRADER_30DAYS_DATA),
      variables: {
        trader: trader.toLowerCase(),
        dateAfter: Math.round(new Date().getTime() / 1000) - 86400 * 30
      }
    })
    .subscribe({
      next(data) {
        trader30DaysDataRef.value = data.data.traderDayDatas
      },
      error(err) {
        console.error('err', err)
      }
    })
}

export const unsubscribeTrader30DaysData = () => {
  if (trader30DaysDataSubscriptionRef.value) {
    trader30DaysDataSubscriptionRef.value.unsubscribe()
  }
  trader30DaysDataSubscriptionRef.value = null
}

export const useTraderDayData = () => {
  const traderDayData = computed(() => {
    if (!traderDayDataRef.value) {
      return {}
    }
    return toDisplayableTraderDayData(traderDayDataRef.value)
  })

  const trader30DaysData = computed(() => {
    if (!trader30DaysDataRef.value) {
      return {}
    }
    return toDisplayableTraderDayData(trader30DaysDataRef.value)
  })

  return {
    traderDayData,
    trader30DaysData
  }
}
