<template>
  <PTable
    corners
    class="bg-background-black overflow-scroll max-h-table"
    :border="false"
    :headers="[
      { name: 'Wallet' },
      { name: 'Invest' },
      { name: 'Commission', tooltip },
      { name: 'Date' }
    ]"
  >
    <PTableRow v-for="item in earnings" :key="item.id">
      <PTableCell>{{ formatAddress(item.user) }}</PTableCell>
      <PTableCell>{{ item.amount.toFixed(6) }} BNB</PTableCell>
      <PTableCell>~{{ formatCommission(item) }}</PTableCell>
      <PTableCell>{{ formatDate(item.timestamp) }}</PTableCell>
    </PTableRow>
    <PTableRow v-if="earnings.length === 0">
      <PTableCell colspan="4">No earnings found</PTableCell>
    </PTableRow>
  </PTable>
</template>

<script>
import { formatDistanceToNowStrict } from 'date-fns'
import {
  useLaunchpadProject,
  useLaunchpadReferrals
} from '@/hooks/useLaunchpad'
import { formatAddress } from '@/formatters/contract'
import { PTable, PTableRow, PTableCell } from '../../../palmswap-vue-ui'
import { FEE_PCT_DENOMINATOR } from '@/utils/utils'

export default {
  components: {
    PTable,
    PTableRow,
    PTableCell
  },
  props: {
    earnings: Array
  },
  setup() {
    const { launchpadProject } = useLaunchpadProject()
    const { affiliate } = useLaunchpadReferrals()
    return {
      formatAddress,
      affiliate,
      launchpadProject
    }
  },
  computed: {
    tooltip() {
      if (this.affiliate.isVip) {
        return null
      }
      return 'Subject to Change: The more BNBs deposited in the TVL, the smaller your proportion and PALMs you will receive.'
    }
  },
  methods: {
    formatCommission(item) {
      if (this.affiliate.isSalesManager) {
        return `${item.commissionAmount.toFixed(6)} BNB`
      } else if (this.affiliate.isVip) {
        return `${item.influencerCommission.toFixed(6)} BNB`
      }
      const commission = this.launchpadProject.price.eq(0)
        ? 0
        : item.amount
            .div(this.launchpadProject.price)
            .div(FEE_PCT_DENOMINATOR)
            .mul(this.launchpadProject.sponsorFee)
            .toFixed(0)
      return `${commission} PALM`
    },
    formatDate(ts) {
      return formatDistanceToNowStrict(new Date(ts * 1000), {
        addSuffix: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.max-h-table {
  max-height: 525px;
}
</style>
