import { apiGatewayClient } from '@/api/gateway'

export const getNonce = async (address) =>
  await apiGatewayClient.get(`/auth/${address}/nonce`).then(({ data }) => data)

const baseKey = 'authToken'
const month = 30 * 24 * 3600 * 1000

// expire nonce after a month
export function setTokenWithExpiry(address, value, ttl = month) {
  const now = new Date()
  const item = {
    value: value,
    expiry: now.getTime() + ttl
  }
  localStorage.setItem(
    `${baseKey}.${address.toLowerCase()}`,
    JSON.stringify(item)
  )
}

export function getTokenWithExpiry(address) {
  const key = `${baseKey}.${address.toLowerCase()}`
  const itemStr = localStorage.getItem(key)
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }
  return item.value
}
