<template>
  <div class="px-5 py-3">
    <SideSelector v-model:side="side" class="mt-3" />
    <div class="mt-5 mb-1 text-sm"><span>Trigger Price</span>&nbsp;</div>
    <PriceInput v-model="triggerPrice" @update:modelValue="setPriceTouched" />
    <div class="flex justify-between mt-5 mb-1 text-sm">
      <span>Size</span>&nbsp;
      <span>
        Avbl
        <span class="text-white">
          {{ availableBalance }}
        </span>
      </span>
    </div>
    <AmountInput
      v-model="size"
      :maxSize="currentPosition && currentPosition.size.abs()"
      :error="formattedError"
      :totalCollateral="totalCollateral"
      :price="triggerPrice"
      :currency-symbol="currencySymbol"
    />
    <Leverage
      class="mt-5"
      v-model="leverage"
      :disabled="reduceOnly"
      :side="side"
    />
    <OrderDetails
      v-model:totalCost="totalCost"
      v-model:collateral="collateral"
      :side="side"
      :leverage="leverage"
      :size="size"
      :price="triggerPrice"
      class="mt-5"
    />
    <ReduceOnly class="my-5" v-model="reduceOnly" />
    <Alert
      class="my-4"
      v-if="needsPosition"
      title="No Position found"
      message="You need a position to place a take profit order."
      type="info"
    />
    <Alert
      class="my-4"
      v-if="currentPosition && isOverMaxSize"
      title="Trade size too large"
      message="You can't open an order larger than your current position."
      type="error"
    />
    <Alert
      class="my-4"
      v-if="currentPosition && isWrongSide"
      :title="`Switch to &quot;${orderSide}&quot;`"
      :message="`To set a Stop Market order for your ${positionSide} position, you need to click on ${orderSide}.`"
      type="error"
    />
    <Alert
      class="my-4"
      v-if="triggerPriceError && reduceOnly"
      title="This order will start to execute immediately"
      :message="`The trigger price is ${
        side === SIDE_LONG ? 'below' : 'above'
      } the current market price`"
      type="info"
    />
    <Alert
      class="my-4"
      v-if="error && error.title"
      :title="error.title"
      :message="error.message"
      type="error"
    />
    <Alert
      v-if="ordersDisabled"
      class="mt-4"
      title="Temporarily disabled"
      message="Limit orders are currently disabled, we are improving them and they will be back shortly"
      type="warning"
    />
    <PlaceOrderButtons
      :side="side"
      :totalCost="totalCost"
      :disabled="isDisabled"
      :loading="isLoading"
      @confirm="handleConfirm"
    />
  </div>
</template>
<script>
import Big from 'big.js'
import { ref, computed } from 'vue'
import { addTPSL } from '../../../contracts'
import { roundAmount, SIDE_LONG, SIDE_SHORT } from '../../../contracts/helpers'
import { useAppState } from '@/hooks/useAppState'
import { useAMM } from '@/hooks/useAMM'
import { useUserPosition } from '@/hooks/useUserPosition'
import AmountInput from './AmountInput.vue'
import PriceInput from './PriceInput.vue'
import PlaceOrderButtons from './PlaceOrderButtons.vue'
import ReduceOnly from './ReduceOnly.vue'
import OrderDetails from './OrderDetails.vue'
import Leverage from './Leverage.vue'
import SideSelector from './SideSelector.vue'
import { mapState } from 'vuex'
import Alert from '@/components/common/Alert'
import { isFeatureEnabled } from '@/utils/utils'
import { FEATURE_FLAGS } from '@/config/constants'
import { useOrderForm } from '@/hooks/useOrderForm'

export default {
  components: {
    Alert,
    PriceInput,
    PlaceOrderButtons,
    OrderDetails,
    AmountInput,
    SideSelector,
    Leverage,
    ReduceOnly
  },
  setup(props) {
    const { currencySymbol, pairSymbolAmm, slippage } = useAppState()
    const { markPrice } = useAMM()
    const { currentPosition } = useUserPosition()
    const position = currentPosition.value || {}

    const {
      maxPosition,
      leverage,
      side,
      size,
      totalCollateral,
      price: triggerPrice
    } = useOrderForm()

    if (position.leverage) {
      leverage.value = new Big(position.leverage)
    }
    side.value = position.side === SIDE_LONG ? SIDE_SHORT : SIDE_LONG
    const spotPrice = ref(0)
    const totalCost = ref(new Big(0))
    const collateral = ref(new Big(0))
    const sizeInputTouched = ref(false)
    const error = ref(null)

    return {
      markPrice,
      triggerPrice,
      error,
      totalCollateral,
      sizeInputTouched,
      currentPosition,
      maxPosition,
      leverage,
      collateral,
      SIDE_LONG,
      SIDE_SHORT,
      pairSymbolAmm: computed(() => {
        if (props.pairSymbol) {
          return props.pairSymbol.replace('/', '')
        }
        return pairSymbolAmm.value
      }),
      currencySymbol: computed(() => {
        if (props.pairSymbol) {
          return props.pairSymbol.split('/')[0]
        }
        return currencySymbol.value
      }),
      slippage,
      spotPrice,
      totalCost,
      side,
      size
    }
  },
  data() {
    return {
      isLoading: false,
      reduceOnly: true,
      isPriceTouched: false,
      triggerPriceError: null,
      isValid: null,
      isOverMaxSize: null
    }
  },
  mounted() {
    this.setDefaultPrice()
  },
  computed: {
    ...mapState({
      connected: (state) => state.web3Modal.connected,
      walletBalance: (state) => state.user.balance.wallet, // user wallet balance (metamask, walletconnect)
      walletAddress: (state) => state.user.address.wallet,
      signer: (state) => state.web3Modal.provider.getSigner(),
      orderBookPrice: (state) => state.tradeStore.orderBookValues.price
    }),
    ordersDisabled() {
      return !isFeatureEnabled(FEATURE_FLAGS.LIMIT_ORDERS)
    },
    orderValueFormatted() {
      return this.orderValue !== '0.00'
        ? `~${Number(this.orderValue).toFixed(2)} USDT`
        : '-'
    },
    isWrongSide() {
      return this.reduceOnly && this.side === this.currentPosition.side
    },
    positionSide() {
      return this.currentPosition.side === SIDE_SHORT ? 'short' : 'long'
    },
    orderSide() {
      return this.currentPosition.side === SIDE_SHORT ? 'Buy' : 'Sell'
    },
    totalCostFormatted() {
      return this.totalCost !== '0.00'
        ? `${this.totalCost.round(2, Big.roundDown).toFixed(2)} USDT`
        : '-'
    },
    formattedError() {
      if (typeof this.error !== 'string') {
        return null
      }
      return this.error
    },
    needsPosition() {
      return this.reduceOnly && !this.currentPosition
    },
    isDisabled() {
      return (
        // Double check because of of initial size value = 0
        !this.isSizeValid() ||
        this.needsPosition ||
        this.isOverMaxSize ||
        this.isWrongSide ||
        !this.isValid ||
        this.ordersDisabled ||
        this.totalCost.lte(0)
      )
    },
    formattedBalance() {
      return this.walletBalance
        ? `${(+this.walletBalance).toFixed(2)} USDT`
        : '-'
    },
    availableBalance() {
      if (!this.reduceOnly) {
        return this.formattedBalance
      }
      return this.currentPosition && this.currentPosition.size
        ? `${this.currentPosition.size.abs().toFixed(4)} ${
            this.currentPosition.symbol
          }`
        : '-'
    }
  },
  props: {
    pairSymbol: {
      type: String,
      default: null
    }
  },
  watch: {
    reduceOnly() {
      if (this.currentPosition.leverage) {
        this.leverage = new Big(this.currentPosition.leverage)
      }
    },
    orderBookPrice() {
      this.triggerPrice = this.orderBookPrice
    },
    side(newValue) {
      this.side = newValue
      this.setDefaultPrice()
      this.validateTriggerPrice()
    },
    markPrice() {
      if (!this.isPriceTouched) {
        this.setDefaultPrice()
      }
    },
    pairSymbolAmm() {
      this.resetPriceTouched()
    },
    triggerPrice(newValue) {
      this.triggerPrice = newValue
      this.validateTriggerPrice()
    },
    size(newSize) {
      const currentPosSize =
        this.currentPosition &&
        roundAmount(this.currentPosition.size.abs()).toNumber()
      this.isOverMaxSize = currentPosSize ? newSize > currentPosSize : false
      this.validateSize()
    }
  },
  methods: {
    setPriceTouched() {
      this.isPriceTouched = true
    },
    resetPriceTouched() {
      this.isPriceTouched = false
    },
    setDefaultPrice() {
      this.triggerPrice = (
        Number(this.markPrice) +
        30 * (this.side === SIDE_SHORT ? -1 : 1)
      ).toFixed(2)
    },
    isSizeValid() {
      if (!this.size) {
        return false
      }
      const size = this.size.toNumber()
      return size > 0
    },
    validateTriggerPrice() {
      const triggerPriceBN = new Big(this.triggerPrice)
      if (this.side === SIDE_LONG) {
        if (this.markPrice.gte(triggerPriceBN)) {
          this.triggerPriceError =
            'Trigger price cannot be above the market price.'
        } else {
          this.triggerPriceError = ''
        }
      } else if (this.side === SIDE_SHORT) {
        if (this.markPrice.lte(triggerPriceBN)) {
          this.triggerPriceError =
            'Trigger price cannot be below the market price.'
        } else {
          this.triggerPriceError = ''
        }
      } else {
        this.isValid = false
      }
    },
    async validateSize() {
      if (this.sizeInputTouched && !this.isSizeValid()) {
        this.isValid = false
        this.error = 'Size must be greater than 0'
        return
      } else {
        // Make this validation error disappear if user enters correct value
        // before async validation functions are resolved
        this.isValid = true
        this.error = null
      }

      const size = this.size.toNumber()

      if (this.maxPosition > 0 && size > this.maxPosition) {
        this.error = `Max position size is ${this.maxPosition}`
        return
      }

      this.isValid = true
      this.error = null
    },
    formatUSDT(amount) {
      return `${Number(amount).toFixed(2)} USDT`
    },
    async handleConfirm() {
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      const id = Date.now()
      this.$store.commit('addId', id)
      const size =
        this.side === SIDE_LONG ? this.size : this.size.mul(new Big(-1))
      this.$notify({
        id,
        title: 'Place Stop Loss',
        duration: -1,
        type: 'progress',
        data: {
          kind: 'order',
          type: 'not market',
          side: this.side,
          price: this.triggerPrice,
          size: size.abs()
        }
      })
      try {
        await addTPSL(this.pairSymbolAmm, this.signer, this.walletAddress, {
          type: 1,
          price: this.triggerPrice,
          positionSize: size.toString(),
          collateral: this.reduceOnly
            ? this.currentPosition.margin.toString()
            : this.collateral,
          leverage: this.reduceOnly
            ? this.currentPosition.leverage.toString()
            : this.leverage,
          slippage: this.slippage.toString(),
          reduceOnly: this.reduceOnly
        })
        this.$notify({
          id,
          title: 'Place Stop Loss',
          type: 'success',
          data: {
            kind: 'order',
            type: 'not market',
            side: this.side,
            price: this.triggerPrice,
            size: size.toString()
          }
        })
      } catch (e) {
        console.error(e)
        this.$notify({
          id,
          title: 'Place Stop Loss',
          type: 'error',
          data: {
            kind: 'order',
            type: 'not market',
            side: this.side,
            price: this.triggerPrice,
            size: size
          }
        })
      } finally {
        this.$notify.close(id)
        this.$store.commit('removeId', id)
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
