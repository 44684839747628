const stakingStore = {
  state: {
    userInfo: null,
    scheduler: null
  },
  mutations: {
    updateUserInfo(state, newUserInfo) {
      state.userInfo = newUserInfo
    },
    updateScheduler(state, newScheduler) {
      state.scheduler = newScheduler
    },
    stopScheduler(state) {
      state.scheduler.stop()
    }
  }
}

export default stakingStore
