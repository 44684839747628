import Big from 'big.js';
import { correctDecimalPlaces, getLiquidationPrice, SIDE_LONG, SIDE_SHORT, OPEN_LONG, OPEN_SHORT } from '../contracts/helpers';
import { getSymbol } from '@/contracts/contracts';
import { format } from 'date-fns';
import { LAUNCHPAD_INFLUENCER_ROLE } from '@/contracts/constants';
export const FEE_PCT_DENOMINATOR = 100000;
let cachedValues = {};
export const cacheGet = (key) => {
    if (!(key in cachedValues)) {
        return undefined;
    }
    if (!cachedValues[key].value) {
        return undefined;
    }
    return cachedValues[key].value;
};
export const cacheSet = (key, value, duration = 60000) => {
    if (key in cachedValues) {
        clearTimeout(cachedValues[key].timeout);
    }
    const timeout = setTimeout(() => {
        delete cachedValues[key];
    }, duration);
    cachedValues[key] = {
        timeout,
        value
    };
};
export const getTermsStatusFromStorage = (address) => {
    const item = localStorage.getItem(address);
    if (typeof item !== 'string') {
        return undefined;
    }
    const savedAddressData = JSON.parse(item);
    return savedAddressData?.terms;
};
export const formatDate = (date) => {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    return `${date.getFullYear()}-${addZeroToDateVariable(date.getMonth() + 1)}-${addZeroToDateVariable(date.getDate())} ${addZeroToDateVariable(date.getHours())}:${addZeroToDateVariable(date.getMinutes())}:${addZeroToDateVariable(date.getSeconds())}`;
};
const addZeroToDateVariable = (dateTimeVariable) => {
    return String(dateTimeVariable).padStart(2, '0');
};
const BASE_ASSET_DIGITS_OF_HISTORY = 4;
export function formatPositionSize(sizeBN) {
    if (sizeBN.lt(0.001) && sizeBN.gt(-0.001)) {
        return `< 0.001`;
    }
    else {
        const size = formatNumber(sizeBN.toFixed(getQuoteAssetDigits(sizeBN, BASE_ASSET_DIGITS_OF_HISTORY)));
        return size;
    }
}
export const formatTableNumber = (number) => {
    return number.toFixed(2);
};
// check regex 101 online, https://regex101.com/r/EeneAE/1
// to understand this regex
const regexUSLocaleNumber = new RegExp(/\d(?=(\d{3})+\.)/g);
// format number like 1000 => 1,000
export function formatNumber(number) {
    return number.replace(regexUSLocaleNumber, '$&,');
}
export const PNL_DIGITS = 2;
export function formatNumberWithDp(number, dp) {
    const _number = typeof number === 'string' ? new Big(number) : number;
    return formatNumber(_number.toFixed(dp));
}
export function formatPnl(number) {
    const _number = formatNumberWithDp(number, PNL_DIGITS);
    return Number(_number) === 0 ? formatNumberWithDp('0', PNL_DIGITS) : _number;
}
export const getQuoteAssetDigits = (num, displayLength = 6, minimal = 1) => {
    const fixed = num.toFixed();
    const firstNonZeroMatched = fixed.match(/[1-9]/);
    let dotIndex = fixed.indexOf('.');
    if (dotIndex === -1) {
        // the number is an integer, set the dotIndex to last char
        dotIndex = fixed.length;
    }
    if (!firstNonZeroMatched) {
        return minimal;
    }
    if (new Big(num).toNumber() > 1) {
        return Math.max(displayLength - dotIndex, minimal);
    }
    else {
        // -2 is for "0." these two chars
        return displayLength + (firstNonZeroMatched.index - 2);
    }
};
export const limitSliderPosition = (position) => {
    if (position > 100) {
        return 100;
    }
    else if (position < 0) {
        return 0;
    }
    else {
        return position;
    }
};
/**
 * @deprecated unused
 */
export const formatActivePosition = (rawPosition) => {
    if (rawPosition) {
        const positionSize = correctDecimalPlaces(rawPosition.positionSize);
        if (positionSize.eq(0)) {
            return null;
        }
        const margin = correctDecimalPlaces(rawPosition.margin);
        const entryPrice = correctDecimalPlaces(rawPosition.entryPrice);
        const openNotional = correctDecimalPlaces(rawPosition.openNotional);
        const leverage = correctDecimalPlaces(rawPosition.leverage);
        const liquidationPrice = getLiquidationPrice(positionSize, entryPrice, leverage);
        return {
            ...rawPosition,
            margin,
            size: positionSize,
            openNotional,
            leverage,
            leverageFromNotional: new Big(rawPosition.openNotional).div(new Big(rawPosition.margin)),
            entryPrice,
            pnl: correctDecimalPlaces(rawPosition.unrealizedPnl),
            side: positionSize.gt(0) ? SIDE_LONG : SIDE_SHORT,
            liquidationPrice
        };
    }
    else {
        return null;
    }
};
export const formatHistoricalPosition = (rawPosition) => {
    const size = correctDecimalPlaces(rawPosition.exchangedPositionSize);
    const positionNotional = correctDecimalPlaces(rawPosition.positionNotional);
    return {
        ...rawPosition,
        margin: correctDecimalPlaces(rawPosition.margin),
        positionNotional,
        size,
        entryPrice: size.gt(0) ? positionNotional.div(size) : new Big(0),
        pnl: correctDecimalPlaces(rawPosition.realizedPnl),
        exitPrice: correctDecimalPlaces(rawPosition.spotPrice),
        side: size.gt(new Big(0)) ? SIDE_LONG : SIDE_SHORT,
        time: new Date(Number(rawPosition.timestamp) * 1000).toISOString(),
        timestamp: Number(rawPosition.timestamp)
    };
};
export const formatOrder = (rawOrder) => {
    const limitPrice = correctDecimalPlaces(rawOrder.limitPrice);
    const orderSize = correctDecimalPlaces(rawOrder.orderSize);
    const filledSize = correctDecimalPlaces(rawOrder.filledSize);
    const usedQuoteAmount = correctDecimalPlaces(rawOrder.usedQuoteAmount);
    const filledPrice = !filledSize.eq(0)
        ? usedQuoteAmount.div(filledSize.abs())
        : null;
    const pairSymbol = getSymbol(rawOrder.asset);
    return {
        ...rawOrder,
        source: pairSymbol.substring(0, 3),
        target: pairSymbol.substring(3),
        symbol: pairSymbol,
        limitPrice,
        filledPrice,
        orderSize,
        collateral: rawOrder.collateral
            ? correctDecimalPlaces(rawOrder.collateral)
            : null,
        leverage: rawOrder.leverage
            ? correctDecimalPlaces(rawOrder.leverage)
            : null,
        slippage: rawOrder.slippage
            ? correctDecimalPlaces(rawOrder.slippage)
            : null,
        tipFee: rawOrder.tipFee ? correctDecimalPlaces(rawOrder.tipFee) : null,
        orderSide: orderSize.gte(0) ? OPEN_LONG : OPEN_SHORT,
        filledSize,
        usedQuoteAmount
    };
};
export const formatAmm = (rawAmm) => {
    if (rawAmm) {
        return {
            ...rawAmm,
            positionBalance: correctDecimalPlaces(rawAmm.positionBalance),
            openInterestSize: correctDecimalPlaces(rawAmm.openInterestSize),
            openInterestNotional: correctDecimalPlaces(rawAmm.openInterestNotional),
            quoteAssetReserve: correctDecimalPlaces(rawAmm.quoteAssetReserve),
            baseAssetReserve: correctDecimalPlaces(rawAmm.baseAssetReserve)
        };
    }
    else {
        return null;
    }
};
export const formatLiquidation = (rawLiquidation) => {
    return {
        ...rawLiquidation,
        positionSize: correctDecimalPlaces(rawLiquidation.positionSize),
        positionNotional: correctDecimalPlaces(rawLiquidation.positionNotional),
        liquidationFee: correctDecimalPlaces(rawLiquidation.liquidationFee)
    };
};
export const formatLaunchpadProject = (rawProject) => {
    return {
        ...rawProject,
        saleAmount: correctDecimalPlaces(rawProject.saleAmount),
        totalFundedAmount: correctDecimalPlaces(rawProject.totalFundedAmount),
        sponsorFeeFraction: rawProject.sponsorFee / FEE_PCT_DENOMINATOR,
        influencerFeeFraction: rawProject.influencerFee / FEE_PCT_DENOMINATOR
    };
};
export const formatVestingClaim = (rawClaim) => {
    return {
        ...rawClaim,
        amount: correctDecimalPlaces(rawClaim.amount)
    };
};
export const formatLaunchpadAffiliate = (rawAffiliate, salesManager) => {
    return {
        ...rawAffiliate,
        isVip: rawAffiliate.role === LAUNCHPAD_INFLUENCER_ROLE ||
            rawAffiliate.id.toLowerCase() === salesManager.toLowerCase(),
        isSalesManager: rawAffiliate.id.toLowerCase() === salesManager.toLowerCase(),
        totalFundedAmount: correctDecimalPlaces(rawAffiliate.totalFundedAmount),
        totalCommission: correctDecimalPlaces(rawAffiliate.totalCommission)
    };
};
export const formatLaunchpadParticipant = (rawParticipant) => {
    return {
        ...rawParticipant,
        totalFundedAmount: correctDecimalPlaces(rawParticipant.totalFundedAmount),
        totalInfluencerCommission: correctDecimalPlaces(rawParticipant.totalInfluencerCommission),
        totalSaleManagerCommission: correctDecimalPlaces(rawParticipant.totalSaleManagerCommission),
        totalCommission: correctDecimalPlaces(rawParticipant.totalCommission),
        claimableAmount: correctDecimalPlaces(rawParticipant.claimableAmount)
    };
};
export const formatLaunchpadFundingEvent = (rawEvent) => {
    return {
        ...rawEvent,
        amount: correctDecimalPlaces(rawEvent.amount),
        sponsorCommission: correctDecimalPlaces(rawEvent.sponsorCommission),
        influencerCommission: correctDecimalPlaces(rawEvent.influencerCommission),
        salesManagerCommission: correctDecimalPlaces(rawEvent.salesManagerCommission)
    };
};
export const formatLaunchpadBnbCommissionEvent = (rawEvent) => {
    return {
        ...rawEvent,
        investAmount: correctDecimalPlaces(rawEvent.investAmount),
        commissionAmount: correctDecimalPlaces(rawEvent.commissionAmount)
    };
};
export const formatTrade = (rawTrade) => {
    const size = correctDecimalPlaces(rawTrade.exchangedPositionSize);
    return {
        ...rawTrade,
        size,
        side: size.gte(0) ? SIDE_LONG : SIDE_SHORT,
        price: correctDecimalPlaces(rawTrade.spotPrice),
        fee: correctDecimalPlaces(rawTrade.fee),
        order: rawTrade.order
            ? {
                ...rawTrade.order,
                leverage: rawTrade.order.leverage
                    ? correctDecimalPlaces(rawTrade.order.leverage)
                    : null
            }
            : null
    };
};
export const formatAmmPosition = (rawAmmPosition) => {
    return {
        ...rawAmmPosition,
        margin: correctDecimalPlaces(rawAmmPosition.margin),
        unrealizedPnl: correctDecimalPlaces(rawAmmPosition.unrealizedPnl)
    };
};
export const formatGlobalState = (rawGlobalState) => {
    return {
        ...rawGlobalState,
        cumulativeVolume: correctDecimalPlaces(rawGlobalState.cumulativeVolume)
    };
};
export function isFeatureEnabled(featureFlag) {
    const value = process.env[`VUE_APP_FEATURE_${featureFlag.toUpperCase()}`];
    return value && value === 'enabled';
}
export const formatSeconds = (timestamp, dateFormat) => {
    const dateFormatString = dateFormat === 'long' ? 'yyyy-MM-dd ppp' : 'yyyy-MM-dd';
    return format(timestamp * 1000, dateFormatString);
};
