<template>
  <PDialog title="Unstake" @close="toggleUnstakeDialog(false)" width="96">
    <div class="text-sm">
      <hr class="mb-5" />
      <div class="space-y-5">
        <div class="space-y-2">
          <div class="flex justify-between items-center">
            <label>Unstake</label>
            <label class="flex items-center gap-1">
              <div>Balance</div>
              <div>
                <PTooltip :content="stakedAmount">
                  {{ stakedAmountFormatted }}
                </PTooltip>
              </div>
              <div><PLogo type="token" class="h-4" /></div>
            </label>
          </div>
          <Input
            v-model="stakeAmount"
            @input="updateInputHandler"
            placeholder="0.0000"
            :error="error"
          >
            <template #suffix>PALM</template>
          </Input>
        </div>
        <div class="space-y-2">
          <PRangeSlider
            color="main"
            min="0"
            max="100"
            step="1"
            suffix="%"
            :model-value="percentage"
            @update:model-value="updateSliderHandler"
          />
        </div>
        <div class="flex justify-between">
          <div>Withdraw Cooldown Period</div>
          <PTooltip
            :content="`If you want to withdraw your tokens from the Staking pool, your tokens will first be put into the Cool Down pool, these are withdrawable after 7 days.`"
            placement="left"
            icon
            class="cursor-default"
          >
            {{ cooldownPeriodFormatted }}
          </PTooltip>
        </div>
        <PButton class="w-full" @click="handleConfirm">Withdraw</PButton>
      </div>
    </div>
  </PDialog>
</template>
<script>
import { useDialogs } from '@/hooks/useDialogs'
import {
  PDialog,
  PRangeSlider,
  PButton,
  PLogo,
  PTooltip
} from '../../../palmswap-vue-ui'
import Input from '@/components/common/Input'
import { mapState } from 'vuex'
import { withdrawStake, getUserInfo } from '@/contracts/staking'
import metadata from '@/contracts/metadata'
import { utils } from 'ethers'
import { stakes } from '@/hooks/useStakes'
import { correctDecimalPlaces } from '@/contracts/helpers'
import { truncateNumber } from '@/formatters/price'
import { poolInfos } from '@/hooks/usePoolInfos'

export default {
  components: {
    PDialog,
    PRangeSlider,
    Input,
    PButton,
    PLogo,
    PTooltip
  },
  methods: {
    toggleUnstakeDialog: useDialogs().toggleUnstakeDialog,
    updateSliderHandler(newPercentage) {
      this.percentage = newPercentage
      newPercentage == 100
        ? (this.stakeAmount = this.stakedAmount)
        : (this.stakeAmount = (
            (this.stakedAmount * newPercentage) /
            100
          ).toFixed(4))
    },
    updateInputHandler() {
      this.percentage = Math.min(
        parseInt((this.stakeAmount / this.stakedAmount) * 100),
        100
      )
    },
    async handleConfirm() {
      if (!this.stakeAmount || this.stakeAmount <= 0) {
        this.error = 'Unstake amount must be greater than 0'
        return
      }
      if (Number(this.stakeAmount) > Number(this.stakedAmount)) {
        this.error = 'You cannot unstake more than your staked PALM balance'
        return
      }
      if (isNaN(this.stakeAmount)) {
        this.error = 'Unstake amount is invalid—must be a number'
        return
      }
      const _tokenAddress = this.tokenAddress
      this.toggleUnstakeDialog(false)
      const stakeAmountWei = utils.parseUnits(
        this.stakeAmount.toString(),
        'ether'
      )
      const id = Date.now()
      this.$store.commit('addId', id)
      try {
        const result = await withdrawStake(
          _tokenAddress,
          stakeAmountWei,
          this.signer,
          () => {
            this.$notify({
              id,
              title: 'Withdrawal',
              type: 'progress',
              duration: -1,
              data: {
                body: `Your withdrawal of ${this.stakeAmount} PALM is waiting for confirmation.`
              }
            })
          }
        )
        if (result) {
          await this.$store.dispatch('updateBalance')
          this.$store.commit(
            'updateUserInfo',
            await getUserInfo(_tokenAddress, this.account)
          )
          this.$notify({
            id,
            title: 'Withdrawal',
            type: 'success',
            data: {
              body: `Your withdrawal of ${this.stakeAmount} PALM is confirmed.`
            }
          })
        } else {
          this.$notify({
            id,
            title: 'Error while confirming the withdrawal',
            type: 'error'
          })
        }
      } catch (error) {
        console.error(error)
        this.$notify({
          id,
          title: 'Error while confirming the withdrawal',
          type: 'error'
        })
      } finally {
        this.$notify.close(id)
        this.$store.commit('removeId', id)
      }
    }
  },
  data() {
    return {
      stakeAmount: null,
      percentage: 0,
      error: null,
      stakes,
      poolInfos
    }
  },
  computed: {
    ...mapState({
      balance: (state) => state.user.balance.palmToken,
      signer: (state) => state.web3Modal.provider.getSigner(),
      account: (state) => state.user.address.account
    }),
    tokenId() {
      return useDialogs().metadata.value
        ? useDialogs().metadata.value.tokenId
        : null
    },
    tokenAddress() {
      return metadata[this.tokenId]
    },
    stake() {
      if (this.stakes) {
        return this.stakes.find(
          (stake) =>
            stake.stakedToken.toLowerCase() === this.tokenAddress.toLowerCase()
        )
      } else {
        return null
      }
    },
    stakedAmount() {
      if (this.stake) {
        return correctDecimalPlaces(this.stake.stakedAmount)
      } else {
        return null
      }
    },
    stakedAmountFormatted() {
      if (this.stake) {
        return truncateNumber(this.stakedAmount, 6)
      } else {
        return null
      }
    },
    poolInfo() {
      if (this.poolInfos) {
        return this.poolInfos.find(
          (poolInfo) =>
            poolInfo.token.toLowerCase() === this.tokenAddress.toLowerCase()
        )
      } else {
        return null
      }
    },
    cooldownPeriod() {
      if (this.poolInfo) {
        return this.poolInfo.cooldownPeriod / (24 * 60 * 60)
      } else {
        return null
      }
    },
    cooldownPeriodFormatted() {
      if (this.poolInfo) {
        return `${this.cooldownPeriod} days`
      } else {
        return null
      }
    }
  }
}
</script>
