import {
  fetchReferralCommissions,
  fetchReferrals,
  fetchReferralsDataPoints
} from '@/api'
import { gql } from '@apollo/client'
import { wssClient } from '../api/graph'
import { REFERRAL_SUBSCRIPTION } from '../api/queries'
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'

export const INTERVALS = {
  DAY: '1d',
  HOUR: '1h',
  FULL: 'full'
}

export const SECONDS = {
  [INTERVALS.HOUR]: 3600,
  [INTERVALS.DAY]: 24 * 3600
}

const emptyDataPoint = {
  volume: '0',
  earnings: '0',
  referrals: '0'
}

const subscriptionRef = ref(null)
const referralRef = ref(null)

export const subscribeReferral = (trader) => {
  if (subscriptionRef.value) {
    subscriptionRef.value.unsubscribe()
  }
  subscriptionRef.value = wssClient
    .subscribe({
      query: gql(REFERRAL_SUBSCRIPTION),
      variables: {
        referral: trader.toLowerCase()
      }
    })
    .subscribe({
      next(data) {
        referralRef.value =
          data.data.referrals.length === 0 ? null : data.data.referrals[0]
      },
      error(err) {
        console.error('err', err)
      }
    })
}

export const unsubscribeReferral = () => {
  if (subscriptionRef.value) {
    subscriptionRef.value.unsubscribe()
  }
  subscriptionRef.value = null
}

export const useReferrals = () => {
  const dataPointsRef = ref({})
  const referrals = ref([])
  const commissions = ref([])

  const fetchDataPoints = async (referrer, interval, startTime, endTime) => {
    const result = await fetchReferralsDataPoints(
      referrer,
      interval,
      startTime,
      endTime
    )
    let dataPoints = result
    if (interval !== INTERVALS.FULL) {
      const indexed = result.reduce(
        (acc, item) => ({ ...acc, [item.timestamp]: item }),
        {}
      )
      for (let i = startTime; i <= endTime; i += SECONDS[interval]) {
        indexed[i] = indexed[i] || {
          ...emptyDataPoint,
          interval,
          referrer,
          timestamp: i
        }
      }
      dataPoints = Object.values(indexed)
    }
    dataPointsRef.value[interval] = dataPoints
  }

  const referral = computed(() => referralRef.value)

  const dataPoints = computed(() => dataPointsRef.value)
  const { state } = useStore()
  const address = computed(() => state.user.address.account)
  watch(
    address,
    async (address) => {
      if (!address) {
        return
      }
      referrals.value = await fetchReferrals(address)
      commissions.value = await fetchReferralCommissions(address)
    },
    { immediate: true }
  )
  return {
    fetchDataPoints,
    dataPoints,
    referrals,
    referral,
    commissions
  }
}
