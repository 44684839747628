<template>
  <div>
    <OrderTypeTab v-model:selected="orderType" />
    <Market
      v-if="orderType === ORDER_TYPE_MARKET"
      class="tab-content"
      :pairSymbol="pairSymbol"
    />
    <Limit
      v-if="orderType === ORDER_TYPE_LIMIT"
      class="tab-content"
      :pairSymbol="pairSymbol"
    />
    <TakeProfit
      v-if="orderType === ORDER_TYPE_TAKE_PROFIT"
      class="tab-content"
      :pairSymbol="pairSymbol"
    />
    <StopLoss
      v-if="orderType === ORDER_TYPE_STOP_LOSS"
      class="tab-content"
      :pairSymbol="pairSymbol"
    />
  </div>
</template>
<script>
import OrderTypeTab from './make-order/OrderTypeTab.vue'
import Market from './make-order/Market.vue'
import Limit from './make-order/Limit.vue'
import TakeProfit from '@/components/trade/make-order/TakeProfit'
import StopLoss from '@/components/trade/make-order/StopLoss'
import {
  ORDER_TYPE_MARKET,
  ORDER_TYPE_TAKE_PROFIT,
  ORDER_TYPE_STOP_LOSS,
  ORDER_TYPE_LIMIT,
  selectOrderType,
  useOrderForm
} from '@/hooks/useOrderForm'

export default {
  props: {
    pairSymbol: {
      type: String,
      required: true
    }
  },
  components: {
    StopLoss,
    TakeProfit,
    Market,
    Limit,
    OrderTypeTab
  },
  setup() {
    const { selectedOrderType } = useOrderForm()
    return {
      selectOrderType,
      selectedOrderType,
      ORDER_TYPE_MARKET,
      ORDER_TYPE_TAKE_PROFIT,
      ORDER_TYPE_STOP_LOSS,
      ORDER_TYPE_LIMIT
    }
  },
  computed: {
    orderType: {
      get() {
        return this.selectedOrderType
      },
      set(val) {
        selectOrderType(val)
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
