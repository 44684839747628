import { ref } from 'vue'
import { wssClient } from '../api/graph'
import { STAKES_SUBSCRIPTION } from '../api/queries'
import { gql } from '@apollo/client/core'

let stakes = ref()
let stakesSubscription = ref()

function subscribeStakes(userAddress) {
  if (stakesSubscription.value) {
    stakesSubscription.value.unsubscribe()
    stakes.value = null
  }

  stakesSubscription.value = wssClient
    .subscribe({
      query: gql(STAKES_SUBSCRIPTION),
      variables: {
        userAddress: userAddress.toLowerCase()
      }
    })
    .subscribe({
      next(data) {
        stakes.value = data.data.stakes
      }
    })
}

export { subscribeStakes, stakes }
