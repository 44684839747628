<template>
  <div class="tabs">
    <button @click="setSelected(0)" :class="{ selected: selected === 0 }">
      {{ left }}
    </button>
    <button @click="setSelected(1)" :class="{ selected: selected === 1 }">
      {{ right }}
    </button>
  </div>
</template>
<script>
export default {
  props: {
    selected: {
      type: Number,
      default: 0
    },
    left: {
      type: String
    },
    right: {
      type: String
    }
  },
  methods: {
    setSelected(idx) {
      this.$emit('update:selected', idx)
    }
  }
}
</script>
<style lang="scss" scoped>
.tabs {
  display: flex;
  width: 100%;
  height: 38px;
  border: 1px solid #282828;
  border-radius: 4px;
  & > * {
    flex: 1;
  }
  button {
    background: transparent;
    border-radius: 4px;
    border: 0;
    padding: 6px;
    margin: 3px;
    cursor: pointer;
    font-size: 14px;
    color: var(--text);
    &.selected {
      background: #26272a;
      color: white;
    }
  }
}
</style>
