<template>
  <PDialog title="Deposit" @close="toggleDepositDialog(false)">
    <DepositForm />
  </PDialog>
</template>
<script>
import DepositForm from '@/components/deposit/DepositForm.vue'
import { useDialogs } from '@/hooks/useDialogs'
import { PDialog } from '../../palmswap-vue-ui'

export default {
  components: {
    PDialog,
    DepositForm
  },
  setup() {
    const { toggleDepositDialog } = useDialogs()
    return {
      toggleDepositDialog
    }
  }
}
</script>
<style lang="scss" scoped>
.deposit {
  &-dialog {
    padding: 16px 24px 24px 24px;
    width: 480px;
  }
}
</style>
