<template>
  <PDialog title="Withdrawal" @close="toggleWithdrawalDialog(false)">
    <div class="flex flex-col mt-3 space-y-5 w-97">
      <div>
        <div class="flex justify-between text-sm mb-2">
          <span>Wallet Balance</span>
          <span>
            Available:
            <span class="text-white">
              {{ formattedBalance('wallet') }} USDT
            </span>
          </span>
        </div>
        <CurrencyInput
          v-model.number="amount"
          placeholder="0.00"
          currency="USDT"
          :error="error"
          dataCy="withdraw-dialog_amount-input"
        />
      </div>
      <div class="flex space-x-5 items-center w-max">
        <div class="flex flex-col space-y-1">
          <span class="text-sm">Palmswap Wallet</span>
          <div class="flex space-x-2 items-center">
            <span
              class="text-white font-bold"
              :class="isBalanceBig ? 'text-xl' : 'text-3xl'"
            >
              {{ formattedBalance('wallet') }}
            </span>
            <img src="/img/usdt.svg" class="w-5" />
            <span class="text-sm">USDT</span>
          </div>
        </div>
        <div class="rounded-full bg-background-3">
          <ChevronRightIcon class="w-6" />
        </div>
        <div class="flex flex-col space-y-1">
          <span class="text-sm">After Withdraw</span>
          <div class="flex space-x-2 items-center">
            <span
              class="text-white font-bold"
              :class="isBalanceBig ? 'text-xl' : 'text-3xl'"
            >
              {{ balanceAfterWithdraw }}
            </span>
            <img src="/img/usdt.svg" class="w-5" />
            <span class="text-sm">USDT</span>
          </div>
        </div>
      </div>
      <div>
        <PButton
          class="w-full flex justify-center items-center"
          size="lg"
          @click="initWithdraw"
          :disabled="isLoading"
          data-cy="withdraw-dialog_withdraw-button"
        >
          <span v-if="!isLoading">Withdraw</span>
          <SmallLoader v-if="isLoading" />
        </PButton>
      </div>
    </div>
  </PDialog>
</template>
<script>
import CurrencyInput from '@/components/trade/make-order/CurrencyInput'
import { useDialogs } from '@/hooks/useDialogs'
import { withdraw } from '../contracts'
import { mapState } from 'vuex'
import { PDialog, PButton } from '../../palmswap-vue-ui'
import SmallLoader from '@/components/common/SmallLoader'
import Big from 'big.js'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { accountGetLastWithdrawInfo, getDailyWithdrawLimit } from '@/contracts'
import { correctDecimalPlaces } from '@/contracts/helpers'
import { numberWithCommas } from '@/formatters/price'
import { useAccount } from '@/hooks/useAccount'

export default {
  components: {
    PDialog,
    CurrencyInput,
    PButton,
    SmallLoader,
    ChevronRightIcon
  },
  setup() {
    const { toggleWithdrawalDialog } = useDialogs()
    const { smartWallet } = useAccount()
    return {
      toggleWithdrawalDialog,
      smartWallet
    }
  },
  data() {
    return {
      amount: null,
      isLoading: false,
      error: null,
      dailyWithdrawLimit: null
    }
  },
  computed: {
    ...mapState({
      accountBalance: (state) => state.user.balance.account, // user wallet balance (metamask, walletconnect)
      walletBalance: (state) => new Big(state.user.balance.wallet),
      walletAddress: (state) => state.user.address.wallet,
      signer: (state) => state.web3Modal.provider.getSigner()
    }),
    isBalanceBig() {
      const number = this.countDigits(this.walletBalance)
      return number > 3
    },
    balanceAfterWithdraw() {
      if (!this.amount) {
        return this.walletBalance.toFixed(2)
      }
      const balance = this.walletBalance.sub(new Big(this.amount))
      return balance.lt(0) ? '0.00' : balance.toFixed(2)
    },
    dailyWithdrawLimitFormatted() {
      return numberWithCommas(correctDecimalPlaces(this.dailyWithdrawLimit), 0)
    },
    currentDayIdx() {
      return parseInt(Date.now() / 1000 / 86400) // in days
    }
  },
  methods: {
    countDigits(n) {
      for (var i = 0; n > 1; i++) {
        n /= 10
      }
      return i
    },
    formattedBalance(type) {
      if (type == 'account') {
        return this.accountBalance ? (+this.accountBalance).toFixed(2) : '0.00'
      }
      return this.walletBalance ? this.walletBalance.toFixed(2) : '0.00'
    },
    async validate() {
      const amount = new Big(this.amount)
      if (amount.lt(50)) {
        this.error = 'Minimum withdrawal amount is 50 USDT'
        return false
      }
      if (amount.gt(correctDecimalPlaces(this.dailyWithdrawLimit))) {
        this.error = `Maximum daily withdrawal amount is ${this.dailyWithdrawLimitFormatted} USDT`
        return false
      }
      const [lastWithdrawDayIdx, lastDayWithdrawnAmount] =
        await accountGetLastWithdrawInfo(this.smartWallet)
      if (!(this.currentDayIdx > lastWithdrawDayIdx)) {
        const lastDayUpdatedAmount = amount
          .times(new Big(10).pow(18))
          .plus(lastDayWithdrawnAmount)
        if (lastDayUpdatedAmount.gt(this.dailyWithdrawLimit)) {
          const lastDayWithdrawnAmountFormatted = correctDecimalPlaces(
            lastDayWithdrawnAmount
          )
          this.error = `Maximum daily withdrawal amount is ${this.dailyWithdrawLimitFormatted} USDT (you have already withdrawn ${lastDayWithdrawnAmountFormatted} USDT)`
          return false
        }
      }
      return true
    },
    async initWithdraw() {
      if (!(await this.validate())) {
        return
      }
      const id = Date.now()
      this.$store.commit('addId', id)
      this.toggleWithdrawalDialog(false)
      this.isLoading = true
      try {
        const result = await withdraw(
          this.amount,
          this.signer,
          this.walletAddress,
          () => {
            this.$notify({
              id,
              title: 'Withdraw',
              type: 'progress',
              duration: -1,
              data: {
                body: `Your withdraw of ${this.amount} USDT is waiting for confirmation.`
              }
            })
          }
        )
        if (result) {
          await this.$store.dispatch('updateBalance')
          this.isLoading = false
          this.$notify({
            id,
            title: 'Withdraw success',
            type: 'success',
            data: {
              body: `Your withdraw of ${this.amount} USDT should appear in your wallet shortly.`
            }
          })
        } else {
          this.$notify({
            id,
            title: 'Error while making the withdrawal!',
            type: 'error'
          })
          this.isLoading = false
        }
        this.toggleWithdrawalDialog(false)
      } catch (error) {
        console.error(error)
        this.$notify({
          id,
          title: 'Error while making the withdrawal!',
          type: 'error'
        })
        this.isLoading = false
      } finally {
        this.$notify.close(id)
        this.$store.commit('removeId', id)
      }
    }
  },
  async mounted() {
    this.dailyWithdrawLimit = await getDailyWithdrawLimit()
  }
}
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
</style>
