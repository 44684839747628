<template>
  <router-link to="/portfolio/overview">
    <NavItem title="Overview" routeName="overview">
      <ChartBarSquareIcon class="w-5 h-5" />
    </NavItem>
  </router-link>
  <router-link to="/portfolio/positions">
    <NavItem title="Positions" routeName="positions">
      <CubeIcon class="w-5 h-5" />
    </NavItem>
  </router-link>
  <router-link to="/portfolio/invite" v-if="isInviteEnabled">
    <NavItem title="Invite" routeName="invite">
      <UserGroupIcon class="w-5 h-5" />
    </NavItem>
  </router-link>
  <router-link to="/portfolio/history">
    <NavItem title="History" routeName="history">
      <BackwardIcon class="w-5 h-5" />
    </NavItem>
  </router-link>
  <router-link to="/portfolio/fees">
    <NavItem title="Fees" routeName="fees">
      <CalculatorIcon class="w-5 h-5" />
    </NavItem>
  </router-link>
</template>

<script>
import NavItem from './NavItem.vue'
import {
  ChartBarSquareIcon,
  CubeIcon,
  UserGroupIcon,
  BackwardIcon,
  CalculatorIcon
} from '@heroicons/vue/24/solid'
import { isFeatureEnabled } from '@/utils/utils'
import { FEATURE_FLAGS, NODE_ENV } from '@/config/constants'

export default {
  components: {
    NavItem,
    ChartBarSquareIcon,
    CubeIcon,
    UserGroupIcon,
    BackwardIcon,
    CalculatorIcon
  },
  setup() {
    const isInviteEnabled = isFeatureEnabled(FEATURE_FLAGS.INVITE)
    return {
      isInviteEnabled
    }
  }
}
</script>
