import { apiGatewayClient } from '@/api/gateway'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const userRef = ref(null)

export const searchUser = async (params) => {
  const queryParams = new URLSearchParams(params)
  return await apiGatewayClient
    .get(`/contacts?${queryParams.toString()}`)
    .then(({ data }) => data)
}

export const getUser = async (ethAddress) =>
  await apiGatewayClient
    .authorize(ethAddress)
    .get(`/contacts/${ethAddress}`)
    .then(({ data }) => data)
    .catch(() => null)

export const useUserSettings = () => {
  const { state } = useStore()
  const address = computed(() => state.user.address.account)

  const user = computed(() => {
    return userRef.value
  })

  watch(
    address,
    async (address) => {
      if (!address) {
        return
      }
      getUser(address).then((data) => {
        if (!data) {
          return
        }
        userRef.value = data
      })
    },
    { immediate: true }
  )

  const deleteUser = async (id) =>
    await apiGatewayClient
      .authorize(id)
      .delete(`/contacts/${id}`)
      .then(() => (userRef.value = null))

  const saveUser = async (data) => {
    const newUser = {
      id: address.value,
      ...data
    }
    return await apiGatewayClient
      .authorize(address.value)
      .put(`/contacts`, newUser)
      .then(() => (userRef.value = newUser))
  }

  const verifyUser = async (verificationCode) =>
    searchUser({ verified: false, verificationCode })
      .then(({ data }) => (data.length > 0 ? data[0] : false))
      .then((user) => {
        if (!user) {
          throw new Error('Wrong verification code')
        }
        return saveUser({
          ...user,
          verified: true,
          verificationCode: ''
        })
      })

  return {
    user,
    saveUser,
    verifyUser,
    deleteUser,
    getUser
  }
}
