<template>
  <component
    :is="linkComponent"
    :to="route"
    :href="route"
    @click="handleNavigation"
  >
    <div
      :class="{
        'text-inactive hover:text-white': !isActiveRoute,
        'text-white': isActiveRoute
      }"
      class="px-3 py-2 rounded-sm transition text-sm"
    >
      {{ name }} {{ icon ? icon : '' }}
    </div>
  </component>
</template>
<script>
import { useDialogs } from '@/hooks/useDialogs'

export default {
  props: ['name', 'route', 'icon'],
  setup() {
    const { toggleLeavingNetworkDialog } = useDialogs()
    return { toggleLeavingNetworkDialog }
  },
  computed: {
    isActiveRoute() {
      if (!this.$route.name) {
        return false
      }
      return (
        this.$route.name === this.name || this.$route.name.startsWith(this.name)
      )
    },
    isExternal() {
      return this.route.startsWith('http')
    },
    linkComponent() {
      return this.isExternal ? 'a' : 'router-link'
    }
  },
  methods: {
    handleNavigation(e) {
      if (this.isExternal && this.name !== 'Faucet') {
        e.preventDefault()
        this.toggleLeavingNetworkDialog(true, {
          url: this.route,
          name: this.name
        })
      }
    }
  }
}
</script>
