import { ref, computed } from 'vue'
import { gql } from '@apollo/client'
import { getAmmContractAddress } from '../contracts/contracts'
import { wssClient, httpClient } from '../api/graph'
import {
  LIQUIDATIONS_SUBSCRIPTION,
  LIQUIDATION_PENALTY_QUERY
} from '../api/queries'
import { formatLiquidation } from '../utils/utils'
import { useAccount } from './useAccount'

const rawLiquidationsRef = ref([])
const liquidationSubscriptionRef = ref(null)

const toDisplayableLiquidations = (rawLiquidations) => {
  return rawLiquidations.map((rawLiquidation) => rawLiquidation)
}

export const subscribeLiquidations = (ammSymbol) => {
  const { isConnected, account } = useAccount()
  if (liquidationSubscriptionRef.value) {
    liquidationSubscriptionRef.value.unsubscribe()
  }

  liquidationSubscriptionRef.value = wssClient
    .subscribe({
      query: gql(LIQUIDATIONS_SUBSCRIPTION),
      variables: {
        trader: isConnected ? account.smartWallet.toLowerCase() : '',
        asset: getAmmContractAddress(ammSymbol).toLowerCase()
      }
    })
    .subscribe({
      next(data) {
        rawLiquidationsRef.value = data.data.positionLiquidatedEvents.map(
          (liquidation) => formatLiquidation(liquidation)
        )
      },
      error(err) {
        console.error('err', err)
      }
    })
}

export const unsubscribeLiquidations = () => {
  if (liquidationSubscriptionRef.value) {
    liquidationSubscriptionRef.value.unsubscribe()
  }
  liquidationSubscriptionRef.value = null
}

export const useLiquidations = () => {
  const rawLiquidations = computed(() => {
    return rawLiquidationsRef.value
  })
  const liquidations = computed(() => {
    if (!rawLiquidationsRef.value) {
      return []
    }
    return toDisplayableLiquidations(rawLiquidationsRef.value)
  })

  return {
    rawLiquidations,
    liquidations
  }
}

export const getPenalty = async (id) => {
  const { data } = await httpClient.query({
    query: gql(LIQUIDATION_PENALTY_QUERY),
    variables: {
      id: id
    }
  })
  return data.positionChangedEvent.liquidationPenalty
}
