import { ref, computed } from 'vue'
import { gql } from '@apollo/client'
import { wssClient } from '../api/graph'
import { GLOBAL_POSITION_CHANGED_EVENT_SUBSCRIPTION } from '../api/queries'
import { getAmmContractAddress } from '../contracts/contracts'
import { formatHistoricalPosition } from '../utils/utils'
import { LAST_TRADES_COUNT } from '../config/constants'

const subscriptionRef = ref(null)
const globalPositionsRef = ref([])

export const subscribeGlobalPositions = (ammSymbol) => {
  // TODO: query when total count is bigger than 1000
  if (subscriptionRef.value) {
    subscriptionRef.value.unsubscribe()
  }

  subscriptionRef.value = wssClient
    .subscribe({
      query: gql(GLOBAL_POSITION_CHANGED_EVENT_SUBSCRIPTION),
      variables: {
        amm: getAmmContractAddress(ammSymbol).toLowerCase()
      }
    })
    .subscribe({
      next(data) {
        globalPositionsRef.value = data.data.positionChangedEvents
          .map((event) => formatHistoricalPosition(event))
          .reverse()
      },
      error(err) {
        console.error('err', err)
      }
    })
}

export const unsubscribeGlobalPositions = () => {
  if (subscriptionRef.value) {
    subscriptionRef.value.unsubscribe()
  }
  subscriptionRef.value = null
}

export const useGlobalPosition = () => {
  const globalPositions = computed(() => {
    return globalPositionsRef.value
  })

  const lastTrades = computed(() => {
    if (!globalPositionsRef.value) {
      return []
    }
    if (globalPositionsRef.value.length > LAST_TRADES_COUNT) {
      return globalPositionsRef.value.slice(-1 * LAST_TRADES_COUNT).reverse()
    }
    return globalPositionsRef.value.reverse()
  })

  return {
    globalPositions,
    lastTrades
  }
}
