import Big from 'big.js'
import {
  farmingStaking,
  palmUSDTLP,
  setSignedContracts,
  getSignedContracts,
  palmBNBLP,
  palmTokenAddr
} from '@/contracts/contracts'
import metadata from './metadata'
import { formatEther } from '@ethersproject/units'

export async function getBNBLPPoolInfo() {
  const poolInfo = await farmingStaking.poolInfo(metadata.palmBNBLP)
  return poolInfo
}

export async function approvePalmUSDTLP(
  spender,
  amount,
  signer,
  progressCallback
) {
  setSignedContracts(signer)
  const { palmUSDTLPSigned } = getSignedContracts()
  if (progressCallback) {
    progressCallback()
  }
  const tx = await palmUSDTLPSigned.approve(spender, amount)
  const receipt = await tx.wait()
  return receipt
}

export async function approvePalmBNBLP(
  spender,
  amount,
  signer,
  progressCallback
) {
  setSignedContracts(signer)
  const { palmBNBLPSigned } = getSignedContracts()
  if (progressCallback) {
    progressCallback()
  }
  const tx = await palmBNBLPSigned.approve(spender, amount)
  const receipt = await tx.wait()
  return receipt
}

export async function allowancePalmBNBLP(spender, address) {
  const allowance = await palmBNBLP.allowance(address, spender)
  return formatEther(allowance.toString())
}

export async function allowancePalmUSDTLP(spender, address) {
  const allowance = await palmUSDTLP.allowance(address, spender)
  return formatEther(allowance.toString())
}

export async function depositBNBLP(amount, signer, progressCallback) {
  setSignedContracts(signer)
  const { farmingStakingSigned } = getSignedContracts()
  if (progressCallback) {
    progressCallback()
  }
  const tx = await farmingStakingSigned.deposit(metadata.palmBNBLP, amount)
  const receipt = await tx.wait()
  return receipt
}

export async function depositUSDTLP(amount, signer, progressCallback) {
  setSignedContracts(signer)
  const { farmingStakingSigned } = getSignedContracts()
  if (progressCallback) {
    progressCallback()
  }
  const tx = await farmingStakingSigned.deposit(metadata.palmUSDTLP, amount)
  const receipt = await tx.wait()
  return receipt
}

export async function withdrawBNBLP(amount, signer, progressCallback) {
  setSignedContracts(signer)
  const { farmingStakingSigned } = getSignedContracts()
  if (progressCallback) {
    progressCallback()
  }
  const tx = await farmingStakingSigned.withdraw(metadata.palmBNBLP, amount)
  const receipt = await tx.wait()
  return receipt
}

export async function withdrawUSDTLP(amount, signer, progressCallback) {
  setSignedContracts(signer)
  const { farmingStakingSigned } = getSignedContracts()
  if (progressCallback) {
    progressCallback()
  }
  const tx = await farmingStakingSigned.withdraw(metadata.palmUSDTLP, amount)
  const receipt = await tx.wait()
  return receipt
}

export async function getBnbLpPriceInUsd(bnbPrice) {
  return getLpPriceInUSD(palmBNBLP, bnbPrice)
}

export async function getLpPriceInUSD(lpToken, quotePriceInUSD) {
  const res = await Promise.all([
    lpToken.getReserves(),
    lpToken.token0(),
    lpToken.totalSupply()
  ])
  const token0 = res[1].toLowerCase()

  const quoteReserve = res[0][token0 === palmTokenAddr.toLowerCase() ? 1 : 0]

  return new Big(quoteReserve.toString())
    .mul(2)
    .mul(quotePriceInUSD)
    .div(res[2].toString())
}

export async function getBnbLpPriceInPalm() {
  return getLpPriceInPalm(palmBNBLP)
}

export async function getLpPriceInPalm(lpToken) {
  const res = await Promise.all([
    lpToken.getReserves(),
    lpToken.token0(),
    lpToken.totalSupply()
  ])
  const token0 = res[1].toLowerCase()

  const palmReserve = res[0][token0 === palmTokenAddr.toLowerCase() ? 0 : 1]

  return new Big(palmReserve.toString()).mul(2).div(res[2].toString())
}

export async function getPalmPrice(bnbprice) {
  const res = await Promise.all([palmBNBLP.getReserves(), palmBNBLP.token0()])
  const token0 = res[1].toLowerCase()

  const palmReserve = res[0][token0 === palmTokenAddr.toLowerCase() ? 0 : 1]
  const bnbReserve = res[0][token0 === palmTokenAddr.toLowerCase() ? 1 : 0]

  return Number(
    Big(bnbReserve).mul(bnbprice.div(1e8)).div(palmReserve).toString()
  )
}
