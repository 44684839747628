import { createRouter, createWebHistory } from 'vue-router'
import { isFeatureEnabled } from '@/utils/utils'
import { FEATURE_FLAGS } from '@/config/constants'
import { isProtectedEnvironment } from '@/helpers'
import { getAuth0 } from '@/getAuth0'

const beforeEnterAuth = async (to) => {
  if (!isProtectedEnvironment) {
    return true
  }

  const auth0 = await getAuth0()
  const resolved = await auth0.authGuard(to)

  return resolved
}

const routes = [
  {
    path: '/verify-email/:verificationCode',
    name: 'Verify email address',
    component: () => import('@/views/VerifyEmail'),
    beforeEnter: beforeEnterAuth
  },
  {
    path: '/invite/:inviteCode',
    name: 'Invite',
    component: () => import('@/views/Invite.vue'),
    beforeEnter: beforeEnterAuth
  }
]

const dexRoutes = [
  {
    path: '/trade/:currency',
    name: 'Trade',
    component: () => import('@/views/Trade.vue'),
    beforeEnter: beforeEnterAuth
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Trade.vue'),
    beforeEnter: beforeEnterAuth
  },
  {
    path: '/portfolio/:page',
    name: 'Portfolio',
    component: () => import('@/views/Portfolio.vue'),
    beforeEnter: beforeEnterAuth
  }
]
dexRoutes.forEach((route) => routes.push(route))

if (isFeatureEnabled(FEATURE_FLAGS.COMPETITION)) {
  routes.push({
    path: '/leaderboard',
    name: 'Leaderboard',
    component: () => import('@/views/Leaderboard.vue'),
    beforeEnter: beforeEnterAuth
  })
}

if (
  isFeatureEnabled(FEATURE_FLAGS.STAKING) ||
  isFeatureEnabled(FEATURE_FLAGS.FARM)
) {
  const earnRoutes = {
    path: '/earn',
    name: 'Earn',
    component: () => import('@/views/Earn.vue'),
    children: [],
    beforeEnter: beforeEnterAuth
  }

  if (isFeatureEnabled(FEATURE_FLAGS.STAKING)) {
    const stakingRoutes = [
      {
        path: 'staking',
        name: 'Staking',
        component: () => import('@/views/Staking.vue'),
        beforeEnter: beforeEnterAuth
      }
    ]
    stakingRoutes.forEach((route) => earnRoutes.children.push(route))
  }

  if (isFeatureEnabled(FEATURE_FLAGS.FARM)) {
    const farmRoutes = [
      {
        path: 'farm',
        name: 'Farm',
        component: () => import('@/views/Farm.vue'),
        beforeEnter: beforeEnterAuth
      }
    ]
    farmRoutes.forEach((route) => earnRoutes.children.push(route))
  }

  routes.push(earnRoutes)
}

if (isFeatureEnabled(FEATURE_FLAGS.LAUNCHPAD)) {
  const launchpadRoutes = [
    {
      path: '/launchpad',
      name: 'Launchpad',
      component: () => import('@/views/Launchpad.vue'),
      beforeEnter: beforeEnterAuth
    },
    {
      path: '/launchpad/referral',
      name: 'Launchpad Referral Program',
      component: () => import('@/views/LaunchpadReferralProgram'),
      beforeEnter: beforeEnterAuth
    },
    {
      path: '/launchpad/:product',
      name: 'Launchpad Product',
      component: () => import('@/views/LaunchpadProduct.vue'),
      beforeEnter: beforeEnterAuth
    }
  ]
  launchpadRoutes.forEach((route) => routes.push(route))
}

if (isFeatureEnabled(FEATURE_FLAGS.TRADING_REWARDS)) {
  routes.push({
    path: '/rewards',
    name: 'Rewards',
    component: () => import('@/views/Rewards.vue'),
    beforeEnter: beforeEnterAuth
  })
}
if (isFeatureEnabled(FEATURE_FLAGS.VESTING)) {
  routes.push({
    path: '/claim',
    name: 'Claim',
    component: () => import('@/views/Vesting'),
    beforeEnter: beforeEnterAuth
  })
}

if (isFeatureEnabled(FEATURE_FLAGS.FAUCET)) {
  routes.push({
    path: '/faucet',
    name: 'Faucet',
    component: () => import('@/views/Faucet.vue'),
    beforeEnter: beforeEnterAuth
  })
}

// Note: This catch-all route must be added last, before `const router = createRouter({ ...`
routes.push({
  path: '/:pathMatch(.*)*',
  name: 'CatchAll',
  component: () => import('@/views/CatchAll.vue'),
  beforeEnter: beforeEnterAuth
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    const content = document.getElementById('portfolio-content')
    if (content) {
      content.scroll(0, 0)
    }
    return { top: 0 }
  }
})

export default router
