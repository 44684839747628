import { reactive, computed, watch } from 'vue'
import { isFeatureEnabled } from '@/utils/utils'
import { usePriceData, DEFAULT_START_DATE, DEFAULT_END_DATE } from './useChart'
import { useAMM } from './useAMM'
import { fetchSpotPrice, formatUSD, nFormatter } from '../formatters/price'
import { subscribeActivePosition } from '@/hooks/useUserPosition'
import { subscribeOrders } from '@/hooks/useOrders'
import { subscribeTrades } from '@/hooks/useTrades'
import { subscribeLiquidations } from '@/hooks/useLiquidations'
import { subscribeGlobalPositions } from '@/hooks/useGlobalPosition'
import { useTradesStats } from '@/hooks/useTrades'
import { subscribeAmm } from '@/hooks/useAMM'
import store from '../store'
import { ammGet1hFunding } from '@/contracts/amm'
import { correctDecimalPlaces } from '@/contracts/helpers'
import { FEATURE_FLAGS } from '@/config/constants'
import { getSymbol } from '@/contracts/contracts'

export const currencies = reactive([
  {
    logo: '/img/eth.svg',
    symbol: 'ETH',
    details: 'Ethereum',
    name: 'ETH/USDT',
    ammSymbol: 'ETHUSDT',
    ticker: 'ETH-USDT',
    price: '-',
    high: 0,
    low: 0,
    change: 0,
    volume: '-',
    volume24h: 0,
    enabled: true
  }
])

const defaultSettings = JSON.parse(localStorage.getItem('settings')) || {
  slippage: 0.5,
  currency: currencies[0],
  lastUpdateTime: null
}

const settings = reactive(defaultSettings)

watch(settings, (settings) => {
  localStorage.setItem('settings', JSON.stringify(settings))
})

export const getCurrency = (symbol) => {
  const currency = currencies.find((currency) => currency.symbol === symbol)
  if (!currency) {
    throw `No currency with symbol ${symbol}`
  }
  return currency
}

export const getTradingPair = (ammSymbol) => {
  const pair = currencies.find((pair) => pair.ammSymbol === ammSymbol)
  if (!pair) {
    throw `No trading pair with symbol ${ammSymbol}`
  }
  return pair
}

export const getTradingPairByAddress = (ammAddress) => {
  const ammSymbol = getSymbol(ammAddress.toLowerCase())
  return getTradingPair(ammSymbol)
}

const setCurrency = (symbol) => {
  const userAccountAddress = store.state.user.address.account
  const userWalletAddress = store.state.user.address.wallet
  const connected = store.state.web3Modal.connected
  const currency = getCurrency(symbol)

  settings.currency = currency

  subscribeGlobalPositions(currency.ammSymbol)
  subscribeAmm(currency.ammSymbol)

  if (connected) {
    subscribeActivePosition(currency.ammSymbol)
    subscribeOrders(userAccountAddress)
    subscribeTrades(currency.ammSymbol, userWalletAddress)
    subscribeLiquidations(currency.ammSymbol)
  }
}

const setSlippage = (slippage) => {
  settings.slippage = slippage
}

export const updateCurrenciesData = async (symbol) => {
  try {
    const currency = getCurrency(symbol)
    const { fetch24hData } = usePriceData()
    const { getIndexPrice } = useAMM()
    const { trades24hStats } = useTradesStats(currency.ammSymbol)
    const data = await fetch24hData(
      currency.ammSymbol,
      DEFAULT_START_DATE,
      DEFAULT_END_DATE
    )
    const { volumes, prices } = data
    const idxPrice = await getIndexPrice(currency.ammSymbol)
    const markPrice = await fetchSpotPrice(currency.ammSymbol)
    currency.price = idxPrice
    currency.markPrice = formatUSD(markPrice)
    if (trades24hStats.value.volume) {
      currency.volume24h = trades24hStats.value.volume.toFixed(2)
    }
    currency.fundingRate = await ammGet1hFunding(currency.ammSymbol)
    const [firstPrice, lastPrice] = [prices[0], prices[prices.length - 1]]
    const [volume] = volumes
    if (firstPrice && lastPrice) {
      const change = (lastPrice.close - firstPrice.open) / firstPrice.open
      currency.change = change
    }
    if (prices) {
      currency.high = Math.max.apply(
        Math,
        prices.map(function (o) {
          return o.high
        })
      )
      currency.low = Math.min.apply(
        Math,
        prices.map(function (o) {
          return o.low
        })
      )
    }
    if (volume) {
      const volumeAmount = Math.round(volume.value * idxPrice)
      currency.volumeRaw = volumeAmount
      currency.volume = `$${nFormatter(volumeAmount, 0)}`
    }
  } catch (e) {
    console.error('useAppState.updateCurrenciesData:', e)
  }
}

watch(
  () => settings.currency.ammSymbol,
  async () => {
    updateCurrenciesData(settings.currency.symbol)
  }
)
currencies.forEach(({ symbol }) => updateCurrenciesData(symbol))

export const useAppState = () => {
  const slippage = computed(() => settings.slippage)
  const lastUpdateTime = computed(() => settings.lastUpdateTime)
  const currency = computed(() => settings.currency)
  /**
   * @deprecated Use pairSymbolAmm instead
   */
  const pairSymbol = computed(() => settings.currency.ammSymbol)
  const pairSymbolAmm = computed(() => settings.currency.ammSymbol)
  const currencySymbol = computed(() => settings.currency.symbol)
  const spotPrice = computed(() => settings.spotPrice)
  const totalVolume = computed(() =>
    nFormatter(
      correctDecimalPlaces(
        currencies.reduce(
          (acc, ccy) => (ccy.volumeRaw ? acc + ccy.volumeRaw : acc),
          0
        )
      ),
      0
    )
  )

  return {
    spotPrice,
    currencySymbol,
    setCurrency,
    pairSymbol,
    pairSymbolAmm,
    currency,
    slippage,
    setSlippage,
    lastUpdateTime,
    totalVolume
  }
}
