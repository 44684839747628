<template>
  <div
    v-if="show && isMobile"
    class="fixed top-0 left-0 right-0 flex flex-col bg-background-3 z-50 w-screen"
  >
    <div class="text-sm p-3 text-white flex items-top space-x-2">
      <div class="flex-1">
        Palmswap is not yet optimized for mobile. Please use the App on Desktop.
      </div>
      <div class="cursor-pointer">
        <XMarkIcon class="w-3 h-3" @click="close" />
      </div>
    </div>
    <div class="h-1 bg-background-black w-full">
      <div
        class="h-full bg-main progress-bar"
        :style="{ width: `${width}%` }"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import useBreakpoints from '@/hooks/useBreakpoints'

export default {
  components: {
    XMarkIcon
  },
  setup() {
    const { isMobile } = useBreakpoints()
    const show = ref(true)
    const width = ref(100)
    const decreaseWidth = () => (width.value = width.value - 1)
    let interval = setInterval(decreaseWidth, 100)
    const close = () => {
      show.value = false
      width.value = 0
      clearInterval(interval)
    }
    setTimeout(close, 10000)
    return { show, width, isMobile, close }
  }
}
</script>

<style lang="scss" scoped>
.progress-bar {
  transition: width 100ms linear;
}
</style>
