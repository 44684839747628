<template>
  <div class="success-container">
    <img src="@/assets/confirmation.svg" />
    <div class="success-container-message">
      {{ message }}
    </div>
    <div class="success-container-transaction" v-if="transaction">
      <a target="_blank" :href="blockExplorerLink">View on BscScan</a>
    </div>
  </div>
</template>
<script>
import { transactionUrl } from '@/helpers'

export default {
  props: {
    message: { type: String, default: '' },
    transaction: { type: String, default: '' }
  },
  computed: {
    blockExplorerLink: function () {
      return transactionUrl(this.transaction)
    }
  }
}
</script>
<style lang="scss" scoped>
.success {
  &-container {
    padding: 0 16px 0 16px;
    &-message {
      margin-top: 35px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
    }
    &-transaction {
      a {
        color: #5d7fca;
      }
      margin-top: 16px;
    }
  }
}
</style>
