export const POSITION_CHANGED_EVENT_SUBSCRIPTION = `
subscription getPositionChangedEvents($trader: String!, $amm: String!) {
  positionChangedEvents(first: 1000, orderBy: timestamp, orderDirection: desc, where: {trader: $trader, amm: $amm}) {
    id
    trader
    amm
    margin
    positionNotional
    exchangedPositionSize
    realizedPnl
    spotPrice
    blockNumber
    timestamp
  }
}`

export const GLOBAL_POSITION_CHANGED_EVENT_SUBSCRIPTION = `
subscription getPositionChangedEvents($amm: String!) {
  positionChangedEvents(first: 1000, orderBy: timestamp, orderDirection: desc, where: {amm: $amm}) {
    id
    trader
    amm
    margin
    positionNotional
    exchangedPositionSize
    realizedPnl
    spotPrice
    blockNumber
    timestamp
  }
}`

export const AMM_POSITION_SUBSCRIPTION = `
subscription ammPositionUpdated($id: String!) {
  ammPosition(id: $id) {
    id
    amm
    trader
    positionSize
    margin
    openNotional
    leverage
    entryPrice
    realizedPnl
    unrealizedPnl
    blockNumber
    timestamp
  }
}`

export const AMM_SUBSCRIPTION = `
subscription ammUpdated($id: String!) {
  amm(id: $id) {
    id
    address
    positionBalance
    openInterestSize
    openInterestNotional
    quoteAssetReserve
    baseAssetReserve
    blockNumber
    timestamp
  }
}`

export const AMMS_SUBSCRIPTION = `
subscription {
  amms (where: { paused: false }) {
    id
    positionBalance
    openInterestSize
    openInterestNotional
    tradingVolume
    quoteAssetReserve
    baseAssetReserve
    blockNumber
    timestamp
  }
}`

export const ORDERS_SUBSCRIPTION = `
subscription ordersUpdated($amms: [String], $trader: String!) {
  orders(first: 1000, where: {asset_in: $amms, trader: $trader}) {
    id
    trader
    asset
    reduceOnly
    stillValid
    filled
    orderType
    limitPrice
    orderSize
    collateral
    leverage
    slippage
    tipFee
    expiry
    filledSize
    usedQuoteAmount
    updatedTimestamp
    createdTimestamp
  }
}`

export const LIQUIDATIONS_SUBSCRIPTION = `
subscription positionLiquidated($trader: String!) {
  positionLiquidatedEvents(first: 1000, where: {trader: $trader}) {
    id
    trader
    amm
    positionNotional
    positionSize
    liquidationFee
    liquidator
    badDebt
    blockNumber
    timestamp
    isPartial
  }
}`

export const LIQUIDATION_PENALTY_QUERY = `
query positionChangedEvent($id: String!) {
  positionChangedEvent(id: $id) {
    id
    liquidationPenalty
  }
}`

export const TRADES_SUBSCRIPTION = `
subscription tradesUpdated($trader: String!, $asset: String!) {
  positionChangedEvents(first: 1000, where: {trader: $trader, amm: $asset}) {
    id
    order {
      id
      orderType
      leverage
      createdTimestamp
    }
    trader
    amm
    exchangedPositionSize
    spotPrice
    timestamp
    realizedPnl
    fee
    positionNotional
    isLiquidation
  }
}`

export const TRADES_FILTERED_QUERY = `
query tradesUpdated($trader: String!, $amms: [String], $timestampLte: Int!, $timestampGte: Int! ) {
  positionChangedEvents(where: {amm_in: $amms, trader: $trader, timestamp_lte: $timestampLte, timestamp_gte: $timestampGte}) {
    id
    order {
      id
      orderType
      leverage
      createdTimestamp
    }
    trader
    amm
    exchangedPositionSize
    spotPrice
    timestamp
    realizedPnl
    fee
    positionNotional
    isLiquidation
  }
}`

export const TRADES_FILTERED_QUERY_BY_PAGE = `
query tradesUpdated($trader: String!, $amms: [String], $timestampLte: Int!, $timestampGte: Int!, $skip: Int! ) {
  positionChangedEvents(first: 200, skip: $skip, where: {amm_in: $amms, trader: $trader, timestamp_lte: $timestampLte, timestamp_gte: $timestampGte}) {
    id
    order {
      id
      orderType
      leverage
      createdTimestamp
    }
    trader
    amm
    exchangedPositionSize
    spotPrice
    timestamp
    realizedPnl
    fee
    positionNotional
    isLiquidation
  }
}`

export const LAUNCHPAD_PROJECT_SUBSCRIPTION = `
subscription launchpadProject($id: String!) {
  launchpadProject(id: $id) {
    id
    salesManager
		totalFundedAmount
		withdrawnAmount
		totalCommission
		startTime
		endTime
		saleAmount
		sponsorFee
		influencerFee
		participantsCount
  }
}`

export const LAUNCHPAD_EARNINGS_SUBSCRIPTION = `
subscription launchpadFundedEvents($sponsor: String!, $project: String!) {
  launchpadFundedEvents(first: 1000, orderBy: timestamp, orderDirection: desc, where: { sponsor: $sponsor, project: $project }) {
		id
		user
		project
		sponsor
		amount
		sponsorCommission
		influencerCommission
		salesManagerCommission
		timestamp
  }
}`

export const LAUNCHPAD_BNB_COMMISSION_SUBSCRIPTION = `
subscription launchpadBNBCommissionEvents($user: String!) {
  launchpadBNBCommissionEvents(first: 1000, orderBy: timestamp, orderDirection: desc, where: { user: $user }) {
		id
		user
		from
    investAmount
    commissionAmount
		timestamp
  }
}`

export const LAUNCHPAD_AFFILIATE_SUBSCRIPTION = `
subscription launchpadAffiliate($sponsor: String!) {
  launchpadAffiliate(id: $sponsor) {
    id
    role
    openCommission
	  totalFundedAmount
		totalReferrals
		totalCommission
  }
}`

export const LAUNCHPAD_REFERRAL_PARTICIPANTS_SUBSCRIPTION = `
subscription launchpadParticipants($sponsor: String!) {
  launchpadParticipants(first:10, orderBy: lastFundedTime, orderDirection: desc, where: { sponsor: $sponsor }) {
    id
		sponsor
		lastFundedTime
		totalFundedAmount
		totalInfluencerCommission
		totalSaleManagerCommission
		totalCommission
		claimableAmount
  }
}`

export const LAUNCHPAD_SALES_MANAGER_REFERRAL_PARTICIPANTS_SUBSCRIPTION = `
subscription launchpadParticipants {
  launchpadParticipants(first:10, orderBy: lastFundedTime, orderDirection: desc, where: { totalSaleManagerCommission_gt: 0 }) {
    id
		sponsor
		lastFundedTime
		totalFundedAmount
		totalInfluencerCommission
		totalSaleManagerCommission
		totalCommission
		claimableAmount
  }
}`

export const LAUNCHPAD_FUNDED_EVENTS_QUERY = `
query launchpadFundedEvents($sponsor: String!, $project: String!, $startTime: Int!, $endTime: Int!) {
  launchpadFundedEvents(first: 1000, orderBy: timestamp, orderDirection: desc, where: { sponsor: $sponsor, project: $project, timestamp_gt: $startTime, timestamp_lt: $endTime }) {
		id
		user
		project
		sponsor
		amount
		influencerCommission
		salesManagerCommission
		sponsorCommission
		timestamp
  }
}`

export const LAUNCHPAD_REFERRALS_QUERY = `
query launchpadParticipants($sponsor: String!, $startTime: Int!, $endTime: Int!) {
  launchpadParticipants(first: 1000, orderBy: lastFundedTime, orderDirection: desc, where: { sponsor: $sponsor, lastFundedTime_gt: $startTime, lastFundedTime_lt: $endTime }) {
    id
		sponsor
		lastFundedTime
		totalFundedAmount
		totalInfluencerCommission
		totalSaleManagerCommission
		totalCommission
		claimableAmount
  }
}`

export const LAUNCHPAD_SALES_MANAGER_REFERRALS_QUERY = `
query launchpadParticipants($startTime: Int!, $endTime: Int!) {
  launchpadParticipants(first: 1000, orderBy: lastFundedTime, orderDirection: desc, where: { totalSaleManagerCommission_gt: 0, lastFundedTime_gt: $startTime, lastFundedTime_lt: $endTime }) {
    id
		sponsor
		lastFundedTime
		totalFundedAmount
		totalInfluencerCommission
		totalSaleManagerCommission
		totalCommission
		claimableAmount
  }
}`

export const LAUNCHPAD_BNB_COMMISSION_QUERY = `
query launchpadBNBCommissionEvents($user: String!, $startTime: Int!, $endTime: Int!) {
  launchpadBNBCommissionEvents(first: 1000, orderBy: timestamp, orderDirection: desc, where: { user: $user, timestamp_gt: $startTime, timestamp_lt: $endTime }) {
		id
		user
		from
    investAmount
    commissionAmount
		timestamp
  }
}`

export const VESTING_CLAIM_EVENTS = `
subscription vestingClaimEvents($user: String!) {
  vestingClaimEvents(first: 1000, orderBy: timestamp, orderDirection: desc, where: { user: $user }) {
		id
		tx
		category
		user
		amount
		timestamp
  }
}`

export const TRADES_24H_DATAPOINT = `
subscription tradesDataPoints($amms: [String], $timeAfter: Int!) {
  tradesDataPoints(first: 1000, where: {market_in: $amms, interval: "1h", timestamp_gt: $timeAfter}) {
    id
    market
    interval
    volume
    timestamp
    fees
    count
  }
}`

export const TRADES_24H_DATAPOINT_BY_SYMBOL = `
subscription tradesDataPoints($market: String!, $timeAfter: Int!) {
  tradesDataPoints(first: 24, where: {market: $market, interval: "1h", timestamp_gt: $timeAfter}) {
    id
    market
    interval
    volume
    timestamp
    fees
    count
  }
}`

export const COMP_SMART_WALLETS_SUBSCRIPTION = `
subscription smartWallets($skip: Int) {
  smartWallets(first: 1000, skip: $skip, where: {
    joined: true
  }) {
    id
    owner
    blockCreated
    pnlPercentage
    realizedPnl
    openedMargin
    openedPosition
    joined
  }
}
`

export const COMP_GLOBAL_STATE_SUBSCRIPTION = `
subscription globalState($trader: String) {
  globalState(id: "GlobalState") {
    cumulativeVolume
    cumulativeTraders
    endBlock
  }
}
`

export const TRADER_30DAYS_DATA = `
subscription traderDayDatas($trader: String!, $dateAfter: Int!) {
  traderDayDatas(where: { trader: $trader, date_gte: $dateAfter }) {
    fee
    tradingVolume
  }
}
`

export const TRADER_DAY_DATA = `
subscription traderDayData($id: String!) {
  traderDayDatas(
    first: 1000
    where: { trader: $id }
  ) {
    fee
    date
    tradingVolume
    fundingPayment
    realizedPnl
  }
}
`

export const COMP_GLOBAL_STATE_QUERY = `
query globalState {
  globalState(id: "GlobalState") {
    cumulativeVolume
    cumulativeTraders
    endBlock
  }
}
`

export const CANDLE_STICKS = `
query($market: String!, $interval: String!, $first: Int!, $endTime: Int) {
  candleSticks(
    first: $first,
    orderBy: startTime,
    orderDirection: desc,
    where: {
      interval: $interval,
      startTime_lte: $endTime
      market: $market
    }
  ) {
    id,
    startTime,
    open,
    close
    high,
    low,
    volume,
    market
  }
}
`
export const TRADES_24H_BY_TRADER = `
query($amms: [String], $startTime: Int!, $endTime: Int, $trader: String) {
	positionChangedEvents(
		first: 1000,
    where: {
      amm_in: $amms
      timestamp_gte: $startTime
      timestamp_lte: $endTime
      trader: $trader
    }
  ) {
    id
  }
}
`
export const TRADES_24H_BY_ASSET = `
query($startTime: Int!, $endTime: Int, $asset: String) {
	positionChangedEvents(
		first: 1000,
    where: {
      timestamp_gte: $startTime
      timestamp_lte: $endTime
      amm: $asset
    }
  ) {
    id
  }
}
`

export const STATS_24H = `
query($market: String!, $startTime: Int!, $endTime: Int) {
  candleSticks(
    first: 100,
    where: {
      interval: "15m",
      startTime_gte: $startTime
      startTime_lte: $endTime
      market: $market
    }
  ) {
    id,
    startTime,
    open,
    close
    high,
    low,
    volume,
    market
  }
}
`

export const REFERRALS_DATA_POINTS = `
query($referrer: String!, $interval: String!, $startTime: Int!, $endTime: Int) {
  referralsDataPoints(
    first: 1000,
    where: {
      interval: $interval,
      timestamp_gte: $startTime
      timestamp_lte: $endTime
      referrer: $referrer
    }
  ) {
    id,
    interval,
    referrer,
    timestamp,
    volume
    earnings,
    referrals,
  }
}
`

export const REFERRALS_OF = `
query($referrer: String!) {
  referrals(
    first: 100,
    where: {
      referrer: $referrer
    },
    orderBy: trades,
    orderDirection: desc,
  ) {
    id,
    referrer,
    referral,
    createdAt,
    trades,
    totalFeeDiscount,
    refundedFee
  }
}
`

export const REFERRAL_OF = `
query($referral: String!) {
  referrals(
    first: 1,
    where: {
      referral: $referral
    },
  ) {
    id,
    referrer,
    referral,
    createdAt,
    trades,
    totalFeeDiscount,
    refundedFee
  }
}
`

export const REFERRAL_COMMISSIONS_OF = `
query($referrer: String!) {
  referralCommissions(
    first: 1000,
    where: {
      referrer: $referrer
    },
    orderBy: tradedAt,
    orderDirection: desc,
  ) {
    id,
		referrer,
		referral,
		market,
		tradedAt,
		feeRatio,
		commission,
  }
}
`

export const FUNDING_RATE_UPDATED_EVENTS_QUERY = `
query($startTime: Int!, $endTime: Int!) {
  fundingRateUpdatedEvents(first: 1000, orderBy: timestamp, orderDirection: desc, where: {timestamp_gte: $startTime, timestamp_lte: $endTime}) {
    amm
    rate
    underlyingPrice
    blockNumber
    timestamp
  }
}
`
export const POSITION_CHANGED_EVENTS_IN_PERIOD_QUERY = `
query($trader: String, $startTime: Int!, $endTime: Int) {
	positionChangedEvents(
		first: 1000,
    where: {
      timestamp_gte: $startTime
      timestamp_lte: $endTime
      trader: $trader
    }
  ) {
    trader
    amm
    positionSizeAfter
    blockNumber
    timestamp
  }
}
`

export const LAST_POSITION_CHANGED_EVENT_QUERY = `
query($trader: String, $time: Int!) {
	positionChangedEvents(
		first: 1,
    where: {
      timestamp_lte: $time
      trader: $trader
    }
  ) {
    trader
    amm
    positionSizeAfter
    blockNumber
    timestamp
  }
}
`

export const TRANSFERS_OF = `
subscription transfers($address: String!) {
  transfers(
    first: 1000,
    where: {
      smartWallet: $address
    }
    orderBy: timestamp,
    orderDirection: desc
  ) {
    id
		smartWallet
		action
		value
		timestamp
		txHash
  }
}
`

export const REWARDS_WALLETS = `
subscription smartWallets {
  smartWallets {
    id
    owner
    deposited
    tradeVolume
    milestone
  }
}
`
export const REWARDS_WALLET = `
subscription smartWallet($id: String!) {
  smartWallet(id: $id) {
    id
    owner
    deposited
    tradeVolume
    milestone
  }
}
`

export const SMART_WALLET_INFO = `
subscription smartWalletInfo($id: String!) {
  smartWallet(id: $id) {
    tradeCount
    tradeVolume
    created
    owner
    sponsor
    totalPnL
  }
}
`

export const REWARDS_MILESTONE_STATES = `
subscription milestoneStates {
  milestoneStates {
    id
    milestone
    count
  }
}
`

export const STAKES_SUBSCRIPTION = `
subscription stakesSubscription($userAddress: String!) {
  stakes(first: 1000, where: {userAddress: $userAddress}) {
    id
    userAddress
    stakedToken
    stakedAmount
    withdrawPendingAmount
    totalRewardedAmount
  }
}`

export const POOL_INFOS_SUBSCRIPTION = `
subscription {
  poolInfos {
    id
    token
    rewardPerBlock
    cooldownPeriod
    lastUpdatedBlock
    totalStaked
    totalRewarded
    totalEarned
  }
}`

export const FEE_DISCOUNT_SUBSCRIPTION = `
subscription feeDiscount($id: String!) {
  feeDiscount(id: $id) {
    id
    level
    tier
    feeReturn
    claimedFee
  }
}`

export const GLOBAL_INFOS_SUBSCRIPTION = `
subscription {
  globalInfos {
    id
    palmToken
    tgeTime
    sponsorCommissionRatio
    referralFeeDiscountRatio
  }
}`

export const SPONSORS_SUBSCRIPTION = `
subscription sponsor($id: String!) {
  sponsor(id: $id) {
    totalCommission
    claimedCommission
    commissionRatio
  }
}`

export const REFERRAL_SUBSCRIPTION = `
subscription referrals($referral: String!) {
  referrals(first: 1, where: {referral: $referral}) {
    id,
    referrer,
    referral,
    createdAt,
    trades,
    totalFeeDiscount,
    refundedFee
  }
}`
