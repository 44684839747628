<template>
  <PDialog @close="toggleLinkWalletDialog(false)">
    <template v-slot:header>
      <div class="flex flex-col">
        <div class="text-lg text-white text-center font-medium">
          Link Wallet
        </div>
        <div class="text-xs text-inactive text-center px-6 mb-3.5 leading-5">
          You will receive signature request.<br />
          Signing is free and will not send a transaction.
        </div>
      </div>
    </template>
    <div class="flex flex-col gap-9 px-6">
      <div class="flex items-center gap-2.5">
        <div
          class="w-11 h-11 flex items-center justify-center rounded-full"
          :class="[step === 2 ? 'bg-green bg-opacity-20' : 'bg-background-3']"
        >
          <div class="text-xs" v-if="step === 1 && !isLoading">1</div>
          <div class="w-4 h-4" v-if="isLoading">
            <div class="loader">
              <svg class="circular-loader" viewBox="13 13 26 26">
                <circle
                  class="loader-path"
                  cx="26"
                  cy="26"
                  r="12"
                  fill="none"
                ></circle>
              </svg>
            </div>
          </div>
          <CheckIcon class="text-green h-4" v-if="step === 2 && !isLoading" />
        </div>
        <div class="flex flex-col gap-2 text-sm">
          <div class="text-white font-medium">Verify Ownership</div>
          <div class="text-inactive">
            Confirm you are the owner of this wallet
          </div>
        </div>
      </div>
      <PButton
        color="main"
        outlined
        size="md"
        class="h-10 w-full flex flex-none justify-center text-sm items-center"
        data-cy="link-wallet-dialog_button"
        @click="onClick"
      >
        {{ buttonText }}
      </PButton>
    </div>
  </PDialog>
</template>
<script>
import { useDialogs } from '@/hooks/useDialogs'
import { PButton, PDialog } from '../../../palmswap-vue-ui'
import { mapState } from 'vuex'
import { getNonce, setTokenWithExpiry } from '@/helpers/auth'
import { CheckIcon } from '@heroicons/vue/20/solid'

export default {
  components: {
    PButton,
    PDialog,
    CheckIcon
  },
  computed: {
    ...mapState({
      signer: (state) => Object.freeze(state.web3Modal.provider.getSigner())
    }),
    buttonText() {
      if (this.step === 1 && !this.isLoading) {
        return 'Send Request'
      } else if (this.step === 2 && !this.isLoading) {
        return 'Trade Now'
      } else return 'Try Again'
    }
  },
  setup() {
    const { toggleLinkWalletDialog } = useDialogs()

    return {
      toggleLinkWalletDialog
    }
  },
  data() {
    return {
      step: 1,
      isLoading: false
    }
  },
  watch: {},
  methods: {
    async onClick() {
      if (this.step === 2) {
        this.toggleLinkWalletDialog(false)
        return
      }
      this.isLoading = true
      const address = await this.signer.getAddress()
      try {
        try {
          let token = ''
          const { nonce, message } = await getNonce(address)
          const signature = await this.signer.signMessage(message)
          token = `${nonce}:${signature}`
          setTokenWithExpiry(address, token)
          this.$store.commit('setAuthToken', token)
        } catch (e) {
          this.isLoading = false
          return
        }
        this.step = 2
        this.isLoading = false
      } catch (e) {
        return
      }
    }
  }
}
</script>
<style lang="scss">
::-webkit-scrollbar {
  display: none;
}
</style>
<style lang="scss" scoped>
@keyframes animate-stroke {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
.loader-path {
  fill: none;
  stroke: #6c7284;
  stroke-width: 2px;
  stroke-linecap: round;
  animation: animate-stroke 1.5s ease-in-out infinite;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.circular-loader {
  animation: rotate 1s linear infinite;
}
</style>
