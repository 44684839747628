<template>
  <button :disabled="disabled" :class="{ disabled }" @click="$emit('onClick')">
    <SmallLoader class="loader" v-if="isLoading" />
    <slot />
  </button>
</template>
<script>
import SmallLoader from './SmallLoader.vue'
export default {
  props: {
    isLoading: {
      type: Boolean
    },
    overlay: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  components: { SmallLoader }
}
</script>

<style lang="scss" scoped>
button {
  background: var(--primary);
  border: 0;
  outline: none;
  color: var(--text);
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  & .loader {
    margin-right: 8px;
  }
  &:hover {
    border: 0;
    background: #7592d2;
  }
}
.disabled {
  cursor: default;
  color: var(--gray20);
  background: var(--gray40);
  &:hover {
    border: 0;
    background: var(--gray40);
  }
}
</style>
