<template>
  <Dialog>
    <div class="transaction-dialog">
      <DialogHeader @on-close="toggleTransactionDialog(false)" />
      <div class="transaction-dialog-container">
        <PendingTransaction v-if="state === 'Pending'" />

        <Error :message="'Transaction failed'" v-if="state === 'Failed'" />
        <Success
          :message="'Transaction submitted'"
          :transaction="transaction"
          v-if="state === 'Success'"
        />
        <div
          class="transaction-dialog-container-actions"
          v-if="state !== 'Pending'"
        >
          <Button overlay @click="toggleTransactionDialog(false)"
            >Dismiss</Button
          >
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script>
import DialogHeader from '@/components/common/DialogHeader.vue'
import Dialog from '@/components/common/Dialog.vue'
import Button from '@/components/common/Button.vue'
import { useDialogs } from '@/hooks/useDialogs'
import { useTransactionDialog } from '@/hooks/transactionDialog'
import Error from './common/Error.vue'
import Success from './common/Success.vue'
import PendingTransaction from './common/PendingTransaction.vue'

export default {
  components: {
    Dialog,
    Button,
    DialogHeader,
    Error,
    Success,
    PendingTransaction
  },
  data: function () {
    return {
      error: 'Transaction rejected'
    }
  },
  mounted() {
    setTimeout(() => {
      this.state = 'Success'
    }, 5000)
  },

  setup() {
    const { toggleTransactionDialog } = useDialogs()
    const { state, transaction } = useTransactionDialog()
    return {
      state,
      transaction,
      toggleTransactionDialog
    }
  }
}
</script>
<style lang="scss" scoped>
.transaction {
  &-dialog {
    padding: 16px 24px 24px 24px;
    width: 400px;
    box-sizing: border-box;
    &-container {
      padding: 0 16px 0 16px;
      &-message {
        margin-top: 35px;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
      }
      &-actions {
        margin-top: 40px;
        > * {
          height: 44px;
          width: 100%;
        }
      }
    }
  }
}
</style>
