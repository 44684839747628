<template>
  <div class="pending-transaction-container">
    <Loader />
    <div class="pending-transaction-container-title">
      Wating For Confirmation
    </div>
    <!-- <div class="pending-transaction-container-exchange">{{ exchange }}</div> -->
    <div class="pending-transaction-container-confirm">
      Confirm this trasnation in your wallet
    </div>
  </div>
</template>
<script>
import Loader from './Loader.vue'
export default {
  components: {
    Loader
  },
  props: {
    message: { type: String, default: '' },
    transaction: { type: String, default: '' },
    from: { type: String, default: '0.0311323 ETH' },
    to: { type: String, default: '93.4915 BTC' }
  },
  computed: {
    exchange: function () {
      return `Exchanging ${this.from} for ${this.to}`
    }
  }
}
</script>
<style lang="scss" scoped>
.pending-transaction {
  &-container {
    padding: 0 -4px 0 -4px;
    &-title {
      margin-top: 36px;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      letter-spacing: -0.5px;
      color: #ffffff;
    }
    &-exchange {
      margin-top: 35px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
    }
    &-confirm {
      margin-top: 8px;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: rgba(256, 256, 256, 0.6);
    }
  }
}
</style>
