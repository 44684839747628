import { PROTECTED_ENVIRONMENTS, NODE_ENV } from '@/config/constants'

const timers = new Map()
export const debounce = (func, waitMilliseconds, id) => {
  const running = timers.get(id)
  if (running) {
    clearTimeout(running)
  }
  const timeout = setTimeout(() => {
    func()
  }, waitMilliseconds)
  timers.set(id, timeout)
}

export const INFO = 'info'
export const ERROR = 'error'

export const addressUrl = (address) => {
  return `${process.env.VUE_APP_BLOCK_EXPLORER_URL}/address/${address}`
}

export const transactionUrl = (transaction) => {
  return `${process.env.VUE_APP_BLOCK_EXPLORER_URL}/tx/${transaction}`
}

export const tokenUrl = (token) => {
  return `${process.env.VUE_APP_BLOCK_EXPLORER_URL}/token/${token}`
}

export const isProtectedEnvironment = PROTECTED_ENVIRONMENTS.includes(NODE_ENV)

export const isMainnet = NODE_ENV === 'mainnet'
