import { computed, ref } from 'vue'
import { Colors } from '../globals'
import { fetch24hStats } from '@/api'
import { parsePriceData, parseVolumeData } from '../parsers/chart'
import { format, sub } from 'date-fns'

export const DEFAULT_START_DATE = Number(
  format(sub(new Date(), { hours: 24 }), 't')
)

export const DEFAULT_END_DATE = Math.floor(Date.now() / 1000)

const latestPriceRef = ref(0)
const prevPriceRef = ref(0)
const last24hPriceRef = ref(null)

export const updateLast24hPrice = async (symbol) => {
  const currentTimestamp = Math.floor(Date.now() / 1000)
  const startTime = Number(format(sub(new Date(), { hours: 24 }), 't'))
  const { prices, volumes } = await fetch24hData(
    symbol,
    startTime,
    currentTimestamp
  )
  if (prices && volumes) {
    last24hPriceRef.value = {
      high: Math.max.apply(
        Math,
        prices.map(function (o) {
          return o.high
        })
      ),
      low: Math.min.apply(
        Math,
        prices.map(function (o) {
          return o.low
        })
      ),
      volume: volumes.map((o) => o.value).reduce((acc, i) => acc + i, 0)
    }
  } else {
    last24hPriceRef.value = null
  }
}

const fetch24hData = async (symbol, startTime, endTime) => {
  const data = await fetch24hStats(symbol, startTime, endTime)
  const prices = data.map((entry) => parsePriceData(entry))
  const volumes = data
    .map((entry) => parseVolumeData(entry))
    .map((volume, idx, arr) => {
      if (idx > 0) {
        const prev = arr[idx - 1]
        return {
          ...volume,
          color: prev.value > volume.value ? Colors.RED_60 : Colors.GREEN_60
        }
      }
      return {
        ...volume,
        color: Colors.GREEN_60
      }
    })
  return {
    prices,
    volumes
  }
}

export const usePriceData = () => {
  const last24hPrice = computed(() => {
    return last24hPriceRef.value
  })
  const lastPrice = computed(() => {
    return {
      val: latestPriceRef.value,
      up: latestPriceRef.value - prevPriceRef.value > 0
    }
  })
  return {
    fetch24hData,
    lastPrice,
    last24hPrice
  }
}
